import React, { useState, useEffect } from "react";
import { useGetClientListQuery, } from "../../../../store/api/reception/manageClient";
import TableRow from "../TableRow";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { saveAs } from "file-saver";
import ReceptionLayout from "../../ReceptionLayout";
import LoadingErrorComponent from "../../../../components/LoadingErrorComponent/LoadingErrorComponent";
import PaginationComp from "../../../../components/Pagination/paginationComp";
import { useSelector } from "react-redux";

const ClientLookUpPage = () => {
  const navigate = useNavigate()
  const { user } = useSelector((store) => {
    return store.userSlice;
  });
  const [paginationDetails, setPaginationDetails] = useState({})
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort_by, setSortBy] = useState(searchParams.get("sort_by") || "");
  const [searchBy, setSearchBy] = useState(searchParams.get("searchBy") || "");
  const [searchKeyword, setSearchKeyword] = useState(searchParams.get("searchKeyword") || "");
  const [page, setPageNo] = useState(searchParams.get("page") || 1);
  const clientList = useGetClientListQuery({
    sort_by: sort_by,
    searchKeyword: searchKeyword,
    page: page,
    per_page: 10,
    searchBy: searchBy,
  });

  const paginationDataString = clientList?.data?.message.split(": ")[1];
  useEffect(() => {
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString?.replace(/=/g, ':').replace(/(\w+)/g, '"$1"'));
      setPaginationDetails({
        CurrentPage: Math.ceil(paginationData.CurrentPage),
        ItemsPerPage: Math.ceil(paginationData.ItemsPerPage),
        TotalCount: Math.ceil(paginationData.TotalCount),
        TotalPages: Math.ceil(paginationData.TotalPages),
      })
    }
  }, [clientList?.data?.message])

  // FUNCTIONS TO HANDLE DATA SEARCH AND FILTER
  const changePage = (page) => {
    setPageNo(page);
  };
  const handleSortBy = (elem) => {
    setSortBy(elem.target.value);
  };
  const handleSearchBy = (elem) => {
    setSearchBy(elem.target.value);
  };
  const handleSearchKeyword = (elem) => {
    setSearchKeyword(elem.target.value);
  };

  useEffect(() => {
    let params = { page };
    if (sort_by) {
      params.sort_by = sort_by;
    }
    if (searchBy) {
      params.searchBy = searchBy;
    }
    if (searchKeyword) {
      params.searchKeyword = searchKeyword;
    }
    setSearchParams(params);
  }, [page, sort_by, searchBy, searchKeyword, setSearchParams]);

  //download list of clients in excel 
  const [DownloadXLState, setDownloadXLState] = useState(false)
  const downloadDataAsExcel = async () => {
    setDownloadXLState(true)
    const queryString = new URLSearchParams({
      page: 1,
      per_page: paginationDetails.TotalCount,
    }).toString();

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/Client/GetClients?${queryString}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      return response.json()
    }).then((response) => {
      const userXLS = response.data?.map((elem, i) => {
        return {
          "sr_no": i + 1,
          "clientID": elem.clientID,
          "barcode_cdc_number": elem.barcode_cdc_number,
          "barcode_cdf_number": elem.barcode_cdf_number,
          "capture_date": elem.capture_date,
          "folder_number": elem.folder_number,
          "Fullname": elem.firstName,
          "dateOfBirth": elem.dateOfBirth,
          "gender": elem.gender,
          "nationality": elem.nationality,
          "identityVerification": elem.identityVerification,
          "identityVerificationNumber": elem.identityVerificationNumber,
          "email": elem.email,
          "contactNumber": elem.contactNumber,
          "nextOfKinContactNumber": elem.nextOfKinContactNumber,
          "relationshipWithClient": elem.relationshipWithClient,
          "booking_id": elem.booking_id,
          "social_mobilizer": elem.social_mobilizer,
          "status": elem.status
        }
      })
      // Convert data to CSV format
      const csvData = convertToCSV(userXLS);
      // Create a Blob containing the CSV data
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      // Trigger download
      saveAs(blob, `manage_client_lookup.csv`);
      setDownloadXLState(false)
    }).catch(err => {
      console.log('somthing went wrong !!')
      setDownloadXLState(false)
    })
  };
  const convertToCSV = (dataArray) => {
    // Convert array of objects to CSV string
    const header = Object.keys(dataArray[0]).join(",") + "\n";
    const body = dataArray?.map((obj) => Object.values(obj).join(",")).join("\n");
    return header + body;
  };

  return (
    <>
      <ReceptionLayout className="content-wrapper">
        <div className="container-full">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="me-auto">
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">
                          <i className="mdi mdi-home-outline" />
                        </Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Manage Client Record
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Client Lookup
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <section className="content">
            <div className="box">
              <div className="box-header with-border">
                <div className="box-in">
                  {user.role === "Super User/Admin" ?
                    <button onClick={() => navigate("/dashboard")} className='btn btn-danger-light'>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-left"
                      >
                        <line x1={19} y1={12} x2={5} y2={12} />
                        <polyline points="12 19 5 12 12 5" />
                      </svg>
                    </button> :
                    <button onClick={() => navigate("/reception/dashboard")} className='btn btn-danger-light'>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-left"
                      >
                        <line x1={19} y1={12} x2={5} y2={12} />
                        <polyline points="12 19 5 12 12 5" />
                      </svg>
                    </button>
                  }
                  <h4 className="box-title me-auto ms-4">
                    <strong>Client Lookup</strong>
                  </h4>
                  <div className="box-right">
                    {user.role === 'Super User/Admin' && <button
                      type="button"
                      className="btn btn-primary btn-export mx-3"
                      onClick={() => navigate("/reception/client-lookup/deleted-clients")}
                    >
                      Deleted Clients
                    </button>}
                    {clientList.isSuccess && (
                      <button
                        type="button"
                        className="btn btn-primary btn-export"
                        onClick={downloadDataAsExcel}
                        disabled={DownloadXLState}
                      >
                        Export to XLS
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="box-body">
                <div className="row mb-3 g-2 justify-content-between">
                  <div className="col-sm-12 col-md-6 col-xl-3">
                    <select
                      className="form-select mb-xl-0 mb-2"
                      aria-label="sort_by"
                      value={sort_by}
                      onChange={handleSortBy}
                    >
                      <option value="">Sort by</option>
                      <option value="first_to_last">First to Last</option>
                      <option value="last_to_first"> Last to First</option>
                    </select>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="table-top-search">
                      <select
                        className="form-select"
                        aria-label="search by"
                        onChange={handleSearchBy}
                        value={searchBy}
                      >
                        <option value="">Search by</option>
                        <option value="barcode">Barcode</option>
                        <option value="folder_number">Folder number</option>
                        <option value="client_name">Client Name</option>
                        <option value="identity_number">Identity Number</option>
                        <option value="status">Status</option>
                      </select>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        aria-controls="search"
                        value={searchKeyword}
                        onChange={handleSearchKeyword}
                      />
                    </div>
                  </div>
                </div>
                {clientList.isError && <LoadingErrorComponent error />}
                {clientList.isLoading && <LoadingErrorComponent loading />}
                {clientList.isSuccess && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive rounded card-table">
                          {
                            <table className="table border-no" id="example1" style={{ width: "100%" }}>
                              <thead>
                                <tr>
                                  <th>Sr.</th>
                                  <th>Capture Date</th>
                                  <th>Batch Number</th>
                                  <th>CDCC Barcode No.</th>
                                  <th>Folder number</th>
                                  <th>Client Name</th>
                                  <th>Nationality</th>
                                  <th>Verification ID</th>
                                  <th>MMC Done</th>
                                  <th>Status</th>
                                  <th>Action Items</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  clientList?.data?.data.length > 0 ? (
                                    clientList?.data?.data?.map((elem, i) => {
                                      return (
                                        <TableRow
                                          key={i}
                                          data={elem}
                                          serialNumber={i}
                                        />
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={8}>
                                        <div className="text-center fw-bold py-4">No Data Found!!</div>
                                      </td>
                                    </tr>
                                  )
                                }
                              </tbody>
                            </table>
                          }
                        </div>
                        {clientList?.data?.data.length > 0 &&
                          <div className="d-flex justify-content-between mt-3">
                            {
                              <div>
                                {`Showing ${page - 1}1 to ${paginationDetails.TotalCount > page * 10 ? page * 10 : paginationDetails.TotalCount}  of ${paginationDetails.TotalCount} entries`}
                              </div>
                            }
                            <div>
                              <PaginationComp currentPage={paginationDetails.CurrentPage} totalPages={paginationDetails.TotalPages} onPageChange={changePage} />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
      </ReceptionLayout>
    </>
  );
};

export default ClientLookUpPage;
