import React from 'react';
import ReceptionLayout from '../../Reception/ReceptionLayout';
import { Link } from 'react-router-dom';
import { useGetDashboardQuery } from '../../../store/api/dashboardApi';


const AdminDashboard = () => {
    const dashboard = useGetDashboardQuery()
    const dashboardData = (dashboard?.data?.data)

    return (
        <ReceptionLayout>
            <div className="container-full">
                <div className="content-header">
                    <div className="d-flex align-items-center">
                        <div className="me-auto">
                            <div className="d-inline-block align-items-center">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#"><i className="mdi mdi-home-outline"></i></a>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">Dashboard</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>

                    </div>
                </div>
                <section className="content">
                    <div className="row g-3">
                        {/* system user */}
                        <div className='col-lg-3 col-12'>
                            <div className="scheduleBox">
                                <div className="scheduleBoxTop">
                                    <div>System Users</div>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/user-management/manage-user/existing-user'} className='scheduleBoxTitle'>
                                        Total System Users
                                    </Link>
                                    <Link to={'/user-management/manage-user/existing-user'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.systemUsers?.totalSystemUsers || 0}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* client record */}
                        <div className='col-lg-3 col-12'>
                            <div className="scheduleBox">
                                <div className="scheduleBoxTop">
                                    <div>Client Records</div>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/reception/client-lookup'} className='scheduleBoxTitle'>
                                        Total Clients
                                    </Link>
                                    <Link to={'/reception/client-lookup'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.clientRecords?.totalClients || 0}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* batch */}
                        <div className='col-lg-3 col-12'>
                            <div className="scheduleBox">
                                <div className="scheduleBoxTop">
                                    <div>Batches</div>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/reception/batch-management'} className='scheduleBoxTitle'>
                                        In Progress
                                    </Link>
                                    <Link to={'/reception/batch-management'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.batches?.inProgress || 0}
                                    </Link>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/reception/batch-management'} className='scheduleBoxTitle'>
                                        Completed
                                    </Link>
                                    <Link to={'/reception/batch-management'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.batches?.completed || 0}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* Follow-up progres */}
                        <div className='col-lg-3 col-12'>
                            <div className="scheduleBox">
                                <div className="scheduleBoxTop">
                                    {/* <i className='fa fa-clock-o'></i> */}
                                    <div>Follow-up Progress </div>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/reception/follow-up/schedule-lookup'} className='scheduleBoxTitle'>
                                        48 hrs Due
                                    </Link>
                                    <Link to={'/reception/follow-up/schedule-lookup'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.followUpProgress?.due48hrs || 0}
                                    </Link>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/reception/follow-up/schedule-lookup'} className='scheduleBoxTitle'>
                                        7 days Due
                                    </Link>
                                    <Link to={'/reception/follow-up/schedule-lookup'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.followUpProgress?.due7days || 0}
                                    </Link>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/reception/follow-up/schedule-lookup'} className='scheduleBoxTitle'>
                                        14 days Due
                                    </Link>
                                    <Link to={'/reception/follow-up/schedule-lookup'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.followUpProgress?.due14days || 0}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* QA1 */}
                        <div className='col-lg-3 col-12'>
                            <div className="scheduleBox">
                                <div className="scheduleBoxTop">
                                    {/* <i className='fa fa-clock-o'></i> */}
                                    <div>QA 1</div>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/qa-1/manage-batch-validation'} className='scheduleBoxTitle'>
                                        In Progress
                                    </Link>
                                    <Link to={'/qa-1/manage-batch-validation'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.qA1?.inProgress || 0}
                                    </Link>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/qa-1/manage-batch-validation'} className='scheduleBoxTitle'>
                                        Completed
                                    </Link>
                                    <Link to={'/qa-1/manage-batch-validation'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.qA1?.completed || 0}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* QA2 */}
                        <div className='col-lg-3 col-12'>
                            <div className="scheduleBox">
                                <div className="scheduleBoxTop">
                                    {/* <i className='fa fa-clock-o'></i> */}
                                    <div>QA 2</div>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/qa-2/manage-batch-validation'} className='scheduleBoxTitle'>
                                        In Progress
                                    </Link>
                                    <Link to={'/qa-2/manage-batch-validation'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.qA2?.inProgress || 0}
                                    </Link>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/qa-2/manage-batch-validation'} className='scheduleBoxTitle'>
                                        Completed
                                    </Link>
                                    <Link to={'/qa-2/manage-batch-validation'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.qA2?.completed || 0}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* Billed */}
                        <div className='col-lg-3 col-12'>
                            <div className="scheduleBox">
                                <div className="scheduleBoxTop">
                                    {/* <i className='fa fa-clock-o'></i> */}
                                    <div>Billed</div>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/reception/manage-billing/billing-summery'} className='scheduleBoxTitle'>
                                        In Progress
                                    </Link>
                                    <Link to={'/reception/manage-billing/billing-summery'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.billed?.inProgress || 0}
                                    </Link>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/reception/manage-billing/billing-summery'} className='scheduleBoxTitle'>
                                        Submitted
                                    </Link>
                                    <Link to={'/reception/manage-billing/billing-summery'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.billed?.submitted || 0}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* Payables */}
                        <div className='col-lg-3 col-12'>
                            <div className="scheduleBox">
                                <div className="scheduleBoxTop">
                                    {/* <i className='fa fa-clock-o'></i> */}
                                    <div>Payables</div>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/billing/billingInvoiceApproval'} className='scheduleBoxTitle'>
                                        Approved
                                    </Link>
                                    <Link to={'/billing/billingInvoiceApproval'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.payables?.approved || 0}
                                    </Link>
                                </div>
                                <div className="scheduleLabel total">
                                    <Link to={'/billing/billingInvoiceApproval'} className='scheduleBoxTitle'>
                                        Rejected
                                    </Link>
                                    <Link to={'/billing/billingInvoiceApproval'} className='scheduleBoxTitleCount'>
                                        {dashboardData?.payables?.rejected || 0}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ReceptionLayout>
    )
}

export default AdminDashboard