import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useUserStatusChangeMutation } from '../../../store/api/userManagement/manageUserApi'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

const ManageUserTableRow = ({ data, index }) => {
    // console.log(data)
    const [statusChange] = useUserStatusChangeMutation()
    const changeToActive = (id) => {
        // console.log(id)
        let data = {
            user_Id: id,
            status: "active"
        }
        statusChange(data).then((res) => {
            console.log(res)
            if (res?.data?.status === 1) {
                toast.success("User Activated !!", { duration: 7000 })
            }
        })
    }
    return (
        <tr className="hover-primary">
            <td>{index + 1}</td>
            <td>{data.firstname} {data.last_Name}</td>
            <td>
                <div>{data.contactNo}</div>
                <div>{data.email}</div>
            </td>
            <td>{data.postRole}</td>
            <td>{data.nationality}</td>
            <td>
                <span className={data.user_Status.toLowerCase() === "pending" ? 'badge badge-danger-light' : 'badge badge-success-light'}>{data.user_Status.toLowerCase() === "pending" ? "Pending" : "Active"}</span>
            </td>
            <td>
                <Dropdown>
                    <Dropdown.Toggle variant="Secondary" >
                        <i className="fa fa-ellipsis-h"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`/user-management/manage-user/view-and-edit/${data.user_Id}`}>View & Edit</Dropdown.Item>
                        <Dropdown.Item>
                            <span onClick={() => changeToActive(data.user_Id)}>Activate User</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    )
}

export default ManageUserTableRow
