import React from 'react'
import ReceptionLayout from '../../ReceptionLayout'
import { Link, useNavigate } from 'react-router-dom'
import { useFollowUpStatusQuery } from '../../../../store/api/reception/followupApi'
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent'
import { useSelector } from 'react-redux'

const ScheduleLookup = () => {
  const { user } = useSelector((store) => {
    return store.userSlice;
  });
  const FollowUpStatusApis = useFollowUpStatusQuery()
  const FollowUpStatus = FollowUpStatusApis.data
  const navigate = useNavigate()
  return (
    <ReceptionLayout>
      <div className="container-full">
        <div className="content-header">
          <div className="d-flex align-items-center">
            <div className="me-auto">
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/"><i className="mdi mdi-home-outline"></i></Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">Follow-up Form</li>
                    <li className="breadcrumb-item active" aria-current="page">Follow-up Progress</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="box">
            <div className="box-header with-border">
              <div className="box-in">
                {user.role === "Super User/Admin" ?
                  <button onClick={() => navigate("/dashboard")} className='btn btn-danger-light'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-left"
                    >
                      <line x1={19} y1={12} x2={5} y2={12} />
                      <polyline points="12 19 5 12 12 5" />
                    </svg>
                  </button> :
                  <button onClick={() => navigate("/reception/dashboard")} className='btn btn-danger-light'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-left"
                    >
                      <line x1={19} y1={12} x2={5} y2={12} />
                      <polyline points="12 19 5 12 12 5" />
                    </svg>
                  </button>
                }
                <h4 className="box-title me-auto ms-4">

                  <strong>Follow-up Progress</strong>
                </h4>
              </div>
            </div>
            <div className="box-body">
              {FollowUpStatusApis.isError && <LoadingErrorComponent error />}
              {FollowUpStatusApis.isLoading && <LoadingErrorComponent loading />}
              {
                FollowUpStatusApis.isSuccess && (
                  <div className='row gy-3'>
                    <div className='col-xl-4 col-md-4 col-sm-6 col-12'>
                      <div className="scheduleBox">
                        <div className="scheduleBoxTop">
                          <i className='fa fa-clock-o'></i>
                          <div>48 Hours</div>
                        </div>
                        <div className="scheduleLabel total">
                          <Link to={'fourtyeight-hours/total'} className='scheduleBoxTitle'>
                            Total
                          </Link>
                          <Link to={'fourtyeight-hours/total'} className='scheduleBoxTitleCount'>
                            {FollowUpStatus.totalDue}
                          </Link>
                        </div>
                        <div className="scheduleLabel overdue">
                          <Link to={'overdue?milestone=48Hours'} className='scheduleBoxTitle'>
                            Overdue
                          </Link>
                          <Link to={'overdue?milestone=48Hours'} className='scheduleBoxTitleCount'>
                            {FollowUpStatus.overdue48Hours}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-4 col-md-4 col-sm-6 col-12'>
                      <div className="scheduleBox">
                        <div className="scheduleBoxTop">
                          <i className='fa fa-clock-o'></i>
                          <div>7 Days</div>
                        </div>
                        <div className="scheduleLabel total">
                          <Link to={'saven-days/total'} className='scheduleBoxTitle'>
                            Total
                          </Link>
                          <Link to={'saven-days/total'} className='scheduleBoxTitleCount'>
                            {FollowUpStatus.totalDue}
                          </Link>
                        </div>
                        <div className="scheduleLabel due">
                          <Link to={'due?milestone=7Days'} className='scheduleBoxTitle'>
                            Due in next 48 hours
                          </Link>
                          <Link to={'due?milestone=7Days'} className='scheduleBoxTitleCount'>
                            {FollowUpStatus.dueFlag7Days}
                          </Link>
                        </div>
                        <div className="scheduleLabel overdue">
                          <Link to={'overdue?milestone=7Days'} className='scheduleBoxTitle'>
                            Overdue
                          </Link>
                          <Link to={'overdue?milestone=7Days'} className='scheduleBoxTitleCount'>
                            {FollowUpStatus.overdue7Days}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-4 col-md-4 col-sm-6 col-12'>
                      <div className="scheduleBox">
                        <div className="scheduleBoxTop">
                          <i className='fa fa-clock-o'></i>
                          <div>14 Days</div>
                        </div>
                        <div className="scheduleLabel total">
                          <Link to={'fourteen-days/total'} className='scheduleBoxTitle'>
                            Total
                          </Link>
                          <Link to={'fourteen-days/total'} className='scheduleBoxTitleCount'>
                            {FollowUpStatus.totalDue}
                          </Link>
                        </div>
                        <div className="scheduleLabel due">
                          <Link to={'due?milestone=14Days'} className='scheduleBoxTitle'>
                            Due in next 48 hours
                          </Link>
                          <Link to={'due?milestone=14Days'} className='scheduleBoxTitleCount'>
                            {FollowUpStatus.dueFlag14Days}
                          </Link>
                        </div>
                        <div className="scheduleLabel overdue">
                          <Link to={'overdue?milestone=14Days'} className='scheduleBoxTitle'>
                            Overdue
                          </Link>
                          <Link to={'overdue?milestone=14Days'} className='scheduleBoxTitleCount'>
                            {FollowUpStatus.overdue14Days}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </section>
      </div>
    </ReceptionLayout>
  )
}

export default ScheduleLookup