import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) || {},
  },
  reducers: {
    addUser(state, action) {
      const data = {
        ...action.payload
      }
      state.user = data;
      localStorage.setItem("user", JSON.stringify(data));
    },
    logOut(state, action) {
      state.user = {};
      localStorage.removeItem("user");
    },
  },
});

export const { addUser, logOut } = userSlice.actions;
export default userSlice.reducer;

