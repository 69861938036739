import React from 'react'
import BillingLayout from '../../BillingLayout'
import { Link, useNavigate } from 'react-router-dom'
import { useBatchSummeryQuery } from '../../../../store/api/billing/billingApi'
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent'

const AmountDue = () => {
    const navigate = useNavigate()
    const AmountDueBatchList = useBatchSummeryQuery()
    return (
        <BillingLayout>
            <div class="content-header">
                <div class="d-flex align-items-center">
                    <div class="me-auto">
                        <div class="d-inline-block align-items-center">
                            <nav>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="mdi mdi-home-outline"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" aria-current="page">Billing & Invoices</li>
                                    <li class="breadcrumb-item active" aria-current="page">Amounts Due from Statement</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="box">
                    <div class="box-header with-border">
                        <div class="box-in">
                            <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">

                                <strong>Amount Due from Statement</strong>
                            </h4>
                        </div>
                    </div>
                    {AmountDueBatchList.isError && <LoadingErrorComponent error />}
                    {AmountDueBatchList.isLoading && <LoadingErrorComponent loading />}
                    {AmountDueBatchList.isSuccess && <div class="box-body">
                        <div class="box-progress">
                            <div>
                                <div class="row g-3">
                                    {
                                        AmountDueBatchList?.data?.data.length > 0 && AmountDueBatchList?.data?.data !== null ?
                                            (
                                                AmountDueBatchList?.data?.data.map((el, i) => (
                                                    <div class="col-xl-4 col-md-4 col-sm-6 col-12 ">
                                                        <Link to={`/billing/dashboard/amountDue/batch/${el.batchNo}`}>
                                                            <div class="scheduleBox">
                                                                <div class="scheduleBoxTop">
                                                                    <i class="fa fa-folder" style={{ color: '#FFC107' }}></i>
                                                                    <div>{el.batchNo}</div>
                                                                </div>
                                                                <div class="scheduleLabel total">
                                                                    <span class="scheduleBoxTitle">Total Records</span>
                                                                    <span class="scheduleBoxTitleCount">{el.noOfClientsInBatch}</span>
                                                                </div>
                                                                <div class="scheduleLabel overdue">
                                                                    <span class="scheduleBoxTitle text-success" >Valid</span>
                                                                    <span class="scheduleBoxTitleCount text-success" >
                                                                        {el.validClients}
                                                                    </span>
                                                                </div>
                                                                <div class="scheduleLabel overdue">
                                                                    <span class="scheduleBoxTitle text-danger" >Invalid</span>
                                                                    <span class="scheduleBoxTitleCount text-danger" >
                                                                        {el.invalidClients}
                                                                    </span>
                                                                </div>
                                                                <div class="scheduleLabel overdue">
                                                                    <span class="scheduleBoxTitle" >Total Amount</span>
                                                                    <span class="scheduleBoxTitleCount" >ZAR {el.totalAmount}</span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                            )
                                            :
                                            <div className='col-12 text-center'> No Data Found </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </section>
        </BillingLayout>
    )
}

export default AmountDue
