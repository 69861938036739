import React from 'react';
import { BsFileEarmarkMedical } from "react-icons/bs";
import "./sidebar.css"
import SidebarItem from './SidebarItem';
import { useSelector } from 'react-redux';

const Sidebar = () => {
    const { user } = useSelector((store) => {
        return store.userSlice;
    })

    const adminMenuLinks = [
        {
            title: 'Dashboard',
            icon: <BsFileEarmarkMedical />,
            link: '/dashboard'
        },
        {
            title: 'System Users',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Data Capture/Receptionist',
                    link: '/user-management/data-capture-receptionist',
                },
                {
                    title: 'Data Validation (QA1)',
                    link: '/user-management/data-validation-QA-one',
                },
                {
                    title: 'Data Validation (QA2)',
                    link: '/user-management/data-validation-QA-two',
                },
                {
                    title: 'Billing/Finance (Approver)',
                    link: '/user-management/billing-finance-approver',
                },
                {
                    title: 'Super User/Admin',
                    link: '/user-management/super-user-admin',
                }
            ]
        },
        {
            title: 'Manage Users',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Add Users',
                    link: '/user-management/manage-user/add-user',
                },
                {
                    title: 'Pending User Requests',
                    link: '/user-management/manage-user/pending-user',
                },
                {
                    title: 'Existing Users',
                    link: '/user-management/manage-user/existing-user',
                }
            ]
        },
        {
            title: 'Manage Client Record',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Add Client',
                    link: '/reception/add-client',
                },
                {
                    title: 'Client Look up',
                    link: '/reception/client-lookup',
                },
                {
                    title: 'Bulk Upload',
                    link: '/reception/bulk-upload',
                }
            ]
        },
        {
            title: 'Batch Management',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Create Batch',
                    link: '/reception/batch-management/create',
                },
                {
                    title: 'Manage Batches',
                    link: '/reception/batch-management',
                }
            ]
        },
        {
            title: 'Follow up Form',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Add Client',
                    link: '/reception/follow-up/add-client',
                },
                {
                    title: 'Bulk Upload',
                    link: '/reception/follow-up/bulk-upload',
                },
                {
                    title: 'Follow-up Progress',
                    link: '/reception/follow-up/schedule-lookup',
                },
                {
                    title: 'Follow-up Lookup',
                    link: '/reception/follow-up/lookup',
                }
            ]
        },
        {
            title: 'Quality Assurance',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'QA 1',
                    link: '/qa-1/manage-batch-validation',
                },
                {
                    title: 'QA 2',
                    link: '/qa-2/manage-batch-validation',
                }
            ]
        },
        {
            title: 'Facility Billing',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Billing Summary',
                    link: '/reception/manage-billing/billing-summery',
                },
                {
                    title: 'Approved & Rejected Payments',
                    link: '/reception/manage-billing/approved-rejected-payment',
                }
            ]
        },
        {
            title: 'Billing & Invoices',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Billing Dashboard',
                    link: '/billing/dashboard',
                },
                {
                    title: 'Billing & Invoice Approvals',
                    link: '/billing/billingInvoiceApproval',
                }
            ]
        },
        {
            title: 'Help & Resources',
            icon: <BsFileEarmarkMedical />,
            link: '/help-resource'
        }
    ]

    const mmcMenuLinks = [
        {
            title: 'Dashboard',
            icon: <BsFileEarmarkMedical />,
            link: '/reception/dashboard'
        },
        {
            title: 'Manage Client Record',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Add Client',
                    link: '/reception/add-client',
                },
                {
                    title: 'Client Look up',
                    link: '/reception/client-lookup',
                },
                {
                    title: 'Bulk Upload',
                    link: '/reception/bulk-upload',
                }
            ]
        },
        {
            title: 'Batch Management',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Create Batch',
                    link: '/reception/batch-management/create',
                },
                {
                    title: 'Manage Batches',
                    link: '/reception/batch-management',
                }
            ]
        },
        {
            title: 'Follow up Form',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Add Client',
                    link: '/reception/follow-up/add-client',
                },
                {
                    title: 'Bulk Upload',
                    link: '/reception/follow-up/bulk-upload',
                },
                {
                    title: 'Follow-up Progress',
                    link: 'reception/follow-up/schedule-lookup',
                },
                {
                    title: 'Follow-up Lookup',
                    link: '/reception/follow-up/lookup',
                }
            ]
        },
        {
            title: 'Manage Billing',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Billing Summary',
                    link: '/reception/manage-billing/billing-summery',
                },
                {
                    title: 'Approved & Rejected Payments',
                    link: 'reception/manage-billing/approved-rejected-payment',
                }
            ]
        },
        {
            title: 'Help & Resources',
            icon: <BsFileEarmarkMedical />,
            link: '/help-resource'
        },
    ]

    const manageUserMenuLinks = [
        {
            title: 'Dashboard',
            icon: <BsFileEarmarkMedical />,
            link: '/reception/dashboard'
        },
        {
            title: 'System Users',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Data Capture/Receptionist',
                    link: '/user-management/data-capture-receptionist',
                },
                {
                    title: 'Data Validation/Call Center Agent',
                    link: '/user-management/data-validation-call-center',
                },
                {
                    title: 'Facility Administrator/Doctor',
                    link: '/user-management/facility-administrator-doctor',
                },
                {
                    title: 'MMC Supervisor/Manager',
                    link: '/user-management/mmc-supervisor-manager',
                },
                {
                    title: 'MMC Administrator',
                    link: '/user-management/mmc-administrator',
                },
                {
                    title: 'General Practitionars',
                    link: '/user-management/general-practitioners',
                }
            ]
        },
        {
            title: 'Manage Users',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Add Users',
                    link: '/user-management/manage-user/add-user',
                },
                {
                    title: 'Pending User Requests',
                    link: '/user-management/manage-user/pending-user',
                },
                {
                    title: 'Existing Users',
                    link: '/user-management/manage-user/existing-user',
                }
            ]
        },
        {
            title: 'Master Data',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Location',
                    link: '/user-management/master-data/location',
                },
                {
                    title: 'Facility',
                    link: '/user-management/master-data/facility',
                },
                {
                    title: 'Implementing Partners',
                    link: '/user-management/master-data/implementing-partners',
                }
            ]
        },
        {
            title: 'Help & Resources',
            icon: <BsFileEarmarkMedical />,
            link: '/help-resource'
        }
    ]

    const billingsMenuLinks = [
        {
            title: 'Billing Dashboard',
            icon: <BsFileEarmarkMedical />,
            link: '/billing/dashboard'
        },
        {
            title: 'Billing & invoice approvals',
            icon: <BsFileEarmarkMedical />,
            link: '/billing/billingInvoiceApproval'
        },
        {
            title: 'Help & Resources',
            icon: <BsFileEarmarkMedical />,
            link: '/help-resource'
        }
    ]

    const QAOneUserMenuLinks = [
        {
            title: 'Dashboard',
            icon: <BsFileEarmarkMedical />,
            link: '/qa-1/dashboard'
        },
        {
            title: 'Manage Batch validation',
            icon: <BsFileEarmarkMedical />,
            link: '/qa-1/manage-batch-validation'
        },
        {
            title: 'Help & Resources',
            icon: <BsFileEarmarkMedical />,
            link: '/qa-1/help-resource'
        }
    ]

    const QATwoUserMenuLinks = [
        {
            title: 'Dashboard',
            icon: <BsFileEarmarkMedical />,
            link: '/qa-2/dashboard'
        },
        {
            title: 'Manage Batch validation',
            icon: <BsFileEarmarkMedical />,
            link: '/qa-2/manage-batch-validation'
        },
        {
            title: 'Help & Resources',
            icon: <BsFileEarmarkMedical />,
            link: '/qa-2/help-resource'
        }
    ]

    return (
        <aside className="main-sidebar">
            <nav>
                <ul className="sidebar-menu tree" data-widget="tree">
                    {/* ADMIN MENU RENDERING */}
                    {
                        user.role === 'Super User/Admin' && (
                            adminMenuLinks?.map((elem, i) => {
                                return (
                                    <li key={i}>
                                        <SidebarItem data={elem} />
                                    </li>
                                )
                            })
                        )
                    }
                    {/* RECIPTIONEST MENU RENDERING */}
                    {
                        user.role === 'Data Capture/Receptionist' && (
                            mmcMenuLinks?.map((elem, i) => {
                                return (
                                    <li key={i}>
                                        <SidebarItem data={elem} />
                                    </li>
                                )
                            })
                        )
                    }

                    {/* MANAGE USER MENU RENDERING */}
                    {
                        user.role === 'manage-user' && (
                            manageUserMenuLinks?.map((elem, i) => {
                                return (
                                    <li key={i}>
                                        <SidebarItem data={elem} />
                                    </li>
                                )
                            })
                        )
                    }

                    {/* BILLING MENU RENDERING */}
                    {
                        user.role === 'Billing/Finance(Approver)' && (
                            billingsMenuLinks?.map((elem, i) => {
                                return (
                                    <li key={i}>
                                        <SidebarItem data={elem} />
                                    </li>
                                )
                            })
                        )
                    }

                    {/* QA ONE USER MENU RENDERING */}
                    {
                        user.role === 'Data Validation(QA1)' && (
                            QAOneUserMenuLinks?.map((elem, i) => {
                                return (
                                    <li key={i}>
                                        <SidebarItem data={elem} />
                                    </li>
                                )
                            })
                        )
                    }

                    {/* QA TWO USER USER MENU RENDERING */}
                    {
                        user.role === 'Data Validation(QA2)' && (
                            QATwoUserMenuLinks?.map((elem, i) => {
                                return (
                                    <li key={i}>
                                        <SidebarItem data={elem} />
                                    </li>
                                )
                            })
                        )
                    }
                </ul>
            </nav>
            <div className="footer">&copy; All Rights Reserved.</div>
        </aside>
    )
};

export default Sidebar;