import React, { useState } from 'react'
import logo from "../../assets/images/logo.png"
import avatar from "../../assets/images/avatar/avatar-1.png"
import { logOut } from "../../store/slice/userSlice";
import { useLogoutUserMutation } from '../../store/api/authApi';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import "./Header.css"

const Header = () => {
	const baseUrl = window.location.origin;
	const { user } = useSelector((store) => {
		return store.userSlice;
	});

	const [logoutUser] = useLogoutUserMutation();
	const dispatch = useDispatch();
	const logout = () => {
		window.location.href = `${baseUrl}/login`;
		dispatch(logOut());
		logoutUser(user.token);
	};

	const ToggleMenu = () => {
		document.body.classList.toggle("sidebar-open")
	}

	return (
		<header className="main-header">
			<div className="d-flex align-items-center logo-box justify-content-start bg-white">
				<div className="logo">
					<div className="logo-mini w-100">
						<span className="light-logo"><img src={logo} alt="logo" /></span>
						<span className="dark-logo"><img src={logo} alt="logo" /></span>
					</div>
				</div>
			</div>
			<nav className="navbar navbar-static-top">
				<div className="app-menu">
					<ul className="header-megamenu nav">
						<li className="btn-group nav-item">
							<div className="waves-effect waves-light nav-link push-btn btn-primary-light" data-toggle="push-menu" role="button" onClick={ToggleMenu}>
								<i className="icon-Menu"><span className="path1"></span><span className="path2"></span></i>
							</div>
						</li>
					</ul>
				</div>
				<div className="navbar-custom-menu r-side">
					<ul className="nav navbar-nav">
						{
							user && (
								<>
									<li className="dropdown user user-menu">
										<div className="waves-effect waves-light dropdown-toggle w-auto l-h-12" title="User">
											<div className="d-flex">
												<div className="text-end me-10">
													<p className="pt-5 fs-14 mb-0 fw-700 text-primary">{user.name}</p>
													<small className="fs-10 mb-0 text-uppercase text-mute">{user.role}</small>
												</div>
												<img src={avatar} className="avatar rounded-10 bg-primary-light" alt="" />
											</div>
										</div>
									</li>
									<li className="btn-group nav-item">
										<Link onClick={logout} title="Logout" className="waves-effect full-screen waves-light btn-danger-light">
											<i className="fa fa-sign-out"></i>
										</Link>
									</li>
								</>
							)
						}
					</ul>
				</div>
			</nav>
		</header>
	)
}

export default Header