export default function validateSAID(idNumber) {
   // Remove spaces and convert to string
   idNumber = idNumber?.replace(/\s/g, "");

   // Check if the ID number is 13 digits long
   if (idNumber?.length !== 13 || isNaN(idNumber)) {
      return { isValid: false };
   }

   // Apply the Luhn algorithm
   let sum = 0;
   for (let i = 0; i < 12; i++) {
      let digit = parseInt(idNumber.charAt(i));
      if (i % 2 === 0) {
         sum += digit;
      } else {
         sum += digit * 2 >= 10 ? digit * 2 - 9 : digit * 2;
      }
   }

   // Calculate the checksum
   let checksum = (10 - (sum % 10)) % 10;

   // Validate the checksum
   if (parseInt(idNumber.charAt(12)) !== checksum) {
      return { isValid: false };
   }

   // Extract date of birth and gender
   let dobYear = parseInt(idNumber.substring(0, 2));
   let currentYear = new Date().getFullYear() % 100; // Get last two digits of current year
   let century = dobYear < currentYear ? "20" : "19";
   let yearOfBirth = parseInt(century + idNumber.substring(0, 2));
   let monthOfBirth = parseInt(idNumber.substring(2, 4));
   const monthString = (monthOfBirth < 10 ? '0' : '') + monthOfBirth;
   let dayOfBirth = parseInt(idNumber.substring(4, 6));
   const dayString = (dayOfBirth < 10 ? '0' : '') + dayOfBirth;
   let DOB = (yearOfBirth + "-" + monthString + "-" + dayString)

   let genderCode = parseInt(idNumber.charAt(6));

   let gender = genderCode < 5 ? "female" : "male";

   // Calculate age in years
   let today = new Date();
   let age = today.getFullYear() - yearOfBirth;
   let month = today.getMonth() + 1;
   if (month < monthOfBirth || (month === monthOfBirth && today.getDate() < dayOfBirth)) {
      age--;
   }

   // Determine nationality
   var nationality =
      idNumber.substring(10, 11) === "0" ? "SA Citizen" : idNumber.substring(10, 11) === "1" ? "Permanent Resident" : { isValid: false };

   return {
      isValid: true,
      age: age,
      gender: gender,
      dateOfBirth: DOB,
      nationality: nationality,
   };
}
