import React, { useEffect, useState } from 'react'
import ReceptionLayout from '../../../../ReceptionLayout'
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LiaTimesSolid } from 'react-icons/lia';
import toast from 'react-hot-toast';
import { Form } from 'react-bootstrap';
import { useGetBatchStatementMutation, useGetByStatementRefMutation, useReSubmitInvoiceMutation } from '../../../../../../store/api/reception/billingApi';
import LoadingErrorComponent from '../../../../../../components/LoadingErrorComponent/LoadingErrorComponent';
import { formatDate } from '../../../../../../methods/convertDateFormat';
import ViewImageModal from '../../../../../../components/modal/ViewImageModal';
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const ResubmitInvoice = () => {
    const [reSubmitInvoice, response] = useReSubmitInvoiceMutation()
    const [BatchStatementDataMutation, BatchStatementData] = useGetByStatementRefMutation()

    const param = useParams()
    const navigate = useNavigate()

    const [formValidated, setFormValidated] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [invoiceNo, setInvoiceNo] = useState("")
    const [creditNo, setCreditNo] = useState("")
    const [amount, setAmount] = useState("")
    const [date, setDate] = useState("")
    const [statementRef, setStatementRef] = useState("")
    const [base64String, setBase64String] = useState("");
    const [creditNoteFile, setCreditNoteFile] = useState("")
    const [reason, setReason] = useState("")
    const [otherReason, setOtherReason] = useState("")

    const [invoiceModal, setInvoiceModal] = useState(false)
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file?.size < 2621440) {
            const reader = new FileReader();
            if (event.target.files[0].type.includes("/pdf")) {
                reader.onloadend = () => {
                    const base64String = reader.result.split(",")[1]; // Get base64 string excluding the data URL prefix
                    setBase64String("data:application/pdf;base64," + base64String);
                };
            } else if (event.target.files[0].type.includes("image/")) {
                reader.onload = () => {
                    const base64 = reader.result;
                    setBase64String(base64);
                };
            }
            reader.readAsDataURL(file);
        } else {
            toast.error("File too large. Max size: 2.5 MB. Please upload a smaller file.")
        }
    };

    const [creditNoteFileModal, setCreditNoteFileModal] = useState(false)
    const handleCreditNoteUpload = (event) => {
        const file = event.target.files[0];
        if (file?.size < 2621440) {
            const reader = new FileReader();
            if (event.target.files[0].type.includes("/pdf")) {
                reader.onloadend = () => {
                    const base64String = reader.result.split(",")[1]; // Get base64 string excluding the data URL prefix
                    setCreditNoteFile("data:application/pdf;base64," + base64String);
                };
            } else if (event.target.files[0].type.includes("image/")) {
                reader.onload = () => {
                    const base64 = reader.result;
                    setCreditNoteFile(base64);
                };
            }
            reader.readAsDataURL(file);
        } else {
            toast.error("File too large. Max size: 2.5 MB. Please upload a smaller file.")
        }
    };

    const handleChangeDate = (a, date) => {
        setDate(date)
    };

    let data = {
        stateRef: param?.statementRef?.toString(),
        resubInvoiceNo: invoiceNo,
        resubInvoiceDate: date,
        resubInvoiceFile: base64String,
        creditNoteNo: creditNo,
        creditNote: creditNoteFile
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === true) {
            reSubmitInvoice(data).then((res) => {
                if (res?.data?.status === 1) {
                    toast.success("Upload Invoice Successfully", { duration: 7000 })
                    navigate("/reception/manage-billing/billing-summery")
                }
                else if (res?.data?.status === 0) {
                    toast.error("Unable to resubmit invoice, please try again", { duration: 7000 })
                }
                else {
                    toast.error("Unable to resubmit invoice, please try again", { duration: 7000 })
                }
            })
        }
        setFormValidated(true);
    }

    useEffect(() => {
        BatchStatementDataMutation({ statementRef: param?.statementRef?.toString() }).then((res) => {
            if (res?.data?.status === 1) {
                setStatementRef(res?.data?.data?.stateRef)
                setAmount(res?.data?.data?.totalAmount)
                setReason(res?.data?.data?.reason)
                setOtherReason(res?.data?.data?.otherReason)
            }
        })
    }, [])

    return (
        <ReceptionLayout>
            <div className="content-header">
                <div className="d-flex align-items-center">
                    <div className="me-auto">
                        <div className="d-inline-block align-items-center">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="">
                                            <i className="mdi mdi-home-outline"></i>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Facility Billing</li>
                                    <li className="breadcrumb-item" aria-current="page">Approved & Rejected Payments</li>
                                    <li className="breadcrumb-item active" aria-current="page">Re-Upload Invoice</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                {/* <!-- Manage Batches --> */}
                <div className="box">
                    <div className="box-header with-border">
                        <div className="box-in">
                            <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">
                                <strong>Re-Upload Invoice</strong>
                            </h4>
                        </div>
                    </div>
                    <div className="box-body">
                        {BatchStatementData.isError && <LoadingErrorComponent error />}
                        {BatchStatementData.isLoading && <LoadingErrorComponent loading />}
                        {BatchStatementData.isSuccess && (
                            <>
                                <div className="form-check ps-0 mt-3 mb-3">
                                    <input className="filled-in chk-col-danger edit-form edit-form" type="checkbox" id="confirmCheck" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                                    <label className="form-check-label" htmlFor="confirmCheck">
                                        I hearby verify this statement to be correct and confirm it for billing.
                                    </label>
                                </div>
                                {
                                    isChecked && (
                                        <Form noValidate validated={formValidated}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="bg-danger-light p-4 mb-15 rounded-3">
                                                        <strong>Reason for rejection</strong>
                                                        <p className="mb-0">
                                                            {reason}{otherReason && (`: ${otherReason}`)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="invoiceNumber" className="form-label">
                                                                    Credit Note No.
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id='invoiceNumber'
                                                                    className="form-control"
                                                                    onChange={(e) => setCreditNo(e.target.value)}
                                                                    placeholder='Credit Note No.'
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="file" className="form-label">Upload Credit Note</label>
                                                                <input
                                                                    type="file"
                                                                    id='file'
                                                                    className="form-control"
                                                                    onChange={handleCreditNoteUpload}
                                                                    required
                                                                    accept="image/*,.pdf"
                                                                />
                                                            </div>
                                                            {creditNoteFile && (
                                                                <>
                                                                    <div className="timeline-fill-box overlay-timeline-fill-box" >
                                                                        <div className="overlay" onClick={() => setCreditNoteFileModal(true)}>Click here to view</div>
                                                                        {
                                                                            creditNoteFile.includes("image/") ? (
                                                                                <img src={creditNoteFile} alt="" />
                                                                            ) : (
                                                                                <iframe src={creditNoteFile} alt="" height={300} width={"100%"} title="pdf" />
                                                                            )
                                                                        }
                                                                        <LiaTimesSolid style={{ zIndex: '2' }} onClick={() => setCreditNoteFile("")} />
                                                                    </div>
                                                                    <ViewImageModal ModalTitle={"Consent Form"} modalState={creditNoteFileModal} modalSetState={setCreditNoteFileModal} modalUrl={creditNoteFile} />

                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="invoiceNumber" className="form-label">New Invoice No.</label>
                                                        <input
                                                            type="text"
                                                            id='invoiceNumber'
                                                            className="form-control"
                                                            placeholder='New Invoice No'
                                                            value={invoiceNo}
                                                            onChange={(e) => setInvoiceNo(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="" className="form-label">New Invoice Date</label>
                                                        <DatePicker
                                                            maxDate={dayjs(formatDate(new Date()), dateFormat)}
                                                            className='form-control'
                                                            name="date"
                                                            onChange={handleChangeDate}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="amount" className="form-label">Statement Amount (ZAR)</label>
                                                        <input
                                                            type="text"
                                                            id='amount'
                                                            className="form-control"
                                                            placeholder='Statement Amount'
                                                            disabled
                                                            value={amount}
                                                            onChange={(e) => setAmount(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="refNo" className="form-label">Statement Reference No</label>
                                                        <input
                                                            type="text"
                                                            id='refNo'
                                                            className="form-control"
                                                            disabled
                                                            value={statementRef}
                                                            onChange={(e) => setStatementRef(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="file" className="form-label">Upload Invoice</label>
                                                        <input
                                                            type="file"
                                                            id='file'
                                                            className="form-control"
                                                            onChange={handleImageUpload}
                                                            required
                                                            accept="image/*,.pdf"
                                                        />
                                                    </div>
                                                    {base64String && (
                                                        <>
                                                            <div className="timeline-fill-box overlay-timeline-fill-box" >
                                                                <div className="overlay" onClick={() => setInvoiceModal(true)}>Click here to view</div>
                                                                {
                                                                    base64String.includes("image/") ? (
                                                                        <img src={base64String} alt="" />
                                                                    ) : (
                                                                        <iframe src={base64String} alt="" height={300} width={"100%"} title="pdf" />
                                                                    )
                                                                }
                                                                <LiaTimesSolid style={{ zIndex: '2' }} onClick={() => setBase64String("")} />
                                                            </div>
                                                            <ViewImageModal ModalTitle={"Consent Form"} modalState={invoiceModal} modalSetState={setInvoiceModal} modalUrl={base64String} />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="text-center mt-3">
                                                {
                                                    response.isLoading === true ?
                                                        (<button disabled className="btn btn-primary">
                                                            <span className="spinnerInButton"></span>
                                                        </button>)
                                                        :
                                                        (<button type="button" onClick={handleSubmit} className="btn btn-primary">Re-Submit</button>)
                                                }
                                            </div>
                                        </Form>
                                    )
                                }
                            </>
                        )}
                    </div>
                </div>
            </section>
        </ReceptionLayout>
    )
}

export default ResubmitInvoice
