import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const dashboardApi = createApi({
    reducerPath: "dashboardApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            headers.set('Authorization', `Bearer ${userSlice.user.token}`);
            headers.set('Content-Type', `application/json;charset=UTF-8`);
            return headers
        },
    }),
    tagTypes: ["dashboard"],
    endpoints: (builder) => ({
        getDashboard: builder.query({
            query: () => {
                return {
                    url: "/Metric/MainDash",
                    method: "GET"
                }
            },
            providesTags: ['dashboard']
        }),
    }),
});

export const { useGetDashboardQuery } = dashboardApi;
