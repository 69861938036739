import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import { useAddToBatchMutation, useClientListQuery } from '../../../../../../../store/api/reception/batchApi';
import AddMoreTableRow from './AddMoreTableRow'
import toast from "react-hot-toast";

const AddMoreModal = ({batchNo, addModalState, setAddModal}) => {
  const navigate = useNavigate()
  const clientList = useClientListQuery()
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelect = (id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];
    
    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, id];
    } else {
      newSelectedRows = selectedRows.filter(rowId => rowId !== id);
    }
    setSelectedRows(newSelectedRows);
  };

  // Function to handle select all checkbox
  const handleSelectAll = (event) => {
    if (event.target.checked){
      const allIds = clientList?.data?.data.map(item => item.client_id);
      setSelectedRows(allIds);
    } else {
      setSelectedRows([]);
    }
  };


  const [addToBatch] = useAddToBatchMutation()
  const handleAddToBatch = () => {
    const data = [{
      batch_Number: batchNo,
      client_id: selectedRows
    }]
    addToBatch(data).then((res) => {
      console.log(res)
      toast.success("Clent Added Successfull", { duration: 7000 })
      setAddModal(false)
      navigate("/reception/batch-management/progress/"+batchNo)      
    })
  }

  return (
    <Modal size="lg" show={addModalState} onHide={()=>setAddModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title className='mb-0'>Add Records</Modal.Title>
        <p className='mb-0 ms-3'>Assign Client Record [{selectedRows.length} records selected]</p>
      </Modal.Header>
      <Modal.Body style={{
        maxHeight: 'calc(100vh - 180px)',
        overflowY: 'auto'
      }} className='pb-0'>
        <div className="table-responsive rounded card-table">
          {
            <table
              className="table border-no"
              id="example1"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      id={`all_client_id`}
                      className="filled-in chk-col-danger"
                      checked={selectedRows.length === clientList?.data?.data?.length}
                      defaultChecked={selectedRows.length === clientList?.data?.data?.length}
                      onChange={handleSelectAll}
                    />
                    <label htmlFor={`all_client_id`} />
                  </th>
                  <th>Sr.</th>
                  <th>Capture Date</th>
                  <th>CDC Barcode No.</th>
                  <th>Client Name</th>
                  <th>Client ID</th>
                  <th>Verification ID</th>
                  <th>Booking ID</th>
                  <th>Folder Number</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  clientList?.data?.data?.map((elem, i)=>{
                    return(
                      <AddMoreTableRow
                        handleRowSelect={() => handleRowSelect(elem.client_id)}
                        selectedRows={selectedRows}
                        data={elem}
                        index={i}
                        key={i}
                      />
                    )
                  })
                }
              </tbody>
            </table>
          }                      
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button variant="primary" onClick={handleAddToBatch}>
          Add to list
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddMoreModal