import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.png";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "../../../store/api/authApi";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addUser } from "../../../store/slice/userSlice";
import GoBackWrapper from "../../../components/wrapper/GoBackWrapper/GoBackWrapper";
import toast from "react-hot-toast";
import processPassword from "../../../methods/hashPassword";

const Login = () => {
  const [loginUserData, response] = useLoginUserMutation()
  const [passwordType, setPasswordType] = useState('password')
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    // On component mount, check if there are stored credentials
    const storedUsername = localStorage.getItem('vmmc_username');
    const storedPassword = localStorage.getItem('vmmc_password');
    let decryptedPassword = processPassword(storedPassword, 'decrypt');
    const storedRememberMe = localStorage.getItem('vmmc_rememberMe') === 'true';
    setRememberMe(storedRememberMe)
    if (storedRememberMe) {
      if (storedUsername && decryptedPassword) {
        setFormData({
          email: storedUsername,
          password: decryptedPassword,
        });
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
    } else {
      setPasswordType('password')
    }
  }

  const loginUser = async (e) => {
    e.preventDefault();
    const modifiedBody = {
      email: formData.email,
      password: formData.password.replace(/\s/g, ''),
    }
    await loginUserData(modifiedBody).then((res) => {
      if (res?.data?.status === 1) {
        if (res?.data?.message === "Login successful") {
          setTimeout(() => {
            toast.success(res?.data?.message, { duration: 7000 });
          }, [500]);
          dispatch(addUser(res?.data?.data));
          setTimeout(() => {
            if (res?.data?.data.role === 'Super User/Admin') {
              navigate("/dashboard");
            }
            if (res?.data?.data.role === 'Data Capture/Receptionist') {
              navigate("/reception/dashboard");
            }
            if (res?.data?.data.role === 'Data Validation(QA1)') {
              navigate("/qa-1/dashboard");
            }
            if (res?.data?.data.role === 'Data Validation(QA2)') {
              navigate("/qa-2/dashboard");
            }
            if (res?.data?.data.role === 'Billing/Finance(Approver)') {
              navigate("/billing/dashboard");
            }
          }, [500]);
          // Remember me ligic
          if (rememberMe) {
            localStorage.setItem('vmmc_username', formData.email);
            const realPassword = formData.password.replace(/\s/g, '');
            let encryptedPassword = processPassword(realPassword, 'encrypt');
            localStorage.setItem('vmmc_password', encryptedPassword);
            localStorage.setItem('vmmc_rememberMe', rememberMe);
          } else {
            localStorage.removeItem('vmmc_username');
            localStorage.removeItem('vmmc_password');
            localStorage.removeItem('vmmc_rememberMe');
          }
        } else {
          toast.error(res?.data?.message, { duration: 7000 });
        }
      }

      if (res?.data?.status === 0) {
        toast.error(res?.data?.message, { duration: 7000 });
      }
      if (res?.data?.status !== 0 && res?.data?.status !== 1) {
        toast.error("Unable to login, please try again", { duration: 7000 });
      }
    });

  };

  return (
    <GoBackWrapper>
      <div className="auth_container">
        <div>
          <div className="row justify-content-center g-0">
            {/* <div className=""> */}
            <div className="bg-white rounded10 shadow-lg p-40">
              <div className="content-top-agile mb-30">
                <img
                  src={logo}
                  className="w-120"
                  alt="Your Logo"
                />
                <h3 className="text-dark fw-bold mt-3 mb-0">Welcome! Please Login</h3>
              </div>
              <Form method="post" onSubmit={loginUser}>
                <div className="form-group">
                  <div className="input-group mb-3">
                    <span className="input-group-text bg-transparent">
                      <i className="ti-user"></i>
                    </span>
                    <Form.Control
                      type="text"
                      placeholder="Enter your Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group mb-2">
                  <div className="input-group password_toggle">
                    <span className="input-group-text bg-transparent">
                      <i className="ti-lock"></i>
                    </span>
                    <button type="button" onClick={togglePassword}>
                      {
                        passwordType === 'password' ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>
                      }
                    </button>
                    <Form.Control
                      type={passwordType}
                      placeholder="Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="fog-pwd d-flex justify-content-between">
                  <div>
                    <input
                      type="checkbox"
                      id="deviceCheck"
                      className="filled-in chk-col-danger"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label htmlFor="deviceCheck">
                      Remember me
                    </label>
                  </div>
                </div>
                <div className="fog-pwd d-flex justify-content-between mt-2">
                  <Link to={"/register"}>
                    Register Now
                  </Link>
                  <Link to={"/forgot-password"}>
                    Forgot Password?
                  </Link>
                </div>
                <div className="text-center mt-30">
                  {
                    response.isLoading === true ?
                      (<button disabled className="btn btn-primary">
                        <span className="spinnerInButton"></span>
                      </button>)
                      :
                      (<button type="submit" className="btn btn-primary">
                        SIGN IN
                      </button>)
                  }
                </div>
              </Form>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </GoBackWrapper>
  );
};

export default Login;
