import React, { useEffect } from 'react'
import ViewQAClient from '../../../../QA_Users/Components/ViewQAClient/ViewQAClient'
import ReceptionLayout from '../../../ReceptionLayout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetBillingClientByIDMutation } from '../../../../../store/api/reception/billingApi'
import LoadingErrorComponent from '../../../../../components/LoadingErrorComponent/LoadingErrorComponent'

const ViewClient = () => {
  const navigate = useNavigate()
  const param = useParams()
  const [GetBillingClientByID, GetBillingClientByIDResponse] = useGetBillingClientByIDMutation()

  useEffect(() => {
    GetBillingClientByID({
      client_id: param.clientID
    })
  }, [])

  return (
    <ReceptionLayout>
      <div class="content-header">
        <div class="d-flex align-items-center">
          <div class="me-auto">
            <div class="d-inline-block align-items-center">
              <nav>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <Link to="">
                      <i class="mdi mdi-home-outline"></i>
                    </Link>
                  </li>
                  <li class="breadcrumb-item">Facility Billing</li>
                  <li class="breadcrumb-item">
                    Billing Summery
                  </li>
                  <li class="breadcrumb-item">
                    Batch - {param.batchNo}
                  </li>
                  <li class="breadcrumb-item">
                    View Client
                  </li>
                  <li class="breadcrumb-item active">
                    {param.clientID}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section class="content">
        <div class="box">
          <div class="box-header with-border">
            <div class="box-in">
              <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-left"
                >
                  <line x1={19} y1={12} x2={5} y2={12} />
                  <polyline points="12 19 5 12 12 5" />
                </svg>
              </button>
              <h4 className="box-title me-auto ms-4">
                <strong>View Client</strong>
              </h4>
            </div>
          </div>
          <div class="box-body">
            {GetBillingClientByIDResponse.isError && <LoadingErrorComponent error />}
            {GetBillingClientByIDResponse.isLoading && <LoadingErrorComponent loading />}
            {GetBillingClientByIDResponse.isSuccess && (
              <div className="clint-from">
                <ViewQAClient QaType="completed" data={GetBillingClientByIDResponse?.data?.data} />
              </div>
            )}
          </div>
        </div>
      </section>
    </ReceptionLayout>
  )
}

export default ViewClient