import React, { useEffect, useState } from 'react'
import BillingLayout from '../../../BillingLayout'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useViewRecordInBatchMutation } from '../../../../../store/api/billing/billingApi'
import LoadingErrorComponent from '../../../../../components/LoadingErrorComponent/LoadingErrorComponent'
import PaginationComp from '../../../../../components/Pagination/paginationComp'
import toast from 'react-hot-toast'
import { saveAs } from "file-saver";
import { useSelector } from 'react-redux'

const ViewBatch = () => {
    const navigate = useNavigate()
    const { user } = useSelector((store) => {
        return store.userSlice;
    });
    const param = useParams()
    const [paginationDetails, setPaginationDetails] = useState({})
    const [viewRecordInBatch, response] = useViewRecordInBatchMutation()
    const [record, setRecord] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [sort_by, setSortBy] = useState(searchParams.get("sort_by") || "");
    const [searchBy, setSearchBy] = useState(searchParams.get("searchBy") || "");
    const [searchKeyword, setSearchKeyword] = useState(
        searchParams.get("searchKeyword") || ""
    );
    const [page, setPageNo] = useState(searchParams.get("page") || 1);


    // FUNCTIONS TO HANDLE DATA SEARCH AND FILTER
    const changePage = (page) => {
        setPageNo(page);
    };
    const handleSortBy = (elem) => {
        setSortBy(elem.target.value);
    };
    const handleSearchBy = (elem) => {
        setSearchBy(elem.target.value);
    };
    const handleSearchKeyword = (elem) => {
        setSearchKeyword(elem.target.value);
    };

    useEffect(() => {
        viewRecordInBatch({
            BatchNo: param.batchNo,
            sort_by: sort_by,
            searchKeyword: searchKeyword,
            page: page,
            per_page: 10,
            searchBy: searchBy,
        }).then((res) => {
            setRecord(res?.data?.data)
        })
    }, [param.batchNo, sort_by, searchKeyword, page, searchBy])

    useEffect(() => {
        let params = { page };
        if (sort_by) {
            params.sort_by = sort_by;
        }
        if (searchBy) {
            params.searchBy = searchBy;
        }
        if (searchKeyword) {
            params.searchKeyword = searchKeyword;
        }
        setSearchParams(params);
    }, [page, sort_by, searchBy, searchKeyword, setSearchParams]);

    const paginationDataString = response?.data?.message.split(": ")[1];
    useEffect(() => {
        if (paginationDataString) {
            const paginationData = JSON.parse(paginationDataString?.replace(/=/g, ':').replace(/(\w+)/g, '"$1"'));
            setPaginationDetails({
                CurrentPage: Math.ceil(paginationData.CurrentPage),
                ItemsPerPage: Math.ceil(paginationData.ItemsPerPage),
                TotalCount: Math.ceil(paginationData.TotalCount),
                TotalPages: Math.ceil(paginationData.TotalPages),
            })
        }
    }, [response?.data?.message])

    //download list of clients in excel 
    const [DownloadXLState, setDownloadXLState] = useState(false)
    const downloadDataAsExcel = async () => {
        setDownloadXLState(true)
        const queryString = new URLSearchParams({
            batchNo: param.batchNo.toString(),
            page: 1,
            per_page: paginationDetails.TotalCount,
        }).toString();

        fetch(`${process.env.REACT_APP_API_ENDPOINT}/Billing/ViewRecordsInBatch?${queryString}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            const userXLS = response.data
            if (userXLS.length > 0) {
                // Convert data to CSV format
                const csvData = convertToCSV(userXLS);
                // Create a Blob containing the CSV data
                const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
                // Trigger download
                saveAs(blob, `Billing_amount_due_batch_${param.batchNo.toString()}.csv`);
                setDownloadXLState(false)
            } else {
                toast.error('No record found to export', { duration: 7000 });
            }
        }).catch(err => {
            setDownloadXLState(false)
            toast.error('Unable to download excel file, please try again', { duration: 7000 });
        })
    };

    const convertToCSV = (dataArray) => {
        // Convert array of objects to CSV string
        const header = Object.keys(dataArray[0]).join(",") + "\n";
        const body = dataArray?.map((obj) => Object.values(obj).join(",")).join("\n");
        return header + body;
    };

    return (
        <BillingLayout>
            <div className="container-full">
                <div className="content-header">
                    <div class="d-flex align-items-center">
                        <div class="me-auto">
                            <div class="d-inline-block align-items-center">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="#">
                                                <i className="mdi mdi-home-outline"></i>
                                            </a>
                                        </li>
                                        <li class="breadcrumb-item" aria-current="page">Billing & Invoices</li>
                                        <li class="breadcrumb-item" aria-current="page">Amounts Due from Statement</li>
                                        <li class="breadcrumb-item active" aria-current="page">Batch - {param.batchNo}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='content'>
                    <div className="box">
                        <div class="box-header with-border">
                            <div class="box-in">
                                <button onClick={() => navigate("/billing/dashboard/amountDue")} className='btn btn-danger-light'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-arrow-left"
                                    >
                                        <line x1={19} y1={12} x2={5} y2={12} />
                                        <polyline points="12 19 5 12 12 5" />
                                    </svg>
                                </button>
                                <h4 className="box-title me-auto ms-4">

                                    <strong>Batch no - {param.batchNo}</strong>
                                </h4>
                                <div className="box-right">
                                    {response.isSuccess && (
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-export"
                                            onClick={downloadDataAsExcel}
                                            disabled={DownloadXLState}
                                        >
                                            Export to XLS
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        {response.isError && <LoadingErrorComponent error />}
                        {response.isLoading && <LoadingErrorComponent loading />}
                        {response.isSuccess && (
                            <div class="box-body">
                                <div class="row mb-3 g-2 justify-content-between">
                                    <div class="col-sm-12 col-md-2">
                                        <select
                                            class="form-select mb-xl-0 mb-2"
                                            aria-label="Default select example"
                                            value={sort_by}
                                            onChange={handleSortBy}
                                        >
                                            <option selected>Sort by</option>
                                            <option value="first_to_last">First to Last</option>
                                            <option value="last_to_first"> Last to First</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                    </div>
                                    <div class="col-sm-12 col-md-4">
                                        <div class="table-top-search">
                                            <select
                                                class="form-select"
                                                aria-label="Default select example"
                                                onChange={handleSearchBy}
                                                value={searchBy}
                                            >
                                                <option value="">Search by</option>
                                                <option value="cdcc_barcode">Barcode CDCC No</option>
                                                <option value="client_name">Client Name</option>
                                                <option value="identity_number">Identity Number</option>
                                                <option value="folder_number">Folder number</option>
                                            </select>
                                            <input
                                                type="search"
                                                class="form-control"
                                                placeholder="Search"
                                                aria-controls="complex_header"
                                                value={searchKeyword}
                                                onChange={handleSearchKeyword}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="table-responsive">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Sr.</th>
                                                        <th>Capture Date</th>
                                                        <th>CDC Barcode No.</th>
                                                        <th>Client Name</th>
                                                        <th>Client ID</th>
                                                        <th>Verification ID</th>
                                                        <th>Folder Number</th>
                                                        <th>QA 1</th>
                                                        <th>QA 2</th>
                                                        <th>Validation Status</th>
                                                        <th>Action Items</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        record.length > 0 ? (
                                                            record?.map((el, i) => (
                                                                <tr class="hover-primary">
                                                                    <td>{i + 1}</td>
                                                                    <td>{el.captureDate}</td>
                                                                    <td>{el.barcode_cdc_number}</td>
                                                                    <td>{el.firstname} {el.lastname}</td>
                                                                    <td>{el.client_id}</td>
                                                                    <td>
                                                                        <div>{el.verification_id}</div>
                                                                        <div>{el.identity_verification}</div>
                                                                    </td>
                                                                    <td>{el.folder_number}</td>
                                                                    <td>
                                                                        {el.qA1status.toLowerCase() === "done" && <span class="fs-14 badge badge-success-light">
                                                                            <i class="fa fa-check"></i>
                                                                        </span>}
                                                                    </td>
                                                                    <td>
                                                                        {el.qA2status.toLowerCase() === "done" && <span class="fs-14 badge badge-success-light">
                                                                            <i class="fa fa-check"></i>
                                                                        </span>}
                                                                    </td>
                                                                    <td>
                                                                        {el.verificationStatus !== "" ? (
                                                                            el.verificationStatus.toLowerCase() === "valid" ? <span class="badge badge-success-light">Valid</span> : <span class="badge badge-danger-light">Invalid</span>
                                                                        ) : ''}
                                                                    </td>
                                                                    <td>
                                                                        <div class="btn-group">
                                                                            <Link to={`/billing/dashboard/amountDue/batch/${param.batchNo}/view-client/${el.client_id}`} class="hover-primary dropdown-toggle no-caret btn-more px-2">
                                                                                <i class="fa fa-eye"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                No Data Found
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {record.length > 0 &&
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    {`Showing ${page - 1}1 to ${paginationDetails.TotalCount > page * 10 ? page * 10 : paginationDetails.TotalCount}  of ${paginationDetails.TotalCount} entries`}
                                                </div>
                                                <div>
                                                    <PaginationComp currentPage={paginationDetails.CurrentPage} totalPages={paginationDetails.TotalPages} onPageChange={changePage} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </BillingLayout>
    )
}

export default ViewBatch
