import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const QaOne = createApi({
    reducerPath: "QaOne",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            return headers.set('Authorization', `Bearer ${userSlice.user.token}`);
        },
    }),
    tagTypes: ["QAOneBatches", "QAOneRecordsInBatch"],
    endpoints: (builder) => ({
        getBatches: builder.query({
            query: () => {
                return {
                    url: "/QA1/ViewQABatches",
                    method: "GET",
                }
            },
            providesTags: ['QAOneRecordsInBatch']
        }),
        getRecordsInBatch: builder.query({
            query: (data) => {
                return {
                    url: "/QA1/ViewRecordsInBatch",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ['QAOneRecordsInBatch']
        }),
        getClientByID: builder.mutation({
            query: (data) => {
                return {
                    url: `/QA1/GetClientByID`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["QAOneRecordsInBatch"],
        }),
        QaOneVerifyRecord: builder.mutation({
            query: (data) => {
                return {
                    url: `/QA1/VerifyRecord`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["QAOneRecordsInBatch"],
        }),
        SubmitBatch: builder.mutation({
            query: (data) => {
                return {
                    url: `/QA1/SubmitBatch`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["QAOneRecordsInBatch"],
        }),
        getQAReason: builder.query({
            query: () => {
                return {
                    url: "/Billing/QaReasonsDrop",
                    method: "GET",
                }
            },
            providesTags: ['QAOneRecordsInBatch']
        }),
    }),
});

export const {
    useGetBatchesQuery,
    useGetRecordsInBatchQuery,
    useGetClientByIDMutation,
    useQaOneVerifyRecordMutation,
    useSubmitBatchMutation,
    useGetQAReasonQuery
} = QaOne;