import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
  }),
  tagTypes: ["auth", 'TokenValidity'],
  endpoints: (builder) => ({
    CheckTokenValidity: builder.mutation({
      query: (data) => {
        return {
          url: "/Auth/HealthCheck",
          method: "POST",
          body: data,
        }
      },
      invalidatesTags: ["TokenValidity"],
    }),

    Registration: builder.mutation({
      query: (data) => {
        return {
          url: "/Auth/SelfRegister",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["auth"],
    }),

    loginUser: builder.mutation({
      query: (data) => {
        return {
          url: "/Auth/Login",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["auth"],
    }),

    logoutUser: builder.mutation({
      query: (data) => {
        return {
          url: "/Auth/Logout",
          method: "POST",
          headers: {
            'Authorization': `Bearer ${data}`,
          },
        };
      },
      invalidatesTags: ["auth"],
    }),

    forgotPassword: builder.mutation({
      query: (data) => {
        return {
          url: `/Auth/ForgotPassword`,
          method: "POST",
          body: {
            email: data
          },

        };
      },
      invalidatesTags: ["auth"],
    }),

    pinVerification: builder.mutation({
      query: (data) => {
        return {
          url: `/Auth/VerifyPin`,
          method: "POST",
          body: data
        };
      },
      invalidatesTags: ["auth"],
    }),

    changePassword: builder.mutation({
      query: (data) => {
        return {
          url: "/Auth/AddNewPassword",
          method: "POST",
          body: data,
          headers: {
            'Authorization': `Bearer ${data.token}`,
          },
        };
      },
      invalidatesTags: ["auth"],
    }),

    verifyTempPassword: builder.mutation({
      query: (data) => {
        return {
          url: "/Auth/VerifyTemp",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["auth"],
    }),
  }),
});

export const { useCheckTokenValidityMutation, useRegistrationMutation, useLogoutUserMutation, useForgotPasswordMutation, useLoginUserMutation, usePinVerificationMutation, useChangePasswordMutation, useVerifyTempPasswordMutation } = authApi;
