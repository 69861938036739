import React from 'react'
import BillingLayout from '../../BillingLayout'
import { useBatchPendingQuery } from '../../../../store/api/billing/billingApi'
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent'
import { Link, useNavigate } from 'react-router-dom'

const AmountProgress = () => {
    const navigate = useNavigate()
    const batchPending = useBatchPendingQuery()
    const qa1Data = batchPending?.data?.data.filter(item => item.qa === 'QA1');
    const qa2Data = batchPending?.data?.data.filter(item => item.qa === 'QA2');

    console.log(qa1Data)

    return (
        <BillingLayout>
            <div class="content-header">
                <div class="d-flex align-items-center">
                    <div class="me-auto">
                        <div class="d-inline-block align-items-center">
                            <nav>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <Link to="">
                                            <i class="mdi mdi-home-outline"></i>
                                        </Link>
                                    </li>
                                    <li class="breadcrumb-item" aria-current="page">Billing & Invoices</li>
                                    <li class="breadcrumb-item" aria-current="page">Billing Dashboard</li>
                                    <li class="breadcrumb-item active" aria-current="page">Amount in Progress</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="box">
                    <div class="box-header with-border">
                        <div class="box-in">
                            <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">
                                <strong>Amount in Progress</strong>
                            </h4>
                        </div>
                    </div>
                    {batchPending.isError && <LoadingErrorComponent error />}
                    {batchPending.isLoading && <LoadingErrorComponent loading />}
                    {batchPending.isSuccess && <div class="box-body">
                        <div class="box-progress">
                            <div>
                                <h5 class="fw-bold">QA1 Batches</h5>
                                <div class="row g-3">
                                    {
                                        qa1Data?.length > 0 ? qa1Data?.map((el, i) => (
                                            <div class="col-xl-4 col-md-4 col-sm-6 col-12">
                                                <div to={``}>
                                                    {/* to={`/billing/dashboard/amountDue/batch/${el.batchNo}`}> */}
                                                    <div className="scheduleBox">
                                                        <div className="scheduleBoxTop">
                                                            <i className="fa fa-folder" style={{ color: 'rgb(255, 193, 7)' }}></i>
                                                            <div> {el.batchNo} </div>
                                                        </div>
                                                        {/* <div className="scheduleLabel overdue">
                                                            <span className="scheduleBoxTitle">Statement Ref.</span>
                                                            <span className="scheduleBoxTitleCount">{el.statementRef}</span>
                                                        </div> */}
                                                        <div className="scheduleLabel overdue">
                                                            <span className="scheduleBoxTitle">Statement Amount</span>
                                                            <span className="scheduleBoxTitleCount">ZAR {el.totalAmount} </span>
                                                        </div>
                                                        <div className="scheduleLabel total">
                                                            <span className="scheduleBoxTitle">Total Records</span>
                                                            <span className="scheduleBoxTitleCount">{el.noOfClientsInBatch}</span>
                                                        </div>
                                                        <div className="scheduleLabel overdue">
                                                            <span className="scheduleBoxTitle text-success">Valid</span>
                                                            <span className="scheduleBoxTitleCount text-success">{el.validClients}</span>
                                                        </div>
                                                        <div className="scheduleLabel overdue">
                                                            <span className="scheduleBoxTitle text-danger">Invalid</span>
                                                            <span className="scheduleBoxTitleCount text-danger">{el.invalidClients}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) :
                                            (
                                                <div className='col-12'>
                                                    No Data Found
                                                </div>
                                            )
                                    }
                                </div>
                            </div>

                            <div class="mt-4">
                                <h5 class="fw-bold">QA2 Batches</h5>
                                <div class="row g-3">
                                    {
                                        qa2Data?.length > 0 ? qa2Data?.map((el, i) => (
                                            <div class="col-xl-4 col-md-4 col-sm-6 col-12">

                                                {/* to={`/billing/dashboard/amountDue/batch/${el.batchNo}`}> */}
                                                <div className="scheduleBox">
                                                    <div className="scheduleBoxTop">
                                                        <i className="fa fa-folder" style={{ color: 'rgb(255, 193, 7)' }}></i>
                                                        <div> {el.batchNo} </div>
                                                    </div>
                                                    {/* <div className="scheduleLabel overdue">
                                                            <span className="scheduleBoxTitle">Statement Ref.</span>
                                                            <span className="scheduleBoxTitleCount">{el.statementRef}</span>
                                                        </div> */}
                                                    <div className="scheduleLabel overdue">
                                                        <span className="scheduleBoxTitle">Statement Amount</span>
                                                        <span className="scheduleBoxTitleCount">ZAR {el.totalAmount} </span>
                                                    </div>
                                                    <div className="scheduleLabel total">
                                                        <span className="scheduleBoxTitle">Total Records</span>
                                                        <span className="scheduleBoxTitleCount">{el.noOfClientsInBatch}</span>
                                                    </div>
                                                    <div className="scheduleLabel overdue">
                                                        <span className="scheduleBoxTitle text-success">Valid</span>
                                                        <span className="scheduleBoxTitleCount text-success">{el.validClients}</span>
                                                    </div>
                                                    <div className="scheduleLabel overdue">
                                                        <span className="scheduleBoxTitle text-danger">Invalid</span>
                                                        <span className="scheduleBoxTitleCount text-danger">{el.invalidClients}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )) :
                                            (
                                                <div>
                                                    No Data Found
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </section>
        </BillingLayout>
    )
}

export default AmountProgress
