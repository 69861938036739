import React, { useEffect, useState } from 'react'


const BillingLayout = ({ children }) => {
    const [contentHeight, setContentHeight] = useState(700)

    useEffect(() => {
        // Get the element
        const headerHeight = document.getElementsByClassName('main-header')[0]?.offsetHeight;
        const windowHeight = window.innerHeight;
        setContentHeight(windowHeight - headerHeight)


        const handleResize = () => {
            const headerHeight = document.getElementsByClassName('main-header')[0]?.offsetHeight;
            const windowHeight = window.innerHeight;
            setContentHeight(windowHeight - headerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    return (
        <div className="content-wrapper receptionLayout" style={{ height: contentHeight + 'px', overflowY: 'auto' }}>
            {children}
        </div>
    )
}

export default BillingLayout