import React, { useEffect, useState } from 'react'
import ReceptionLayout from '../../../../Reception/ReceptionLayout'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGetRecordsInBatchQuery } from '../../../../../store/api/qa_one/QAOneApi'
import LoadingErrorComponent from '../../../../../components/LoadingErrorComponent/LoadingErrorComponent'
import PaginationComp from '../../../../../components/Pagination/paginationComp'
import { saveAs } from "file-saver";
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'

const QAOneUsersCompletedBatches = () => {
  const { user } = useSelector((store) => {
    return store.userSlice;
  });
  const [paginationDetails, setPaginationDetails] = useState({})
  let params = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const [mobiliser, setMobiliser] = useState(
    searchParams.get("mobiliser") || ""
  );
  const [sort_by, setSortBy] = useState(searchParams.get("sort_by") || "");
  const [searchBy, setSearchBy] = useState(searchParams.get("searchBy") || "");
  const [searchKeyword, setSearchKeyword] = useState(
    searchParams.get("searchKeyword") || ""
  );
  const [page, setPageNo] = useState(searchParams.get("page") || 1);

  const batchListApi = useGetRecordsInBatchQuery({
    batchNo: params?.id?.toString(),
    sort_by: sort_by,
    searchKeyword: searchKeyword,
    page: page,
    per_page: 10,
    sort_by_mobiliser: mobiliser,
    searchBy: searchBy,
  })

  var percentage = parseInt(batchListApi?.data?.message.match(/\d+/)[0]);

  // Function to handle page change
  const changePage = (selected) => {
    setPageNo(selected);
  };
  const handleSortBy = (elem) => {
    setSortBy(elem.target.value);
  };
  const handleSearchBy = (elem) => {
    setSearchBy(elem.target.value);
  };
  const handleSearchKeyword = (elem) => {
    setSearchKeyword(elem.target.value);
  };

  useEffect(() => {
    let params = { page };
    if (mobiliser) {
      params.sort_by_mobiliser = mobiliser;
    }
    if (sort_by) {
      params.sort_by = sort_by;
    }
    if (searchBy) {
      params.searchBy = searchBy;
    }
    if (searchKeyword) {
      params.searchKeyword = searchKeyword;
    }
    setSearchParams(params);
  }, [page, mobiliser, sort_by, searchBy, searchKeyword, setSearchParams]);

  const paginationDataString = batchListApi?.data?.message;

  useEffect(() => {
    if (paginationDataString) {
      const startIndex = paginationDataString?.indexOf('{');
      const endIndex = paginationDataString?.lastIndexOf('}');
      const objectString = paginationDataString?.substring(startIndex, endIndex + 1);
      const paginationData = JSON.parse(objectString.replace(/(\w+)\s*=\s*(\d+)/g, '"$1":$2'));

      setPaginationDetails({
        CurrentPage: Math.ceil(paginationData.CurrentPage),
        ItemsPerPage: Math.ceil(paginationData.ItemsPerPage),
        TotalCount: Math.ceil(paginationData.TotalCount),
        TotalPages: Math.ceil(paginationData.TotalPages),
      })
    }
  }, [batchListApi?.data?.message])


  //download list of clients in excel 
  const [DownloadXLState, setDownloadXLState] = useState(false)
  const downloadDataAsExcel = async () => {
    setDownloadXLState(true)
    const queryString = new URLSearchParams({
      batchNo: params?.id?.toString(),
      page: 1,
      per_page: paginationDetails.TotalCount,
    }).toString();

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/QA1/ViewRecordsInBatch?${queryString}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      return response.json()
    }).then((response) => {
      const userXLS = response.data
      if (userXLS.length > 0) {
        // Convert data to CSV format
        const csvData = convertToCSV(userXLS);
        // Create a Blob containing the CSV data
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        // Trigger download
        saveAs(blob, `QA1_completed_clients_in_batch_${params?.id?.toString()}.csv`);
        setDownloadXLState(false)
      } else {
        toast.error('No record found to export', { duration: 7000 });
      }
    }).catch(err => {
      setDownloadXLState(false)
      toast.error('Unable to download excel file, please try again', { duration: 7000 });
    })
  };

  const convertToCSV = (dataArray) => {
    // Convert array of objects to CSV string
    const header = Object.keys(dataArray[0]).join(",") + "\n";
    const body = dataArray?.map((obj) => Object.values(obj).join(",")).join("\n");
    return header + body;
  };
  const navigate = useNavigate()

  return (
    <ReceptionLayout>
      <div className="container-full">
        <div className="content-header">
          <div className="d-flex align-items-center">
            <div className="me-auto">
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="">
                        <i className="mdi mdi-home-outline"></i>
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">Quality Assurance</li>
                    <li className="breadcrumb-item" aria-current="page">QA 1</li>
                    <li className="breadcrumb-item active" aria-current="page">Batch - {params?.id?.toString()}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="box">
            <div className="box-header with-border">
              <div className="box-in">
                <button onClick={() => navigate("/qa-1/manage-batch-validation")} className='btn btn-danger-light'>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-left"
                  >
                    <line x1={19} y1={12} x2={5} y2={12} />
                    <polyline points="12 19 5 12 12 5" />
                  </svg>
                </button>
                <h4 className="box-title me-auto ms-4">

                  <strong>Batch Number - {params?.id?.toString()}</strong>
                </h4>
                <div className="box-right">
                  {batchListApi.isSuccess && (
                    <button
                      type="button"
                      className="btn btn-primary btn-export"
                      onClick={downloadDataAsExcel}
                      disabled={DownloadXLState}
                    >
                      Export to XLS
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="box-body">
              <div className="row">
                <div className="col-12">
                  <div className="row mb-3 gx-2 justify-content-between">
                    <div className="col-sm-12 col-md-2">
                      <select
                        className="form-select mb-xl-0 mb-2"
                        aria-label="sort_by"
                        value={sort_by}
                        onChange={handleSortBy}
                      >
                        <option selected="">Sort by</option>
                        <option value="first_to_last">First to Last</option>
                        <option value="last_to_first"> Last to First</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      {
                        batchListApi.isSuccess && (
                          <>
                            <div className="d-flex fs-12 fw-bold justify-content-between lh-1 mb-1">
                              <span>0</span>
                              <span>100%</span>
                            </div>
                            <div className="progress mb-0" style={{ height: '15px' }}>
                              <div className={percentage >= 60 ? "progress-bar bg-success" : "progress-bar"} role="progressbar" style={{ width: `${percentage}%` }} aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">{`${percentage}%`}</div>
                            </div>
                          </>
                        )
                      }
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="table-top-search">
                        <select
                          className="form-select"
                          aria-label="search by"
                          onChange={handleSearchBy}
                          value={searchBy}
                        >
                          <option selected="">Search by</option>
                          <option value="cdcc_barcode">Barcode CDCC No</option>
                          <option value="client_name">Client Name</option>
                          <option value="identity_number">Identity Number</option>
                          <option value="folder_number">Folder number</option>
                        </select>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          aria-controls="search"
                          value={searchKeyword}
                          // value={inputValue}
                          onChange={handleSearchKeyword}
                        />
                      </div>
                    </div>
                  </div>
                  {batchListApi.isError && <LoadingErrorComponent error />}
                  {batchListApi.isLoading && <LoadingErrorComponent loading />}
                  {batchListApi.isSuccess && (
                    <div className="table-responsive rounded card-table">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Sr.</th>
                            <th>Capture Date</th>
                            <th>CDC Barcode No.</th>
                            <th>Client Name</th>
                            {/* <th>Client ID</th> */}
                            <th>Verification ID</th>
                            <th>Folder Number</th>
                            <th>Validation Status</th>
                            <th>Action Items</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            batchListApi?.data?.data.length > 0 ? (
                              batchListApi?.data?.data.map((elem, i) => {
                                return (
                                  <tr className="hover-primary" key={i}>
                                    <td>{i + 1}</td>
                                    <td>{elem.captureDate}</td>
                                    <td>{elem.barcode_cdc_number !== "" ? elem.barcode_cdc_number : <div className='text-center'>---</div>}</td>
                                    <td>{elem.firstname}{" "}{elem.lastname}</td>
                                    {/* <td>{elem?.client_id}</td> */}
                                    <td>
                                      <div>{elem.verification_id}</div>
                                      <div>{elem.identity_verification}</div>
                                    </td>
                                    <td>{elem.folder_number}</td>
                                    <td>
                                      {
                                        elem.verificationStatus.toLowerCase() === 'valid' ? (
                                          <span className="badge badge-success-light">Valid</span>
                                        ) : (
                                          <span className="badge badge-danger-light">Invalid</span>
                                        )
                                      }
                                    </td>
                                    <td>
                                      <div className="btn-group">
                                        <Link to={`view-client/${elem.client_id}`} className="hover-primary dropdown-toggle no-caret btn-more px-2">
                                          <i className="fa fa-eye"></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td colSpan={10} className='border-0'>No Data Found!!</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  )}
                  {batchListApi?.data?.data.length > 0 && <div className="d-flex justify-content-between mt-3">
                    {
                      <div>
                        {`Showing ${page - 1}1 to ${paginationDetails.TotalCount > page * 10 ? page * 10 : paginationDetails.TotalCount}  of ${paginationDetails.TotalCount} entries`}
                      </div>
                    }
                    <div>
                      <PaginationComp currentPage={paginationDetails.CurrentPage} totalPages={paginationDetails.TotalPages} onPageChange={changePage} />
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ReceptionLayout>
  )
}

export default QAOneUsersCompletedBatches