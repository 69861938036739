import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { useAddEditfollowUpFormMutation, useFollowUpClientByCDFMutation } from '../../../../store/api/reception/followupApi';
import ReceptionLayout from '../../ReceptionLayout';
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent';
import FollowUpClientForm from '../../../../components/FollowUp/FollowupClientForm';

const FollowupForm = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [clientDetails, response] = useFollowUpClientByCDFMutation()
    const [addEditfollowUpForm] = useAddEditfollowUpFormMutation()
    useEffect(() => {
        clientDetails({ cdfbarcode: id.toString() })
    }, [])
    return (
        <ReceptionLayout>
            <div className="container-full">
                <div className="content-header">
                    <div className="d-flex align-items-center">
                        <div className="me-auto">
                            <div className="d-inline-block align-items-center">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/"><i className="mdi mdi-home-outline"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item">Follow-up Form</li>
                                        <li className="breadcrumb-item">Edit Follow-up</li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {id}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="box">
                        <div className="box-header with-border">
                            <div className="box-in">
                                <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-arrow-left"
                                    >
                                        <line x1={19} y1={12} x2={5} y2={12} />
                                        <polyline points="12 19 5 12 12 5" />
                                    </svg>
                                </button>
                                <h4 className="box-title me-auto ms-4">

                                    <strong>Followup Form</strong>
                                </h4>
                            </div>
                        </div>
                        <div className="box-body">
                            {response.isError && (
                                <LoadingErrorComponent error />
                            )}
                            {response.isLoading && (
                                <LoadingErrorComponent loading />
                            )}
                            {
                                response.isSuccess && (
                                    response.data.data ?
                                        <FollowUpClientForm type="edit" mutation={addEditfollowUpForm} data={response.data.data} />
                                        : (
                                            <LoadingErrorComponent error />
                                        )
                                )
                            }
                        </div>
                    </div>
                </section>
            </div>
        </ReceptionLayout>
    )
}

export default FollowupForm