import React, { useState } from "react";
import OtpInput from "react-otp-input";
import "./otpVerificaton.css";
import { usePinVerificationMutation } from "../../../store/api/authApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

const OtpVerificationPage = () => {
  const [otpVerification, response] = usePinVerificationMutation();
  let navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [formData, setFormData] = useState({
    input: "",
  });


  const handleChange = (otp) => {
    setOtp(otp);
    setFormData({ ...formData, input: otp?.toString() }); // Update formData with entered OTP
  };
  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    await otpVerification(formData).then((res) => {
      if (res?.data?.status === 1) {
        toast.success(res?.data?.message, { duration: 7000 });
        navigate("/change-password");
        localStorage.setItem('otpToken', res?.data?.data?.token);
      }
      if (res?.data?.status === 0) {
        toast.error(res?.data?.message, { duration: 7000 });
      }
    });
  };


  return (
    <>
      <section className="login-back min-vh-100">
        <div className="signin login min-vh-100">
          <div className="container">
            <div
              className="row justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="col-lg-4">
                <div className="we-login-register-otp">
                  <div>
                    <p className="otp-title"> OTP Verification Code</p>
                    <OtpInput
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      separator={<span style={{ width: "8px" }}></span>}
                      shouldAutoFocus={true}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                      inputStyle={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        width: "39px",
                        height: "39px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                      }}
                      focusStyle={{
                        border: "1px solid #CFD3DB",
                        outline: "none",
                      }}
                    />
                    {
                      response.isLoading === true ?
                        (<button disabled className="otpbutton btn btn-primary">
                          <span className="spinnerInButton"></span>
                        </button>)
                        :
                        (<button className="otpbutton" style={{ backgroundColor: "#ED1A3B", color: "white" }} onClick={handleSubmitOtp}>
                          Submit
                        </button>)
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtpVerificationPage;
