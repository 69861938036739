import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const SystemUserApis = createApi({
    reducerPath: "SystemUserApis",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            headers.set('Authorization', `Bearer ${userSlice.user.token}`);
            headers.set('Content-Type', `application/json;charset=UTF-8`);
            return headers
        },
    }),
    tagTypes: ["SystemUser"],
    endpoints: (builder) => ({
        viewSystemUserList: builder.query({
            query: (data) => {
                console.log(data)
                return {
                    url: "/Auth/ViewAllUsers",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["SystemUser"],
        }),
    }),
});

export const { useViewSystemUserListQuery } = SystemUserApis;
