import ThemeWrapper from "./components/wrapper/ThemeWrapper/ThemeWrapper";
import AllRoutes from "./routes/AllRoutes";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCheckTokenValidityMutation, useLogoutUserMutation } from "./store/api/authApi";
import { logOut } from "./store/slice/userSlice";
import Login from "./pages/Auth/Login/Login";

function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const baseUrl = window.location.origin;

  const { user } = useSelector((store) => {
    return store.userSlice;
  });
  const [checkTokenStatus, Response] = useCheckTokenValidityMutation()
  const [logoutUser] = useLogoutUserMutation();

  useEffect(() => {
    if (user.token) {
      checkTokenStatus({
        token: user.token?.toString()
      }).then((Response) => {
        if (Response.data) {
          if (Response.data.valid_status === false) {
            logoutUser(user.token);
            dispatch(logOut());
            navigate("/login")
          }
        }
      })
    }
    if (Object.keys(user).length === 0) {
      dispatch(logOut());
      navigate("/login")
      // window.location.href = `${baseUrl}/login`;
    }
  }, [])


  if (pathname === '/login' || pathname === '/register-successfull' || pathname === '/register' || pathname === '/forgot-password' || pathname === '/otp-verification' || pathname === '/change-password' || pathname.includes("set-new-password")) {
    return (
      <ThemeWrapper>
        <Toaster position="top-center" reverseOrder={false} />
        <AllRoutes />
      </ThemeWrapper>
    );
  } else {
    return (
      <ThemeWrapper>
        <Header />
        <Sidebar />
        <Toaster position="top-center" reverseOrder={false} />
        <AllRoutes />
      </ThemeWrapper>
    );
  }
}

export default App;
