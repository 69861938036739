import React, { useEffect, useState, useReducer } from 'react'
import BillingLayout from '../../BillingLayout'
import { useNavigate, useParams } from 'react-router-dom'
import { useInvoiceApprovalMutation, useInvoiceRecordByInvoiceNoMutation, useRejectReasonDropdownQuery } from '../../../../store/api/billing/billingApi'
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent'
import toast from 'react-hot-toast'
import ViewImageModal from '../../../../components/modal/ViewImageModal'

const ViewInvoice = () => {
    const invoice = useParams()
    const navigate = useNavigate()
    const [invoiceByInvoiceNo, res] = useInvoiceRecordByInvoiceNoMutation()
    const [invoiceApproval, response] = useInvoiceApprovalMutation()
    const [invoDetails, setInvoDetails] = useState({})
    const [invoiceModal, setInvoiceModal] = useState(false)
    const rejectReasons = useRejectReasonDropdownQuery()

    const dataValidationInitState = {
        confirmation: false,
        status: '',
        invalidMessage: '',
        invalidMessageOther: ''
    }

    const DataValidationReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_STATE":
                return { ...payload };
            case "SET_CONFIRMATION":
                return {
                    ...state,
                    confirmation: payload,
                };
            case "SET_STATUS":
                return {
                    ...state,
                    status: payload
                };
            case "SET_INVALID_MESSAGE":
                return {
                    ...state,
                    invalidMessage: payload
                };
            case "SET_INVALID_MESSAGE_OTHER":
                return {
                    ...state,
                    invalidMessageOther: payload
                };
            default:
                return state;
        }
    }
    const [DataValidationState, DataValidationDispatch] = useReducer(DataValidationReducer, dataValidationInitState);

    useEffect(() => {
        invoiceByInvoiceNo(invoice.invoiceNo).then((res) => {
            setInvoDetails(res?.data?.data)
        })
    }, [])

    let data = {
        stateRef: invoDetails?.statementRef,
        reason: DataValidationState?.invalidMessage,
        statementStatus: DataValidationState?.status,
        OtherReason: DataValidationState?.invalidMessageOther || ""
    }
    const handleInvoiceApproval = () => {
        console.log(data)
        invoiceApproval(data).then((res) => {
            if (res?.data?.status === 1) {
                navigate("/billing/billingInvoiceApproval")
                toast.success("Submitted Successfully", { duration: 7000 })
            }
            else if (res?.data?.status === 0) {
                toast.error("Unable to load invoice, please try again", { duration: 7000 })
            }
            else {
                toast.error('Unable to load invoice, please try again', { duration: 7000 })
            }
        })
    }

    useEffect(() => {
        if (DataValidationState.confirmation === false) {
            DataValidationDispatch({
                type: 'SET_STATUS',
                payload: ''
            })
            DataValidationDispatch({
                type: 'SET_INVALID_MESSAGE',
                payload: ''
            })
            DataValidationDispatch({
                type: 'SET_INVALID_MESSAGE_OTHER',
                payload: ''
            })
        }

        if (DataValidationState.status === 'approved') {
            DataValidationDispatch({
                type: 'SET_INVALID_MESSAGE',
                payload: ''
            })
            DataValidationDispatch({
                type: 'SET_INVALID_MESSAGE_OTHER',
                payload: ''
            })
        }

        if (DataValidationState.invalidMessage.toLowerCase() !== "other") {
            DataValidationDispatch({
                type: 'SET_INVALID_MESSAGE_OTHER',
                payload: ''
            })
        }
    }, [DataValidationState.confirmation, DataValidationState.status, DataValidationState.invalidMessage])

    return (
        <BillingLayout>
            <div class="content-header">
                <div class="d-flex align-items-center">
                    <div class="me-auto">
                        <div class="d-inline-block align-items-center">
                            <nav>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="mdi mdi-home-outline"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" aria-current="page">Billing & Invoices</li>
                                    <li class="breadcrumb-item" aria-current="page">Billing & Invoice Approvals</li>
                                    <li class="breadcrumb-item active" aria-current="page">Invoice - {invoice.invoiceNo}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="box">
                    <div class="box-header with-border">
                        <div class="box-in">
                            <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">

                                <strong>View Invoice</strong>
                            </h4>
                        </div>
                    </div>
                    {res.isError && <LoadingErrorComponent error />}
                    {res.isLoading && <LoadingErrorComponent loading />}
                    {res.isSuccess && <div class="box-body">
                        <form action="">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="" class="form-label">Invoice No.</label>
                                        <input type="text" value={invoDetails?.resubInvoiceNo ? invoDetails?.resubInvoiceNo : invoDetails?.invoiceNo} disabled class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="" class="form-label">Invoice Date</label>
                                        <input type="text" value={invoDetails?.resubInvoiceDate ? invoDetails?.resubInvoiceDate : invoDetails?.invoiceDate} disabled class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="" class="form-label">Statement Amount (ZAR)</label>
                                        <input type="text" value={invoDetails?.totalAmount} disabled class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="" class="form-label">Statement Ref. No.</label>
                                        <input type="text" value={invoDetails?.statementRef} disabled class="form-control" />
                                    </div>
                                </div>
                                {/* <div class="col-md-6">
                                    <h5 class="fw-bold">Record statistics</h5>
                                    <p class="mb-1 fw-bold">{invoDetails?.noOfClientsInBatch} records</p>
                                    <p class="mb-1 fw-bold text-success">{invoDetails?.validClients} valid</p>
                                    <p class="mb-1 fw-bold text-danger">{invoDetails?.invalidClients} invalid</p>
                                </div> */}
                                <div class="col-4">
                                    <div className="timeline-fill-box overlay-timeline-fill-box" >
                                        <div className="overlay" onClick={() => setInvoiceModal(true)}>Click here to view</div>
                                        {
                                            invoDetails?.resubInvoiceFile && invoDetails?.resubInvoiceFile.includes("/pdf") ? (
                                                <iframe src={invoDetails?.resubInvoiceFile} alt="" height={300} width={"100%"} title="pdf" />
                                            ) : invoDetails?.resubInvoiceFile && invoDetails?.resubInvoiceFile?.includes("image/") ? (
                                                <img class="img-fluid border" src={invoDetails?.resubInvoiceFile} alt="" />
                                            ) : invoDetails?.invoiceFile && invoDetails?.invoiceFile.includes("/pdf") ? (
                                                <iframe src={invoDetails?.invoiceFile} alt="" height={300} width={"100%"} title="pdf" />
                                            ) : (
                                                <img class="img-fluid border" src={invoDetails.invoiceFile} alt="" />
                                            )
                                        }
                                        <ViewImageModal ModalTitle={"Invoice File"} modalState={invoiceModal} modalSetState={setInvoiceModal} modalUrl={invoDetails?.resubInvoiceFile ? invoDetails?.resubInvoiceFile : invoDetails?.invoiceFile} />
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div id="collapseValidation" class="" aria-labelledby="collapseValidation" data-bs-parent="">
                            <div class="">
                                <div class="row justify-content-center">
                                    <div class="col-md-5">
                                        <div class="form-check ps-0 mt-3 mb-3">
                                            <input
                                                class="filled-in chk-col-danger edit-form edit-form"
                                                type="checkbox"
                                                id="confirmCheck"
                                                onChange={(event) => DataValidationDispatch({
                                                    type: 'SET_CONFIRMATION',
                                                    payload: event.target.checked
                                                })}
                                                checked={DataValidationState.confirmation}
                                            />
                                            <label class="form-check-label" for="confirmCheck">
                                                I confirm that I have reviewed the information contained in this
                                                invoice.
                                            </label>
                                        </div>
                                        {
                                            DataValidationState.confirmation && (
                                                <div class="row afterConfirmCheck ">
                                                    <div class="col-md-12">
                                                        <div>
                                                            <div class="form-check form-check-inline mx-2 ps-0">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    name="validInvalid"
                                                                    id="exampleRadios1"
                                                                    value="approved"
                                                                    onChange={(event) => DataValidationDispatch({
                                                                        type: 'SET_STATUS',
                                                                        payload: event.target.value
                                                                    })}
                                                                    checked={DataValidationState.status.toLowerCase() === 'approved'}
                                                                />
                                                                <label class="form-check-label me-0" for="exampleRadios1">
                                                                    Approve invoice
                                                                </label>
                                                            </div>
                                                            <div class="form-check form-check-inline mx-2 ps-0">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    name="validInvalid"
                                                                    id="exampleRadios2"
                                                                    value="rejected"
                                                                    onChange={(event) => DataValidationDispatch({
                                                                        type: 'SET_STATUS',
                                                                        payload: event.target.value
                                                                    })}
                                                                    checked={DataValidationState.status.toLowerCase() === 'rejected'}
                                                                />
                                                                <label class="form-check-label me-0" for="exampleRadios2">
                                                                    Reject invoice
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {
                                                            (DataValidationState.confirmation && DataValidationState.status.toLowerCase() === 'rejected') && (
                                                                <div class="mt-4 invalidatedTextarea text-start">
                                                                    <label for="" class="form-label">Reason for rejection</label>
                                                                    <select name="" id="" className='form-select'
                                                                        onChange={(event) => DataValidationDispatch({
                                                                            type: 'SET_INVALID_MESSAGE',
                                                                            payload: event.target.value
                                                                        })}
                                                                    >
                                                                        <option value="">Select Reason</option>
                                                                        {
                                                                            rejectReasons?.data?.data?.reasons?.map((el) => {
                                                                                return (
                                                                                    <option value={el.reason}>{el.reason}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {(DataValidationState.invalidMessage.toLowerCase() === "other") && (
                                                                        <textarea textarea
                                                                            name=""
                                                                            id=""
                                                                            cols="30"
                                                                            rows="5"
                                                                            class="form-control mt-3"
                                                                            placeholder="Write reason for rejection... "
                                                                            onChange={(event) => DataValidationDispatch({
                                                                                type: 'SET_INVALID_MESSAGE_OTHER',
                                                                                payload: event.target.value
                                                                            })}
                                                                            value={DataValidationState.invalidMessageOther}
                                                                            required={DataValidationState.invalidMessage.toLowerCase() === "other" ? true : false}
                                                                        ></textarea>
                                                                    )}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div class="box-footer d-flex justify-content-center mt-4">
                                    {
                                        response.isLoading === true ?
                                            (<button disabled className="btn btn-primary">
                                                <span className="spinnerInButton"></span>
                                            </button>)
                                            :
                                            (<button type="button" class="btn btn-primary edit" onClick={handleInvoiceApproval}>Submit</button>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </section>
        </BillingLayout >
    )
}

export default ViewInvoice
