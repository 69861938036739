import React, { useEffect, useState } from 'react'
import ReceptionLayout from '../../ReceptionLayout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAddEditfollowUpFormMutation } from '../../../../store/api/reception/followupApi'
import FollowUpClientForm from '../../../../components/FollowUp/FollowupClientForm'
import { useGetSingleClientMutation } from '../../../../store/api/reception/manageClient'
import { useSelector } from 'react-redux'


const OnlyAddFollowupClient = () => {
    const { user } = useSelector((store) => {
        return store.userSlice;
    });
    const [addEditfollowUpForm] = useAddEditfollowUpFormMutation()
    const navigate = useNavigate()
    return (
        <ReceptionLayout>
            <div className="container-full">
                <div className="content-header">
                    <div className="d-flex align-items-center">
                        <div className="me-auto">
                            <div className="d-inline-block align-items-center">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/"><i className="mdi mdi-home-outline"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            Follow-up Form
                                        </li>
                                        <li className="breadcrumb-item active">
                                            Add Follow-up
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="box">
                        <div className="box-header with-border">
                            <div className="box-in">
                                {user.role === "Super User/Admin" ?
                                    <button onClick={() => navigate("/dashboard")} className='btn btn-danger-light'>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-arrow-left"
                                        >
                                            <line x1={19} y1={12} x2={5} y2={12} />
                                            <polyline points="12 19 5 12 12 5" />
                                        </svg>
                                    </button> :
                                    <button onClick={() => navigate("/reception/dashboard")} className='btn btn-danger-light'>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-arrow-left"
                                        >
                                            <line x1={19} y1={12} x2={5} y2={12} />
                                            <polyline points="12 19 5 12 12 5" />
                                        </svg>
                                    </button>
                                }
                                <h4 className="box-title me-auto ms-4">
                                    <strong>Add Follow-up Client</strong>
                                </h4>
                            </div>
                        </div>
                        <div className="box-body">
                            <FollowUpClientForm type="onlyAdd" mutation={addEditfollowUpForm} />
                        </div>
                    </div>
                </section>
            </div>
        </ReceptionLayout>
    )
}

export default OnlyAddFollowupClient;
