import React, { useEffect, useState } from 'react'
import ReceptionLayout from '../../../ReceptionLayout'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGetBatchStatementMutation, useViewRecordInBatchMutation } from '../../../../../store/api/reception/billingApi'
import LoadingErrorComponent from '../../../../../components/LoadingErrorComponent/LoadingErrorComponent'
import PaginationComp from '../../../../../components/Pagination/paginationComp'

const BillingSummeryListToProcess = () => {
    const navigate = useNavigate()
    const param = useParams()
    const [paginationDetails, setPaginationDetails] = useState({})
    const [BatchStatementDataMutation, BatchStatementData] = useGetBatchStatementMutation()
    const [searchParams, setSearchParams] = useSearchParams();
    const [viewRecordInBatch, response] = useViewRecordInBatchMutation()
    const [record, setRecord] = useState([])

    const [page, setPageNo] = useState(searchParams.get("page") || 1);
    const changePage = (page) => {
        setPageNo(page);
    };

    const paginationDataString = response?.data?.message.split(": ")[1];
    useEffect(() => {
        if (paginationDataString) {
            const paginationData = JSON.parse(paginationDataString?.replace(/=/g, ':').replace(/(\w+)/g, '"$1"'));
            setPaginationDetails({
                CurrentPage: Math.ceil(paginationData.CurrentPage),
                ItemsPerPage: Math.ceil(paginationData.ItemsPerPage),
                TotalCount: Math.ceil(paginationData.TotalCount),
                TotalPages: Math.ceil(paginationData.TotalPages),
            })
        }
    }, [response?.data?.message])

    useEffect(() => {
        let params = { page };
        setSearchParams(params);
    }, [page, setSearchParams]);

    useEffect(() => {
        viewRecordInBatch({
            BatchNo: param?.batchNo?.toString()
        }).then((res) => {
            setRecord(res?.data?.data)
        })
        BatchStatementDataMutation({ batch_Number: param?.batchNo?.toString() })
    }, [])

    return (
        <ReceptionLayout>
            <div class="content-header">
                <div class="d-flex align-items-center">
                    <div class="me-auto">
                        <div class="d-inline-block align-items-center">
                            <nav>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <Link to="">
                                            <i class="mdi mdi-home-outline"></i>
                                        </Link>
                                    </li>
                                    <li class="breadcrumb-item" aria-current="page">Facility Billing</li>
                                    <li class="breadcrumb-item " aria-current="page">
                                        Billing Summery
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Batch - {param?.batchNo?.toString()}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="box">
                    <div class="box-header with-border">
                        <div class="box-in">
                            <button onClick={() => navigate("/reception/manage-billing/billing-summery")} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">
                                <strong>Billing Summary List</strong>
                            </h4>
                            <div class="box-right">
                                <div class="row justify-content-end">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="table-top-search">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Search by</option>
                                                <option value="cdcc_barcode">Barcode CDCC No</option>
                                                <option value="client_name">Client Name</option>
                                                <option value="identity_number">Identity Number</option>
                                                <option value="folder_number">Folder number</option>
                                            </select>
                                            <input type="search" class="form-control" placeholder="Search" aria-controls="complex_header" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {response.isError && <LoadingErrorComponent error />}
                    {response.isLoading && <LoadingErrorComponent loading />}
                    {response.isSuccess && (
                        <>
                            <div class="box-body">
                                {BatchStatementData.isSuccess && (
                                    BatchStatementData?.data?.data && (
                                        <div class="mb-4">
                                            <p class="mb-1">
                                                <span class="fw-bold me-2">Statement -</span> Total {BatchStatementData?.data?.data?.noOfClientsInBatch} records
                                                <span class="fw-bold text-success ms-2">{BatchStatementData?.data?.data?.validClients} valid</span>
                                                <span class="fw-bold text-danger ms-2">{BatchStatementData?.data?.data?.invalidClients} invalid</span>
                                            </p>
                                        </div>
                                    )
                                )}
                                <div class="table-responsive">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Sr.</th>
                                                <th>Capture Date</th>
                                                <th>CDC Barcode No.</th>
                                                <th>Client Name</th>
                                                {/* <th>Client ID</th> */}
                                                <th>Verification ID</th>
                                                <th>Folder Number</th>
                                                <th>QA 1</th>
                                                <th>QA 2</th>
                                                <th className='text-center'>Validation Status</th>
                                                <th>Action Items</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                record?.map((el, i) => (
                                                    <tr class="hover-primary">
                                                        <td>{i + 1}</td>
                                                        <td>{el.captureDate}</td>
                                                        <td>{el.barcode_cdc_number}</td>
                                                        <td>{el.firstname} {el.lastname}</td>
                                                        {/* <td>{el.client_id}</td> */}
                                                        <td>
                                                            {el.verification_id && <div>{el.verification_id}</div>}
                                                            {el.identity_verification && <div>{el.identity_verification}</div>}
                                                        </td>
                                                        <td>{el.folder_number}</td>
                                                        <td>
                                                            {el.qA1status.toLowerCase() === "done" && (
                                                                <span class="fs-14 badge badge-success-light">
                                                                    <i class="fa fa-check"></i>
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {el.qA2status.toLowerCase() === "done" && (
                                                                <span class="fs-14 badge badge-success-light">
                                                                    <i class="fa fa-check"></i>
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td className='text-center'>
                                                            {el.verificationStatus.toLowerCase() === "valid" ? <span class="badge badge-success-light">Valid</span> : <span class="badge badge-danger-light">Invalid</span>}
                                                        </td>
                                                        <td>
                                                            <div class="btn-group">
                                                                <Link to={`/reception/manage-billing/billing-summery/batch-processed/${param.batchNo}/view-client/${el.client_id}`} class="hover-primary dropdown-toggle no-caret btn-more px-2">
                                                                    <i class="fa fa-eye"></i>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    className='d-flex align-items-center justify-content-between mt-2'
                                >
                                    {
                                        <div>
                                            {`Showing ${page - 1}1 to ${paginationDetails.TotalCount > page * 10 ? page * 10 : paginationDetails.TotalCount}  of ${paginationDetails.TotalCount} entries`}
                                        </div>
                                    }
                                    <div>
                                        <PaginationComp currentPage={paginationDetails.CurrentPage} totalPages={paginationDetails.TotalPages} onPageChange={changePage} />
                                    </div>
                                </div>

                                {/* <div class="text-center mt-2">
                                    <Link to={`/reception/manage-billing/billing-summery/batch-to-process/${param.batchNo}/uploadInvoice`} class="btn btn-primary">Upload Invoice</Link>
                                </div> */}
                            </div>
                        </>
                    )}
                </div>
            </section>
        </ReceptionLayout>
    )
}

export default BillingSummeryListToProcess
