import React from 'react'

const AddMoreTableRow = ({data, index, handleRowSelect, selectedRows}) => {
  console.log(data)
  return (
    <tr>
        <td className="vertical-align">
            <input
              type="checkbox"
              id={`${data?.client_id}_client_id`}
              className="filled-in chk-col-danger"
              checked={selectedRows?.includes(data?.client_id)}
              defaultChecked={selectedRows?.includes(data?.client_id)}
              onChange={handleRowSelect}
            />
            <label htmlFor={`${data?.client_id}_client_id`} />
        </td>
        <td>{index+1}</td>
        <td>{new Date(data?.capture_date).toLocaleDateString()}</td>
        <td>{data?.barcodeCDCNumber}</td>
        <td>{data?.clientName}</td>
        <td>{data?.client_id}</td>
        <td>
          {/* {data?.identityVerification} */}
          {data?.veriificationId}
        </td>
        <td>{data?.booking_id}</td>
        <td>{data?.folder_number}</td>                             
        <td className="text-center">
            <span className="badge badge-danger-light">{data?.status}</span>
        </td>
    </tr>
  )
}

export default AddMoreTableRow