import React, { useState, useEffect } from "react";
import { useSearchParams, useParams, useNavigate, Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { useGetClientListQuery } from "../../../../../store/api/reception/manageClient";
import { useSubmitBatchMutation, useViewBatchQuery } from "../../../../../store/api/reception/batchApi";
import AddMoreModal from "./components/AddMore/AddMoreModal";
import toast from "react-hot-toast";
import ReceptionLayout from "../../../ReceptionLayout";
import LoadingErrorComponent from "../../../../../components/LoadingErrorComponent/LoadingErrorComponent";
import PaginationComp from "../../../../../components/Pagination/paginationComp";
import { useSelector } from "react-redux";

const BatchProgress = () => {
  const { user } = useSelector((store) => {
    return store.userSlice;
  });
  const [paginationDetails, setPaginationDetails] = useState({})
  const [addModal, setAddModal] = useState(false);
  let params = useParams()
  const navigate = useNavigate();

  const [checkedItems, setCheckedItems] = useState([]);
  const handleCheckboxChange = (value) => {
    if (checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter(item => item !== value));
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort_by, setSortBy] = useState(searchParams.get("sort_by") || "");
  const [searchBy, setSearchBy] = useState(searchParams.get("searchBy") || "");
  const [searchKeyword, setSearchKeyword] = useState(searchParams.get("searchKeyword") || "");
  const [page, setPageNo] = useState(searchParams.get("page") || 1);
  let batch_view = useViewBatchQuery({
    batchNumber: params.id,
    sort_by: sort_by,
    searchKeyword: searchKeyword,
    page: page,
    per_page: 10,
    searchBy: searchBy,
  })
  let selectedcClientIds = batch_view?.data?.data.map(item => item.clientID);
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = batch_view?.data?.data.map((item) => item.clientID);
      setCheckedItems(allIds);
    } else {
      setCheckedItems([]);
    }
  };

  useEffect(() => {
    setCheckedItems(selectedcClientIds)
  }, [batch_view.status])


  const clientList = useGetClientListQuery();


  // Function to handle page change
  const changePage = (selected) => {
    setPageNo(selected);
  };
  const handleSortBy = (elem) => {
    setSortBy(elem.target.value);
  };
  const handleSearchBy = (elem) => {
    setSearchBy(elem.target.value);
  };
  const handleSearchKeyword = (elem) => {
    setSearchKeyword(elem.target.value);
  };

  useEffect(() => {
    let params = { page };
    if (sort_by) {
      params.sort_by = sort_by;
    }
    if (searchBy) {
      params.searchBy = searchBy;
    }
    if (searchKeyword) {
      params.searchKeyword = searchKeyword;
    }
    setSearchParams(params);
  }, [page, sort_by, searchBy, searchKeyword, setSearchParams]);

  const paginationDataString = batch_view?.data?.message.match(/{([^}]+)}/)[1].replace(/(\w+)\s*=\s*(\d+)/g, '"$1": $2');
  useEffect(() => {
    if (paginationDataString) {
      const paginationData = JSON.parse("{" + paginationDataString + "}");
      console.log(paginationData)

      setPaginationDetails({
        CurrentPage: Math.ceil(paginationData.CurrentPage),
        ItemsPerPage: Math.ceil(paginationData.ItemsPerPage),
        TotalCount: Math.ceil(paginationData.TotalCount),
        TotalPages: Math.ceil(paginationData.TotalPages),
      })
    }
  }, [batch_view?.data?.message])

  const [submitBatch, response] = useSubmitBatchMutation()
  const checkedItemsString = checkedItems?.map(number => number?.toString());

  const handleSubmitBatch = () => {
    const data = {
      batch_Number: params.id,
      client_id: checkedItemsString
    }
    if (checkedItemsString.length > 0) {
      submitBatch(data).then((res) => {
        if (res?.data?.status === 1) {
          toast.success("Batch Updated Successfully!", { duration: 7000 })
          navigate("/reception/batch-management")
        } else if (res?.data?.status === 0) {
          toast.error(res?.data?.message, { duration: 7000 })
        } else {
          toast.error("Unable to submit data, please try again", { duration: 7000 })
        }
      })
    } else {
      toast.error("Please select atleast one client!", { duration: 7000 })
    }
  }

  //download list of clients in excel 
  const [DownloadXLState, setDownloadXLState] = useState(false)
  const downloadDataAsExcel = async () => {
    setDownloadXLState(true)
    const queryString = new URLSearchParams({
      batchNumber: params.id,
      page: 1,
      per_page: paginationDetails.TotalCount,
    }).toString();

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/MedicalForm/ViewBatch?${queryString}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      return response.json()
    }).then((response) => {
      const userXLS = response.data?.map((elem, i) => {
        return {
          "sr_no": i + 1,
          "clientID": elem.clientID,
          "barcode_cdc_number": elem.barcode_cdc_number,
          "capture_date": elem.capture_date,
          "folder_number": elem.folder_number,
          "Fullname": elem.firstName,
          "dateOfBirth": elem.dateOfBirth,
          "gender": elem.gender,
          "nationality": elem.nationality,
          "identityVerification": elem.identityVerification,
          "identityVerificationNumber": elem.identityVerificationNumber,
          "email": elem.email,
          "contactNumber": elem.contactNumber,
          "nextOfKinContactNumber": elem.nextOfKinContactNumber,
          "relationshipWithClient": elem.relationshipWithClient,
          "booking_id": elem.booking_id,
          "social_mobilizer": elem.social_mobilizer,
          "status": elem.status
        }
      })
      if (userXLS.length > 0) {
        // Convert data to CSV format
        const csvData = convertToCSV(userXLS);
        // Create a Blob containing the CSV data
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        // Trigger download
        saveAs(blob, `batch_inprogress_${params.id}.csv`);
        setDownloadXLState(false)
      } else {
        toast.error('No record found to export', { duration: 7000 });
      }
    }).catch(err => {
      console.log(err)
      setDownloadXLState(false)
      toast.error('Unable to download excel file, please try again', { duration: 7000 });
    })
  };
  const convertToCSV = (dataArray) => {
    // Convert array of objects to CSV string
    const header = Object.keys(dataArray[0]).join(",") + "\n";
    const body = dataArray?.map((obj) => Object.values(obj).join(",")).join("\n");
    return header + body;
  };

  return (
    <ReceptionLayout>
      <div className="container-full">
        <div className="content-header">
          <div className="d-flex align-items-center">
            <div className="me-auto">
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="mdi mdi-home-outline" />
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Batch Management
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Manage Batches
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Batch-{params.id}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="box">
            <div className="box-header with-border">
              <div className="box-in">
                <button onClick={() => navigate("/reception/batch-management")} className='btn btn-danger-light'>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-left"
                  >
                    <line x1={19} y1={12} x2={5} y2={12} />
                    <polyline points="12 19 5 12 12 5" />
                  </svg>
                </button>
                <h4 className="box-title me-auto ms-4">
                  <strong>Batch-{params.id}</strong>
                </h4>
                <div className="box-right">
                  {batch_view.isSuccess && (
                    <>
                      <button type="button" className="btn btn-secondary mx-2" style={{ minWidth: '125px' }} onClick={() => setAddModal(true)}>
                        + Add Records
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-export"
                        onClick={downloadDataAsExcel}
                        disabled={DownloadXLState}
                      >
                        Export to XLS
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="box-body">
              <div className="row mb-3 gx-2 justify-content-between">
                <div className="col-sm-12 col-md-6 col-xl-3">
                  <select
                    className="form-select mb-xl-0 mb-2"
                    aria-label="sort_by"
                    value={sort_by}
                    onChange={handleSortBy}
                  >
                    <option selected="">Sort by</option>
                    <option value="first_to_last">First to Last</option>
                    <option value="last_to_first"> Last to First</option>
                  </select>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="table-top-search">
                    <select className="form-select" aria-label="search by" onChange={handleSearchBy} value={searchBy}>
                      <option selected="">Search by</option>
                      <option value="barcode">Barcode</option>
                      <option value="client_name">Client Name</option>
                      <option value="identity_number">Identity Number</option>
                      <option value="contact_number">Contact number</option>
                      <option value="folder_number">Folder number</option>
                      <option value="status">Status</option>
                    </select>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      aria-controls="search"
                      value={searchKeyword}
                      onChange={handleSearchKeyword}
                    />
                  </div>
                </div>
              </div>
              {batch_view.isError && <LoadingErrorComponent error />}
              {batch_view.isLoading && <LoadingErrorComponent loading />}
              {batch_view.isSuccess && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive rounded card-table">
                        {
                          <table className="table border-no" id="example1" style={{ width: "100%" }}>
                            <thead>
                              <tr>
                                <th className="vertical-align">
                                  <input
                                    type="checkbox"
                                    id="b_check"
                                    className="filled-in chk-col-danger"
                                    onChange={handleSelectAll}
                                    checked={checkedItems?.length === batch_view?.data?.data?.length}
                                  />
                                  <label htmlFor="b_check" />
                                </th>
                                <th>Sr.</th>
                                <th>Capture Date</th>
                                <th>CDC Barcode No.</th>
                                <th>Client Name</th>
                                <th>Verification ID</th>
                                <th>Folder Number</th>
                                <th>Status</th>
                                <th>Action Items</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                batch_view?.data?.data.length > 0 ? (
                                  batch_view?.data?.data.map((ele, i) => (
                                    <tr key={i}>
                                      <td className="vertical-align">
                                        <input
                                          type="checkbox"
                                          className="filled-in chk-col-danger"
                                          id={`b_checkbox_${ele.clientID}`}
                                          onChange={() => handleCheckboxChange(ele.clientID)}
                                          checked={checkedItems?.includes(ele.clientID)}
                                        />
                                        <label htmlFor={`b_checkbox_${ele.clientID}`} />
                                      </td>
                                      <td>{i + 1}</td>
                                      <td>{ele.capture_date}</td>
                                      <td>{ele.barcode_cdc_number}</td>
                                      <td>{ele.firstName}</td>
                                      <td>
                                        <div>{ele.identityVerification}</div>
                                        <div>{ele.identityVerificationNumber}</div>
                                      </td>
                                      <td>{ele.folder_number}</td>
                                      <td>
                                        {ele.status !== "" && <span
                                          className={
                                            ele.status.toLowerCase() === "updated" ? "badge badge-success-light"
                                              :
                                              ele.status.toLowerCase() === "newly added" ? "badge badge-warning-light" : "badge badge-danger-light"
                                          }
                                        >{ele.status}</span>}
                                      </td>
                                      <td>
                                        <button type="button" className="dropdown-toggle btn btn-Secondary" onClick={() => navigate(`/reception/batch-view/${ele.clientID}`)}>
                                          <i className="fa fa-eye"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) :
                                  (
                                    <td colSpan={11} className="text-center pt-3">No Data Found</td>
                                  )
                              }
                            </tbody>
                          </table>
                        }
                      </div>

                      {batch_view?.data?.data.length > 0 &&
                        <div className="d-flex justify-content-between mt-3">
                          {
                            <div>
                              {`Showing ${page - 1}1 to ${paginationDetails.TotalCount > page * 10 ? page * 10 : paginationDetails.TotalCount}  of ${paginationDetails.TotalCount} entries`}
                            </div>
                          }
                          <div>
                            <PaginationComp currentPage={paginationDetails.CurrentPage} totalPages={paginationDetails.TotalPages} onPageChange={changePage} />
                          </div>
                        </div>
                      }
                      {batch_view?.data?.data.length > 0 &&
                        (<div className="mt-3 text-center">
                          {
                            response.isLoading === true ?
                              (<button disabled className="btn btn-primary">
                                <span className="spinnerInButton"></span>
                              </button>)
                              :
                              (<button type="button" className="btn btn-primary mx-2" style={{ minWidth: '117px' }} onClick={handleSubmitBatch}>
                                Submit
                              </button>)
                          }
                        </div>)
                      }
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </div>

      <AddMoreModal batchNo={params.id} addModalState={addModal} setAddModal={setAddModal} existingClientList={clientList?.data?.data?.map(elem => elem.clientID)} />
    </ReceptionLayout>
  );
}

export default BatchProgress