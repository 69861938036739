import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import SomthingWentWrongImg from '../../assets/images/auth-bg/no_access.png'
import './NoAccessOnThisPage.css'
const NoAccessOnThisPage = () => {
  const [contentHeight, setContentHeight] = useState(700)
  useEffect(() => {
    // Get the element
    const headerHeight = document.getElementsByClassName('main-header')[0]?.offsetHeight;
    const windowHeight = window.innerHeight;
    setContentHeight(windowHeight - headerHeight)

    const handleResize = () => {
      const headerHeight = document.getElementsByClassName('main-header')[0]?.offsetHeight;
      const windowHeight = window.innerHeight;
      setContentHeight(windowHeight - headerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      // const baseUrl = window.location.origin;
      // window.location.href = `${baseUrl}/login`;
    }, 3000);

    return () => clearTimeout(redirectTimer);
  }, [])

  return (
    <Layout>
      <div className="container-full" style={{ height: contentHeight + 'px', overflowY: 'auto' }}>
        <section className="NoPageFound">
          <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 118 87"
            width={118}
            height={87}
          >
            <title>barricade</title>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n\t\t.s0 { fill: #d8d3cb } \n\t\t.s1 { fill: #f4f2ed } \n\t\t.s2 { fill: #ad2125 } \n\t"
              }}
            />
            <g id="barricade">
              <g id="Layer">
                <path id="Layer" className="s0" d="m2 87h17.3l18.1-85.5-16-0.5z" />
              </g>
              <g id="Layer">
                <path id="Layer" className="s0" d="m81.9 1.5l18.1 85.5h17.3l-19.5-86z" />
              </g>
              <g id="Layer">
                <path id="Layer" className="s1" d="m2.5 24.9h112.6l1.7-18.1h-116.1z" />
              </g>
              <g id="Layer">
                <path id="Layer" className="s2" d="m2.5 24.9h0.6l18.1-18.1h-20.5z" />
              </g>
              <g id="Layer">
                <path id="Layer" className="s2" d="m99.1 24.9h16l1.6-17.7z" />
              </g>
              <g id="Layer">
                <path id="Layer" className="s2" d="m20.6 24.9h22.2l18.1-18.1h-22.2z" />
              </g>
              <g id="Layer">
                <path id="Layer" className="s2" d="m60 24.9h22.2l18-18.1h-22.1z" />
              </g>
              <g id="Layer">
                <path id="Layer" className="s1" d="m0.7 54.5h116.1l-1.7-18.1h-112.6z" />
              </g>
              <g id="Layer">
                <path id="Layer" className="s2" d="m0.7 54.5h0.7l18.2-18.1h-17.1z" />
              </g>
              <g id="Layer">
                <path id="Layer" className="s2" d="m97.4 54.5h19.4l-1.7-17.7z" />
              </g>
              <g id="Layer">
                <path id="Layer" className="s2" d="m19 54.5h22.1l18.1-18.1h-22.1z" />
              </g>
              <g id="Layer">
                <path id="Layer" className="s2" d="m58.4 54.5h22.1l18.1-18.1h-22.2z" />
              </g>
            </g>
          </svg>

          <div>
            <h1>Access Denied</h1>
            <p>
              You currently does not have access to this page. <br />Please try again later
            </p>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default NoAccessOnThisPage