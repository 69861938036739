import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const FollowUpApis = createApi({
    reducerPath: "followUpApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            headers.set('Authorization', `Bearer ${userSlice.user.token}`);
            return headers
        },
    }),
    tagTypes: ["followUp"],
    endpoints: (builder) => ({
        followUpStatus: builder.query({
            query: () => {
                return {
                    url: "/FollowUpForm/FollowUpStatus",
                    method: "GET",
                }
            },
            providesTags: ["followUp"],
        }),
        fourtyEightHourClients: builder.query({
            query: (data) => {
                return {
                    url: "/FollowUpForm/Get48HourClients",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["followUp"],
        }),
        savenDaysClients: builder.query({
            query: (data) => {
                return {
                    url: "/FollowUpForm/Get7DayClients",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["followUp"],
        }),
        fourteenDaysClients: builder.query({
            query: (data) => {
                return {
                    url: "/FollowUpForm/Get14DayClients",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["followUp"],
        }),
        overdueClientList: builder.query({
            query: (data) => {
                return {
                    url: `/FollowUpForm/ViewOverdueClients`,
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["followUp"],
        }),
        dueClientList: builder.query({
            query: (data) => {
                return {
                    url: "/FollowUpForm/GetDueIn48Hours",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["followUp"],
        }),
        followUpClientByBar: builder.mutation({
            query: (data) => {
                return {
                    url: `FollowUpForm/GetBulkClientByBar`,
                    method: "POST",
                    params: {
                        cdfbarcode: data
                    },
                };
            },
            invalidatesTags: ["followUp"],
        }),
        getBulkClients: builder.query({
            query: (data) => {
                return {
                    url: "/FollowUpForm/BulkFollowUpLookUp",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["followUp"],
        }),
        addEditfollowUpForm: builder.mutation({
            query: (data) => {
                return {
                    url: `FollowUpForm/Add-EditFollowUpForm`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["followUp"],
        }),
        followUpClientById: builder.mutation({
            query: (data) => {
                return {
                    url: `FollowUpForm/GetFollowUpClientById`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["followUp"],
        }),
        followUpClientByCDF: builder.mutation({
            query: (data) => {
                return {
                    url: `FollowUpForm/GetClientByCDF`,
                    method: "POST",
                    params: data
                };
            },
            invalidatesTags: ["followUp"],
        }),
        followupLookup: builder.query({
            query: (data) => {
                return {
                    url: `/FollowUpForm/ListFollowUpClients`,
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["followUp"],
        }),
    }),
});

export const {
    useFollowupLookupQuery,
    useFollowUpStatusQuery,
    useFourtyEightHourClientsQuery,
    useSavenDaysClientsQuery,
    useFourteenDaysClientsQuery,
    useOverdueClientListQuery,
    useDueClientListQuery,
    useFollowUpClientByIdMutation,
    useGetBulkClientsQuery,
    useFollowUpClientByBarMutation,
    useAddEditfollowUpFormMutation,
    useFollowUpClientByCDFMutation
} = FollowUpApis;
