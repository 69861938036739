import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import Login from '../pages/Auth/Login/Login'
import Dashboard from '../pages/Reception/Dashboard/Dashboard'
import ForgotPassword from '../pages/Auth/Forgot_password/ForgotPassword'
import PrivateRoute from './PrivateRoute'
import OtpVerificationPage from '../pages/Auth/Forgot_password/OtpVerificationPage'
import ChangePassword from '../pages/Auth/Forgot_password/ChangePassword'
import HapeAndResourcesPage from '../pages/Reception/HelpAndResources/HelpAndResourcesPage'
import BatchView from '../pages/Reception/BatchManagement/manage/BatchView/BatchView'
import {
  Manage as ManageBatch, Create as CreateBatch, BatchProgress, BatchSubmitted,
  ScheduleLookup, BulkDataUpload as FollowUpBulkDataUpload,
  FourteeEightTotalFollowUpList, SavenTotalFollowUpList, FourteeDaysTotalFollowUpList,
  Overdue, Due, DataCaptureReceptionist, DataValidationCallCenter, FacilityAdministratorDoctor,
  MmcSupervisorManager, MmcAdministrator, AddUsers, ExistingUsers, PendingUsers,
  Facility, ImplementingPartners, Location,
  QAOneUsersManageBatchValidationPage, QAOneUsersInProgressBatches, QAOneUsersCompletedBatches,
  QAUsersDashboardPage, QAUsersHelpAndResourcesPage,
  QATwoUsersManageBatchValidationPage,
  QATwoUsersInProgressBatches,
  QAOneViewCompletedClient,
  QAOneViewInprogressClient,
  QATwoViewInprogressClient,
  QATwoUsersCompletedBatches,
  QATwoViewCompletedClient,
  BillingDashboard,
  BillingInvoiceApprovals,
  ViewInvoice,
  BillingSummery,
  Registration,
  ViewBillingClient,
  NoAccessOnThisPage,
  AdminDashboard,
  AfterRegistration,
  AddFollowupClient,
  ViewClientBilling,
  FollowupForm,
} from '../pages/AllPages'

import ViewFollowupClient from '../pages/Reception/FollowUp/ScheduleLookup/ViewClient/ViewClient'
import ClientLookUpPage from '../pages/Reception/ManageClientRecord/ClientLookup/ClientLookUpPage'
import AddClientPage from '../pages/Reception/ManageClientRecord/AddClient/AddClientPage'
import ClientLookupViewandedit from '../pages/Reception/ManageClientRecord/ClientLookup/ClientLookupView_and_edit'
import BulkUpload from '../pages/Reception/ManageClientRecord/BulkUpload/Bulk_Upload'
import EditUser from '../pages/userManagement/manageUsers/editUser/EditUser'
import ApproveRejectedPayment from '../pages/Reception/ManageBilling/ApproveRejectedPayments/ApproveRejectedPayment'
import UploadInvoice from '../pages/Reception/ManageBilling/BillingSummery/uploadInvoice/UploadInvoice'
import AmountDue from '../pages/Billing/billingDashboard/AmountDue/AmountDue'
import AmountProgress from '../pages/Billing/billingDashboard/AmountProgress/AmountProgress'
import ViewBatch from '../pages/Billing/billingDashboard/AmountDue/ViewBatch/ViewBatch'
import RejectedBatchView from '../pages/Reception/ManageBilling/ApproveRejectedPayments/BatchView/RejectedBatchView'
import ApprovedBatchView from '../pages/Reception/ManageBilling/ApproveRejectedPayments/BatchView/ApprovedBatchView'
import ResubmitInvoice from '../pages/Reception/ManageBilling/ApproveRejectedPayments/BatchView/resubmitInvoice/ResubmitInvoice'
import BillingSummeryListToProcess from '../pages/Reception/ManageBilling/BillingSummery/BillingSummeryList/BillingSummeryListToProcess'
import BillingSummeryListProcessed from '../pages/Reception/ManageBilling/BillingSummery/BillingSummeryList/BillingSummeryListProcessed'
import SetNewPassword from '../pages/Auth/setNewPassword/SetNewPassword'
import OnlyAddFollowupClient from '../pages/Reception/FollowUp/AddClient/OnlyAddFollowupClient'
import DeletedClientLookUpPage from '../pages/Reception/ManageClientRecord/ClientLookup/DeletedClientLookUpPage'
import ViewOnlyFollowupForm from '../pages/Reception/FollowUp/FollowupForm/ViewOnlyFollowupForm'
import DeletedClientLookupView from '../pages/Reception/ManageClientRecord/ClientLookup/DeletedClientLookupView'
import FollowupLookup from '../pages/Reception/FollowUp/FollowupLookup'
import BillingStatement from '../pages/Reception/ManageBilling/BillingSummery/BillingStatement/BillingStatement'

const AllRoutes = () => {
  const { user } = useSelector((store) => {
    return store.userSlice;
  });

  return (
    <Routes>
      <Route path='*' element={<h1 className='text-center'>Page not found</h1>} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/register-successfull" element={<AfterRegistration />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/otp-verification" element={<OtpVerificationPage />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/help-resource" element={<HapeAndResourcesPage />} />
      <Route path="/set-new-password/:passwordToken" element={<SetNewPassword />} />
      {/* {
        Object.keys(user).length === 0 && (
          <Route path="/login" element={<Login />} />
        )
      } */}
      {
        // ADMIN
        (user.role === 'Super User/Admin') ? (
          <>
            <Route path='dashboard' element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
            <Route path='/user-management'>
              {/* SYSTEM USER */}
              <Route path='data-capture-receptionist' element={<PrivateRoute><DataCaptureReceptionist /></PrivateRoute>} />
              <Route path='data-validation-QA-one' element={<PrivateRoute><DataValidationCallCenter /></PrivateRoute>} />
              <Route path='data-validation-QA-two' element={<PrivateRoute><FacilityAdministratorDoctor /></PrivateRoute>} />
              <Route path='super-user-admin' element={<PrivateRoute><MmcSupervisorManager /></PrivateRoute>} />
              <Route path='billing-finance-approver' element={<PrivateRoute><MmcAdministrator /></PrivateRoute>} />

              {/* MANAGE USER */}
              <Route path='manage-user/add-user' element={<PrivateRoute><AddUsers /></PrivateRoute>} />
              <Route path='manage-user/pending-user' element={<PrivateRoute><PendingUsers /></PrivateRoute>} />
              <Route path='manage-user/view-and-edit/:id' element={<PrivateRoute><EditUser /></PrivateRoute>} />
              <Route path='manage-user/existing-user' element={<PrivateRoute><ExistingUsers /></PrivateRoute>} />

              {/* MASTER DATA */}
              <Route path='master-data/location' element={<PrivateRoute><Location /></PrivateRoute>} />
              <Route path='master-data/facility' element={<PrivateRoute><Facility /></PrivateRoute>} />
              <Route path='master-data/implementing-partners' element={<PrivateRoute><ImplementingPartners /></PrivateRoute>} />
            </Route>
          </>
        ) : (
          <>
            <Route path='/'>
              <Route path='*' element={<NoAccessOnThisPage />} />
              <Route path='/dashboard' element={<NoAccessOnThisPage />} />
            </Route>
          </>
        )
      }

      {
        // RECIPTIONEST
        (user.role === 'Data Capture/Receptionist' || user.role === 'Super User/Admin') ? (
          <Route path='/reception'>
            {/* MANAGE CLIENT RECORDS */}
            <Route path='dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path='add-client' element={<PrivateRoute><AddClientPage /></PrivateRoute>} />
            <Route path='client-lookup' element={<PrivateRoute><ClientLookUpPage /></PrivateRoute>} />
            <Route path='client-lookup/deleted-clients' element={<PrivateRoute><DeletedClientLookUpPage /></PrivateRoute>} />
            <Route path='client-lookup/deleted-clients/view/:id' element={<PrivateRoute><DeletedClientLookupView /></PrivateRoute>} />
            <Route path='client-lookup/edit/:clientID' element={<PrivateRoute><ClientLookupViewandedit /></PrivateRoute>} />
            <Route path='bulk-upload' element={<PrivateRoute><BulkUpload /></PrivateRoute>} />
            <Route path='bulk-upload/edit/:clientID' element={<PrivateRoute><ClientLookupViewandedit /></PrivateRoute>} />

            {/* BATCH MANAGEMENT */}
            <Route path='batch-management' element={<PrivateRoute><ManageBatch /></PrivateRoute>} />
            <Route path='batch-management/create' element={<PrivateRoute><CreateBatch /></PrivateRoute>} />
            <Route path='batch-management/progress/:id' element={<PrivateRoute><BatchProgress /></PrivateRoute>} />
            <Route path='batch-management/submitted/:id' element={<PrivateRoute><BatchSubmitted /></PrivateRoute>} />
            <Route path='batch-view/:clientID' element={<PrivateRoute><BatchView /></PrivateRoute>} />

            {/* FOLLOW UP FORM */}
            <Route path='follow-up/add-client' element={<PrivateRoute><OnlyAddFollowupClient /></PrivateRoute>} />
            <Route path='follow-up/add-client/:clientId' element={<PrivateRoute><AddFollowupClient /></PrivateRoute>} />
            <Route path='follow-up/bulk-upload' element={<PrivateRoute><FollowUpBulkDataUpload /></PrivateRoute>} />
            <Route path='follow-up/schedule-lookup' element={<PrivateRoute><ScheduleLookup /></PrivateRoute>} />
            <Route path='follow-up/lookup' element={<PrivateRoute><FollowupLookup /></PrivateRoute>} />
            <Route path='follow-up/followup-form/:id' element={<PrivateRoute><FollowupForm /></PrivateRoute>} />
            <Route path='follow-up/followup-form/view-only/:barcode' element={<PrivateRoute><ViewOnlyFollowupForm /></PrivateRoute>} />

            {/* FOLLOWUP TOTAL */}
            <Route path='follow-up/schedule-lookup/fourtyeight-hours/total' element={<PrivateRoute><FourteeEightTotalFollowUpList /></PrivateRoute>} />
            <Route path='follow-up/schedule-lookup/saven-days/total' element={<PrivateRoute><SavenTotalFollowUpList /></PrivateRoute>} />
            <Route path='follow-up/schedule-lookup/fourteen-days/total' element={<PrivateRoute><FourteeDaysTotalFollowUpList /></PrivateRoute>} />
            <Route path='follow-up/schedule-lookup/fourtyeight-hours/total/view-client/:clientId' element={<PrivateRoute><ViewFollowupClient /></PrivateRoute>} />

            {/* FOLLOWUP OVERDUE */}
            <Route path='follow-up/schedule-lookup/overdue' element={<PrivateRoute><Overdue /></PrivateRoute>} />

            {/* FOLLOWUP DUE */}
            <Route path='follow-up/schedule-lookup/due' element={<PrivateRoute><Due /></PrivateRoute>} />

            {/* {FOLLOWUP FORMS IN FOLLOWUP SUBMODULE} */}
            <Route path='follow-up/schedule-lookup/fourty-eight-hours/followup-form/:id' element={<PrivateRoute><FollowupForm /></PrivateRoute>} />
            <Route path='follow-up/schedule-lookup/saven-Days/followup-form/:id' element={<PrivateRoute><FollowupForm /></PrivateRoute>} />
            <Route path='follow-up/schedule-lookup/fourteen-Days/followup-form/:id' element={<PrivateRoute><FollowupForm /></PrivateRoute>} />

            {/* billing summery */}
            <Route path='manage-billing/billing-summery' element={<PrivateRoute><BillingSummery /></PrivateRoute>} />
            <Route path='manage-billing/billing-summery/statement' element={<PrivateRoute><BillingStatement /></PrivateRoute>} />
            <Route path='manage-billing/billing-summery/batch-to-process/:batchNo' element={<PrivateRoute><BillingSummeryListToProcess /></PrivateRoute>} />
            <Route path='manage-billing/billing-summery/batch-to-process/:batchNo/view-client/:clientID' element={<PrivateRoute><ViewBillingClient /></PrivateRoute>} />
            <Route path='manage-billing/billing-summery/batch-processed/:batchNo' element={<PrivateRoute><BillingSummeryListProcessed /></PrivateRoute>} />
            <Route path='manage-billing/billing-summery/batch-processed/:batchNo/view-client/:clientID' element={<PrivateRoute><ViewBillingClient /></PrivateRoute>} />
            <Route path='manage-billing/billing-summery/statement-to-process/:statementRef/uploadInvoice' element={<PrivateRoute><UploadInvoice /></PrivateRoute>} />
            <Route path='manage-billing/approved-rejected-payment' element={<PrivateRoute><ApproveRejectedPayment /></PrivateRoute>} />
            <Route path='manage-billing/approved-rejected-payment/rejected-batch/:batchNo' element={<PrivateRoute><RejectedBatchView /></PrivateRoute>} />
            <Route path='manage-billing/approved-rejected-payment/rejected-statement/:statementRef/reupload-invoice' element={<PrivateRoute><ResubmitInvoice /></PrivateRoute>} />
            <Route path='manage-billing/approved-rejected-payment/approved-batch/:batchNo' element={<PrivateRoute><ApprovedBatchView /></PrivateRoute>} />
          </Route>
        ) : (
          <>
            <Route path='/reception'>
              <Route path='*' element={<NoAccessOnThisPage />} />
            </Route>
          </>
        )
      }

      {
        // BILLING ROUTES
        (user.role === 'Billing/Finance(Approver)' || user.role === 'Super User/Admin') ? (
          <Route path='/billing'>
            <Route path='dashboard' element={<PrivateRoute><BillingDashboard /></PrivateRoute>} />
            <Route path='dashboard/amountDue' element={<PrivateRoute><AmountDue /></PrivateRoute>} />
            <Route path='dashboard/amountDue/batch/:batchNo' element={<PrivateRoute><ViewBatch /></PrivateRoute>} />
            <Route path='dashboard/amountDue/batch/:batchNo/view-client/:clientID' element={<PrivateRoute><ViewClientBilling /></PrivateRoute>} />
            <Route path='dashboard/amountProgress' element={<PrivateRoute><AmountProgress /></PrivateRoute>} />
            <Route path='billingInvoiceApproval' element={<PrivateRoute><BillingInvoiceApprovals /></PrivateRoute>} />
            <Route path='billingInvoiceApproval/invoice/:invoiceNo' element={<PrivateRoute><ViewInvoice /></PrivateRoute>} />
          </Route>
        ) : (
          <Route path='/billing'>
            <Route path='*' element={<NoAccessOnThisPage />} />
          </Route>
        )
      }

      {
        // QA 1
        (user.role === 'Data Validation(QA1)' || user.role === 'Super User/Admin') ? (
          <Route path='/qa-1'>
            <Route path='dashboard' element={<PrivateRoute><QAUsersDashboardPage /></PrivateRoute>} />
            <Route path='manage-batch-validation' element={<PrivateRoute><QAOneUsersManageBatchValidationPage /></PrivateRoute>} />
            <Route path='manage-batch-validation/progress/:id' element={<PrivateRoute><QAOneUsersInProgressBatches /></PrivateRoute>} />
            <Route path='manage-batch-validation/progress/:id/view-client/:clientID' element={<PrivateRoute><QAOneViewInprogressClient /></PrivateRoute>} />
            <Route path='manage-batch-validation/completed/:id' element={<PrivateRoute><QAOneUsersCompletedBatches /></PrivateRoute>} />
            <Route path='manage-batch-validation/completed/:id/view-client/:clientID' element={<PrivateRoute><QAOneViewCompletedClient /></PrivateRoute>} />
            <Route path='help-resource' element={<PrivateRoute><QAUsersHelpAndResourcesPage /></PrivateRoute>} />
          </Route>
        ) : (
          <Route path='/qa-1'>
            <Route path='*' element={<NoAccessOnThisPage />} />
          </Route>
        )
      }

      {
        // QA 2
        (user.role === 'Data Validation(QA2)' || user.role === 'Super User/Admin') ? (
          <Route path='/qa-2'>
            <Route path='dashboard' element={<PrivateRoute><QAUsersDashboardPage /></PrivateRoute>} />
            <Route path='manage-batch-validation' element={<PrivateRoute><QATwoUsersManageBatchValidationPage /></PrivateRoute>} />
            <Route path='manage-batch-validation/progress/:id' element={<PrivateRoute><QATwoUsersInProgressBatches /></PrivateRoute>} />
            <Route path='manage-batch-validation/progress/:id/view-client/:clientID' element={<PrivateRoute><QATwoViewInprogressClient /></PrivateRoute>} />
            <Route path='manage-batch-validation/completed/:id' element={<PrivateRoute><QATwoUsersCompletedBatches /></PrivateRoute>} />
            <Route path='manage-batch-validation/completed/:id/view-client/:clientID' element={<PrivateRoute><QATwoViewCompletedClient /></PrivateRoute>} />
            <Route path='help-resource' element={<PrivateRoute><QAUsersHelpAndResourcesPage /></PrivateRoute>} />
          </Route>
        ) : (
          <Route path='/qa-2'>
            <Route path='*' element={<NoAccessOnThisPage />} />
          </Route>
        )
      }
    </Routes>
  )
}

export default AllRoutes