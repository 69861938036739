import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

const TableRowBatch = ({ data, index, handleCheckboxChange,checkedItems }) => {
    const navigate = useNavigate();

    return (
        <tr>
            <td className="vertical-align">
                <input
                    type="checkbox"
                    id={`b_checkbox${index}`}
                    className="filled-in chk-col-danger"
                    onChange={() => handleCheckboxChange(data.client_id)}
                    checked={checkedItems.includes(data.client_id)}
                />
                <label htmlFor={`b_checkbox${index}`} />
            </td>
            <td>{index + 1}</td>
            <td>{data?.capture_date.slice(0, 10)}</td>
            <td>{data?.barcodeCDCNumber}</td>
            <td>{data?.clientName}</td>
            <td>{data?.client_id}</td>
            <td>
                <p>
                    {data?.veriificationId}
                </p>
                <p>
                    {data?.verificationId}
                </p>
            </td>
            <td>{data?.booking_id}</td>
            <td>{data?.folder_number}</td>
            <td><span className="badge badge-danger-light">{data?.status}</span></td>
            <td>
                <button type="button" className="btn btn-Secondary actionBtn" onClick={() => navigate(`/reception/batch-view/${data.client_id}`)}>
                    <i className="fa fa-eye"></i>
                </button>
            </td>
          
        </tr>
    )
}

export default TableRowBatch;
