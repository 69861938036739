import React from 'react'
import a from "../../../assets/images/svg-icon/color-svg/custom-20.svg"
import b from "../../../assets/images/svg-icon/color-svg/custom-18.svg"
import c from "../../../assets/images/svg-icon/color-svg/custom-19.svg"
import ReceptionLayout from '../ReceptionLayout'




const Dashboard = () => {
    return (
        <ReceptionLayout>
            <div className="container-full">
                <div className="content-header">
                    <div className="d-flex align-items-center">
                        <div className="me-auto">
                            <div className="d-inline-block align-items-center">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#"><i className="mdi mdi-home-outline"></i></a>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">Dashboard</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="row g-3">
                        <div className="col-lg-3 col-12">
                            <div className="box">
                                <div className="box-body">
                                    <div className="d-flex align-items-center">
                                        <div className="me-15">
                                            <img src={a} alt="" className="w-120" />
                                        </div>
                                        <div>
                                            <h4 className="mb-0">Client Records</h4>
                                            <h3 className="mb-0">110</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="box">
                                <div className="box-body">
                                    <div className="d-flex align-items-center">
                                        <div className="me-15">
                                            <img src={b} alt="" className="w-120" />
                                        </div>
                                        <div>
                                            <h4 className="mb-0">Consent Forms</h4>
                                            <h3 className="mb-0">85</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="box">
                                <div className="box-body">
                                    <div className="d-flex align-items-center">
                                        <div className="me-15">
                                            <img src={c} alt="" className="w-120" />
                                        </div>
                                        <div>
                                            <h4 className="mb-0">Batches</h4>
                                            <h3 className="mb-0">6</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="box">
                                <div className="box-body">
                                    <div className="d-flex align-items-center">
                                        <div className="me-15">
                                            <img src={a} alt="" className="w-120" />
                                        </div>
                                        <div>
                                            <h4 className="mb-0">On Process</h4>
                                            <h3 className="mb-0">58</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-xl-4 col-12">
                            <div className="box">
                                <div className="box-header">
                                    <h4 className="box-title">Analytics</h4>
                                </div>
                                <div className="box-body ps-0">
                                    <div id="overview_trend"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-12">
                            <div className="box">
                                <div className="box-header">
                                    <h4 className="box-title">Appointments Overview</h4>
                                </div>
                                <div className="box-body">
                                    <div id="chart432"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-12">
                            <div className="box">
                                <div className="box-header">
                                    <h4 className="box-title">Overal appointment</h4>
                                </div>
                                <div className="box-body">
                                    <div id="appointment_overview"></div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </section>
            </div>
        </ReceptionLayout>
    )
}

export default Dashboard