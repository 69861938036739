import React, { useEffect, useState } from 'react'
import ReceptionLayout from '../../../../../Reception/ReceptionLayout'
import LoadingErrorComponent from '../../../../../../components/LoadingErrorComponent/LoadingErrorComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetClientByIDMutation } from '../../../../../../store/api/billing/billingApi';
import ViewQAClient from '../../../../../QA_Users/Components/ViewQAClient/ViewQAClient';

const ClientViewBilling = (QaType) => {
    let params = useParams()
    let [singlePage, response] = useGetClientByIDMutation()
    const [userData, setUserData] = useState()
    useEffect(() => {
        singlePage({ client_id: params.clientID }).then((res) => {
            setUserData(res?.data?.data)
        })
    }, [])
    const navigate = useNavigate()
    return (
        <ReceptionLayout>
            <div className='container-full'>
                <div className="content-header">
                    <div className="d-flex align-items-center">
                        <div className="me-auto">
                            <div className="d-inline-block align-items-center">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="#">
                                                <i className="mdi mdi-home-outline"></i>
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">Billing</li>
                                        <li className="breadcrumb-item" aria-current="page">View Client</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="box">
                        <div className="box-header with-border">
                            <div className="box-in">
                                <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-arrow-left"
                                    >
                                        <line x1={19} y1={12} x2={5} y2={12} />
                                        <polyline points="12 19 5 12 12 5" />
                                    </svg>
                                </button>
                                <h4 className="box-title me-auto ms-4">

                                    <strong>Client Details</strong>
                                </h4>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="clint-from">
                                {
                                    userData ? <ViewQAClient QaType="completed" data={userData} /> : <LoadingErrorComponent loading />
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ReceptionLayout>
    )
}

export default ClientViewBilling;
