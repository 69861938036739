import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import UserManagementLayout from '../../UserManagementLayout';
import { useAllExistingUsersQuery } from '../../../../store/api/userManagement/manageUserApi';
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent';
import ExistingUserTableRow from '../existingUserTableRow';
import PaginationComp from '../../../../components/Pagination/paginationComp';

const ExistingUser = () => {
  const navigate = useNavigate()
  const [paginationDetails, setPaginationDetails] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort_by, setSortBy] = useState(searchParams.get("sort_by") || "");
  const [searchBy, setSearchBy] = useState(searchParams.get("searchBy") || "");
  const [searchKeyword, setSearchKeyword] = useState(searchParams.get("searchKeyword") || "");
  const [page, setPageNo] = useState(searchParams.get("page") || 1);
  const allExistingUser = useAllExistingUsersQuery({
    sort_by: sort_by,
    searchKeyword: searchKeyword,
    page: page,
    per_page: 10,
    searchBy: searchBy,
  });

  const paginationDataString = allExistingUser?.data?.message.split(": ")[1];

  useEffect(() => {
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString?.replace(/=/g, ':').replace(/(\w+)/g, '"$1"'));
      setPaginationDetails({
        CurrentPage: Math.ceil(paginationData.CurrentPage),
        ItemsPerPage: Math.ceil(paginationData.ItemsPerPage),
        TotalCount: Math.ceil(paginationData.TotalCount),
        TotalPages: Math.ceil(paginationData.TotalPages),
      })
    }
  }, [allExistingUser?.data?.message])

  // FUNCTIONS TO HANDLE DATA SEARCH AND FILTER
  const changePage = (page) => {
    setPageNo(page);
  };
  const handleSortBy = (elem) => {
    setSortBy(elem.target.value);
  };
  const handleSearchBy = (elem) => {
    setSearchBy(elem.target.value);
  };
  const handleSearchKeyword = (elem) => {
    setSearchKeyword(elem.target.value);
  };

  useEffect(() => {
    let params = { page };
    if (sort_by) {
      params.sort_by = sort_by;
    }
    if (searchBy) {
      params.searchBy = searchBy;
    }
    if (searchKeyword) {
      params.searchKeyword = searchKeyword;
    }
    setSearchParams(params);
  }, [page, sort_by, searchBy, searchKeyword, setSearchParams]);


  return (
    <UserManagementLayout>
      <div className="container-full">
        <div className="content-header">
          <div className="d-flex align-items-center">
            <div className="me-auto">
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">
                        <i className="mdi mdi-home-outline"></i>
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Manage Users
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Existing Users
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="box">
            <div className="box-header with-border">
              <div className="box-in">
                <button onClick={() => navigate("/dashboard")} className='btn btn-danger-light'>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-left"
                  >
                    <line x1={19} y1={12} x2={5} y2={12} />
                    <polyline points="12 19 5 12 12 5" />
                  </svg>
                </button>
                <h4 className="box-title me-auto ms-4">
                  <strong>Existing Users</strong>
                </h4>
                <div className="box-right">
                  <input
                    className="choose-file"
                    type="file"
                    id="Bulk"
                    accept="application/pdf,application/vnd.ms-excel"
                    multiple
                  />
                </div>
              </div>
            </div>
            <div id="sform" className="box-body">
              <div className="row mb-3 g-2 justify-content-between">
                <div className="col-sm-12 col-md-6 col-xl-3">
                  <select
                    className="form-select mb-xl-0 mb-2"
                    aria-label="sort_by"
                    value={sort_by}
                    onChange={handleSortBy}
                  >
                    <option value="">Sort by</option>
                    <option value="first_to_last">First to Last</option>
                    <option value="last_to_first"> Last to First</option>
                  </select>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="table-top-search">
                    <select className="form-select" aria-label="search by" onChange={handleSearchBy} value={searchBy}>
                      <option value="">Search by</option>
                      <option value="User_name">User Name</option>
                      <option value="phone">Phone</option>
                      <option value="email">Email</option>
                      <option value="user_role">User Role</option>
                    </select>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      aria-controls="search"
                      value={searchKeyword}
                      onChange={handleSearchKeyword}
                    />
                  </div>
                </div>
              </div>
              {allExistingUser.isError && <LoadingErrorComponent error />}
              {allExistingUser.isLoading && <LoadingErrorComponent loading />}
              {allExistingUser.isSuccess && <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th>Full Name</th>
                          <th>Contact (Email & Phone)</th>
                          <th>Role Assigned</th>
                          <th>Nationality</th>
                          <th>Status</th>
                          <th>Action Items</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          allExistingUser?.data?.data.length > 0 ?
                            allExistingUser?.data?.data.map((el, i) => {
                              return (
                                <ExistingUserTableRow key={i} data={el} index={i} />
                              )
                            })
                            :
                            (
                              <tr>
                                <td colSpan={10} className='border-0'>No Data Found!!</td>
                              </tr>
                            )
                        }
                      </tbody>
                    </table>
                  </div>
                  {allExistingUser?.data?.data.length > 0 && (
                    <div className="d-flex justify-content-between mt-3">
                      <div>
                        {`Showing ${page - 1}1 to ${paginationDetails.TotalCount > page * 10 ? page * 10 : paginationDetails.TotalCount}  of ${paginationDetails.TotalCount} entries`}
                      </div>
                      <div>
                        <PaginationComp currentPage={paginationDetails.CurrentPage} totalPages={paginationDetails.TotalPages} onPageChange={changePage} />
                      </div>
                    </div>
                  )}
                </div>
              </div>}
            </div>
          </div>
        </section>
      </div>
    </UserManagementLayout>
  )
}

export default ExistingUser
