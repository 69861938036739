import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const receptionBillingApi = createApi({
    reducerPath: "ReceptionBillingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            return headers.set('Authorization', `Bearer ${userSlice.user.token}`);
        },
    }),
    tagTypes: ["ReceptionBilling", "BatchStatement"],
    endpoints: (builder) => ({
        batchSummery: builder.query({
            query: () => {
                return {
                    url: `/Billing/BatchesSummary`,
                    method: "GET",
                };
            },
            providesTags: ["ReceptionBilling"]
        }),
        invoiceRecord: builder.query({
            query: () => {
                return {
                    url: `/Billing/ViewApprovedRejectedInvoices`,
                    method: "GET",
                    params: {
                        page: 1,
                        per_page: 10
                    }
                };
            },
            providesTags: ["ReceptionBilling"]
        }),
        GetBatchStatement: builder.mutation({
            query: (data) => {
                return {
                    url: `/Billing/GetBatchStatement`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["BatchStatement"]
        }),
        invoiceRecordByInvoiceNo: builder.mutation({
            query: (data) => {
                return {
                    url: `/Billing/GetRecordbyInvoiceNo`,
                    method: "POST",
                    body: {
                        invoiceNo: data
                    }
                };
            },
            invalidatesTags: ["ReceptionBilling"]
        }),
        viewRecordInBatch: builder.mutation({
            query: (data) => {
                return {
                    url: `/Billing/ViewRecordsInBatch`,
                    method: "POST",
                    params: data
                };
            },
            invalidatesTags: ["ReceptionBilling"]
        }),
        submitInvoice: builder.mutation({
            query: (data) => {
                return {
                    url: `/Billing/SubmitInvoice`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["ReceptionBilling"]
        }),
        reSubmitInvoice: builder.mutation({
            query: (data) => {
                return {
                    url: `/Billing/ResubmitInvoice`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["ReceptionBilling"]
        }),
        GetBillingClientByID: builder.mutation({
            query: (data) => {
                return {
                    url: `/Billing/GetClientByID`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["BatchStatement"]
        }),
        getCurrentStatement: builder.query({
            query: () => {
                return {
                    url: `/Billing/GetCurrentStatement`,
                    method: "GET",
                };
            },
            providesTags: ["ReceptionBilling"]
        }),
        GetByStatementRef: builder.mutation({
            query: (data) => {
                return {
                    url: `/Billing/ViewByStateRef`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["BatchStatement"]
        }),
    }),
});

export const {
    useGetCurrentStatementQuery,
    useBatchSummeryQuery,
    useInvoiceRecordQuery,
    useGetBatchStatementMutation,
    useInvoiceRecordByInvoiceNoMutation,
    useViewRecordInBatchMutation,
    useSubmitInvoiceMutation,
    useReSubmitInvoiceMutation,
    useGetBillingClientByIDMutation,
    useGetByStatementRefMutation
} = receptionBillingApi;