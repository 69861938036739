import React, { useEffect, useReducer, useState } from "react";
import UserManagementLayout from "../../UserManagementLayout";
import { Link, useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAddUserMutation, useGetDropdownQuery } from "../../../../store/api/userManagement/manageUserApi";
import toast from "react-hot-toast";
import validateSAID from "../../../../methods/validateSAID";
import Select from 'react-select'
dayjs.extend(customParseFormat);


const AddUsers = () => {
  const [validated, setValidated] = useState(false);
  const [addUser, response] = useAddUserMutation();
  const navigate = useNavigate()
  const [clientDetailsId, setClientDetailsId] = useState("");

  const initialState = {
    userDetails: {
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      contactNumber: "",
      nationality: "",
      countryOfOrigin: "",
      employeeNo: "",
      password: "",
      postRole: "",
      province: '',
      district: "",
      subDistrict: "",
      facility: [],
    },
  };

  const addClientReducer = (state, { type, payload }) => {
    switch (type) {
      case "SET_ADD_PATIENT":
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
            [payload.name]: payload.data,
          },
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(addClientReducer, initialState);

  const handleChangeUserDetails = (e) => {
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };
  const [contactNumberValid, setContactNumberValid] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [showNatanality, setShowNatanality] = useState(false);


  const handleNationalityChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "non_south_african") {
      setShowNatanality(true);
    } else if (selectedValue === "south_african") {
      setShowNatanality(true);
      dispatch({
        type: "SET_ADD_PATIENT",
        payload: {
          name: "countryOfOrigin",
          data: "south_africa",
        },
      });
    }
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };
  const handleChangeProvince = (e) => {
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: "district",
        data: "",
      },
    });
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: "subDistrict",
        data: "",
      },
    });
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: "facility",
        data: [],
      },
    });
  }

  const handleChangeDistrict = (e) => {
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: "subDistrict",
        data: "",
      },
    });
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: "facility",
        data: [],
      },
    });
  }

  const handleChangeSubdistrict = (e) => {
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: "subDistrict",
        data: e.target.value,
      },
    });
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: "facility",
        data: [],
      },
    });
  }

  const data = {
    firstname: state.userDetails.firstName,
    last_Name: state.userDetails.lastName,
    email: state.userDetails.email,
    gender: state.userDetails.gender,
    nationality: state.userDetails.nationality,
    contactNo: contactNumber,
    countryOfOrigin: state.userDetails.countryOfOrigin,
    password: state.userDetails.password,
    user_Status: "",
    user_Id: "",
    postRole: state.userDetails.assignRole,
    createdBy: state.userDetails.firstName,
    national: state.userDetails.national,
    province: state.userDetails.province,
    district: state.userDetails.district,
    subDistrict: state.userDetails.subDistrict,
    facility: state.userDetails.facility,
    employeeNo: state.userDetails.employeeNo
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true && contactNumberValid) {
      addUser(data).then((res) => {
        console.log(res)
        if (res?.data?.status === 1) {
          toast.success(res?.data?.message, { duration: 7000 });
          navigate("/user-management/manage-user/existing-user")
        } else {
          toast.error(res?.data?.message, { duration: 7000 })
        }
      });
    } else {
      toast.error("Please Fill Data Properly!!", { duration: 7000 })
    }
    setValidated(true);
  };

  // PHONE NUMBER VALIDATION
  useEffect(() => {
    if (contactNumber) {
      setContactNumberValid(isValidPhoneNumber(contactNumber?.toString()));
    }
  }, [contactNumber]);
  const PhoneInputElem = document.querySelectorAll(".PhoneInput");
  useEffect(() => {
    // PHONE NUMBER VALIDATION
    if (PhoneInputElem) {
      PhoneInputElem.forEach((elem) => {
        elem.querySelector(".PhoneInputInput").setAttribute("required", true);
      });
    }
  }, [PhoneInputElem]);

  const dropdownList = useGetDropdownQuery({
    pid: state.userDetails.province,
    dloc: state.userDetails.district,
    sloc: state.userDetails.subDistrict
  })

  const facilityListDropData = dropdownList?.data?.data?.facility.map((elem, i) => {
    return {
      value: elem.facilityName,
      label: elem.facilityName
    }
  })

  const defaultFacility = state.userDetails?.facility?.map(item => ({
    value: item,
    label: item
  }));

  const handleChangePage = (data) => {
    dispatch({
      type: "SET_ADD_PATIENT",
      payload: {
        name: "facility",
        data: data?.map((elem, i) => elem.value),
      },
    });
  };

  return (
    <UserManagementLayout>
      <div className="container-full">
        <div className="content-header">
          <div className="d-flex align-items-center">
            <div className="me-auto">
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">
                        <i className="mdi mdi-home-outline"></i>
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Manage Users
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Add User
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="box">
            <div className="box-header with-border">
              <div className="box-in">
                <button onClick={() => navigate('/dashboard')} className='btn btn-danger-light'>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-left"
                  >
                    <line x1={19} y1={12} x2={5} y2={12} />
                    <polyline points="12 19 5 12 12 5" />
                  </svg>
                </button>
                <h4 className="box-title me-auto ms-4">

                  <strong>Add User</strong>
                </h4>
                <div className="box-right">
                  <input
                    className="choose-file"
                    type="file"
                    id="Bulk"
                    accept="application/pdf,application/vnd.ms-excel"
                    multiple
                  />
                </div>
              </div>
            </div>
            <div id="sform" className="box-body">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  {/* first name */}
                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="First name"
                      name="firstName"
                      value={state.userDetails.firstName}
                      onChange={handleChangeUserDetails}
                    />
                  </Form.Group>
                  {/* last name */}
                  <Form.Group as={Col} md="4" controlId="validationCustom02">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      required
                      name="lastName"
                      type="text"
                      placeholder="Last name"
                      value={state.userDetails.lastName}
                      onChange={handleChangeUserDetails}
                    />
                  </Form.Group>
                  {/* gender */}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="relations" className="form-label">
                        Gender
                      </label>
                      <select
                        className="form-select"
                        required
                        name="gender"
                        onChange={handleChangeUserDetails}
                        value={state.userDetails.gender}
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                      </select>
                      <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                        {state.userDetails.identityVerification === "South African ID" && (state.userDetails.verificationId && state.userDetails.gender && clientDetailsId.gender !== state.userDetails.gender.toLowerCase()) && "Gender Mismatch!!"}
                      </div>
                    </div>
                  </div>
                  {/* email */}
                  <Form.Group as={Col} md="4" controlId="validationCustom04">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      required
                      value={state.userDetails.email}
                      onChange={handleChangeUserDetails}
                      name="email"
                    />
                  </Form.Group>
                  <div className="col-lg-4 col-md-6">
                    {/* contact number */}
                    <div className="mb-3">
                      <label className="form-label" htmlFor="mobile">
                        Contact number
                      </label>
                      <PhoneInput
                        international
                        defaultCountry="ZA"
                        placeholder="Enter phone number"
                        onChange={setContactNumber}
                        value={contactNumber}
                        name="contactNumber"
                        id="mobile2"
                        required
                        className={
                          validated &&
                            isValidPhoneNumber(contactNumber?.toString())
                            ? "form-control-valid"
                            : contactNumber === "" &&
                            validated &&
                            "form-control-invalid"
                        }
                      />
                    </div>
                  </div>
                  {/* nationality */}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="relations" className="form-label">
                        Nationality
                      </label>
                      <select
                        className="form-select"
                        required
                        name="nationality"
                        onChange={handleNationalityChange}
                        value={state.userDetails.nationality}
                      >
                        <option value="">Select Nationality</option>
                        <option value="south_african">South African</option>
                        <option value="non_south_african">
                          Non-South African
                        </option>
                      </select>
                    </div>
                  </div>
                  {/* country of origin */}
                  <div
                    id="cog"
                    className="col-lg-4 col-md-6 NationalID"
                    style={{ display: showNatanality ? "block" : "none" }}
                  >
                    {state.userDetails.nationality === "non_south_african" ? (
                      <>
                        <label htmlFor="National" className="form-label">
                          Country of Origin
                        </label>
                        <select
                          className="form-select"
                          id="National"
                          required
                          name="countryOfOrigin"
                          onChange={handleChangeUserDetails}
                          value={state.userDetails.countryOfOrigin}
                        >
                          <option value="">Select</option>
                          <option value={"botswana"}>Botswana</option>
                          <option value={"eswatini"}>Eswatini</option>
                          <option value={"lesotho"}>Lesotho</option>
                          <option value={"mozambique"}>Mozambique</option>
                          <option value={"namibia"}>Namibia</option>
                          <option value={"zimbabwe"}>Zimbabwe</option>
                        </select>
                      </>
                    ) : (
                      <>
                        <label htmlFor="National" className="form-label">
                          Country of Origin
                        </label>
                        <select
                          className="form-select"
                          id="National"
                          required
                          onChange={handleChangeUserDetails}
                          name="countryOfOrigin"
                          value={"south_africa"}
                        >
                          <option value="">Select</option>
                          <option value="south_africa">South Africa</option>
                        </select>
                      </>
                    )}
                  </div>
                  {/* employee number */}
                  <Form.Group as={Col} md="4" controlId="validationCustom04">
                    <Form.Label>Employee No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Employee No."
                      name="employeeNo"
                      onChange={handleChangeUserDetails}
                      required
                    />
                  </Form.Group>
                  {/* assign role */}
                  <div className="mt-4 col-md-12">
                    <h4 className="">User Role</h4>
                    <div className="col-md-12">
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="assignRole"
                          id="inlineRadio1"
                          value="Data Capture/Receptionist"
                          onChange={handleChangeUserDetails}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">
                          Data Capture/Receptionist
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="assignRole"
                          id="inlineRadio2"
                          value="Data Validation(QA1)"
                          onChange={handleChangeUserDetails}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">
                          Data Validation (QA1)
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="assignRole"
                          id="inlineRadio3"
                          value="Data validation(QA2)"
                          onChange={handleChangeUserDetails}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio3">
                          Data validation (QA2)
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="assignRole"
                          id="inlineRadio4"
                          value="Billing/Finance(Approver)"
                          onChange={handleChangeUserDetails}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio4">
                          Billing/Finance (Approver)
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="assignRole"
                          id="inlineRadio5"
                          value="Super User/Admin"
                          onChange={handleChangeUserDetails}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio5">
                          Super User/Admin
                        </label>
                      </div>
                      {validated && ((state.userDetails.assignRole === undefined) || (state.userDetails.assignRole === "")) && <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                        Please select a role!!!
                      </div>}
                    </div>
                  </div>
                  {/* Accessibility */}
                  <h4 className="mt-3 col-md-12">Accessibility</h4>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="rolemodule" className="form-label">Select Province</label>
                        <select className="form-select" id="rolemodule" required onChange={handleChangeProvince} value={state.userDetails.province} name="province">
                          <option value="">Select Province</option>
                          {
                            dropdownList?.data?.data?.province.map((el, i) => (
                              <option value={el.provinceName} key={i}>{el.provinceName}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="rolemodule" className="form-label">Select District</label>
                        <select className="form-select" id="rolemodule" required onChange={handleChangeDistrict} value={state.userDetails.district} name="district" disabled={!state.userDetails.province}>
                          <option value="">Select District</option>
                          {
                            dropdownList?.data?.data?.district.map((el, i) => (
                              <option value={el.districtName} key={i}>{el.districtName}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="rolemodule" className="form-label">Select Sub District</label>
                        <select className="form-select" id="rolemodule" onChange={handleChangeSubdistrict} value={state.userDetails.subDistrict} name="subDistrict" required disabled={!state.userDetails.district || !state.userDetails.province}>
                          <option value="">Select Sub District</option>
                          {
                            dropdownList?.data?.data?.sub_District.map((el, i) => (
                              <option value={el.subDistrictName} key={i}>{el.subDistrictName}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="rolemodule" className="form-label">Select Facility</label>
                        {console.log(state.userDetails.facility)}
                        <Select
                          className={
                            validated &&
                              state?.userDetails?.facility?.length === 0
                              ? "form-control-invalid"
                              : state?.userDetails?.facility?.length > 0 &&
                              validated &&
                              "form-control-valid"
                          }
                          options={facilityListDropData}
                          isMulti
                          onChange={handleChangePage}
                          required
                          isDisabled={!state.userDetails.subDistrict || !state.userDetails.district || !state.userDetails.province}
                          value={defaultFacility}
                        />
                      </div>
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  {
                    response.isLoading === true ?
                      (<button disabled className="btn btn-primary">
                        <span className="spinnerInButton"></span>
                      </button>)
                      :
                      (
                        <div className="box-footer d-flex justify-content-center">
                          <button className="btn btn-secondary me-3" onClick={() => navigate(-1)}>
                            Cancel
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      )
                  }
                </div>
              </Form>
            </div>
          </div>
        </section>
      </div>
    </UserManagementLayout>
  );
};

export default AddUsers;
