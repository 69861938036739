import React from 'react'
import { Link } from 'react-router-dom'

const BatchItem = ({type, data}) => {
  if(type === "progress"){
    return (
      <Link to={`progress/${data.batchNo}`} className="folder-container">
          <div className="folder-icon">
              <i className="fa fa-folder folder-icon-color"></i>
          </div>
          <div className="folder-name">
              <h4>{data?.batchNo}</h4>
          </div>
      </Link>
    )
  } if(type === "completed"){
    return (
      <Link to={`completed/${data.batchNo}`} className="folder-container">
          <div className="folder-icon">
              <i className="fa fa-folder folder-icon-color"></i>
          </div>
          <div className="folder-name">
              <h4>{data?.batchNo}</h4>
          </div>
      </Link>
    )
  } else {
    return (
      <Link to={`submitted/${data.batchNo}`} className="folder-container">
          <div className="folder-icon">
              <i className="fa fa-folder folder-icon-color"></i>
          </div>
          <div className="folder-name">
              <h4>{data?.batchNo}</h4>
          </div>
      </Link>
    )
  }
}

export default BatchItem