import React, { useEffect, useState } from 'react'
import BillingLayout from '../BillingLayout'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useInvoiceRecordQuery } from '../../../store/api/billing/billingApi'
import LoadingErrorComponent from '../../../components/LoadingErrorComponent/LoadingErrorComponent'
import PaginationComp from '../../../components/Pagination/paginationComp'

const BillingInvoiceApprovals = () => {
    const navigate = useNavigate()

    const [paginationDetails, setPaginationDetails] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
    const [sort_by, setSortBy] = useState(searchParams.get("sort_by") || "");
    const [searchBy, setSearchBy] = useState(searchParams.get("searchBy") || "");
    const [searchKeyword, setSearchKeyword] = useState(
        searchParams.get("searchKeyword") || ""
    );
    const [page, setPageNo] = useState(searchParams.get("page") || 1);

    const invoiceRecord = useInvoiceRecordQuery({
        sort_by: sort_by,
        searchKeyword: searchKeyword,
        page: page,
        per_page: 10,
        searchBy: searchBy,
    })
    console.log(invoiceRecord)

    // FUNCTIONS TO HANDLE DATA SEARCH AND FILTER
    const changePage = (page) => {
        setPageNo(page);
    };
    const handleSortBy = (elem) => {
        setSortBy(elem.target.value);
    };
    const handleSearchBy = (elem) => {
        setSearchBy(elem.target.value);
    };
    const handleSearchKeyword = (elem) => {
        setSearchKeyword(elem.target.value);
    };

    useEffect(() => {
        let params = { page };
        if (sort_by) {
            params.sort_by = sort_by;
        }
        if (searchBy) {
            params.searchBy = searchBy;
        }
        if (searchKeyword) {
            params.searchKeyword = searchKeyword;
        }
        setSearchParams(params);
    }, [page, sort_by, searchBy, searchKeyword, setSearchParams]);

    const paginationDataString = invoiceRecord?.data?.message.split(": ")[1];
    useEffect(() => {
        if (paginationDataString) {
            const paginationData = JSON.parse(paginationDataString?.replace(/=/g, ':').replace(/(\w+)/g, '"$1"'));
            setPaginationDetails({
                CurrentPage: Math.ceil(paginationData.CurrentPage),
                ItemsPerPage: Math.ceil(paginationData.ItemsPerPage),
                TotalCount: Math.ceil(paginationData.TotalCount),
                TotalPages: Math.ceil(paginationData.TotalPages),
            })
        }
    }, [invoiceRecord?.data?.message])

    return (
        <BillingLayout>
            <div class="content-header">
                <div class="d-flex align-items-center">
                    <div class="me-auto">
                        <div class="d-inline-block align-items-center">
                            <nav>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <Link to=""><i class="mdi mdi-home-outline"></i></Link>
                                    </li>
                                    <li class="breadcrumb-item" aria-current="page">Billing & Invoices</li>
                                    <li class="breadcrumb-item active" aria-current="page">Billing & Invoice Approvals</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="box">
                    <div class="box-header with-border">
                        <div class="box-in">
                            <button onClick={() => navigate("/billing/dashboard")} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">
                                <strong>Approval Summary</strong>
                            </h4>
                        </div>
                    </div>


                    <div class="box-body">
                        <div class="row mb-3 g-2 justify-content-between">
                            <div class="col-sm-12 col-md-6 col-xl-3">
                                <select class="form-select mb-xl-0 mb-2" aria-label="Default select example" onChange={handleSortBy}>
                                    <option selected>Sort by</option>
                                    <option value="first_to_last">Latest to First</option>
                                    <option value="last_to_first">First to Latest</option>
                                </select>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div class="table-top-search">
                                    <select class="form-select" aria-label="Default select example" onChange={handleSearchBy} value={searchBy}>
                                        <option value="">Search by</option>
                                        <option value="invoiceNo">Invoice No</option>
                                        <option value="statementRef">Statement Ref</option>
                                        <option value="statementAmount">Statement Amount</option>
                                    </select>
                                    <input
                                        type="search"
                                        class="form-control"
                                        placeholder="Search"
                                        aria-controls="complex_header"
                                        value={searchKeyword}
                                        onChange={handleSearchKeyword}
                                    />
                                </div>
                            </div>
                        </div>
                        {invoiceRecord.isError && <LoadingErrorComponent error />}
                        {invoiceRecord.isLoading && <LoadingErrorComponent loading />}
                        {invoiceRecord.isSuccess && <div class="row">
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Sr.</th>
                                                <th>Invoice No.</th>
                                                <th>Date</th>
                                                <th>Statement Ref.</th>
                                                <th>Statement Amount</th>
                                                <th>Approval Status</th>
                                                <th>Batches</th>
                                                <th>Approve/ Reject</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                invoiceRecord?.data?.data.length > 0 ? (
                                                    invoiceRecord?.data?.data?.map((el, i) => (
                                                        <tr class="hover-primary">
                                                            <td>{i + 1}</td>
                                                            <td>{el.invoiceNo}</td>
                                                            <td>{el.date}</td>
                                                            <td>{el.statementRef}</td>
                                                            <td>ZAR {el.amount}</td>
                                                            <td>
                                                                {el.validationStatus.toLowerCase() === "pending" &&
                                                                    <span class="badge badge-warning-light">
                                                                        Pending
                                                                    </span>
                                                                }
                                                                {el.validationStatus.toLowerCase() === "approved" &&
                                                                    <span class="badge badge-success-light">
                                                                        Approved
                                                                    </span>
                                                                }
                                                                {el.validationStatus.toLowerCase() === "rejected" &&
                                                                    <span class="badge badge-danger-light">
                                                                        Rejected
                                                                    </span>
                                                                }
                                                            </td>
                                                            <td>
                                                                {el.totalRecords > 1 ? `${el.totalRecords} Batches` : `${el.totalRecords} Batch`}
                                                            </td>
                                                            <td>
                                                                <div class="btn-group">
                                                                    <Link to={`/billing/billingInvoiceApproval/invoice/${el.invoiceNo}`}
                                                                        class="hover-primary dropdown-toggle no-caret btn-more px-2">
                                                                        <i class="fa fa-eye"></i>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={10} className='border-0'>No Data Found!!</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {invoiceRecord?.data?.data.length > 0 && <div className="d-flex justify-content-between mt-3">
                                    {
                                        <div>
                                            {`Showing ${page - 1}1 to ${paginationDetails.TotalCount > page * 10 ? page * 10 : paginationDetails.TotalCount}  of ${paginationDetails.TotalCount} entries`}
                                        </div>
                                    }
                                    <div>
                                        <PaginationComp currentPage={paginationDetails.CurrentPage} totalPages={paginationDetails.TotalPages} onPageChange={changePage} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </section>
        </BillingLayout>
    )
}

export default BillingInvoiceApprovals
