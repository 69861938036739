import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import BatchItem from '../../../../components/BatchItem/BatchItem'
import { useGetBatchesQuery } from '../../../../store/api/reception/batchApi';
import ReceptionLayout from '../../ReceptionLayout';
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent';
import { useSelector } from 'react-redux';

const Manage = () => {
    const { user } = useSelector((store) => {
        return store.userSlice;
    });
    let batchdata = useGetBatchesQuery()
    const navigate = useNavigate();
    let [inProgressData, setInProgressData] = useState([])
    let [submittedData, setSubmittedData] = useState([])
    let [invalidData, setInvalidData] = useState([])

    useEffect(() => {
        if (batchdata?.isSuccess) {
            setInProgressData(batchdata.data.data.filter(item => item.status === 'In Progress'));
            setSubmittedData(batchdata.data.data.filter(item => item.status === 'Submitted'));
            setInvalidData(batchdata.data.data.filter(item => item.status === 'Invalid'));
        }
    }, [batchdata.status])

    return (
        <ReceptionLayout>
            <div className="container-full">
                <div className="content-header">
                    <div className="d-flex align-items-center">
                        <div className="me-auto">
                            <div className="d-inline-block align-items-center">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="#">
                                                <i className="mdi mdi-home-outline" />
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            Batch Management
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Manage Batches
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="box">
                        <div className="box-header with-border">
                            <div className="box-in">
                                {user.role === "Super User/Admin" ?
                                    <button onClick={() => navigate("/dashboard")} className='btn btn-danger-light'>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-arrow-left"
                                        >
                                            <line x1={19} y1={12} x2={5} y2={12} />
                                            <polyline points="12 19 5 12 12 5" />
                                        </svg>
                                    </button> :
                                    <button onClick={() => navigate("/reception/dashboard")} className='btn btn-danger-light'>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-arrow-left"
                                        >
                                            <line x1={19} y1={12} x2={5} y2={12} />
                                            <polyline points="12 19 5 12 12 5" />
                                        </svg>
                                    </button>
                                }
                                <h4 className="box-title me-auto ms-4">
                                    <strong>Manage Batches</strong>
                                </h4>
                                <div className="box-right">
                                    {/* <div className="row g-1"> */}
                                    {/* <div className="col-sm-12 col-md-8">
                                            <div className="table-top-search">
                                                <select className="form-select" aria-label="Default select example">
                                                    <option selected>Search by</option>
                                                    <option value="4">Folder number</option>
                                                </select>
                                                <input type="search" className="form-control" placeholder="Search" aria-controls="complex_header" />
                                            </div>
                                        </div> */}
                                    <div className="">
                                        <Link to={"/reception/batch-management/create"} className="btn btn-primary btn-export">Create Batch</Link>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        {batchdata.isError && <LoadingErrorComponent error />}
                        {batchdata.isLoading && <LoadingErrorComponent loading />}
                        {batchdata.isSuccess && (
                            <div className="box-body">
                                <div className="row gy-4">
                                    <div className="col-sm-12">
                                        <div className="box-progress">
                                            <h5 className="box-title">Batches in Progress</h5>
                                            <div className="row">
                                                {
                                                    inProgressData?.length > 0 ? (
                                                        inProgressData?.map((elem, i) => {
                                                            return (
                                                                <div className='col-auto' key={i}>
                                                                    <BatchItem type="progress" data={elem} />
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <div className='col-12 text-center'>Batches yet to be added.</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="box-progress">
                                            <h5 className="box-title">Batches Submitted</h5>
                                            <div className="row">
                                                {
                                                    submittedData?.length > 0 ? (
                                                        submittedData?.map((elem, i) => {
                                                            return (
                                                                <div className="col-auto" key={i}>
                                                                    <BatchItem type="submitted" data={elem} />
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <div className='col-12 text-center'>Batches yet to be submitted.</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* invalid data batch */}
                                    <div className="col-sm-12">
                                        <div className="box-progress">
                                            <h5 className="box-title">Batches - Invalid Records</h5>
                                            <div className="row">
                                                {
                                                    invalidData?.length > 0 ? (
                                                        invalidData?.map((elem, i) => {
                                                            return (
                                                                <div className="col-auto" key={i}>
                                                                    <BatchItem type="submitted" data={elem} />
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <div className='col-12 text-center'>No Invalid Batches</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </ReceptionLayout>
    )
}

export default Manage