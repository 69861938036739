import React, { useReducer, useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../assets/css/style.css";
import validateSAID from "../../methods/validateSAID";
import PhoneInput from 'react-phone-number-input';
import { LiaTimesSolid } from "react-icons/lia";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ThemeWrapper from "../wrapper/ThemeWrapper/ThemeWrapper";
import LoadingErrorComponent from "../LoadingErrorComponent/LoadingErrorComponent";
import ViewImageModal from "../modal/ViewImageModal";
import { getNextDate } from "../../methods/getNextDate";
import { formatDate } from "../../methods/convertDateFormat";
import { useGetAeTypeQuery } from "../../store/api/reception/manageClient";
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const ClientReadOnlyForm = ({ singlePage, response, updateClient }) => {
    const params = useParams();
    let singleData = response?.data?.data

    const [accordionActiveKey, setAccordionActiveKey] = useState("0");

    // form details section
    const [procedureDate, setProcedureDate] = useState("")

    // patient details section
    const [showNatanality, setShowNatanality] = useState(false);
    const [dateValid, setDateValid] = useState(null);
    const [age, setAge] = useState("");
    const [clientDob, setClientDob] = useState("");
    const [selectedSaId, setSelectedSaId] = useState("");

    // medical service received
    const [ifHiv, setIfHiv] = useState("")
    const [hivTest, setHivTest] = useState('')
    const [hivResult, setHivResult] = useState('')

    // voluntiary male medical surcumstation
    const [clientName, setClientName] = useState("")

    // consent
    const [medicalProcedure, setMedicalProcedure] = useState([])
    const [patientSignature, setPatientSignature] = useState("")
    const [gurdianNameSurname, setGurdianNameSurname] = useState("")
    const [gurdianPatientSignature, setGurdianPatientSignature] = useState("")
    const [counsellorNameSurname, setCounsellorNameSurname] = useState("")
    const [formAttachment, setFormAttachment] = useState(false)
    const [parentSignatureAttachment, setParentSignatureAttachment] = useState(false)
    const [counsellorSignature, setCounsellorSignature] = useState("")

    // procedure
    const [surgeonName, setSurgeonName] = useState("")
    const [surgeonSignature, setSurgeonSignature] = useState("")
    const [surgeonSancNo, setSurgeonSancNo] = useState("")
    const [surgicalMethod, setSurgicalMethod] = useState("")
    const [deviceOrAidName, setDeviceOrAidName] = useState()


    // marketing surveys
    const [wherePatientHear, setWherePatientHear] = useState("")
    const [patientCircumcisedArr, setPatientCircumcisedArr] = useState([])
    const [smNo, setSmNo] = useState("")
    const [smName, setSmName] = useState("")

    // intraoperative observation
    const [aeType, setAeType] = useState([])
    const getAeType = useGetAeTypeQuery()
    const [aeSensitivity, setAeSensitivity] = useState("")
    const [noteOnAe, setNoteOnAe] = useState("")

    // attachment
    const [base64String, setBase64String] = useState('');
    const [pdfFile, setPdfFile] = useState("")
    const [consentModal, setConsentModal] = useState("")
    const [idImageModal, setIdImageModal] = useState(false)
    const [otherAttachmentModal, setOtherAttachmentModal] = useState(false)
    const [base64StringOthers, setBase64StringOthers] = useState('');
    const [secondaryAttachmentModal, setSecondaryAttachmentModal] = useState(false)
    const [base64StringSecondary, setBase64StringSecondary] = useState('');

    const initialState = {
        formDetails: {
            barcodeCDCNumber: "",
            serviceDeliveryPartner: "",
            facility: "",
            procedureDate: "",
            vmmcServiceDeliverySetting: "",
            folderNo: "",
        },
        patientDetails: {
            firstName: "",
            surname: "",
            dateOfBirth: "",
            nationality: "",
            countryOfOrigin: "",
            identityVerification: "",
            saidOrDlOrBcOrAffid: "",
            email: "",
            contactNumber: "",
            gender: "",
            nextOfKinContactNumber: "",
            relationshipWithClient: "",
            active: "",
        }
    };

    const addClientReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_STATE":
                return { ...payload };
            case "SET_ADD_CLIENT":
                return {
                    ...state,
                    formDetails: {
                        ...state.formDetails,
                        [payload.name]: payload.data,
                    },
                };
            case "SET_ADD_PATIENT":
                return {
                    ...state,
                    patientDetails: {
                        ...state.patientDetails,
                        [payload.name]: payload.data,
                    },
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(addClientReducer, initialState);
    useEffect(() => {
        if (response?.status === "fulfilled") {
            dispatch({
                type: "SET_STATE",
                payload: {
                    formDetails: {
                        barcodeCDCNumber: singleData?.formDetails?.barcodeCDCNumber,
                        serviceDeliveryPartner: singleData?.formDetails?.serviceDeliveryPartner,
                        facility: singleData?.formDetails?.facility,
                        procedureDate: singleData?.formDetails?.procedureDate,
                        vmmcServiceDeliverySetting: singleData?.formDetails?.vmmcServiceDeliverySetting,
                        folderNo: singleData?.formDetails?.folderNo,
                    },
                    patientDetails: {
                        firstName: singleData?.patientDetails?.firstName,
                        surname: singleData?.patientDetails?.surname,
                        dateOfBirth: singleData?.patientDetails?.dateOfBirth.split("/").join('-'),
                        nationality: singleData?.patientDetails?.nationality,
                        countryOfOrigin: singleData?.patientDetails?.countryOfOrigin,
                        identityVerification: singleData?.patientDetails?.identityVerification,
                        identityVerificationNumber: singleData?.patientDetails?.identityVerificationNumber,
                        email: singleData?.patientDetails?.email,
                        contactNumber: singleData?.patientDetails?.contactNumber,
                        gender: singleData?.patientDetails?.gender.toLowerCase(),
                        nextOfKinContactNumber: singleData?.patientDetails?.nextOfKinContactNumber,
                        relationshipWithClient: singleData?.patientDetails?.relationshipWithClient.toLowerCase(),
                        active: singleData?.patientDetails?.active,
                        clientID: params.clientID
                    },
                }
            })

            setHivTest(singleData?.medicalServicesReceived?.hivTesting)
            setHivResult(singleData?.medicalServicesReceived?.hivStatusTestResult)
            setIfHiv(singleData?.medicalServicesReceived?.posistiveCheckbox || "")
            setClientName(singleData?.voluntaryMaleMedicalCircumcision?.clientName)
            //consent
            setFormAttachment(singleData?.consent?.consentFormAttachment)
            setCounsellorNameSurname(singleData?.consent?.counsellorNameAndSurname)
            setCounsellorSignature(singleData?.consent?.counsellorSignature)
            setGurdianPatientSignature(singleData?.consent?.guardianParentSignature)
            setGurdianPatientSignature(singleData?.consent?.guardianParentNameAndSurname)
            setMedicalProcedure(singleData?.consent?.medicalProcedures)
            setPatientSignature(singleData?.consent?.paitientSignature.toLowerCase())
            setParentSignatureAttachment(singleData?.consent?.parentSignatureAttachment)
            setGurdianNameSurname(singleData?.consent?.guardianParentNameAndSurname)
            //procedure
            if (singleData?.procedure?.surgicalMethod.toLowerCase() === 'dorsal slit') {
                setSurgicalMethod('Dorsal')
            } else if (singleData?.procedure?.surgicalMethod.toLowerCase() === 'sleeve resection') {
                setSurgicalMethod('Sleeve')
            } else if (singleData?.procedure?.surgicalMethod.toLowerCase() === 'device/surgical aid') {
                setSurgicalMethod('DeviceOrAid')
            } else {
                setSurgicalMethod(singleData?.procedure?.surgicalMethod)
            }
            setDeviceOrAidName(singleData?.procedure?.deviceOrAidName)
            setSurgeonName(singleData?.procedure?.surgeonName)
            setSurgeonSignature(singleData?.procedure?.surgeonSignature?.toLowerCase())
            setSurgeonSancNo(singleData?.procedure?.surgeonHPCSA_SANCNo)

            //Marketing surveys
            setWherePatientHear(singleData?.marketingSurveys?.wherePatientHear)
            setSmNo(singleData?.marketingSurveys?.smNo)
            setSmName(singleData?.marketingSurveys?.recruiterName)
            setPatientCircumcisedArr(singleData?.marketingSurveys?.reasonForCircumcised)

            // intraoperative observation
            setAeSensitivity(singleData?.intraOperativeObservations?.aeSeverity)
            setAeType(singleData?.intraOperativeObservations?.aeType)
            setNoteOnAe(singleData?.intraOperativeObservations?.notesOnAe)
            setSelectedSaId(singleData?.patientDetails?.identityVerification)
            // attachment
            setBase64String(singleData?.attachment && singleData?.attachment[0]?.attachment_Data)
            setPdfFile(singleData?.consentFile && singleData?.consentFile?.consentData)
            setBase64StringOthers(singleData?.attachment && singleData?.attachment[1]?.attachment_Data)
            setBase64StringSecondary(singleData?.attachment && singleData?.attachment[2]?.attachment_Data)

            setProcedureDate(singleData?.formDetails?.procedureDate?.split("/").join("-"))
            setClientDob(singleData?.patientDetails?.dateOfBirth?.split("/").join("-"))

            const birthDate = new Date(singleData?.patientDetails?.dateOfBirth?.split("/").join("-"));
            let NextDate = getNextDate(birthDate)
            let formattedDate = formatDate(NextDate)

            const age = new Date(new Date() - new Date(formattedDate)).getFullYear() - 1970;
            setAge(age)
        }
    }, [response.status])
    const navigate = useNavigate()
    return (
        <div className="container-full">
            <div className="content-header">
                <div className="d-flex align-items-center">
                    <div className="me-auto">
                        <div className="d-inline-block align-items-center">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="#">
                                            <i className="mdi mdi-home-outline"></i>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">
                                        Manage Client Record
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Update Client
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="box">
                    <div className="box-header with-border">
                        <div className="box-in">
                            <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">

                                <strong>View Client Details</strong>
                            </h4>
                            <div className="box-right">
                                <input
                                    className="choose-file"
                                    type="file"
                                    id="Bulk"
                                    accept="application/pdf,application/vnd.ms-excel"
                                    multiple
                                />
                            </div>
                        </div>
                    </div>
                    {response.isError && (
                        <LoadingErrorComponent error />
                    )}
                    {response.isLoading && (
                        <LoadingErrorComponent loading />
                    )}
                    {response.isSuccess && (
                        response.data.data ? (
                            <div id="sform" className="box-body">
                                <div id="myForm" className="clint-from">
                                    <Accordion activeKey={accordionActiveKey} flush>
                                        {/* form details section */}
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("0")}>
                                                <div className="acco-box">
                                                    <p>Form details</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Form className="we-form">
                                                    <Row>
                                                        <Form.Group xl="6" as={Col} className="mb-2 pb-1">
                                                            <Form.Label>Barcode CDC Number</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={state?.formDetails?.barcodeCDCNumber}
                                                                name="barcodeCDCNumber"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} className="mb-2 pb-1">
                                                            <Form.Label>Facility</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter facility"
                                                                name="facility"
                                                                value={state?.formDetails?.facility}
                                                                className="prevent-validation"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} className="mb-2 pb-1">
                                                            <Form.Label>
                                                                Service Delivery Partner
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Service delivery partner"
                                                                name="serviceDeliveryPartner"
                                                                value={state?.formDetails?.serviceDeliveryPartner}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                    <Row>
                                                        <div className="col-md-12">
                                                            <div className="Service-Delivery mb-3">
                                                                <h3 className="me-3">
                                                                    VMMC SERVICE DELIVERY SETTING
                                                                </h3>
                                                                <div className="d-flex flex-wrap">
                                                                    <div>
                                                                        <input
                                                                            name="vmmcServiceDeliverySetting"
                                                                            type="radio"
                                                                            id="radio1"
                                                                            className="with-gap radio-col-danger form-check-input"
                                                                            value="fixed"
                                                                            checked={state?.formDetails?.vmmcServiceDeliverySetting?.toLowerCase() === "fixed"}
                                                                            disabled
                                                                        />
                                                                        <label className="form-check-label" htmlFor="radio1">Fixed</label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            name="vmmcServiceDeliverySetting"
                                                                            type="radio"
                                                                            id="radio2"
                                                                            className="with-gap radio-col-danger form-check-input"
                                                                            value="outreach"
                                                                            checked={state?.formDetails?.vmmcServiceDeliverySetting === "outreach"}
                                                                            disabled
                                                                        />
                                                                        <label className="form-check-label" htmlFor="radio2">Outreach</label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            name="vmmcServiceDeliverySetting"
                                                                            type="radio"
                                                                            id="radio3"
                                                                            className="with-gap radio-col-danger form-check-input"
                                                                            value="mobile"
                                                                            checked={state?.formDetails?.vmmcServiceDeliverySetting === "mobile"}
                                                                            disabled
                                                                        />
                                                                        <label className="form-check-label" htmlFor="radio3">Mobile</label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            name="vmmcServiceDeliverySetting"
                                                                            type="radio"
                                                                            id="radio4"
                                                                            className="with-gap radio-col-danger form-check-input"
                                                                            value="TMI_CMI"
                                                                            checked={state?.formDetails?.vmmcServiceDeliverySetting === "TMI_CMI"}
                                                                            disabled
                                                                        />
                                                                        <label className="form-check-label" htmlFor="radio4">TMI / CMI</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <Form.Group as={Col} className="mb-2 pb-1">
                                                            <Form.Label>Folder no.</Form.Label>
                                                            <Form.Control
                                                                className="prevent-validation"
                                                                type="text"
                                                                placeholder="Enter folderNo"
                                                                name="folderNo"
                                                                value={state?.formDetails?.folderNo}
                                                                style={{ border: '1px solid #bbbbbb', backgroundImage: 'none' }}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} className="mb-2 pb-1">
                                                            <Form.Label>Procedure Date</Form.Label>
                                                            {procedureDate && <DatePicker
                                                                defaultValue={dayjs(procedureDate, dateFormat)}
                                                                className="form-control"
                                                                name="procedureDate"
                                                                disabled
                                                            />}
                                                        </Form.Group>
                                                    </Row>
                                                </Form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* patient details */}
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("1")}>
                                                <div className="acco-box">
                                                    <p>Patient details</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Form className="we-form">
                                                    <Row>
                                                        {/* first name */}
                                                        <Form.Group className="col-lg-4 col-md-6">
                                                            <Form.Label> First name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                className="form-control"
                                                                id="firstName"
                                                                placeholder="First name"
                                                                disabled
                                                                name="firstName"
                                                                value={state?.patientDetails?.firstName}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is disabled
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        {/* surname */}
                                                        <Form.Group className="col-lg-4 col-md-6">
                                                            <Form.Label> Surname</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                className="form-control"
                                                                id="Surname"
                                                                placeholder="Surname"
                                                                name="surname"
                                                                value={state?.patientDetails?.surname}
                                                                disabled
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                This field is disabled
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        {/* date of birth */}
                                                        <div className="col-lg-4 col-md-6">
                                                            <Form.Group as={Col} className="mb-2 pb-1">
                                                                <Form.Label>Date of birth</Form.Label>
                                                                {clientDob && <DatePicker
                                                                    className="form-control"
                                                                    name="dateOfBirth"
                                                                    defaultValue={dayjs(clientDob, dateFormat)}
                                                                    disabled
                                                                />}
                                                                <div className="justify-content-between" style={{ fontSize: "0.875em", color: "#198754" }}>
                                                                    <div> {age && "age: " + age}</div>
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                        {/* nationality */}
                                                        <Form.Group className="col-lg-4 col-md-6">
                                                            <div className="mb-3">
                                                                <Form.Label className="form-label">
                                                                    Nationality
                                                                </Form.Label>
                                                                <select
                                                                    className="form-select"
                                                                    id="National"
                                                                    name="nationality"
                                                                    disabled
                                                                    value={state?.patientDetails?.nationality}
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="South_African">
                                                                        South African
                                                                    </option>
                                                                    <option value="non_South_African">
                                                                        Non South African
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </Form.Group>
                                                        {/* country of origin(problem) */}
                                                        <div id="cog" className="col-lg-4 col-md-6 NationalID" style={{ display: showNatanality ? "block" : "none" }}>
                                                            {state?.patientDetails?.nationality === "non_South_African" ? (
                                                                <>
                                                                    <label htmlFor="National" className="form-label" >
                                                                        Country of Origin
                                                                    </label>
                                                                    <select className="form-select" id="National"
                                                                        name="countryOfOrigin">
                                                                        <option value="">Select</option>
                                                                        <option value={"botswana"}>Botswana</option>
                                                                        <option value={"eswatini"}>Eswatini</option>
                                                                        <option value={"lesotho"}>Lesotho</option>
                                                                        <option value={"mozambique"}>Mozambique</option>
                                                                        <option value={"namibia"}>Namibia</option>
                                                                        <option value={"zimbabwe"}>Zimbabwe</option>
                                                                    </select>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <label htmlFor="National" className="form-label" >
                                                                        Country of Origin
                                                                    </label>
                                                                    <select className="form-select" id="National" value={state?.patientDetails?.nationality} name="countryOfOrigin"
                                                                    >
                                                                        <option value="">Select</option>
                                                                        <option value={"south_africa"}>
                                                                            South Africa
                                                                        </option>
                                                                    </select>
                                                                </>
                                                            )}
                                                        </div>
                                                        {/* identity verification */}
                                                        <div className="col-lg-4 col-md-6" id="SAID">
                                                            <div className="mb-3">
                                                                <label htmlFor="saID" className="form-label">
                                                                    Identity Verification
                                                                </label>
                                                                <select className="form-select" id="saID" disabled value={state?.patientDetails?.identityVerification} name="identityVerification">
                                                                    <option value="">Select</option>
                                                                    <option value="South African ID">
                                                                        South African ID
                                                                    </option>
                                                                    <option value="Driving License">
                                                                        Driving License
                                                                    </option>
                                                                    <option value="Birth Certificate">
                                                                        Birth Certificate
                                                                    </option>
                                                                    <option value="Affidavit">Affidavit</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 margin-medium" id="additionalInput">
                                                            {(selectedSaId === "South African ID" ||
                                                                selectedSaId === "Driving License" ||
                                                                selectedSaId === "Birth Certificate" ||
                                                                selectedSaId === "Affidavit") && (
                                                                    <>
                                                                        <label htmlFor="saID" className="form-label">
                                                                            {selectedSaId}
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={selectedSaId}
                                                                            name="identityVerificationNumber"
                                                                            value={state?.patientDetails?.identityVerificationNumber}
                                                                            disabled
                                                                        />
                                                                    </>
                                                                )}
                                                        </div>
                                                        {/* email */}
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label" htmlFor="email001">
                                                                    Email (if any)
                                                                </label>
                                                                <input
                                                                    id="email001"
                                                                    className="prevent-validation form-control"
                                                                    type="email"
                                                                    placeholder="Email"
                                                                    style={{ border: '1px solid #bbbbbb', backgroundImage: 'none' }}
                                                                    name="email"
                                                                    value={state?.patientDetails?.email}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* contact number */}
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label" htmlFor="mobile">
                                                                    Contact number
                                                                </label>
                                                                <PhoneInput
                                                                    international
                                                                    defaultCountry="ZA"
                                                                    placeholder="Enter phone number"
                                                                    value={state?.patientDetails?.contactNumber}
                                                                    name="contactNumber"
                                                                    id="mobile2"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* gender */}
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="sex" className="form-label">
                                                                    Gender
                                                                </label>
                                                                <select
                                                                    className={"form-select"}
                                                                    id="sex"
                                                                    name={"gender"}
                                                                    disabled
                                                                    value={state?.patientDetails?.gender.toLowerCase()}
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="male">Male</option>
                                                                    <option value="female">Female</option>
                                                                    <option value="other">Other</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* next kin of contact number */}
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label" htmlFor="mobile2">
                                                                    Next of Kin Contact Number
                                                                </label>
                                                                <PhoneInput
                                                                    international
                                                                    defaultCountry="ZA"
                                                                    placeholder="Enter phone number"
                                                                    value={state?.patientDetails?.nextOfKinContactNumber}
                                                                    name="nextOfKinContactNumber"
                                                                    id="mobile2"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* relationship with client */}
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="relations" className="form-label">
                                                                    Relationship with Client
                                                                </label>
                                                                <select
                                                                    className="form-select"
                                                                    id="relations"
                                                                    disabled
                                                                    name="relationshipWithClient"
                                                                    value={state?.patientDetails?.relationshipWithClient.toLowerCase()}
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="father">Father</option>
                                                                    <option value="mother">Mother</option>
                                                                    <option value="spouse">Spouse</option>
                                                                    <option value="brother">Brother</option>
                                                                    <option value="sister">Sister</option>
                                                                    <option value="wife">Wife</option>
                                                                    <option value="uncle">Uncle</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </Form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* HIV test */}
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("2")}>
                                                <div className="acco-box">
                                                    <p>HIV TEST</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="mb-3">
                                                    <p className="mb-4">
                                                        I, the client identified above, declare that I
                                                        have received pre-test counselling on
                                                        HIV/AIDS. I understand the test is voluntary
                                                        and I consent to have my HIV status disclosed
                                                        to me. I have been informed about the nature,
                                                        risks and implications involved in an HIV
                                                        test. I received and understood all the
                                                        relevant information about the test. I had
                                                        enough opportunity to ask questions and decide
                                                        if I want to do the test. I consent to a
                                                        sample of blood being taken for an HIV test to
                                                        be performed by the counsellor below. I agree
                                                        that my test results can be communicated to
                                                        the testing provider, to my doctor and to a
                                                        third party for data collection and reporting,
                                                        provided that confidentiality is respected. I
                                                        confirm that I received the HIV test free of
                                                        charge.
                                                    </p>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* medical service received */}
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("3")}>
                                                <div className="acco-box">
                                                    <p>MEDICAL SERVICES RECEIVED</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Form className="we-form">
                                                    <div className="row">
                                                        {/* First Column */}
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <div className="test-box">
                                                                    <h6>HIV testing (tick 1 option)</h6>
                                                                    <Form.Group>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            id="Patient"
                                                                            label="Patient counselled and tested for HIV at this MMC point / Facility"
                                                                            name="MSERVICES"
                                                                            value={'tested_hiv'}
                                                                            checked={hivTest === 'tested_hiv' || hivTest === 'Patient counselled and tested for HIV at this MMC point / Facility'}
                                                                            disabled
                                                                        />
                                                                        <Form.Check
                                                                            type="radio"
                                                                            id="testing"
                                                                            label="Patient arrived with known HIV status (i.e. no testing disabled)"
                                                                            name="MSERVICES"
                                                                            value={'known_status'}
                                                                            checked={hivTest === 'known_status' || hivTest === 'Patient arrived with known HIV status (i.e. no testing required)'}
                                                                            disabled
                                                                        />
                                                                        <Form.Check
                                                                            type="radio"
                                                                            id="declined"
                                                                            label="Patient declined to test for HIV"
                                                                            name="MSERVICES"
                                                                            value={'declined_to_test'}
                                                                            checked={hivTest === 'declined_to_test' || hivTest === 'Patient declined to test for HIV'}
                                                                            feedbackType="invalid"
                                                                            feedback="You must select one option"
                                                                            disabled
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Second Column */}
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <div className="test-box">
                                                                    <h6>
                                                                        HIV status/test result (tick 1 option)
                                                                    </h6>
                                                                    <Form.Group>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            id="HIVpositive"
                                                                            label="HIV positive"
                                                                            name="HIVresult"
                                                                            disabled
                                                                            value={'positive'}
                                                                            checked={hivResult === 'positive' || hivResult === 'HIV positive'}
                                                                        />
                                                                        <Form.Check
                                                                            type="radio"
                                                                            id="HIVnegative"
                                                                            label="HIV negative"
                                                                            name="HIVresult"
                                                                            value={'negative'}
                                                                            checked={hivResult === 'negative' || hivResult === 'HIV negative'}
                                                                            disabled
                                                                        />
                                                                        <Form.Check
                                                                            type="radio"
                                                                            id="Inconclusive"
                                                                            label="Inconclusive"
                                                                            name="HIVresult"
                                                                            value={'inconclusive'}
                                                                            checked={hivResult === 'inconclusive'}
                                                                            disabled
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* voluntary male medical circumcision */}
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("4")}>
                                                <div className="acco-box">
                                                    <p>VOLUNTARY MALE MEDICAL CIRCUMCISION</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Form className="we-form">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <p className="mb-4">
                                                                    I, the client identified above, willingly
                                                                    give my consent/assent to be medically
                                                                    circumcised by a healthcare provider at the
                                                                    facility listed above. The procedure has
                                                                    been explained to me, as well as the
                                                                    possible advantages and risks. I have had
                                                                    opportunity to ask questions and all
                                                                    questions have been answered. By signing
                                                                    this informed consent, I agree to have
                                                                    circumcision surgery. I agree to adhere to
                                                                    the post-operative instructions given to me.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="mb-3"></div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="checkbox_1">
                                                                    <span>I,</span>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control style2 w-200 d-inline-block"
                                                                        id="barcode"
                                                                        disabled
                                                                        value={clientName}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        This field is disabled
                                                                    </Form.Control.Feedback>
                                                                    <span>
                                                                        hereby further authorize Right to Care NPC
                                                                        ("RTC") and any authorized person or
                                                                        entity acting on behalf of RTC to collect,
                                                                        use, disclose, dispose off and otherwise
                                                                        process my personal data in accordance
                                                                        with the South African Protection of
                                                                        Personal Information Act No. 4 of 2013,
                                                                        various sources of law that impose privacy
                                                                        obligations and the Centers for Disease
                                                                        Control and Prevention ("CDC") Data
                                                                        Protection Principles, for the purposes of
                                                                        HIV Testing and Medical Male Circumcision.
                                                                    </span>
                                                                    <span>
                                                                        I further understand that I have the right
                                                                        to withdraw my consent at any time. To
                                                                        withdraw your consent, please contact
                                                                        Email: popia@righttocare.org
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* consent */}
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("5")}>
                                                <div className="acco-box">
                                                    <p>CONSENT</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Form className="we-form">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="Service-Delivery mb-3">
                                                                <p className="me-3">
                                                                    I have been counselled and I consent to the
                                                                    following medical procedures:
                                                                </p>
                                                                <div className="d-flex flex-wrap">
                                                                    <div>
                                                                        <input
                                                                            name="medical"
                                                                            type="checkbox"
                                                                            id="HIV"
                                                                            className="filled-in form-check-input chk-col-danger"
                                                                            value={"hivTest"}
                                                                            checked={medicalProcedure?.includes("hivTest")}
                                                                            disabled
                                                                        />
                                                                        <label htmlFor="HIV">HIV test</label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            name="medical"
                                                                            type="checkbox"
                                                                            id="circumcision"
                                                                            className="filled-in form-check-input chk-col-danger"
                                                                            value={"maleCircumcision"}
                                                                            checked={medicalProcedure?.includes("maleCircumcision")}
                                                                            disabled
                                                                        />
                                                                        <label htmlFor="circumcision"> Male circumcision </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="patientsignature" className="form-label">
                                                                    Patient signature
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="patientsignature"
                                                                    placeholder="Patient signature"
                                                                    value={patientSignature}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="d-flex justify-content-lg-center my-3">
                                                                <input
                                                                    type="checkbox"
                                                                    id="Signed"
                                                                    className="filled-in chk-col-danger"
                                                                    value={formAttachment}
                                                                    disabled
                                                                    checked={formAttachment}
                                                                />
                                                                <label htmlFor="Signed">
                                                                    Signed consent form attached to patient file
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {
                                                            (age > 9 && age < 17) && (
                                                                <>
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <h5>
                                                                                If patient is between age of  15 to 17 years of age, parent,
                                                                                guardian or caregiver must sign
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="Parent/Guardian" className="form-label" >
                                                                                Parent/GuardianParent/Guardian Caregiver
                                                                                name &amp; surname
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="Parent/Guardian"
                                                                                placeholder="Parent/Guardian Caregiver name & surname"
                                                                                value={gurdianNameSurname}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="Parent/Guardiansignature" className="form-label" >
                                                                                Parent/Guardian Caregiver signature
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="Parent/Guardiansignature"
                                                                                placeholder="Parent/Guardian Caregiver signature"
                                                                                value={gurdianPatientSignature}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="Counsellor" className="form-label" >
                                                                                Counsellor name &amp; surname
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="Counsellor"
                                                                                placeholder="Counsellor name & surname"
                                                                                disabled
                                                                                value={counsellorNameSurname}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="Counsellorsignature" className="form-label">
                                                                                Counsellor signature
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="Counsellorsignature"
                                                                                placeholder="Counsellor signature"
                                                                                disabled
                                                                                value={counsellorSignature}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="patient"
                                                                                className="filled-in chk-col-danger"
                                                                                value={parentSignatureAttachment}
                                                                                checked={parentSignatureAttachment}
                                                                                disabled
                                                                            />
                                                                            <label htmlFor="patient">
                                                                                Parent/Guardian/Caregiver signature attached to patient file
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </Form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* procedure */}
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("6")}>
                                                <div className="acco-box">
                                                    <p>PROCEDURE</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Form className="we-form">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="Service-Delivery mb-3">
                                                                <div className="d-flex flex-wrap">
                                                                    <div className="">
                                                                        <label htmlFor="Surgical"> Surgical method used </label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            name="PROnext"
                                                                            type="radio"
                                                                            id="Dorsal"
                                                                            className="with-gap radio-col-danger"
                                                                            value="Dorsal"
                                                                            disabled
                                                                            checked={surgicalMethod === "Dorsal" || surgicalMethod === "Dorsal slit"}
                                                                        />
                                                                        <label htmlFor="Dorsal">Dorsal slit</label>
                                                                        <div className="invalid-feedback">This field is disabled</div>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            name="PROnext"
                                                                            type="radio"
                                                                            id="Sleeve"
                                                                            className="with-gap radio-col-danger"
                                                                            value="Sleeve"
                                                                            disabled
                                                                            checked={surgicalMethod === "Sleeve" || surgicalMethod === "Sleeve resection"}
                                                                        />
                                                                        <label htmlFor="Sleeve"> Sleeve resection </label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="mb-3">
                                                            <label htmlFor="Surgeon" className="form-label" >
                                                                Device / Surgical Aid Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Surgeon"
                                                                placeholder="Device / Surgical Aid Name"
                                                                disabled
                                                                value={deviceOrAidName}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="test-box mb-3">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="Surgeon" className="form-label" >
                                                                        Surgeon's ("Cutter") Name &amp; Surname:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="Surgeon"
                                                                        placeholder="Surgeon's ('Cutter') Name & Surname"
                                                                        disabled
                                                                        value={surgeonName}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        This field is disabled
                                                                    </Form.Control.Feedback>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="SurgeonCutter" className="form-label">
                                                                        Surgeon's ("Cutter") HPCSA / SANC No:
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="SurgeonCutter"
                                                                        placeholder="Surgeon's ('Cutter') HPCSA / SANC No"
                                                                        disabled
                                                                        value={surgeonSancNo}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        This field is disabled
                                                                    </Form.Control.Feedback>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <div className="d-flex flex-md-column flex-sm-row">
                                                                        <div className="text-nowrap me-3">
                                                                            Surgeon's ("Cutter") Signature:
                                                                        </div>
                                                                        <div className="d-flex align-items-center flex-wrap mt-md-2">
                                                                            <div>
                                                                                <input
                                                                                    type="radio"
                                                                                    name="SurgeonSignature"
                                                                                    id="SurgeonSignatureYes"
                                                                                    className="filled-in chk-col-danger form-check-input"
                                                                                    value={"yes"}
                                                                                    checked={surgeonSignature === 'yes'}
                                                                                    disabled
                                                                                    required
                                                                                />
                                                                                <label htmlFor="SurgeonSignatureYes" className="form-check-label" >
                                                                                    Yes
                                                                                </label>
                                                                                <div className="invalid-feedback">This field is required</div>
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="SurgeonSignatureNo"
                                                                                    name="SurgeonSignature"
                                                                                    className="filled-in chk-col-danger form-check-input"
                                                                                    value={"no"}
                                                                                    checked={surgeonSignature === 'no'}
                                                                                    disabled
                                                                                    required
                                                                                />
                                                                                <label htmlFor="SurgeonSignatureNo" className="form-check-label" >
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* marketing surveys */}
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("7")}>
                                                <div className="acco-box">
                                                    <p>MARKETING SURVEYS</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Form className="we-form">
                                                    <div className="row">
                                                        {/* left section */}
                                                        <div className="col-xl-7">
                                                            <div className="mb-3">
                                                                <div className="test-box">
                                                                    <h6>
                                                                        Where did patient hear about medical
                                                                        circumcision? (tick atleast 1 option)
                                                                    </h6>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="inner_box mb-3">
                                                                                <div className="row align-items-center">
                                                                                    <div className="col-lg-5">
                                                                                        <input
                                                                                            type="radio"
                                                                                            name="marketingSurvey"
                                                                                            id="Mobiliser"
                                                                                            className="filled-in chk-col-danger"
                                                                                            value={"mobilizerExist"}
                                                                                            checked={wherePatientHear === "mobilizerExist"}
                                                                                            disabled
                                                                                        />
                                                                                        <label htmlFor="Mobiliser">
                                                                                            Social Mobiliser (SM)
                                                                                        </label>
                                                                                    </div>
                                                                                    {wherePatientHear === "mobilizerExist" && <div className="col-lg-7">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <label htmlFor="SMNumber" className="text-nowrap me-2" >
                                                                                                SM Number
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                id="SMNumber"
                                                                                                placeholder="SM Number"
                                                                                                value={smNo}
                                                                                                checked={wherePatientHear === "smNo"}
                                                                                                disabled
                                                                                            />
                                                                                        </div>
                                                                                    </div>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {wherePatientHear === "mobilizerExist" && <div className="col-md-12">
                                                                            <div className="d-flex flex-column flex-lg-row justify-content-lg-between inner_box mb-3">
                                                                                <label htmlFor="SocialMobiliser" className="text-nowrap me-2">
                                                                                    Social Mobiliser Name
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control box-wid"
                                                                                    id="SocialMobiliser"
                                                                                    placeholder="Social Mobiliser Name"
                                                                                    value={smName}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                        </div>}
                                                                        <div className="col-md-12">
                                                                            <div className="row mb-3">
                                                                                <div className="col-sm-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="marketingSurvey"
                                                                                        id="TV"
                                                                                        className="filled-in chk-col-danger"
                                                                                        value={"tv"}
                                                                                        checked={wherePatientHear === "tv" || wherePatientHear === "TV"}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor="TV">TV</label>
                                                                                </div>
                                                                                <div className="col-sm-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="marketingSurvey"
                                                                                        id="Moya"
                                                                                        className="filled-in chk-col-danger"
                                                                                        value={"moyaApp"}
                                                                                        checked={wherePatientHear === "moyaApp" || wherePatientHear === "Moya App"}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor="Moya">
                                                                                        Moya App
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-sm-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="marketingSurvey"
                                                                                        id="Callcentre"
                                                                                        className="filled-in chk-col-danger"
                                                                                        value={"callCentre"}
                                                                                        checked={wherePatientHear === "callCentre" || wherePatientHear === "Call centre"}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor="Callcentre">
                                                                                        Call centre
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="row mb-3">
                                                                                <div className="col-sm-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="marketingSurvey"
                                                                                        id="Radio"
                                                                                        className="filled-in chk-col-danger"
                                                                                        value={"radio"}
                                                                                        checked={wherePatientHear === "radio" || wherePatientHear === "Radio"}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor="Radio">Radio</label>
                                                                                </div>
                                                                                <div className="col-sm-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="marketingSurvey"
                                                                                        id="Media"
                                                                                        className="filled-in chk-col-danger"
                                                                                        value={"socialMedia"}
                                                                                        checked={wherePatientHear === "socialMedia" || wherePatientHear === "Social Media"}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor="Media">
                                                                                        Social Media
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-sm-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="marketingSurvey"
                                                                                        id="Church"
                                                                                        className="filled-in chk-col-danger"
                                                                                        value={"church"}
                                                                                        checked={wherePatientHear === "church" || wherePatientHear === "Church"}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor="Church">
                                                                                        Church
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="row mb-3">
                                                                                <div className="col-sm-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="marketingSurvey"
                                                                                        id="AmbassadorChampion"
                                                                                        className="filled-in chk-col-danger"
                                                                                        value={"ambasadorOrCampaign"}
                                                                                        checked={wherePatientHear === "ambasadorOrCampaign" || wherePatientHear === "Ambassador / Champion"}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor="AmbassadorChampion">
                                                                                        Ambassador / Champion
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-sm-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="marketingSurvey"
                                                                                        id="Friend"
                                                                                        className="filled-in chk-col-danger"
                                                                                        value={"friendOrFamily"}
                                                                                        checked={wherePatientHear === "friendOrFamily" || wherePatientHear === "Friend / Family"}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor="Friend">
                                                                                        Friend / Family
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-sm-4">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="marketingSurvey"
                                                                                        id="School"
                                                                                        className="filled-in chk-col-danger"
                                                                                        value={"school"}
                                                                                        checked={wherePatientHear === "school" || wherePatientHear === "School"}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor="School">
                                                                                        School
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* right section */}
                                                        <div className="col-xl-5">
                                                            <div className="mb-3">
                                                                <div className="test-box">
                                                                    <h6>
                                                                        Why does patient want to be circumcised? (tick at least 1 option)
                                                                    </h6>
                                                                    <div className="col-md-12">
                                                                        <div className="row mb-3 g-0">
                                                                            <div className="col-sm-6">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="Tradition"
                                                                                    className="filled-in chk-col-danger"
                                                                                    value={"tradition"}
                                                                                    checked={patientCircumcisedArr?.includes("tradition") || patientCircumcisedArr?.includes("Tradition / Culture")}
                                                                                    disabled
                                                                                />
                                                                                <label htmlFor="Tradition">
                                                                                    Tradition / Culture
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="riskSTD"
                                                                                    className="filled-in chk-col-danger"
                                                                                    value={"reduceRiskSTD"}
                                                                                    checked={patientCircumcisedArr?.includes("reduceRiskSTD") || patientCircumcisedArr?.includes("Reduce the risk of STD")}
                                                                                    disabled
                                                                                />
                                                                                <label htmlFor="riskSTD">
                                                                                    Reduce the risk of STD
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="row mb-3 g-0">
                                                                            <div className="col-sm-6">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="cleanliness"
                                                                                    className="filled-in chk-col-danger"
                                                                                    value={"hygine"}
                                                                                    checked={patientCircumcisedArr?.includes("hygine") || patientCircumcisedArr?.includes("Hygiene and cleanliness")}
                                                                                    disabled
                                                                                />
                                                                                <label htmlFor="cleanliness">
                                                                                    Hygiene and cleanliness
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="betterSex"
                                                                                    className="filled-in chk-col-danger"
                                                                                    value={"betterSex"}
                                                                                    checked={patientCircumcisedArr?.includes("betterSex") || patientCircumcisedArr?.includes("Better sex")}
                                                                                    disabled
                                                                                />
                                                                                <label htmlFor="betterSex">
                                                                                    Better sex
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="row mb-3 g-0">
                                                                            <div className="col-sm-6">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="riskHIV"
                                                                                    className="filled-in chk-col-danger"
                                                                                    value={"reduceRiskHIV"}
                                                                                    checked={patientCircumcisedArr?.includes("reduceRiskHIV") || patientCircumcisedArr?.includes("Reduce the risk of HIV")}
                                                                                    disabled
                                                                                />
                                                                                <label htmlFor="riskHIV">
                                                                                    Reduce the risk of HIV
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="Religion"
                                                                                    className="filled-in chk-col-danger"
                                                                                    value={"religion"}
                                                                                    checked={patientCircumcisedArr?.includes("religion") || patientCircumcisedArr?.includes("Religion")}
                                                                                    disabled
                                                                                />
                                                                                <label htmlFor="Religion">
                                                                                    Religion
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* intra-operative observations */}
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("8")}>
                                                <div className="acco-box">
                                                    <p>INTRA-OPERATIVE OBSERVATIONS</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Form className="we-form" >
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="test-box">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="d-flex flex-column flex-sm-row mb-3">
                                                                        <strong className="text-nowrap me-3">
                                                                            AE Severity:
                                                                        </strong>
                                                                        <div className="d-flex align-items-center flex-wrap">
                                                                            <div>
                                                                                <input
                                                                                    type="radio"
                                                                                    name="aeSentivity"
                                                                                    id="Mild"
                                                                                    className="filled-in chk-col-danger form-check-input"
                                                                                    value={"mild"}
                                                                                    checked={aeSensitivity.toLowerCase() === "mild"}
                                                                                    disabled
                                                                                />
                                                                                <label htmlFor="Mild" className="form-check-label" >
                                                                                    Mild
                                                                                </label>
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="Moderate"
                                                                                    name="aeSentivity"
                                                                                    className="filled-in chk-col-danger form-check-input"
                                                                                    value={"moderate"}
                                                                                    disabled
                                                                                    checked={aeSensitivity.toLowerCase() === "moderate"}
                                                                                />
                                                                                <label htmlFor="Moderate" className="form-check-label" >
                                                                                    Moderate
                                                                                </label>
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="Severe"
                                                                                    name="aeSentivity"
                                                                                    className="filled-in chk-col-danger form-check-input"
                                                                                    value={"severe"}
                                                                                    disabled
                                                                                    checked={aeSensitivity.toLowerCase() === "severe"}
                                                                                />
                                                                                <label htmlFor="Severe" className="form-check-label" >
                                                                                    Severe
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="d-flex flex-column flex-sm-row mb-3">
                                                                        <strong className="text-nowrap me-3">
                                                                            AE Type:
                                                                        </strong>
                                                                        <div className="d-flex align-items-center flex-wrap">
                                                                            {
                                                                                getAeType?.data?.data?.map((el, i) => (
                                                                                    <div>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            name="radio1"
                                                                                            id={el.aE_Type_value}
                                                                                            className="filled-in chk-col-danger form-check-input"
                                                                                            value={el.aE_Type_value}
                                                                                            // onChange={handleChangeAeType}
                                                                                            disabled
                                                                                        />
                                                                                        <label htmlFor={el.aE_Type_value} className="form-check-label" >
                                                                                            {el.aE_Type}
                                                                                        </label>
                                                                                    </div>
                                                                                ))
                                                                            }

                                                                            {/* <div>
                                      <input
                                        type="checkbox"
                                        name="radio1"
                                        id="Bleeding"
                                        className="filled-in chk-col-danger form-check-input"
                                        value={"bleeding"}
                                        onChange={handleChangeAeType}
                                      />
                                      <label htmlFor="Bleeding" className="form-check-label">
                                        Bleeding
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        name="radio1"
                                        id="Injurypenis"
                                        className="filled-in chk-col-danger form-check-input"
                                        value={"injuryToPenis"}
                                        onChange={handleChangeAeType}
                                      />
                                      <label htmlFor="Injurypenis" className="form-check-label">
                                        Injury to penis
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        name="radio1"
                                        id="Haematoma"
                                        className="filled-in chk-col-danger form-check-input"
                                        value={"hematoma"}
                                        onChange={handleChangeAeType}
                                      />
                                      <label htmlFor="Haematoma" className="form-check-label">
                                        Haematoma
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        name="radio1"
                                        id="anasthesia"
                                        className="filled-in chk-col-danger form-check-input"
                                        value={"reactionAnasthesia"}
                                        onChange={handleChangeAeType}
                                      />
                                      <label htmlFor="anasthesia" className="form-check-label">
                                        Reaction to anasthesia
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        name="radio1"
                                        id="skinremoval"
                                        className="filled-in chk-col-danger form-check-input"
                                        value={"exxesiveSkinRemoval"}
                                        onChange={handleChangeAeType}
                                      />
                                      <label htmlFor="skinremoval" className="form-check-label">
                                        Excessive skin removal
                                      </label>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        name="radio1"
                                        id="Insufficient"
                                        className="filled-in chk-col-danger form-check-input"
                                        value={"insuffitientSkinRemoval"}
                                        onChange={handleChangeAeType}
                                      />
                                      <label htmlFor="Insufficient" className="form-check-label">
                                        Insufficient skin removal
                                      </label>
                                      {aeType.length === 0 && validate_observation && <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>This field is required</div>}
                                    </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="immediate" className="form-check-label">
                                                                            Notes on immediate management of AE
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="immediate"
                                                                            placeholder="Notes on immediate management of AE"
                                                                            disabled
                                                                            value={noteOnAe}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* file attachment */}
                                        <Accordion.Item eventKey="9">
                                            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("9")}>
                                                <div className="acco-box">
                                                    <p>File Attachment</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="row g-3 timeline-steps aos-init aos-animate" data-aos="fade-up">
                                                    {/* consent form */}
                                                    {pdfFile &&
                                                        (<div className="col-md-4">
                                                            <div className="timeline-step">
                                                                <h3 className="h6 mt-3 mb-2">Consent Form</h3>
                                                                <div className="timeline-fill-box overlay-timeline-fill-box" >
                                                                    <div className="overlay" onClick={() => setConsentModal(true)}>Click here to view</div>
                                                                    {
                                                                        pdfFile.includes("image/") ? (
                                                                            <img src={pdfFile} alt="" />
                                                                        ) : (
                                                                            <iframe src={pdfFile} alt="" height={300} width={"100%"} title="pdf" />
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    }
                                                    {pdfFile && <ViewImageModal ModalTitle={"Consent Form"} modalUrl={pdfFile} modalState={consentModal} modalSetState={setConsentModal} />}
                                                    {/* Identity card */}
                                                    {base64String &&
                                                        (<div className="col-md-3">
                                                            <div className="timeline-step">
                                                                <>
                                                                    <h3 className="h6 mt-3 mb-2">{state.patientDetails.identityVerification}</h3>
                                                                    <div className="timeline-fill-box overlay-timeline-fill-box">
                                                                        <div className="overlay" onClick={() => setIdImageModal(true)}>Click here to view</div>
                                                                        {
                                                                            base64String.includes("image/") ? (
                                                                                <img src={base64String} alt="" />
                                                                            ) : (
                                                                                <iframe src={base64String} alt="" height={300} width={"100%"} title="pdf" />
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            </div>
                                                        </div>)
                                                    }
                                                    {base64String && <ViewImageModal ModalTitle={"Identity Card"} modalUrl={base64String} modalState={idImageModal} modalSetState={setIdImageModal} />}

                                                    {/* other attachment */}
                                                    {base64StringOthers &&
                                                        <div className="col-md-3">
                                                            <div className="timeline-step">
                                                                <>
                                                                    <h3 className="h6 mt-3 mb-2">Other Attachment</h3>
                                                                    <div className="timeline-fill-box overlay-timeline-fill-box">
                                                                        <div className="overlay" onClick={() => setOtherAttachmentModal(true)}>Click here to view</div>
                                                                        {
                                                                            base64StringOthers.includes("image/") ? (
                                                                                <img src={base64StringOthers} style={{ width: '100%' }} alt="" />
                                                                            ) : (
                                                                                <iframe src={base64StringOthers} alt="" height={300} width={"100%"} title="pdf" />
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            </div>
                                                        </div>
                                                    }
                                                    {base64StringOthers && <ViewImageModal ModalTitle={"Others Attachment"} modalUrl={base64StringOthers} modalState={otherAttachmentModal} modalSetState={setOtherAttachmentModal} />}

                                                    {/* secondary attachment */}
                                                    {base64StringSecondary &&
                                                        <div className="col-md-3">
                                                            <div className="timeline-step">
                                                                (<>
                                                                    <h3 className="h6 mt-3 mb-2">Secondary Attachment</h3>
                                                                    <div className="timeline-fill-box overlay-timeline-fill-box">
                                                                        <div className="overlay" onClick={() => setSecondaryAttachmentModal(true)}>Click here to view</div>
                                                                        {
                                                                            base64StringSecondary.includes("image/") ? (
                                                                                <img src={base64StringSecondary} alt="" />
                                                                            ) : (
                                                                                <iframe src={base64StringSecondary} alt="" height={300} width={"100%"} title="pdf" />
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>)
                                                            </div>
                                                        </div>
                                                    }
                                                    {base64StringSecondary && <ViewImageModal ModalTitle={"Secondary Attachment"} modalUrl={base64StringSecondary} modalState={secondaryAttachmentModal} modalSetState={setSecondaryAttachmentModal} />}
                                                    <div>{base64String && base64StringOthers && base64StringSecondary && pdfFile && "No Attachment"}</div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        ) : (
                            <div className="text-center box-body">
                                No Client Found !!
                            </div>
                        )

                    )}
                </div>
            </section>
        </div>
    );
};

export default ClientReadOnlyForm;
