import { useState } from "react";
import { Button, Modal, Offcanvas } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { useDeleteClientMutation } from "../../../store/api/reception/manageClient";
import toast from 'react-hot-toast'
import { FaRegEye } from "react-icons/fa";

const TableRow = ({ serialNumber, data, type }) => {
  const [show, setShow] = useState(false);
  // to delete a client
  const [clientDelete] = useDeleteClientMutation()
  const [deletemodal, setDeleteModal] = useState(false)
  const [deleteReason, setDeleteReason] = useState("")
  let deleteClient = (clientID) => {
    let data = {
      client_id: clientID?.toString(),
      reason: deleteReason
    }
    if (deleteReason) {
      clientDelete(data).then((res) => {
        if (res?.data?.status === 1) {
          toast.success(res?.data?.message, { duration: 7000 })
        } else {
          toast.error(res?.data?.message, { duration: 7000 })
        }
      })
    } else {
      toast.error("Please put a reason for delete")
    }

  }

  return (
    <tr className="hover-primary">
      <td>{serialNumber + 1}</td>
      <td className="name">{data.capture_date}</td>
      <td>{data.batchNo}</td>
      <td>{data.barcode_cdc_number}</td>
      <td>{data.folder_number}</td>
      <td>
        <button
          type="button"
          className="btn btn-user"
          data-bs-toggle="modal"
          data-bs-target="#modal-right"
          onClick={() => setShow(true)}
        >
          <FaRegEye className="me-2" />
          {data?.firstName} {data?.surname}
        </button>
        <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
          <Offcanvas.Header closeButton className="bg-light">
            <Offcanvas.Title className="mb-0 fw-bold">
              User Details
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="user-ul ps-0">
              <li>
                <strong>Date of Birth</strong> <p>{data?.dateOfBirth}</p>
              </li>
              <li>
                <strong>Contact Number</strong> <p>{data?.contactNumber}</p>
              </li>
              <li>
                <strong>Nationality</strong> <p>{data?.nationality?.split("_").join(" ")}</p>
              </li>
              <li>
                <strong>Verification ID</strong> <p>{data?.identityVerification}</p>
              </li>
              <li>
                <strong>Social Mobiliser</strong>{" "}
                <p>
                  {data?.social_mobilizer}
                </p>
              </li>
              <li>
                <strong>Email</strong> <p>{data?.email}</p>
              </li>
              <li>
                <strong>Gender</strong> <p style={{ textTransform: 'capitalize' }}>{data?.gender}</p>
              </li>
              <li>
                <strong>Next of Kin Number</strong>{" "}
                <p>{data?.nextOfKinContactNumber}</p>
              </li>
              <li>
                <strong>Relationship with Client</strong>{" "}
                <p style={{ textTransform: 'capitalize' }}>{data?.relationshipWithClient}</p>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </td>
      <td>{data.nationality.split("_").join(" ")}</td>
      <td>
        <div>{data.identityVerification}</div>
        <div>{data.identityVerificationNumber}</div>
      </td>
      <td className="text-center">
        {
          data.mmcDone.toString() !== "" ? (
            data.mmcDone.toString() === 'yes' ? (
              <span className="badge badge-success-light">
                <i className="fa fa-check"></i>
              </span>
            ) : (
              <span className="badge badge-danger-light">
                <i className="fa fa-times"></i>
              </span>
            )
          ) : (
            <span className="badge badge-danger-light">
              <i className="fa fa-times"></i>
            </span>
          )
        }
      </td>
      <td>
        {data.status !== "" && <span
          className={
            data.status.toLowerCase() === "updated" ? "badge badge-success-light"
              :
              data.status.toLowerCase() === "newly added" ? "badge badge-warning-light" : "badge badge-danger-light"
          }
        >{data.status}</span>}
      </td>
      <td>
        <div className="btn-group">
          <Dropdown>
            <Dropdown.Toggle variant="Secondary" >
              <i className="fa fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {type !== "delete" && <Dropdown.Item as={Link} to={`/reception/client-lookup/edit/${data.clientID}`}>View & Edit</Dropdown.Item>}
              {type === "delete" && <Dropdown.Item onClick={() => setDeleteModal(true)}>Delete Permanently</Dropdown.Item>}
              {type !== "delete" && <Dropdown.Item onClick={() => setDeleteModal(true)}>Delete</Dropdown.Item>}
              {type === "delete" && <Dropdown.Item as={Link} to={`/reception/client-lookup/deleted-clients/view/${data.clientID}`}>View Only</Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </td>
      {/* delete modal */}
      <Modal show={deletemodal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDeleteModal(false)}>
        <Modal.Body style={{ display: "block" }}>
          <div className="p-3 text-center">
            <h4 className="fw-bold mb-3 ">Are you sure want to Delete this client?</h4>
            <label htmlFor="" className="form-label">Reason for delete.</label>
            <textarea type="text" className="form-control" onChange={(e) => setDeleteReason(e.target.value)}></textarea>
            <div className="d-flex gap-2 justify-content-center mt-4">
              <Button variant="primary" onClick={() => { deleteClient(data.clientID); setDeleteModal(false) }}>
                Yes
              </Button>
              <Button variant="secondary" onClick={() => setDeleteModal(false)}>
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </tr>
  );
};

export default TableRow;
