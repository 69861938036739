import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const SidebarItem = ({ data }) => {
    const { pathname } = useLocation();
    const [activeSubmenu, setActiveSubmenu] = useState(false)
    
    useEffect(()=>{
        data.children && data?.children?.map((elem) => {
            const URL = elem.link.split("/").filter((part, index) => index !== 0 && index !== 1).join("/")
            if(pathname.includes(URL)){
                setActiveSubmenu(true)
            }
        })    
    }, [])

    return (
        <>
            {data.hasOwnProperty("link") ? (
                <Link to={`${data.link}`} className={pathname === data.link ? "menu-open" : ''}>
                    <i className="icon-Layout-4-blocks">
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>
                    <span>{data.title}</span>
                </Link>
            ) : (
                <div onClick={()=>setActiveSubmenu(!activeSubmenu)} className={( activeSubmenu ) ? "menu-open" : ""}>
                    <i className="icon-Layout-4-blocks">
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>
                    <span>{data.title}</span>
                    <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                    </span>
                </div>
            )}
            {
                // SUBMENUS
                data.children && (
                    <ul className={activeSubmenu ? "treeview-menu d-block" : 'treeview-menu'}>
                        {
                            data?.children?.map((elem, i) => {
                                return (
                                    <li key={i}>
                                        <Link to={elem.link} className={pathname === elem.link || pathname.includes(elem.link) ? 'active' : ''}>
                                            <i className="icon-Commit">
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                            </i>
                                            {elem.title}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                )
            }
        </>
    );
};

export default SidebarItem;
