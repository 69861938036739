import React from 'react'
import ReceptionLayout from '../../ReceptionLayout'
import { useBatchSummeryQuery } from '../../../../store/api/reception/billingApi'
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent'
import { Link, useNavigate } from 'react-router-dom'

const BillingSummery = () => {
    const navigate = useNavigate()
    const batchSummery = useBatchSummeryQuery()
    let inProgressData = batchSummery.data?.data?.filter(item => item.status.toLowerCase() === "in progress");
    let processedData = batchSummery.data?.data?.filter(item => item.status.toLowerCase() === "processed");

    return (
        <ReceptionLayout>
            <div class="content-header">
                <div class="d-flex align-items-center">
                    <div class="me-auto">
                        <div class="d-inline-block align-items-center">
                            <nav>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <Link to="">
                                            <i class="mdi mdi-home-outline"></i>
                                        </Link>
                                    </li>
                                    <li class="breadcrumb-item" aria-current="page">Facility Billing</li>
                                    <li class="breadcrumb-item active" aria-current="page">Billing Summary</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                {/* <!-- Manage Batches --> */}
                <div class="box">
                    <div class="box-header with-border">
                        <div class="box-in">
                            <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">
                                <strong>Billing Summary</strong>
                            </h4>
                            {/* <div class="box-right">
                                <div class="row justify-content-end">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="table-top-search">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Search by</option>
                                                <option value="1">Client ID</option>
                                                <option value="2">Client Name</option>
                                                <option value="3">Booking ID</option>
                                                <option value="4">Identity Number</option>
                                                <option value="4">Contact number</option>
                                                <option value="4">Folder number</option>
                                            </select>
                                            <input type="search" class="form-control" placeholder="Search" aria-controls="complex_header" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {batchSummery.isError && <LoadingErrorComponent error />}
                    {batchSummery.isLoading && <LoadingErrorComponent loading />}
                    {batchSummery.isSuccess && (
                        <div class="box-body">
                            <div className="row gy-4">
                                <div className="col-12">
                                    <h5 className='fw-bold mb-3'>Approved Batches</h5>
                                    <div class="row">
                                        {
                                            inProgressData?.length > 0 ? inProgressData?.map((el, i) => (
                                                <div class="col-auto" key={i}>
                                                    <Link to={`/reception/manage-billing/billing-summery/batch-to-process/${el.batchNo}`} class="folder-container">
                                                        <div class="folder-icon">
                                                            <i class="fa fa-folder folder-icon-color"></i>
                                                        </div>
                                                        <div class="folder-name">
                                                            <h4>{el.batchNo}</h4>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )) : <div className='col-12'>No batch found.</div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12">
                                    <h5 onClick={() => navigate("/reception/manage-billing/billing-summery/statement")} className='btn btn-danger-light mb-3'>View Current Statement</h5>
                                    {/* <div class="row">
                                        {
                                            processedData?.length > 0 ? processedData?.map((el, i) => (
                                                <div class="col-auto" key={i}>
                                                    <Link to={`/reception/manage-billing/billing-summery/batch-processed/${el.batchNo}`} class="folder-container">
                                                        <div class="folder-icon">
                                                            <i class="fa fa-folder folder-icon-color"></i>
                                                        </div>
                                                        <div class="folder-name">
                                                            <h4>{el.batchNo}</h4>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )) : <div className='col-12'>No batch found.</div>
                                        }
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </ReceptionLayout>
    )
}

export default BillingSummery
