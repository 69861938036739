import React from 'react'
import ReceptionLayout from '../../../ReceptionLayout'
import { useNavigate } from 'react-router-dom'

const RejectedBatchView = () => {
    const navigate = useNavigate()
    const ReSubmitBatch = () => {
        navigate("/reception/manage-billing/approved-rejected-payment/rejected-batch/GP1234/reupload-invoice")
    }
    return (
        <ReceptionLayout>
            <div class="content-header">
                <div class="d-flex align-items-center">
                    <div class="me-auto">
                        <div class="d-inline-block align-items-center">
                            <nav>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" aria-current="page">Medical Form</li>
                                    <li class="breadcrumb-item active" aria-current="page">Manage Batches</li>
                                    <li class="breadcrumb-item active" aria-current="page">Batch Rejected</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="box">
                    <div class="box-header with-border">
                        <div class="box-in">
                            <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">

                                <strong>Batch Rejected</strong>
                            </h4>
                        </div>
                    </div>
                    <div class="box-body">
                        <div class="row mb-3 g-2 justify-content-between">
                            <div class="col-sm-12 col-md-2">
                                <select class="form-select mb-xl-0 mb-2"
                                    aria-label="Default select example">
                                    <option selected>Sort by</option>
                                    <option value="1">Latest to First</option>
                                    <option value="2">First to Latest</option>
                                    <option value="3">South African</option>
                                    <option value="4">Non South African</option>
                                </select>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div class="table-top-search">
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>Search by</option>
                                        <option value="1">Client ID</option>
                                        <option value="2">Client Name</option>
                                        <option value="3">Booking ID</option>
                                        <option value="4">Identity Number</option>
                                        <option value="4">Contact number</option>
                                        <option value="4">Folder number</option>
                                    </select>
                                    <input type="search" class="form-control" placeholder="Search" aria-controls="complex_header" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <p class="mb-0">
                                        <span class="fw-bold me-2">Statement -</span>
                                        Total 10 records
                                        <span class="fw-bold text-success ms-2">5 valid</span>
                                        <span class="fw-bold text-danger ms-2">5 invalid</span>
                                    </p>
                                </div>
                                <div class="bg-danger-light p-4 my-3 rounded-3">
                                    <strong>Reason for rejection</strong>
                                    <p class="mb-0">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae quibusdam eveniet at dignissimos consequatur debitis, repellendus et culpa hic harum quidem libero recusandae mollitia rem porro non laborum sequi laboriosam.
                                    </p>
                                </div>
                                <div class="text-end">
                                    <button type="button" class="btn btn-primary deleteClient d-none">Delete</button>
                                </div>
                                <div class="table-responsive rounded card-table">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input type="checkbox" id="b_checkbox0" class="filled-in chk-col-danger" />
                                                    <label for="b_checkbox0"></label>
                                                </th>
                                                <th>Sr.</th>
                                                <th>Capture Date</th>
                                                <th>CDC Barcode No.</th>
                                                <th>Client Name</th>
                                                {/* <th>Client ID</th> */}
                                                <th>Verification ID</th>
                                                <th>Folder Number</th>
                                                <th>QA 1</th>
                                                <th>QA 2</th>
                                                <th>Validation Status</th>
                                                <th>Action Items</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="hover-primary">
                                                <td>
                                                    <input type="checkbox" id="b_checkbox1" class="filled-in chk-col-danger" />
                                                    <label for="b_checkbox1"></label>
                                                </td>
                                                <td>1</td>
                                                <td>2024/04/12</td>
                                                <td>CDC123409876</td>
                                                <td>KOKIS SEKELE</td>
                                                {/* <td>226</td> */}
                                                <td><div>South African ID</div><div>199805130157083</div></td>
                                                <td>F256245</td>
                                                <td>
                                                    <span class="fs-14 badge badge-success-light">
                                                        <i class="fa fa-check"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    {/* <span class="fs-14 badge badge-danger-light">
                                                        <i class="fa fa-times"></i>
                                                    </span> */}
                                                </td>
                                                <td>
                                                    <span class="badge badge-success-light">Valid</span>
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <a href="view_rejected_client_details.html" class="hover-primary dropdown-toggle no-caret btn-more px-2">
                                                            <i class="fa fa-eye"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="hover-primary">
                                                <td>
                                                    <input type="checkbox" id="b_checkbox2" class="filled-in chk-col-danger" />
                                                    <label for="b_checkbox2"></label>
                                                </td>
                                                <td>2</td>
                                                <td>2024/04/12</td>
                                                <td>CDC123409876</td>
                                                <td>KOKIS SEKELE</td>
                                                <td>226</td>
                                                <td><div>South African ID</div><div>199805130157083</div></td>
                                                <td>F256245</td>
                                                <td>
                                                    <span class="fs-14 badge badge-success-light">
                                                        <i class="fa fa-check"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    {/* <span class="fs-14 badge badge-danger-light">
                                                        <i class="fa fa-times"></i>
                                                    </span> */}
                                                </td>
                                                <td>
                                                    <span class="badge badge-danger-light">Invalid</span>
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <a href="view_rejected_client_details.html" class="hover-primary dropdown-toggle no-caret btn-more px-2">
                                                            <i class="fa fa-eye"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="hover-primary">
                                                <td>
                                                    <input type="checkbox" id="b_checkbox1" class="filled-in chk-col-danger" />
                                                    <label for="b_checkbox1"></label>
                                                </td>
                                                <td>1</td>
                                                <td>2024/04/12</td>
                                                <td>CDC123409876</td>
                                                <td>KOKIS SEKELE</td>
                                                <td>226</td>
                                                <td><div>South African ID</div><div>199805130157083</div></td>
                                                <td>F256245</td>
                                                <td>
                                                    <span class="fs-14 badge badge-success-light">
                                                        <i class="fa fa-check"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    {/* <span class="fs-14 badge badge-danger-light">
                                                        <i class="fa fa-times"></i>
                                                    </span> */}
                                                </td>
                                                <td>
                                                    <span class="badge badge-success-light">Valid</span>
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <a href="view_rejected_client_details.html" class="hover-primary dropdown-toggle no-caret btn-more px-2">
                                                            <i class="fa fa-eye"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="hover-primary">
                                                <td>
                                                    <input type="checkbox" id="b_checkbox2" class="filled-in chk-col-danger" />
                                                    <label for="b_checkbox2"></label>
                                                </td>
                                                <td>2</td>
                                                <td>2024/04/12</td>
                                                <td>CDC123409876</td>
                                                <td>KOKIS SEKELE</td>
                                                <td>226</td>
                                                <td><div>South African ID</div><div>199805130157083</div></td>
                                                <td>F256245</td>
                                                <td>
                                                    <span class="fs-14 badge badge-success-light">
                                                        <i class="fa fa-check"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    {/* <span class="fs-14 badge badge-danger-light">
                                                        <i class="fa fa-times"></i>
                                                    </span> */}
                                                </td>
                                                <td>
                                                    <span class="badge badge-danger-light">Invalid</span>
                                                </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <a href="view_rejected_client_details.html" class="hover-primary dropdown-toggle no-caret btn-more px-2">
                                                            <i class="fa fa-eye"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="text-center mt-4">
                                    <button class="btn btn-primary" onClick={ReSubmitBatch}>Re-Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ReceptionLayout>
    )
}

export default RejectedBatchView
