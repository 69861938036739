import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDueClientListQuery } from '../../../../../store/api/reception/followupApi';
import Dropdown from "react-bootstrap/Dropdown";
import ReceptionLayout from '../../../ReceptionLayout';
import LoadingErrorComponent from '../../../../../components/LoadingErrorComponent/LoadingErrorComponent';
import PaginationComp from '../../../../../components/Pagination/paginationComp';

const Due = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [urlParams, setUrlParams] = useState(searchParams.get("milestone") || "")
    const [mobiliser, setMobiliser] = useState(searchParams.get("sort_by_mobiliser") || "");
    const [sort_by, setSortBy] = useState(searchParams.get("sort_by") || "");
    const [searchBy, setSearchBy] = useState(searchParams.get("searchBy") || "");
    const [searchKeyword, setSearchKeyword] = useState(searchParams.get("searchKeyword") || "");
    const [page, setPageNo] = useState(searchParams.get("page") || 1);
    const DueClientList = useDueClientListQuery({
        milestone: urlParams,
        sort_by: sort_by,
        searchKeyword: searchKeyword,
        page: page,
        per_page: 10,
        sort_by_mobiliser: mobiliser,
        searchBy: searchBy,
    })

    // FUNCTIONS TO HANDLE DATA SEARCH AND FILTER
    const changePage = (page) => {
        setPageNo(page);
    };
    const handleSortByMobiliser = (elem) => {
        setMobiliser(elem.target.value);
    };
    const handleSortBy = (elem) => {
        setSortBy(elem.target.value);
    };
    const handleSearchBy = (elem) => {
        setSearchBy(elem.target.value);
    };
    const handleSearchKeyword = (elem) => {
        setSearchKeyword(elem.target.value);
    };
    const [paginationDetails, setPaginationDetails] = useState({})
    const paginationDataString = DueClientList?.data?.message.split(": ")[1];
    useEffect(() => {
        if (paginationDataString) {
            const paginationData = JSON.parse(paginationDataString?.replace(/=/g, ':').replace(/(\w+)/g, '"$1"'));
            setPaginationDetails({
                CurrentPage: Math.ceil(paginationData.CurrentPage),
                ItemsPerPage: Math.ceil(paginationData.ItemsPerPage),
                TotalCount: Math.ceil(paginationData.TotalCount),
                TotalPages: Math.ceil(paginationData.TotalPages),
            })
        }
    }, [DueClientList?.data?.message])

    useEffect(() => {
        let params = { page };
        if (urlParams) {
            params.milestone = urlParams
        }
        if (mobiliser) {
            params.sort_by_mobiliser = mobiliser;
        }
        if (sort_by) {
            params.sort_by = sort_by;
        }
        if (searchBy) {
            params.searchBy = searchBy;
        }
        if (searchKeyword) {
            params.searchKeyword = searchKeyword;
        }
        setSearchParams(params);
    }, [page, mobiliser, sort_by, searchBy, searchKeyword, urlParams, setSearchParams, setUrlParams]);

    const navigate = useNavigate()
    return (
        <ReceptionLayout>
            <div className="container-full">
                <div className="content-header">
                    <div className="d-flex align-items-center">
                        <div className="me-auto">
                            <div className="d-inline-block align-items-center">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/"><i className="mdi mdi-home-outline"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">Followup Form</li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            Follow-up Progress
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {urlParams?.toString() === '48Hours' && '48 Hours'}
                                            {urlParams?.toString() === '7Days' && '7 Days'}
                                            {urlParams?.toString() === '14Days' && '14 Days'}
                                            {console.log(urlParams?.toString())}
                                            {" "}Due Client List
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="box">
                        <div className="box-header with-border">
                            <div className="box-in">
                                <button onClick={() => navigate("/reception/follow-up/schedule-lookup")} className='btn btn-danger-light'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-arrow-left"
                                    >
                                        <line x1={19} y1={12} x2={5} y2={12} />
                                        <polyline points="12 19 5 12 12 5" />
                                    </svg>
                                </button>
                                <h4 className="box-title me-auto ms-4">
                                    <strong>
                                        {urlParams === '48Hours' && '48 Hours'}
                                        {urlParams === '7Days' && '7 Days'}
                                        {urlParams === '14Days' && '14 Days'}
                                        {" "}
                                        due
                                    </strong>
                                </h4>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="row mb-3 gx-2 justify-content-between">
                                <div className="col-sm-12 col-md-6 col-xl-3">
                                    <select
                                        className="form-select mb-xl-0 mb-2"
                                        aria-label="sort_by"
                                        value={sort_by}
                                        onChange={handleSortBy}
                                    >
                                        <option value="">Sort by</option>
                                        <option value="first_to_last">First to Last</option>
                                        <option value="last_to_first"> Last to First</option>
                                    </select>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="table-top-search">
                                        <select
                                            className="form-select"
                                            aria-label="search by"
                                            onChange={handleSearchBy}
                                            value={searchBy}
                                        >
                                            <option value="">Search by</option>
                                            <option value="cdcc_barcode">Barcode CDCC No</option>
                                            <option value="cdcf_barcode">Barcode CDCF No</option>
                                            <option value="client_name">Client Name</option>
                                            <option value="identity_number">Identity Number</option>
                                            <option value="folder_number">Folder number</option>
                                        </select>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            aria-controls="search"
                                            value={searchKeyword}
                                            onChange={handleSearchKeyword}
                                        />
                                    </div>
                                </div>
                            </div>
                            {DueClientList.isError && (
                                <LoadingErrorComponent error />
                            )}
                            {DueClientList.isLoading && (
                                <LoadingErrorComponent loading />
                            )}
                            {DueClientList.isSuccess && (
                                <>
                                    <div className="table-responsive rounded card-table">
                                        {
                                            <table
                                                className="table border-no"
                                                id="example1"
                                                style={{ width: "100%" }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Sr.</th>
                                                        <th>Procedure Date</th>
                                                        <th>CDC Barcode No.</th>
                                                        <th>CDCF Barcode No.</th>
                                                        <th>Client Name</th>
                                                        {/* <th>Client ID</th> */}
                                                        <th>Verification ID</th>
                                                        <th>Booking ID</th>
                                                        <th>Folder Number</th>
                                                        <th>Status</th>
                                                        <th>Action Items</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        DueClientList?.data?.data.length > 0 ?
                                                            DueClientList?.data?.data?.map((elem, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{i + 1}</td>
                                                                        <td>{elem.capture_date}</td>
                                                                        <td>{elem.barcode_cdc_number}</td>
                                                                        <td>{elem.barcode_cdf_number}</td>
                                                                        <td>{elem.firstName}{" "}{elem.surname}</td>
                                                                        {/* <td>{elem.clientID}</td> */}
                                                                        <td>
                                                                            <div>{elem.identityVerification}</div>
                                                                            <div>{elem.identityVerificationNumber}</div>
                                                                        </td>
                                                                        <td>{elem.booking_id}</td>
                                                                        <td>{elem.folder_number}</td>
                                                                        <td>
                                                                            <span className="badge badge-danger-light">{elem.status}</span>
                                                                        </td>
                                                                        <td>
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="Secondary" >
                                                                                    <i className="fa fa-ellipsis-h"></i>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    {elem.barcode_cdf_number && <Dropdown.Item as={Link} to={`/reception/follow-up/followup-form/${elem.barcode_cdf_number}`}>Edit Followup</Dropdown.Item>}
                                                                                    {!elem.barcode_cdf_number && <Dropdown.Item as={Link} to={`/reception/follow-up/add-client/${elem.clientID}`}>Add Followup</Dropdown.Item>}
                                                                                    {elem.barcode_cdf_number &&
                                                                                        <Dropdown.Item as={Link} to={`/reception/follow-up/followup-form/view-only/${elem.barcode_cdf_number}`}>
                                                                                            View Followup Form
                                                                                        </Dropdown.Item>
                                                                                    }
                                                                                    <Dropdown.Item as={Link} to={`/reception/batch-view/${elem.clientID}`}>Consent Form Details</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : (
                                                                <tr>
                                                                    <td colSpan={10} className='border-0'>No Data Found!!</td>
                                                                </tr>
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                    {DueClientList?.data?.data.length > 0 && <div className='d-flex justify-content-between mt-3'>
                                        {
                                            <div>
                                                {`Showing ${page - 1}1 to ${paginationDetails.TotalCount > page * 10 ? page * 10 : paginationDetails.TotalCount}  of ${paginationDetails.TotalCount} entries`}
                                            </div>
                                        }
                                        <div>
                                            <PaginationComp currentPage={paginationDetails.CurrentPage} totalPages={paginationDetails.TotalPages} onPageChange={changePage} />
                                        </div>
                                    </div>}
                                </>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </ReceptionLayout>
    )
}

export default Due