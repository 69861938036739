import React from 'react'
import { Modal } from 'react-bootstrap';

const ViewImageModal = ({ ModalTitle, modalUrl, modalState, modalSetState }) => {
  return (
    <Modal size="lg" centered show={modalState} onHide={() => modalSetState(false)}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold mb-0">{ModalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          modalUrl?.includes("image/") ? (
            <img style={{ width: '100%' }} src={modalUrl} alt="" />
          ) : (
            <iframe src={modalUrl} alt="" width={"100%"} title="pdf" style={{height:"80vh"}} />
          )
        }
      </Modal.Body>
    </Modal>
  )
}

export default ViewImageModal;
