import React from 'react'
import ReceptionLayout from '../../../Reception/ReceptionLayout'
import { useGetBatchesQuery } from '../../../../store/api/qa_one/QAOneApi'
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent'
import BatchItem from '../../../../components/BatchItem/BatchItem'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const QAOneUsersManageBatchValidationPage = () => {
    const { user } = useSelector((store) => {
        return store.userSlice;
    });
    let batchdata = useGetBatchesQuery()
    const navigate = useNavigate()
    return (
        <ReceptionLayout>
            <div className="container-full">
                <div className="content-header">
                    <div className="d-flex align-items-center">
                        <div className="me-auto">
                            <div className="d-inline-block align-items-center">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="">
                                                <i className="mdi mdi-home-outline"></i>
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">Quality Assurance</li>
                                        <li className="breadcrumb-item active" aria-current="page">QA 1</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="box">
                        <div className="box-header with-border">
                            <div className="box-in">
                                {user.role === "Super User/Admin" ?
                                    <button onClick={() => navigate("/dashboard")} className='btn btn-danger-light'>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-arrow-left"
                                        >
                                            <line x1={19} y1={12} x2={5} y2={12} />
                                            <polyline points="12 19 5 12 12 5" />
                                        </svg>
                                    </button> :
                                    <button onClick={() => navigate("/qa-1/dashboard")} className='btn btn-danger-light'>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-arrow-left"
                                        >
                                            <line x1={19} y1={12} x2={5} y2={12} />
                                            <polyline points="12 19 5 12 12 5" />
                                        </svg>
                                    </button>
                                }
                                <h4 className="box-title me-auto ms-4">

                                    <strong>Manage Batch validation</strong>
                                </h4>
                            </div>
                        </div>
                        {batchdata.isError && <LoadingErrorComponent error />}
                        {batchdata.isLoading && <LoadingErrorComponent loading />}
                        {batchdata.isSuccess && (
                            <div className="box-body">
                                <div className="row gy-3">
                                    {/* Batch in Progress */}
                                    <div className="col-sm-12">
                                        <div className="box-progress">
                                            <h5 className="box-title">Batches in Progress</h5>
                                            <div className="row">
                                                {
                                                    batchdata?.data?.data?.filter(elem => elem.status === 'In Progress').length > 0 ? (
                                                        batchdata?.data?.data?.filter(elem => elem.status === 'In Progress').map((elem, i) => {
                                                            return (
                                                                <div className="col-auto" key={i}>
                                                                    <BatchItem type="progress" data={elem} />
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <h6>Batches yet to be added.</h6>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* Batch Submitted */}
                                    <div className="col-sm-12">
                                        <h5 className="box-title">Batches Completed</h5>
                                        <div className="row">
                                            {
                                                batchdata?.data?.data.filter(elem => elem.status === 'Complete').length > 0 ? (
                                                    batchdata?.data?.data.filter(elem => elem.status === 'Complete').map((elem, i) => {
                                                        return (
                                                            <div className="col-auto" key={i}>
                                                                <BatchItem type="completed" data={elem} />
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    <h6>Batches yet to be added.</h6>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </ReceptionLayout>
    )
}

export default QAOneUsersManageBatchValidationPage