import React, { useState } from "react";
import logo from "../../../assets/images/logo.png";
import { Button, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForgotPasswordMutation } from "../../../store/api/authApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [modalShow, setModalShow] = useState(false);
  const [forgotPassword, response] = useForgotPasswordMutation();
  const [email, setEmail] = useState("");
  let navigate = useNavigate();

  const submitForm = (e) => {
    e.preventDefault();
    forgotPassword(email)
      .then((res) => {
        if (res?.data?.status === 1) {
          toast.success(res?.data?.message, { duration: 7000 });
          navigate("/otp-verification");
        } else {
          toast.error(res?.data?.message, { duration: 7000 });
        }
      })
      .catch((err) => {
        toast.error(err, { duration: 7000 });
      });
  };

  return (
    <div>
      <div className="container h-p100 centered-container">
        <div className="row align-items-center justify-content-md-center h-p100">
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-5 col-md-5 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="p-40">
                    <div className="text-center">
                      <img
                        src={logo}
                        className="w-120"
                        alt="Your Logo"
                      />
                      <h3 className="fw-bold mb-20">Verify your Email ID</h3>
                    </div>
                    <Form onSubmit={submitForm}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <InputGroup style={{ display: "flex" }}>
                          <InputGroup.Text>
                            <i className="ti-lock" />
                          </InputGroup.Text>
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isInvalid={!email}
                            required
                          />
                        </InputGroup>
                      </Form.Group>
                      <div className={response.isLoading === true ? "form-group text-center" : "form-group"}>
                        {
                          response.isLoading === true ?
                            (<button disabled className="btn btn-primary">
                              <span className="spinnerInButton"></span>
                            </button>)
                            :
                            (<>
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                              <Button type="button" className="btn btn-danger float-end" data-bs-dismiss="modal" onClick={() => navigate(-1)}>
                                Cancel
                              </Button>
                            </>)
                        }
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={modalShow}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{ padding: "20px", borderRadius: "3px" }}
          classNameName="padding-5"
        >
          <div className="modal-message">
            <p>We have sent a *reset link" to your email id.</p>
            <p>You need to click on it and follow the instructions</p>
          </div>

          <div
            className="modal-footer modal-footer-uniform modal-buttons"
            onClick={() => setModalShow(false)}
          >
            Close
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
