import Accordion from "react-bootstrap/Accordion";
import React, { useEffect, useReducer, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { DatePicker } from "antd";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import validateSAID from "../../methods/validateSAID";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import toast from "react-hot-toast";
import { formatDate } from "../../methods/convertDateFormat";
import { useGetDropDownQuery } from "../../store/api/reception/manageClient";
import { useNavigate, useParams } from "react-router-dom";
import { getNextDate } from "../../methods/getNextDate";
import { LiaTimesSolid } from "react-icons/lia";
import ViewImageModal from "../modal/ViewImageModal";
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const ViewOnlyFollowUpClientForm = ({ type, mutation, data, response }) => {
  const navigate = useNavigate()
  const getDropDown = useGetDropDownQuery();
  const [accordionActiveKey, setAccordionActiveKey] = useState("0");

  const initialState = {
    followUpClient: {
      barcode_cdf_number: "",
      folder_number: "",
      firstName: "",
      surname: "",
      dateOfBirth: "",
      email: "",
      contactNumber: "",
      identityVerificationNumber: "",
      identityVerification: "",
      nationality: "",
      gender: "",
      nextOfKinContactNumber: "",
      relationshipWithClient: "",
      countryOfOrigin: "",
      facility: ""
    },
    patientFollowUp: {
      followUpDate: "",
      observation: "",
      otherFacilityCheckbox: "",
      client_circumcised_other_facility: "",
      circumcisionOutcome: "",
      followUp_method: ""
    },
    followUpQuestion: {
      urinationSymtoms: "",
      penisAppearance: "",
      sexTime: "",
      herbalMedicineApply: "",
      woundDescribe: ""
    },
    adverse: {
      bleeding: "",
      haematoma: "",
      infaction: "",
      woundDehiscence: "",
      abscess: "",
      insufficientSkinRemoval: "",
      excessiveSkinRemoval: "",
      pain: "",
      swelling: "",
      injury: "",
      scarring: "",
      necrotizing: "",
      adverse_Events: "",
      other_AdverseEvents: "",
      aE_Manaement: ""
    },
    authorisations: {
      authoriser: "",
      healthcareSigned: ""
    }
  }

  const followUpReducer = (state, { type, payload }) => {
    switch (type) {
      case "SET_STATE":
        return payload;
      case "SET_FOLLOWUP_CLIENT":
        return {
          ...state,
          followUpClient: {
            ...state.followUpClient,
            [payload.name]: payload.data,
          },
        };
      case "SET_PATIENT_FOLLOWUP":
        return {
          ...state,
          patientFollowUp: {
            ...state.patientFollowUp,
            [payload.name]: payload.data,
          },
        };
      case "SET_FOLLOWUP_QUESTIONS":
        return {
          ...state,
          followUpQuestion: {
            ...state.followUpQuestion,
            [payload.name]: payload.data,
          },
        };
      case "SET_ADVERSE":
        return {
          ...state,
          adverse: {
            ...state.adverse,
            [payload.name]: payload.data,
          },
        };
      case "SET_AUTHORIZATIONS":
        return {
          ...state,
          authorisations: {
            ...state.authorisations,
            [payload.name]: payload.data,
          },
        };
      case "SET_CLEAR_ADVERSE":
        return {
          ...state,
          adverse: {
            adverse_Events: "no",
            bleeding: "",
            haematoma: "",
            infaction: "",
            woundDehiscence: "",
            abscess: "",
            insufficientSkinRemoval: "",
            excessiveSkinRemoval: "",
            pain: "",
            swelling: "",
            injury: "",
            scarring: "",
            necrotizing: "",
            other_AdverseEvents: "",
            aE_Manaement: ""
          },
        };
      default:
        return state;
    }
  };

  const [formStates, dispatch] = useReducer(followUpReducer, initialState);

  // attachments
  const [attachmentValidate, setAttachmentValidate] = useState(false)
  const [validated_attachment, setValidated_attachment] = useState(false)
  const [pdfFile, setPdfFile] = useState("")
  const [consentModal, setConsentModal] = useState(false)
  const handlePdfFileChange = (event) => {
    const file = event.target.files[0];
    if (file?.size < 2621440) {
      const reader = new FileReader();
      if (event.target.files[0].type.includes("/pdf")) {
        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1]; // Get base64 string excluding the data URL prefix
          setPdfFile("data:application/pdf;base64," + base64String);
        };
      } else if (event.target.files[0].type.includes("image/")) {
        reader.onload = () => {
          const base64 = reader.result;
          setPdfFile(base64);
        };
      }
      reader.readAsDataURL(file);
    } else {
      toast.error("File too large. Max size: 2.5 MB. Please upload a smaller file.")
    }
  };
  const submitAttachment = () => {
    if (pdfFile) {
      setAttachmentValidate(true);
      setAccordionActiveKey("1")
    } else {
      setAttachmentValidate(false)
    }
    setValidated_attachment(true)
  }

  // FORM DETAILS
  const handleChangeFormdetails = (e) => {
    dispatch({
      type: "SET_FOLLOWUP_CLIENT",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };
  const handleChangeDate = (a, date) => {
    dispatch({
      type: "SET_PATIENT_FOLLOWUP",
      payload: {
        name: "followUpDate",
        data: date,
      },
    });
  }
  const [formDetailsValidate, setFormDetailsValidate] = useState(false)
  const [validated, setValidated] = useState(false)
  const submitFormDetails = (e) => {
    e.preventDefault()
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === true && formStates.patientFollowUp.followUpDate) {
      setFormDetailsValidate(true)
      setAccordionActiveKey("2")
    } else {
      setFormDetailsValidate(false)
    }
    setValidated(true);
  }

  // PATIENT DETAILS
  const handleChangePatientdetails = (e) => {
    dispatch({
      type: "SET_FOLLOWUP_CLIENT",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };
  const handleChangeDob = (a, date) => {
    dispatch({
      type: "SET_FOLLOWUP_CLIENT",
      payload: {
        name: "dateOfBirth",
        data: date,
      },
    });

    // calculate proper age
    let NextDate = getNextDate(date)
    let formattedDate = formatDate(NextDate)
    const age = new Date(new Date() - new Date(formattedDate)).getFullYear() - 1970;

    if (isNaN(age)) {
      setAge("");
    } else {
      setAge(age);
    }
    if (age > 14) {
      setDateValid(true);
    } else {
      setDateValid(false);
    }
  };
  const handleNationalityChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "south_african") {
      dispatch({
        type: "SET_FOLLOWUP_CLIENT",
        payload: {
          name: 'countryOfOrigin',
          data: 'south_africa',
        },
      });
    }
    dispatch({
      type: "SET_FOLLOWUP_CLIENT",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };
  const handleContactNumber = (num) => {
    dispatch({
      type: "SET_FOLLOWUP_CLIENT",
      payload: {
        name: "contactNumber",
        data: num,
      },
    });
  };
  const handleNextKinOfContact = (num) => {
    dispatch({
      type: "SET_FOLLOWUP_CLIENT",
      payload: {
        name: "nextOfKinContactNumber",
        data: num,
      },
    });
  };
  const handleChangeIdNumber = (e) => {
    let details = validateSAID(e.target.value);
    setClientDetailsId(details);
    dispatch({
      type: "SET_FOLLOWUP_CLIENT",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };
  const [patientDetailsValidate, setPatientDetailsValidate] = useState(false)
  const [validated_patient, setValidated_patient] = useState(false)
  const [clientDetailsId, setClientDetailsId] = useState({})
  const [age, setAge] = useState("")
  const [dateValid, setDateValid] = useState("")
  const [adultAdviceModal, setAdultAdviceModal] = useState(false)
  const submitPatientDetails = (e) => {
    e.preventDefault()
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === true
      && formStates.followUpClient.nationality === "south_african"
      && (formStates.followUpClient.identityVerification === "South African ID"
        || formStates.followUpClient.identityVerification === "Driving License"
        || formStates.followUpClient.identityVerification === "Birth Certificate"
        || formStates.followUpClient.identityVerification === "South African Passport")
      && clientDetailsId.isValid === true
      && formStates.followUpClient.gender === clientDetailsId.gender
      && clientDetailsId.dateOfBirth === formStates.followUpClient.dateOfBirth
      && formStates.followUpClient.gender !== "female"
      && formStates.followUpClient.gender !== "others"
      && age > 17
      && contactNumberValid) {
      setPatientDetailsValidate(true)
      setAccordionActiveKey("3")
    }
    else if (form.checkValidity() === true
      && formStates.followUpClient.nationality === "south_african"
      && (formStates.followUpClient.identityVerification === "South African ID"
        || formStates.followUpClient.identityVerification === "Driving License"
        || formStates.followUpClient.identityVerification === "Birth Certificate"
        || formStates.followUpClient.identityVerification === "South African Passport")
      && clientDetailsId.isValid === true
      && formStates.followUpClient.gender === clientDetailsId.gender
      && clientDetailsId.dateOfBirth === formStates.followUpClient.dateOfBirth
      && formStates.followUpClient.gender !== "female"
      && formStates.followUpClient.gender !== "others"
      && (age >= 15 && age <= 17)
      && nextKinOfContactValid) {
      setPatientDetailsValidate(true)
      setAccordionActiveKey("3")
    }
    else if (form.checkValidity() === true
      && formStates.followUpClient.nationality === "south_african"
      && formStates.followUpClient.identityVerification === "South African Affidavit"
      && formStates.followUpClient.gender !== "female"
      && formStates.followUpClient.gender !== "others"
      && age > 17
      && contactNumberValid) {
      setPatientDetailsValidate(true)
      setAccordionActiveKey("3")
    }
    else if (form.checkValidity() === true
      && formStates.followUpClient.nationality === "south_african"
      && formStates.followUpClient.identityVerification === "South African Affidavit"
      && formStates.followUpClient.gender !== "female"
      && formStates.followUpClient.gender !== "others"
      && (age >= 15 && age <= 17)
      && nextKinOfContactValid) {
      setPatientDetailsValidate(true)
      setAccordionActiveKey("3")
    }
    else if (form.checkValidity() === true
      && formStates.followUpClient.nationality === "non_south_african"
      && formStates.followUpClient.gender !== "female"
      && formStates.followUpClient.gender !== "others"
      && age > 17
      && contactNumberValid) {
      setPatientDetailsValidate(true)
      setAccordionActiveKey("3")
    }
    else if (form.checkValidity() === true
      && formStates.followUpClient.nationality === "non_south_african"
      && formStates.followUpClient.gender !== "female"
      && formStates.followUpClient.gender !== "others"
      && (age >= 15 && age <= 17)
      && nextKinOfContactValid) {
      setPatientDetailsValidate(true)
      setAccordionActiveKey("3")
    } else {
      setPatientDetailsValidate(false)
    }
    setValidated_patient(true);
  }



  // FOLLOW UP
  const [followupValidate, setFollowupValidate] = useState(false)
  const [validated_followup, setValidated_followup] = useState(false)
  const handleChangeFollowup = (e) => {
    dispatch({
      type: "SET_PATIENT_FOLLOWUP",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };
  const handleChangeFollowupCheckbox = (e) => {
    dispatch({
      type: "SET_PATIENT_FOLLOWUP",
      payload: {
        name: e.target.name,
        data: e.target.checked,
      },
    });
  };
  // FOLLOWUP QUESTIONS
  const handleChangeFollowupQuestions = (e) => {
    console.log(e.target.value)
    dispatch({
      type: "SET_FOLLOWUP_QUESTIONS",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };
  const submitFollowup = (e) => {
    e.preventDefault()
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      setFollowupValidate(true)
      setAccordionActiveKey("4")
    } else {
      setFollowupValidate(false)
    }
    setValidated_followup(true);
  }

  // ADVERSE EVENT
  const [adverseValidate, setAdverseValidate] = useState()
  const [adverseTopCheckValidate, setAdverseTopCheckValidate] = useState()
  const TypesOfAdverseEvents = [
    {
      title: 'Bleeding',
      checkboxName: 'bleedingCheckbox',
      radioName: 'bleeding'
    },
    {
      title: 'Haematoma',
      checkboxName: 'haematomaCheckbox',
      radioName: 'haematoma'
    },
    {
      title: 'Infection',
      checkboxName: 'infectionCheckbox',
      radioName: 'infaction'
    },
    {
      title: 'Wound dehiscence / disruption',
      checkboxName: 'woundDehiscenceCheckbox',
      radioName: 'woundDehiscence'
    },
    {
      title: 'Abscess',
      checkboxName: 'abscessCheckbox',
      radioName: 'abscess'
    },
    {
      title: 'Insufficient skin removal (partial circumcision)',
      checkboxName: 'insufficientSkinRemovalCheckbox',
      radioName: 'insufficientSkinRemoval'
    },
    {
      title: 'Excessive skin removal',
      checkboxName: 'excessiveSkinRemovalCheckbox',
      radioName: 'excessiveSkinRemoval'
    },
    {
      title: 'Pain',
      checkboxName: 'painCheckbox',
      radioName: 'pain'
    },
    {
      title: 'Swelling',
      checkboxName: 'swellingCheckbox',
      radioName: 'swelling'
    },
    {
      title: 'Injury to the penis (Including fistula)',
      checkboxName: 'injuryCheckbox',
      radioName: 'injury'
    },
    {
      title: 'Scarring / Disfigurement',
      checkboxName: 'scarringCheckbox',
      radioName: 'scarring'
    },
    {
      title: 'Necrotizing Fasciitis',
      checkboxName: 'necrotizingCheckbox',
      radioName: 'necrotizing'
    }
  ]
  const handleChangeAdverseEvents = (e) => {
    dispatch({
      type: "SET_ADVERSE",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
    if (e.target.value.toLowerCase() === 'no') {
      dispatch({
        type: "SET_CLEAR_ADVERSE"
      });
      checkboxesDispatch({
        type: "SET_CLEAR_ADVERSE_EVENT_CHECKBOX_RADIO"
      });
    }
  };
  const adverseEventsCheckboxesState = {
    bleedingCheckbox: false,
    haematomaCheckbox: false,
    infectionCheckbox: false,
    woundDehiscenceCheckbox: false,
    abscessCheckbox: false,
    insufficientSkinRemovalCheckbox: false,
    excessiveSkinRemovalCheckbox: false,
    painCheckbox: false,
    swellingCheckbox: false,
    injuryCheckbox: false,
    scarringCheckbox: false,
    necrotizingCheckbox: false,
  }
  const AdverseEventsCheckBoxes = (state, { type, payload }) => {
    switch (type) {
      case "SET_ADVERSE_EVENT_CHECKBOX":
        return {
          ...state,
          [payload.name]: payload.data,
        };
      case "SET_CLEAR_ADVERSE_EVENT_CHECKBOX_RADIO":
        return { ...adverseEventsCheckboxesState };
      default:
        return state;
    }
  };
  const [checkboxesState, checkboxesDispatch] = useReducer(AdverseEventsCheckBoxes, adverseEventsCheckboxesState);
  const handleChangeAdverseEventsCheckboxe = (e) => {
    checkboxesDispatch({
      type: "SET_ADVERSE_EVENT_CHECKBOX",
      payload: {
        name: e.target.name,
        data: e.target.checked,
      },
    });
  };
  const submitAdverseValidate = (e) => {
    e.preventDefault()
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      setAdverseValidate(true)
      setAdverseTopCheckValidate(true)
      setAccordionActiveKey("5")
    } else {
      setAdverseValidate(false)
      setAdverseTopCheckValidate(false)
    }
    setAdverseValidate(true)
  }

  // AUTHORISATIONS
  const [validated_authorization, setValidated_authorization] = useState(false)
  const handleChangeAuthorization = (e) => {
    dispatch({
      type: "SET_AUTHORIZATIONS",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  }

  // to remove data when followup observation changed
  useEffect(() => {
    if (!formStates.patientFollowUp.observation) {
      dispatch({
        type: "SET_PATIENT_FOLLOWUP",
        payload: {
          name: "followUp_method",
          data: "",
        },
      });
    }
  }, [formStates?.patientFollowUp?.observation])

  // PHONE NUMBER VALIDATION
  const [nextKinOfContactValid, setNextKinOfContactValid] = useState("")
  const [contactNumberValid, setContactNumberValid] = useState("")
  useEffect(() => {
    if (formStates.followUpClient.nextOfKinContactNumber) {
      setNextKinOfContactValid(isValidPhoneNumber(formStates.followUpClient.nextOfKinContactNumber?.toString()))
    }
    if (formStates.followUpClient.contactNumber) {
      setContactNumberValid(isValidPhoneNumber(formStates.followUpClient.contactNumber?.toString()))
    }
  }, [formStates.followUpClient.contactNumber, formStates.followUpClient.nextOfKinContactNumber])
  const PhoneInputElem = document.querySelectorAll('.PhoneInput')
  useEffect(() => {
    // PHONE NUMBER VALIDATION
    if (PhoneInputElem) {
      PhoneInputElem.forEach((elem) => {
        elem.querySelector('.PhoneInputInput').setAttribute('required', true)
      })
    }
  }, [PhoneInputElem])

  // render the data from api
  useEffect(() => {
    if (type === "add") {
      dispatch({
        type: 'SET_STATE',
        payload: {
          followUpClient: {
            barcode_cdf_number: data?.patientDetails?.barcode_cdf_number,
            folder_number: data?.formDetails?.folderNo,
            firstName: data?.patientDetails?.firstName,
            surname: data?.patientDetails?.surname,
            dateOfBirth: data?.patientDetails?.dateOfBirth.split("/").join("-"),
            email: data?.patientDetails?.email,
            contactNumber: data?.patientDetails?.contactNumber,
            identityVerificationNumber: data?.patientDetails?.identityVerificationNumber,
            identityVerification: data?.patientDetails?.identityVerification,
            nationality: data?.patientDetails?.nationality?.toLowerCase(),
            gender: data?.patientDetails?.gender.toLowerCase(),
            nextOfKinContactNumber: data?.patientDetails?.nextOfKinContactNumber,
            relationshipWithClient: data?.patientDetails?.relationshipWithClient.toLowerCase(),
            countryOfOrigin: data?.patientDetails?.countryOfOrigin,
            facility: data?.formDetails?.facility
          },
          patientFollowUp: {
            followUpDate: "",
            observation: "",
            otherFacilityCheckbox: "",
            client_circumcised_other_facility: "",
            circumcisionOutcome: "",
            followUp_method: ""
          },
          followUpQuestion: {
            urinationSymtoms: "",
            penisAppearance: "",
            sexTime: "",
            herbalMedicineApply: "",
            woundDescribe: ""
          },
          adverse: {
            adverse_Events: '',
            bleeding: '',
            haematoma: '',
            infaction: '',
            woundDehiscence: '',
            abscess: '',
            insufficientSkinRemoval: '',
            excessiveSkinRemoval: '',
            pain: '',
            swelling: '',
            injury: '',
            scarring: '',
            necrotizing: '',
            other_AdverseEvents: '',
            aE_Manaement: ''
          },
          authorisations: {
            authoriser: '',
            healthcareSigned: ''
          }
        }
      })
      let details = validateSAID(data?.patientDetails?.identityVerificationNumber);
      setClientDetailsId(details);
      // Assuming the date of birth is in the format "YYYY-MM-DD"
      const dobString = data?.patientDetails?.dateOfBirth?.toString();
      let NextDate = getNextDate(dobString)
      let formatted = formatDate(NextDate)
      const age = new Date(new Date() - new Date(formatted)).getFullYear() - 1970;

      if (isNaN(age)) {
        setAge("");
      } else {
        setAge(age);
      }
      if (age > 14) {
        setDateValid(true);
      } else {
        setDateValid(false);
      }
    }
    if (type === 'edit') {
      dispatch({
        type: 'SET_STATE',
        payload: {
          followUpClient: {
            barcode_cdf_number: data.followUpClient.barcode_cdf_number,
            folder_number: data.followUpClient.folder_number,
            firstName: data.followUpClient.firstName,
            surname: data.followUpClient.surname,
            dateOfBirth: data.followUpClient.dateOfBirth.split("/").join("-"),
            email: data.followUpClient.email,
            contactNumber: data.followUpClient.contactNumber,
            identityVerificationNumber: data.followUpClient.identityVerificationNumber,
            identityVerification: data.followUpClient.identityVerification,
            nationality: data.followUpClient.nationality.toLowerCase(),
            gender: data.followUpClient.gender.toLowerCase(),
            nextOfKinContactNumber: data.followUpClient.nextOfKinContactNumber,
            relationshipWithClient: data.followUpClient.relationshipWithClient.toLowerCase(),
            countryOfOrigin: data.followUpClient.countryOfOrigin,
            facility: data.followUpClient.facility
          },
          patientFollowUp: {
            followUpDate: data.patientFollowUp.followUpDate.split("/").join("-"),
            observation: data.patientFollowUp.observation,
            otherFacilityCheckbox: data.patientFollowUp.otherFacilityCheckbox,
            client_circumcised_other_facility: data.patientFollowUp.client_circumcised_other_facility,
            circumcisionOutcome: data.patientFollowUp.circumcisionOutcome,
            followUp_method: data.patientFollowUp.followUp_method,
          },
          followUpQuestion: {
            urinationSymtoms: data.followUpQuestion.urinationSymtoms.toLowerCase(),
            penisAppearance: data.followUpQuestion.penisAppearance.toLowerCase(),
            sexTime: data.followUpQuestion.penisAppearance.toLowerCase(),
            herbalMedicineApply: data.followUpQuestion.herbalMedicineApply.toLowerCase(),
            woundDescribe: data.followUpQuestion.woundDescribe
          },
          adverse: {
            adverse_Events: data.adverse.adverse_Events || '',
            bleeding: data.adverse.bleeding || '',
            haematoma: data.adverse.haematoma || '',
            infaction: data.adverse.infaction || '',
            woundDehiscence: data.adverse.woundDehiscence || '',
            abscess: data.adverse.abscess || '',
            insufficientSkinRemoval: data.adverse.insufficientSkinRemoval || '',
            excessiveSkinRemoval: data.adverse.excessiveSkinRemoval || '',
            pain: data.adverse.pain || '',
            swelling: data.adverse.swelling || '',
            injury: data.adverse.injury || '',
            scarring: data.adverse.scarring || '',
            necrotizing: data.adverse.necrotizing || '',
            other_AdverseEvents: data.adverse.other_AdverseEvents || '',
            aE_Manaement: data.adverse.aE_Manaement || ''
          },
          authorisations: {
            authoriser: data.authorisations.authoriser || '',
            healthcareSigned: data.authorisations.healthcareSigned || ''
          }
        }
      })

      if (data?.followUpFile?.followUpData) {
        setPdfFile(data.followUpFile.followUpData)
      }

      let details = validateSAID(data?.followUpClient?.identityVerificationNumber);
      setClientDetailsId(details);
      // Assuming the date of birth is in the format "YYYY-MM-DD"
      const dobString = data.followUpClient.dateOfBirth?.toString();
      let NextDate = getNextDate(dobString)
      let formatted = formatDate(NextDate)
      const age = new Date(new Date() - new Date(formatted)).getFullYear() - 1970;

      if (isNaN(age)) {
        setAge("");
      } else {
        setAge(age);
      }
      if (age > 14) {
        setDateValid(true);
      } else {
        setDateValid(false);
      }
    }
  }, [data])
  // console.log({ ...formStates, followUpFile: { followUpName: "Follow-up Form", followUpData: pdfFile } })
  // SUBMITING THE FORM
  const finalSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (formDetailsValidate && patientDetailsValidate && followupValidate && formStates.authorisations.healthcareSigned && formStates.authorisations.authoriser) {
      mutation({ ...formStates, followUpFile: { followUpName: "Follow-up Form", followUpData: pdfFile } }).then((res) => {
        console.log(res)
        if (res?.data?.status === 1) {
          toast.success(res?.data?.message, { duration: 7000 });
          navigate("/reception/follow-up/schedule-lookup")
        } else {
          toast.error(res?.data?.message, { duration: 7000 })
        }
      })
    }
    setValidated_authorization(true)
  }

  return (
    <div className="clint-from">
      <>
        <Accordion activeKey={accordionActiveKey} flush>
          {/* attachment */}
          <Accordion.Item eventKey="0">
            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("0")}>
              <div className="acco-box">
                <p>File Attachment</p>
                <i className="mdi mdi-checkbox-multiple-marked-circle"></i>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row g-3 timeline-steps aos-init aos-animate" data-aos="fade-up">
                {/* followup form */}
                <div className="col-md-3">
                  <div className="timeline-step">
                    {pdfFile &&
                      (<>
                        <h3 className="h6 mt-3 mb-2">Follow-up Form</h3>
                        <div className="timeline-fill-box overlay-timeline-fill-box" >
                          <div className="overlay" onClick={() => setConsentModal(true)}>Click here to view</div>
                          {
                            pdfFile.includes("image/") ? (
                              <img src={pdfFile} alt="" />
                            ) : (
                              <iframe src={pdfFile} alt="" height={300} width={"100%"} title="pdf" />
                            )
                          }
                        </div>
                      </>)
                    }
                    {!pdfFile && <h3>No Followup form</h3>}
                  </div>
                </div>
                {pdfFile && <ViewImageModal ModalTitle={"Follow-up Form"} modalState={consentModal} modalSetState={setConsentModal} modalUrl={pdfFile} />}
              </div>
            </Accordion.Body>
          </Accordion.Item>

          {/* form details */}
          <Accordion.Item eventKey="1">
            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("1")}>
              <div className="acco-box">
                <p>Form details</p>
                <i className="mdi mdi-checkbox-multiple-marked-circle"></i>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Form noValidate validated={validated} className="we-form" onSubmit={submitFormDetails}>
                <Row>
                  <Form.Group xl="6" md="6" as={Col} className="mb-2 pb-1">
                    <Form.Label>Barcode CDCF Number</Form.Label>
                    <Form.Control
                      type="text"
                      value={formStates.followUpClient.barcode_cdf_number}
                      name="barcode_cdf_number"
                      onChange={handleChangeFormdetails}
                      placeholder="Barcode CDCF Number"
                      required
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      2-12 charecter and not to include special
                      charecter
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group xl="6" md="6" as={Col} className="mb-2 pb-1">
                    <Form.Label>Folder no.</Form.Label>
                    <Form.Control
                      className="prevent-validation"
                      type="text"
                      placeholder="Enter folderNo"
                      name="folder_number"
                      value={formStates.followUpClient.folder_number}
                      onChange={handleChangeFormdetails}
                      required
                      disabled
                    />
                  </Form.Group>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="relations" className="form-label">
                      Facility
                    </label>
                    <select
                      className="form-select"
                      required
                      name="facility"
                      value={formStates.followUpClient.facility}
                      onChange={handleChangeFormdetails}
                      disabled
                    >
                      <option value="">Select</option>
                      {getDropDown?.data?.data?.facility?.map((el, i) => (
                        <option key={i} value={el.facilityName}>{el.facilityName}</option>
                      ))}
                    </select>
                  </div>
                  <Form.Group xl="6" md="6" as={Col} className="mb-2 pb-1">
                    <Form.Label>Follow-up Date</Form.Label>
                    {
                      type === 'add' ? (
                        <DatePicker
                          name="followUpDate"
                          onChange={handleChangeDate}
                          maxDate={dayjs(formatDate(new Date()), dateFormat)}
                          required
                          className="form-control"
                          disabled
                        />
                      ) : type === "edit" ? (
                        formStates.patientFollowUp.followUpDate && (
                          <DatePicker
                            className="form-control"
                            name="followUpDate"
                            defaultValue={dayjs(formStates.patientFollowUp.followUpDate, dateFormat)}
                            maxDate={dayjs(formatDate(new Date()), dateFormat)}
                            onChange={handleChangeDate}
                            disabled
                          />
                        )
                      ) : (
                        <DatePicker
                          className="form-control"
                          name="followUpDate"
                          onChange={handleChangeDate}
                          maxDate={dayjs(formatDate(new Date()), dateFormat)}
                        />
                      )
                    }
                  </Form.Group>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>

          {/* patient details */}
          <Accordion.Item eventKey="2">
            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("2")}>
              <div className="acco-box">
                <p>Patient details</p>
                <i className="mdi mdi-checkbox-multiple-marked-circle"></i>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Form noValidate validated={validated_patient} className="we-form" onSubmit={submitPatientDetails}>
                <Row>
                  {/* first name */}
                  <Form.Group className="col-lg-4 col-md-6">
                    <Form.Label> First name</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="firstName"
                      placeholder="First name"
                      required
                      name="firstName"
                      value={formStates.followUpClient.firstName}
                      onChange={handleChangePatientdetails}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  {/* surname */}
                  <Form.Group className="col-lg-4 col-md-6">
                    <Form.Label> Surname</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      id="Surname"
                      placeholder="Surname"
                      name="surname"
                      value={formStates.followUpClient.surname}
                      onChange={handleChangePatientdetails}
                      required
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  {/* date of birth */}
                  <div className="col-lg-4 col-md-6">
                    <div className="row gx-2">
                      <Form.Group as={Col} className="mb-2 pb-1 col-md-9">
                        <Form.Label>Date of birth</Form.Label>
                        {
                          type === 'add' ? (
                            formStates.followUpClient.dateOfBirth && (
                              <DatePicker
                                placeholder="Date of birth"
                                onChange={handleChangeDob}
                                maxDate={dayjs(formatDate(new Date()), dateFormat)}
                                defaultValue={formStates.followUpClient.dateOfBirth && dayjs(formStates.followUpClient.dateOfBirth, dateFormat)}
                                required
                                className="form-control"
                                disabled
                              />
                            )
                          ) : type === "edit" ? (
                            formStates.followUpClient.dateOfBirth && (
                              <DatePicker
                                className="form-control"
                                name="dateOfBirth"
                                defaultValue={formStates.followUpClient.dateOfBirth && dayjs(formStates.followUpClient.dateOfBirth, dateFormat)}
                                disabled
                                maxDate={dayjs(formatDate(new Date()), dateFormat)}
                                onChange={handleChangeDob}
                              />
                            )
                          ) : (
                            <DatePicker
                              className="form-control"
                              name="dateOfBirth"
                              maxDate={dayjs(formatDate(new Date()), dateFormat)}
                              onChange={handleChangeDob}
                              disabled
                            />
                          )
                        }
                        <Form.Control.Feedback
                          type="invalid"
                          className="justify-content-between"
                          style={{ display: dateValid === null ? "none" : dateValid === false ? "flex" : "none" }}
                        >
                          {!formStates.followUpClient.dateOfBirth && validated_patient && "This field is required"}
                          <div> {(age >= -1 && age <= 14) && "age: " + Number(age)}</div>
                          <div>{(age >= -1 && age <= 14) && "You are not eligible"}</div>
                        </Form.Control.Feedback>
                        <Form.Control.Feedback
                          type={age > 17 ? "valid" : "invalid"}
                          className="justify-content-between"
                          style={{ display: dateValid === true ? "flex" : "" }}
                        >
                          <div> {age && "age: " + age}</div>
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} className="mb-2 pb-1 col-md-3">
                        <Form.Label>Age</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          placeholder="Age"
                          value={age}
                          required
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </div>
                  {/* nationality */}
                  <Form.Group className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <Form.Label className="form-label">
                        Nationality
                      </Form.Label>
                      <select
                        className="form-select"
                        id="National"
                        name="nationality"
                        required
                        onChange={handleNationalityChange}
                        value={formStates.followUpClient.nationality}
                        disabled
                      >
                        <option value="">Select</option>
                        <option value="south_african">
                          South African
                        </option>
                        <option value="non_south_african">
                          Non South African
                        </option>
                      </select>
                    </div>
                  </Form.Group>
                  {/* country of origin */}
                  <div id="cog" className="col-lg-4 col-md-6 NationalID">
                    {formStates.followUpClient.nationality === "south_african" ? (
                      <>
                        <label htmlFor="National" className="form-label" >
                          Country of Origin
                        </label>
                        <select
                          className="form-select"
                          id="National"
                          onChange={handleChangePatientdetails}
                          name="countryOfOrigin"
                          value={"south_africa"}
                          required
                          disabled
                        >
                          <option value="">Select</option>
                          <option value={"south_africa"}>
                            South Africa
                          </option>
                        </select>
                      </>
                    ) : (
                      <>
                        <label htmlFor="National" className="form-label" >
                          Country of Origin
                        </label>
                        <select required className="form-select" id="National" name="countryOfOrigin" onChange={handleChangePatientdetails} disabled value={formStates.followUpClient.countryOfOrigin}>
                          <option value="" selected>Select</option>
                          <option value={"botswana"}>Botswana</option>
                          <option value={"eswatini"}>Eswatini</option>
                          <option value={"lesotho"}>Lesotho</option>
                          <option value={"mozambique"}>Mozambique</option>
                          <option value={"namibia"}>Namibia</option>
                          <option value={"zimbabwe"}>Zimbabwe</option>
                        </select>
                      </>
                    )}
                  </div>
                  {/* identity verification */}
                  <div className="col-lg-4 col-md-6" id="SAID">
                    <div className="mb-3">
                      <label htmlFor="saID" className="form-label">
                        Identity Verification
                      </label>
                      <select className="form-select" id="saID" required onChange={handleChangePatientdetails} value={formStates.followUpClient.identityVerification} name="identityVerification" disabled>
                        <option value="">Select</option>
                        <option value="South African ID">South African ID</option>
                        <option value="Driving License">Driving License</option>
                        <option value="Birth Certificate"> Birth Certificate</option>
                        {formStates.followUpClient.nationality === "south_african" && <option value="South African Affidavit">South African Affidavit</option>}
                        {formStates.followUpClient.nationality === "south_african" && <option value="South African Passport">South African Passport</option>}
                        {formStates.followUpClient.nationality === "non_south_african" && <option value="Affidavit">Affidavit</option>}
                        {formStates.followUpClient.nationality === "non_south_african" && <option value="Passport">Passport</option>}
                        {formStates.followUpClient.nationality === "non_south_african" && <option value="Assylum Number">Assylum Number</option>}
                      </select>
                    </div>
                  </div>
                  {formStates.followUpClient.identityVerification
                    && (
                      <div className="col-lg-4 margin-medium" id="additionalInput">
                        <label htmlFor="saID" className="form-label">
                          {formStates.followUpClient.nationality === "south_african" ?
                            (formStates.followUpClient.identityVerification === "South African ID")
                              || (formStates.followUpClient.identityVerification === "Driving License")
                              || (formStates.followUpClient.identityVerification === "Birth Certificate")
                              || (formStates.followUpClient.identityVerification === "South African Passport") ? "South African ID" : "South African Affidavit" :
                            formStates.followUpClient.identityVerification
                          }
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={"Number"}
                          onChange={handleChangeIdNumber}
                          name="identityVerificationNumber"
                          value={formStates.followUpClient.identityVerificationNumber}
                          required
                          disabled
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {/* sa id validation message */}
                          {/* {formStates.followUpClient.nationality === "south_african" ?
                            (((formStates.followUpClient.identityVerification === "South African ID")
                              || (formStates.followUpClient.identityVerification === "Driving License")
                              || (formStates.followUpClient.identityVerification === "Birth Certificate")
                              || (formStates.followUpClient.identityVerification === "South African Passport"))
                              && clientDetailsId.isValid === false) ? (
                              <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                Enter a valid SA ID
                              </div>
                            ) : "" :
                            ((formStates.followUpClient.identityVerification === "South African ID")
                              && clientDetailsId.isValid === false) && (
                              <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                Enter a valid SA ID
                              </div>
                            )
                          } */}
                          {/* date of birth message show */}
                          {/* {formStates.followUpClient.nationality === "south_african" ?
                            (((formStates.followUpClient.identityVerification === "South African ID")
                              || (formStates.followUpClient.identityVerification === "Driving License")
                              || (formStates.followUpClient.identityVerification === "Birth Certificate")
                              || (formStates.followUpClient.identityVerification === "South African Passport"))
                              && clientDetailsId.dateOfBirth && clientDetailsId.dateOfBirth !== formStates.followUpClient.dateOfBirth) ? (
                              <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                Date of birth mismatch
                              </div>
                            ) : "" :
                            ((formStates.followUpClient.identityVerification === "South African ID")
                              && clientDetailsId.dateOfBirth && clientDetailsId.dateOfBirth !== formStates.followUpClient.dateOfBirth) && (
                              <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                Date of birth mismatch
                              </div>
                            )
                          } */}
                          {/* gender message show */}
                          {/* {formStates.followUpClient.nationality === "south_african" ?
                            (((formStates.followUpClient.identityVerification === "South African ID")
                              || (formStates.followUpClient.identityVerification === "Driving License")
                              || (formStates.followUpClient.identityVerification === "Birth Certificate")
                              || (formStates.followUpClient.identityVerification === "South African Passport"))
                              && clientDetailsId.gender && formStates.followUpClient.gender !== clientDetailsId.gender) ? (
                              <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                Gender mismatch
                              </div>
                            ) : "" :
                            ((formStates.followUpClient.identityVerification === "South African ID")
                              && clientDetailsId.gender && formStates.followUpClient.gender !== clientDetailsId.gender) && (
                              <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                Gender mismatch
                              </div>
                            )
                          } */}
                        </div>
                      </div>
                    )}
                  {/* email */}
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="email001">
                        Email (if any)
                      </label>
                      <input
                        id="email001"
                        className="prevent-validation form-control"
                        type="email"
                        placeholder="Email"
                        style={{ border: '1px solid #bbbbbb', backgroundImage: 'none' }}
                        name="email"
                        value={formStates.followUpClient.email}
                        onChange={handleChangePatientdetails}
                        disabled
                      />
                    </div>
                  </div>
                  {/* contact number */}
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="mobile">
                        Contact number
                      </label>
                      <PhoneInput
                        international
                        defaultCountry="ZA"
                        placeholder="Enter phone number"
                        onChange={handleContactNumber}
                        value={formStates.followUpClient.contactNumber}
                        name="contactNumber"
                        id="mobile2"
                        required={age > 17}
                        disabled
                        className={formStates.followUpClient.contactNumber && (isValidPhoneNumber(formStates.followUpClient.contactNumber?.toString()) ? 'form-control-valid' : 'form-control-invalid')}
                      />
                    </div>
                  </div>
                  {/* gender */}
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label htmlFor="sex" className="form-label">
                        Gender
                      </label>
                      <select
                        className={"form-select"}
                        id="sex"
                        name={"gender"}
                        required
                        onChange={handleChangePatientdetails}
                        value={formStates?.followUpClient?.gender?.toLowerCase()}
                        disabled
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                      <div className="invalid-feedback manual-feedback">
                        {formStates.followUpClient.gender === "female" ? "you are not eligible" : ""}
                      </div>
                    </div>
                  </div>
                  {/* next kin of contact number */}
                  <div className="col-lg-4 col-md-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="mobile2">
                        Next of Kin Contact Number
                      </label>
                      <PhoneInput
                        international
                        defaultCountry="ZA"
                        placeholder="Enter phone number"
                        onChange={handleNextKinOfContact}
                        value={formStates.followUpClient.nextOfKinContactNumber}
                        name="nextOfKinContactNumber"
                        id="mobile2"
                        disabled
                        required={age < 18}
                        className={formStates.followUpClient.nextOfKinContactNumber && (isValidPhoneNumber(formStates.followUpClient.nextOfKinContactNumber?.toString()) ? 'form-control-valid' : 'form-control-invalid')}
                      />
                    </div>
                  </div>
                  {/* relationship with client */}
                  {age > 17 ? (
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="relations" className="form-label">
                          Relationship with Client
                        </label>
                        <select
                          className="form-select prevent-validation"
                          id="relations"
                          style={{ border: '1px solid #bbbbbb', backgroundImage: 'none' }}
                          name="relationshipWithClient"
                          onChange={handleChangePatientdetails}
                          value={formStates?.followUpClient?.relationshipWithClient?.toLowerCase()}
                          disabled
                        >
                          <option value="">Select</option>
                          <option value="father">Father</option>
                          <option value="mother">Mother</option>
                          <option value="spouse">Spouse</option>
                          <option value="brother">Brother</option>
                          <option value="sister">Sister</option>
                          <option value="wife">Wife</option>
                          <option value="uncle">Uncle</option>
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="relations" className="form-label">
                          Relationship with Client
                        </label>
                        <select
                          className="form-select prevent-validation"
                          id="relations"
                          required
                          name="relationshipWithClient"
                          onChange={handleChangePatientdetails}
                          value={formStates?.followUpClient?.relationshipWithClient?.toLowerCase()}
                          disabled
                        >
                          <option value="">Select</option>
                          <option value="father">Father</option>
                          <option value="mother">Mother</option>
                          <option value="spouse">Spouse</option>
                          <option value="brother">Brother</option>
                          <option value="sister">Sister</option>
                          <option value="wife">Wife</option>
                          <option value="uncle">Uncle</option>
                        </select>
                      </div>
                    </div>
                  )}
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>

          {/* followup */}
          <Accordion.Item eventKey="3">
            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("3")}>
              <div className="acco-box">
                <p>Follow up</p>
                <i className="mdi mdi-checkbox-multiple-marked-circle"></i>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Form noValidate validated={validated_followup} className="we-form" onSubmit={submitFollowup}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <h4 className="mb-0">PATIENT FOLLOW-UP</h4>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3 d-flex">
                      <Form.Group className="me-4">
                        <Form.Control
                          name="observation"
                          type="checkbox"
                          id="observation"
                          className="filled-in form-check-input chk-col-danger"
                          onChange={handleChangeFollowupCheckbox}
                          checked={formStates?.patientFollowUp?.observation}
                          required
                          disabled
                        />
                        <Form.Label htmlFor="observation">
                          Follow-up observation occurred
                        </Form.Label>
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="row">
                        <div className="col-auto">
                          <Form.Control
                            name="followUp_method"
                            type="radio"
                            id="telephonic"
                            className="with-gap radio-col-danger"
                            value="telephonic"
                            onChange={handleChangeFollowup}
                            disabled
                            checked={formStates?.patientFollowUp?.followUp_method === ("telephonic" || "Telephonic")}
                            required
                          />
                          <Form.Label htmlFor="telephonic">Telephonic</Form.Label>
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-auto">
                          <Form.Control
                            name="followUp_method"
                            type="radio"
                            id="inPerson"
                            className="with-gap radio-col-danger"
                            value="in_Person"
                            onChange={handleChangeFollowup}
                            required
                            checked={formStates?.patientFollowUp?.followUp_method === "in_Person" || formStates?.patientFollowUp?.followUp_method === "In Person"}
                            disabled
                          />
                          <Form.Label htmlFor="inPerson">In Person</Form.Label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="mt-3">
                        <input
                          name="otherFacilityCheckbox"
                          type="checkbox"
                          id="clientCircumcised"
                          className="filled-in form-check-input chk-col-danger"
                          onChange={handleChangeFollowupCheckbox}
                          checked={formStates?.patientFollowUp?.otherFacilityCheckbox}
                          disabled
                        />
                        <label htmlFor="clientCircumcised">
                          Client circumcised at other facility
                        </label>
                      </div>
                      {
                        formStates?.patientFollowUp?.otherFacilityCheckbox && (
                          <div className="mt-2">
                            <label for="saID" className="form-label">Name of facility</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name of facility"
                              name="client_circumcised_other_facility"
                              required={formStates?.patientFollowUp?.otherFacilityCheckbox}
                              onChange={handleChangeFollowup}
                              value={formStates?.patientFollowUp?.client_circumcised_other_facility}
                              disabled
                            />
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <h4>CIRCUMCISION OUTCOME</h4>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="d-flex flex-wrap">
                        <div>
                          <input
                            name="circumcisionOutcome"
                            type="radio"
                            id="Cradio1"
                            className="with-gap radio-col-danger"
                            value="complete_circumcision"
                            onChange={handleChangeFollowup}
                            checked={formStates?.patientFollowUp?.circumcisionOutcome === 'Complete circumcision' || formStates?.patientFollowUp?.circumcisionOutcome === 'complete_circumcision'}
                            required
                            disabled
                          />
                          <label htmlFor="Cradio1">
                            Complete circumcision
                          </label>
                          <div className="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                        <div>
                          <input
                            name="circumcisionOutcome"
                            type="radio"
                            id="Cradio2"
                            className="with-gap radio-col-danger"
                            value="partial_circumcision"
                            onChange={handleChangeFollowup}
                            checked={formStates?.patientFollowUp?.circumcisionOutcome === 'Partial circumcision' || formStates?.patientFollowUp?.circumcisionOutcome === 'partial_circumcision'}
                            required
                            disabled
                          />
                          <label htmlFor="Cradio2">
                            Partial circumcision
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <h4>FOLLOW-UP QUESTION</h4>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="row flex-wrap align-items-center">
                        <div className="col-md-8">
                          <span>
                            Does the client experience any symptoms
                            during urination?
                          </span>
                        </div>
                        <div className="col-md-2">
                          <input
                            name="urinationSymtoms"
                            type="radio"
                            id="Fradio1"
                            className="with-gap radio-col-danger"
                            value="yes"
                            onChange={handleChangeFollowupQuestions}
                            checked={formStates?.followUpQuestion?.urinationSymtoms.toLowerCase() === 'yes'}
                            required
                            disabled
                          />
                          <label htmlFor="Fradio1">Yes</label>
                          <div className="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                        <div className="col-md-2">
                          <input
                            name="urinationSymtoms"
                            type="radio"
                            id="Fradio2"
                            className="with-gap radio-col-danger"
                            value="no"
                            onChange={handleChangeFollowupQuestions}
                            checked={formStates?.followUpQuestion?.urinationSymtoms?.toLowerCase() === 'no'}
                            required
                            disabled
                          />
                          <label htmlFor="Fradio2">No</label>
                          <div className="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="row flex-wrap align-items-center">
                        <div className="col-md-8">
                          <span>
                            Is there a current problem with the
                            appearance of the penis?
                          </span>
                        </div>
                        <div className="col-md-2">
                          <input
                            name="penisAppearance"
                            type="radio"
                            id="Fradio3"
                            className="with-gap radio-col-danger"
                            value="yes"
                            onChange={handleChangeFollowupQuestions}
                            checked={formStates?.followUpQuestion?.penisAppearance?.toLowerCase() === 'yes'}
                            required
                            disabled
                          />
                          <label htmlFor="Fradio3">Yes</label>
                          <div className="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                        <div className="col-md-2">
                          <input
                            name="penisAppearance"
                            type="radio"
                            id="Fradio4"
                            className="with-gap radio-col-danger"
                            value="no"
                            onChange={handleChangeFollowupQuestions}
                            checked={formStates?.followUpQuestion?.penisAppearance?.toLowerCase() === 'no'}
                            required
                            disabled
                          />
                          <label htmlFor="Fradio4">No</label>
                          <div className="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="row flex-wrap align-items-center">
                        <div className="col-md-8">
                          <span>
                            Did the client have sex between time of
                            surgery and today?
                          </span>
                        </div>
                        <div className="col-md-2">
                          <input
                            name="sexTime"
                            type="radio"
                            id="Fradio5"
                            className="with-gap radio-col-danger"
                            value="yes"
                            onChange={handleChangeFollowupQuestions}
                            checked={formStates?.followUpQuestion?.sexTime?.toLowerCase() === 'yes'}
                            required
                            disabled
                          />
                          <label htmlFor="Fradio5">Yes</label>
                          <div className="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                        <div className="col-md-2">
                          <input
                            name="sexTime"
                            type="radio"
                            id="Fradio6"
                            className="with-gap radio-col-danger"
                            value="no"
                            onChange={handleChangeFollowupQuestions}
                            checked={formStates?.followUpQuestion?.sexTime?.toLowerCase() === 'no'}
                            required
                            disabled
                          />
                          <label htmlFor="Fradio6">No</label>
                          <div className="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="row flex-wrap align-items-center">
                        <div className="col-md-8">
                          <span>
                            Did the client apply any herbal medicine or
                            home remedies (herbs, cow dung, muthi,
                            spirit, etc)
                          </span>
                        </div>
                        <div className="col-md-2">
                          <input
                            name="herbalMedicineApply"
                            type="radio"
                            id="Fradio7"
                            className="with-gap radio-col-danger"
                            value="yes"
                            onChange={handleChangeFollowupQuestions}
                            checked={formStates?.followUpQuestion?.herbalMedicineApply?.toLowerCase() === 'yes'}
                            required
                            disabled
                          />
                          <label htmlFor="Fradio7">Yes</label>
                          <div className="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                        <div className="col-md-2">
                          <input
                            name="herbalMedicineApply"
                            type="radio"
                            id="Fradio8"
                            className="with-gap radio-col-danger"
                            value="no"
                            onChange={handleChangeFollowupQuestions}
                            checked={formStates?.followUpQuestion?.herbalMedicineApply?.toLowerCase() === 'no'}
                            required
                            disabled
                          />
                          <label htmlFor="Fradio8">No</label>
                          <div className="invalid-feedback">
                            This field is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="facility" className="text-nowrap form-label me-3">
                        Describe the wound (Appearance, presence of
                        sutures, signs of infection):
                      </label>
                      <textarea
                        name="woundDescribe"
                        id="textarea"
                        className="form-control"
                        required
                        disabled
                        placeholder="Describe the wound (Appearance, presence of sutures, signs of infection)"
                        aria-invalid="false"
                        onChange={handleChangeFollowupQuestions}
                        value={formStates?.followUpQuestion?.woundDescribe}
                      />
                      <div className="invalid-feedback">
                        This field is required
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Accordion.Body>
          </Accordion.Item>

          {/* adverse events */}
          <Accordion.Item eventKey="4">
            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("4")}>
              <div className="acco-box">
                <p>Adverse events</p>
                <i className="mdi mdi-checkbox-multiple-marked-circle"></i>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Form noValidate validated={adverseValidate} className="we-form" onSubmit={submitAdverseValidate}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="d-flex flex-wrap align-items-center">
                        <h4 className="me-3 mb-0">ADVERSE EVENTS</h4>
                        <div>
                          <input
                            name="adverse_Events"
                            type="radio"
                            id="eradio1"
                            className="with-gap radio-col-danger form-check-input"
                            value="yes"
                            required
                            checked={formStates?.adverse?.adverse_Events?.toLowerCase() === 'yes'}
                            onChange={handleChangeAdverseEvents}
                            disabled
                          />
                          <label htmlFor="eradio1" className="form-check-label">Yes</label>
                        </div>
                        <div>
                          <input
                            name="adverse_Events"
                            type="radio"
                            id="eradio2"
                            className="with-gap radio-col-danger form-check-input"
                            value="no"
                            required
                            checked={formStates?.adverse?.adverse_Events?.toLowerCase() === 'no'}
                            onChange={handleChangeAdverseEvents}
                            disabled
                          />
                          <label htmlFor="eradio2" className="form-check-label">No</label>
                        </div>
                      </div>
                      <h5 className="mt-3">
                        For each adverse event, please tick one box from
                        each category
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row align-items-center mb-3">
                      <div className="col-md-6">
                        <h4 className="sub-heading mb-3">
                          Type of adverse event
                        </h4>
                      </div>
                      <div className="col-md-6">
                        <h4 className="sub-heading mb-3">
                          Severity of adverse event
                        </h4>
                      </div>
                      <div className="col-md-6"> </div>
                      <div className="col-md-2">
                        <h4 className="sub-heading">Mild</h4>
                      </div>
                      <div className="col-md-2">
                        <h4 className="sub-heading">Moderate</h4>
                      </div>
                      <div className="col-md-2">
                        <h4 className="sub-heading">Severe</h4>
                      </div>
                      {
                        TypesOfAdverseEvents?.map((elem, i) => {
                          return (
                            <>
                              <div className="col-md-6">
                                <fieldset>
                                  <input
                                    name={elem.checkboxName}
                                    className="filled-in form-check-input chk-col-danger"
                                    type="checkbox"
                                    id={elem.checkboxName}
                                    onChange={handleChangeAdverseEventsCheckboxe}
                                    disabled
                                    checked={checkboxesState[elem.checkboxName]}
                                  />
                                  <label htmlFor={elem.checkboxName} className="form-check-label">{elem.title}</label>
                                </fieldset>
                              </div>
                              <div className="col-md-2">
                                <fieldset>
                                  <input
                                    name={elem.radioName}
                                    className="with-gap radio-col-danger form-check-input"
                                    type="radio"
                                    id={`${elem.radioName}_${i + 1}`}
                                    value={"mild"}
                                    checked={formStates?.adverse[elem?.radioName]?.toLowerCase() === "mild"}
                                    disabled
                                    onChange={handleChangeAdverseEvents}
                                    required
                                  />
                                  <label htmlFor={`${elem?.radioName}_${i + 1}`} className="form-check-label" />
                                </fieldset>
                              </div>
                              <div className="col-md-2">
                                <fieldset>
                                  <input
                                    name={elem?.radioName}
                                    className="with-gap radio-col-danger form-check-input"
                                    type="radio"
                                    id={`${elem.radioName}_${i + 2}`}
                                    value={"moderate"}
                                    checked={formStates?.adverse[elem.radioName]?.toLowerCase() === "moderate"}
                                    disabled
                                    onChange={handleChangeAdverseEvents}
                                    required
                                  />
                                  <label htmlFor={`${elem.radioName}_${i + 2}`} className="form-check-label" />
                                </fieldset>
                              </div>
                              <div className="col-md-2">
                                <fieldset>
                                  <input
                                    name={elem.radioName}
                                    className="with-gap radio-col-danger form-check-input"
                                    type="radio"
                                    id={`${elem.radioName}_${i + 3}`}
                                    value={"severe"}
                                    checked={formStates?.adverse[elem.radioName]?.toLowerCase() === "severe"}
                                    disabled
                                    onChange={handleChangeAdverseEvents}
                                    required
                                  />
                                  <label htmlFor={`${elem.radioName}_${i + 3}`} className="form-check-label" />
                                </fieldset>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="specify" className="text-nowrap form-label">
                        If other, specify:
                      </label>
                      <textarea
                        name="other_AdverseEvents"
                        id="specify"
                        className="form-control"
                        placeholder="If other, specify"
                        aria-invalid="false"
                        value={formStates?.adverse?.other_AdverseEvents}
                        disabled
                        onChange={handleChangeAdverseEvents}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="management" className="text-nowrap form-label">
                        Describe your management of the AE (Treatment given, procedure done, dosages, referral)
                      </label>
                      <textarea
                        name="aE_Manaement"
                        id="management"
                        className="form-control"
                        placeholder="Describe your management of the AE (Treatment given, procedure done, dosages, referral)"
                        aria-invalid="false"
                        required
                        value={formStates?.adverse?.aE_Manaement}
                        disabled
                        onChange={handleChangeAdverseEvents}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </Accordion.Body>
          </Accordion.Item>

          {/* authorizations */}
          <Accordion.Item eventKey="5">
            <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("5")}>
              <div className="acco-box">
                <p>Authorisations</p>
                <i className="mdi mdi-checkbox-multiple-marked-circle"></i>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Form className="we-form" noValidate validated={validated_authorization}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="healthcare" className="text-nowrap form-label">
                        Name &amp; surname of healthcare professional
                      </label>
                      <input
                        type="text"
                        name="authoriser"
                        id="healthcare"
                        className="form-control"
                        required
                        placeholder="Name & surname of healthcare professional"
                        onChange={handleChangeAuthorization}
                        value={formStates?.authorisations?.authoriser}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="d-flex flex-sm-column flex-row">
                      <div class="text-nowrap me-3">Signature of healthcare professional</div>
                      <div class="d-flex align-items-center mt-2">
                        <div>
                          <input
                            type="radio"
                            name="healthcareSigned"
                            id="healthcareSignedYes"
                            class="filled-in chk-col-danger form-check-input"
                            required=""
                            value="yes"
                            checked={formStates?.authorisations?.healthcareSigned?.toLowerCase() === "yes"}
                            onChange={handleChangeAuthorization}
                            disabled
                          />
                          <label for="healthcareSignedYes" class="form-check-label">Yes</label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            id="healthcareSignedNo"
                            name="healthcareSigned"
                            class="filled-in chk-col-danger form-check-input"
                            required=""
                            value="no"
                            checked={formStates?.authorisations?.healthcareSigned?.toLowerCase() === "no"}
                            onChange={handleChangeAuthorization}
                            disabled
                          />
                          <label for="healthcareSignedNo" class="form-check-label">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>

    </div>
  );
};

export default ViewOnlyFollowUpClientForm;
