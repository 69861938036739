import React from 'react'
import GoBackWrapper from '../../../components/wrapper/GoBackWrapper/GoBackWrapper'
import checkImage from '../../../assets/images/check-mark_5290058.png'
const AfterRegistration = () => {
  return (
    <GoBackWrapper>
      <div className="auth_container py-45 bg-success-light">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="text-center">
              <img src={checkImage} alt="registered" />
              <h3 className='mb-0 mt-3'>
                Your account is pending approval. An email will be sent to your registered email once approval has been granted, you can then successfully login.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </GoBackWrapper>
  )
}

export default AfterRegistration