import React from 'react'
import ClientLookEditForm from '../../../../components/Manage_Client_Record/ClientLookEditForm/ClientLookEditForm'
import { useParams } from 'react-router-dom';
import { useGetSingleDeletedClientQuery, useUpdateClientMutation } from '../../../../store/api/reception/manageClient';
import ClientLookViewForm from '../../../../components/Manage_Client_Record/ClientLookEditForm/ClientLookViewForm';

const DeletedClientLookupView = () => {
    const params = useParams();
    let singlePage = useGetSingleDeletedClientQuery(params.id)
    let [updateClient, res] = useUpdateClientMutation()
    console.log(singlePage)
    console.log(params)
    return (
        <ClientLookViewForm singlePage={singlePage?.data?.data} response={singlePage} updateClient={updateClient} updateClientRes={res} />
    )
}

export default DeletedClientLookupView