import React, { useState } from "react";
import "./otpVerificaton.css";
import { Form } from "react-bootstrap";
import logo from "../../../assets/images/logo.png";
import { useChangePasswordMutation } from "../../../store/api/authApi";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";


const ChangePassword = () => {
  const [passwordType, setPasswordType] = useState('password');
  const [passwordTypeReEnter, setPasswordTypeReEnter] = useState('password');
  const [changePassword, response] = useChangePasswordMutation();
  let navigate = useNavigate();
  const [validated, setValidated] = useState(false)

  const [formData, setFormData] = useState({
    email: '',
    password: "",
    reEnterpassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const changePasswordUser = async (e) => {
    e.preventDefault();
    if (formData.password === formData.reEnterpassword) {
      await changePassword({
        email: formData.email,
        password: formData.password,
        token:localStorage.getItem('otpToken')
      }).then((res) => {
        if (res?.data?.status === 1) {
          toast.success(res?.data?.message, { duration: 7000 });
          navigate("/login");
          localStorage.removeItem('vmmc_username');
          localStorage.removeItem('vmmc_password');
          localStorage.removeItem('vmmc_rememberMe');
        }
        if (res?.data?.status === 0) {
          toast.error(res?.data?.message, { duration: 7000 });
        }
      });
    } else {
      toast.error("New password and re-enter password does not match!!")
    }
    setValidated(true)
  };

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
    } else {
      setPasswordType('password')
    }
  }

  const togglePasswordReEnter = () => {
    if (passwordTypeReEnter === 'password') {
      setPasswordTypeReEnter('text')
    } else {
      setPasswordTypeReEnter('password')
    }
  }
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  return (
    <div>
      <div
        className="hold-transition theme-primary bg-img"
        style={{ backgroundImage: "url(../images/auth-bg/bg-1.jpg)" }}
      >
        <div className="container h-p100 centered-container">
          <div className="row align-items-center justify-content-md-center h-p100">
            <div className="col-12">
              <div className="row justify-content-center g-0">
                <div className="col-lg-6 col-md-5 col-12">
                  <div className="bg-white rounded10 shadow-lg">
                    <div className="content-top-agile p-20 pb-0 mb-0">
                      <img
                        src={logo}
                        className=" w-120"
                        alt="Your Logo"
                      />
                      <h2 className="text-primary">Change password</h2>
                    </div>

                    <div className="p-40">
                      <Form
                        method="post"
                        onSubmit={changePasswordUser}
                      // noValidate
                      // validated={validated}
                      >
                        <div className="form-group">
                          <div className="input-group mb-3">
                            <span className="input-group-text bg-transparent">
                              <i className="ti-user"></i>
                            </span>
                            <Form.Control
                              type="text"
                              placeholder="Enter Email ID"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group password_toggle">
                            <span className="input-group-text bg-transparent">
                              <i className="ti-lock"></i>
                            </span>
                            <button type="button" onClick={togglePassword}>
                              {
                                passwordType === 'password' ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>
                              }
                            </button>
                            <Form.Control
                              type={passwordType}
                              placeholder="Enter Password"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$'
                              required
                            />
                          </div>
                          {(passwordRegex.test(formData.password) === false) && (
                            <div style={{ color: 'rgb(237,26,59)', fontSize: '12px' }} >
                              *min 8 characters which contain at least one numeric digit, one
                              uppercase,one lowercase letter and a special character (@,!,$,%,&)
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-2">
                          <div className="input-group password_toggle">
                            <span className="input-group-text bg-transparent">
                              <i className="ti-lock"></i>
                            </span>
                            <button type="button" onClick={togglePasswordReEnter}>
                              {
                                passwordTypeReEnter === 'password' ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>
                              }
                            </button>
                            <Form.Control
                              type={passwordTypeReEnter}
                              placeholder="Re-enter Password"
                              name="reEnterpassword"
                              value={formData.reEnterpassword}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="fog-pwd text-end"></div>
                        <div className="text-center mt-30">
                          {
                            response.isLoading === true ?
                              (<button disabled className="btn btn-primary">
                                <span className="spinnerInButton"></span>
                              </button>)
                              :
                              (<button type="submit" className="btn btn-primary">
                                Submit
                              </button>)
                          }
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
