import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ManageUserApis = createApi({
    reducerPath: "ManageUserApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            headers.set('Authorization', `Bearer ${userSlice.user.token}`);
            headers.set('Content-Type', `application/json;charset=UTF-8`);
            return headers;
        },
    }),
    tagTypes: ["ManageUser"],
    endpoints: (builder) => ({
        addUser: builder.mutation({
            query: (data) => {
                return {
                    url: "/Auth/AddUser",
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ["ManageUser"],
        }),
        pendingUsers: builder.query({
            query: (data) => {
                return {
                    url: "/Auth/ViewPendingUsers",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["ManageUser"],
        }),
        allExistingUsers: builder.query({
            query: (data) => {
                return {
                    url: "/Auth/ViewAllUsers",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["ManageUser"],
        }),
        userStatusChange: builder.mutation({
            query: (data) => {
                return {
                    url: "/Auth/ActivateUser",
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ["ManageUser"],
        }),
        getUserById: builder.query({
            query: (userId) => {
                return {
                    url: "/Auth/GetUserById",
                    method: "GET",
                    params: {
                        id: userId
                    }
                }
            },
            providesTags: ["ManageUser"],
        }),
        editUser: builder.mutation({
            query: (data) => {
                return {
                    url: "/Auth/EditUser",
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ["ManageUser"],
        }),
        getDropdown: builder.query({
            query: (data) => {
                return {
                    url: "/Auth/GetAccDropDown",
                    method: "GET",
                    params: {
                        pid: data.pid,
                        dloc: data.dloc,
                        sloc: data.sloc
                    }
                }
            },
            providesTags: ["ManageUser"],
        }),
        userDelete: builder.mutation({
            query: (data) => {
                return {
                    url: "/Auth/DeleteUser",
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ["ManageUser"],
        }),
    }),
});

export const { useAddUserMutation, usePendingUsersQuery, useAllExistingUsersQuery, useUserStatusChangeMutation, useGetUserByIdQuery, useEditUserMutation, useGetDropdownQuery,useUserDeleteMutation } = ManageUserApis;
