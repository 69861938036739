import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./api/authApi";
import userSlice from "./slice/userSlice";
import { manageClient } from "./api/reception/manageClient";
import { batchApi } from "./api/reception/batchApi";
import { FollowUpApis } from "./api/reception/followupApi";
import { SystemUserApis } from "./api/userManagement/systemUserApi";
import { ManageUserApis } from "./api/userManagement/manageUserApi";
import { QaOne } from "./api/qa_one/QAOneApi";
import { QaTwo } from "./api/qa_two/QATwoApi";
import { receptionBillingApi } from "./api/reception/billingApi";
import { billingApi } from "./api/billing/billingApi";
import { dashboardApi } from "./api/dashboardApi";

const Store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [manageClient.reducerPath]: manageClient.reducer,
    [batchApi.reducerPath]: batchApi.reducer,
    [FollowUpApis.reducerPath]: FollowUpApis.reducer,
    [SystemUserApis.reducerPath]: SystemUserApis.reducer,
    [ManageUserApis.reducerPath]: ManageUserApis.reducer,
    [QaOne.reducerPath]: QaOne.reducer,
    [QaTwo.reducerPath]: QaTwo.reducer,
    [receptionBillingApi.reducerPath]: receptionBillingApi.reducer,
    [billingApi.reducerPath]: billingApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    userSlice: userSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      manageClient.middleware,
      batchApi.middleware,
      FollowUpApis.middleware,
      SystemUserApis.middleware,
      ManageUserApis.middleware,
      QaOne.middleware,
      QaTwo.middleware,
      receptionBillingApi.middleware,
      billingApi.middleware,
      dashboardApi.middleware,
    ),
});

setupListeners(Store.dispatch);

export default Store;
