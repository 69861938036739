import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useGetSingleClientMutation } from '../../../../../store/api/reception/manageClient';
import ClientReadOnlyForm from '../../../../../components/FollowUp/ClientReadOnlyForm';
import ReceptionLayout from '../../../ReceptionLayout';

const ViewFollowupClient = () => {
    const params = useParams();
    let [singlePage, response] = useGetSingleClientMutation()
    
    useEffect(() => {
        singlePage(params.clientId).then((res) => {
            console.log(res)
        })
    }, [params.clientId, singlePage])
    
    return (
        <ReceptionLayout>
            <ClientReadOnlyForm singlePage={singlePage} response={response} />
        </ReceptionLayout>
    )
}

export default ViewFollowupClient;
