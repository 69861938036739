import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const billingApi = createApi({
    reducerPath: "billingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            return headers.set('Authorization', `Bearer ${userSlice.user.token}`);
        },
    }),
    tagTypes: ["billing"],
    endpoints: (builder) => ({
        viewDueAmount: builder.query({
            query: () => {
                return {
                    url: `/Billing/ViewDueAmount`,
                    method: "GET",
                };
            },
            providesTags: ["billing"],
        }),
        viewPendingAmount: builder.query({
            query: () => {
                return {
                    url: `/Billing/ViewPendingAmount`,
                    method: "GET",
                };
            },
            providesTags: ["billing"],
        }),
        invoiceRecord: builder.query({
            query: (data) => {
                return {
                    url: `/Billing/ViewInvoiceRecords`,
                    method: "GET",
                    params: data
                };
            },
            invalidatesTags: ["billing"],
        }),
        batchSummery: builder.query({
            query: () => {
                return {
                    url: `/Billing/ViewListDueAmount`,
                    method: "GET",
                };
            },
            providesTags: ["billing"],
        }),
        batchPending: builder.query({
            query: () => {
                return {
                    url: `/Billing/BatchesPending`,
                    method: "GET",
                };
            },
            providesTags: ["billing"],
        }),
        invoiceRecordByInvoiceNo: builder.mutation({
            query: (data) => {
                return {
                    url: `/Billing/GetRecordbyInvoiceNo`,
                    method: "POST",
                    body: {
                        invoiceNo: data
                    }
                };
            },
            invalidatesTags: ["billing"],
        }),
        viewRecordInBatch: builder.mutation({
            query: (data) => {
                return {
                    url: `/Billing/ViewRecordsInBatch`,
                    method: "POST",
                    params: data
                };
            },
            invalidatesTags: ["receptionBilling"],
        }),
        invoiceApproval: builder.mutation({
            query: (data) => {
                return {
                    url: `/Billing/InvoiceApproval`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["receptionBilling"],
        }),
        rejectReasonDropdown: builder.query({
            query: () => {
                return {
                    url: `/Billing/ReasonsDrop`,
                    method: "GET",
                };
            },
            providesTags: ["billing"],
        }),
        getClientByID: builder.mutation({
            query: (data) => {
                return {
                    url: `/Billing/GetClientByID`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["receptionBilling"],
        }),
    }),
});

export const { useViewDueAmountQuery, useViewPendingAmountQuery, useInvoiceRecordQuery, useBatchSummeryQuery, useViewRecordInBatchMutation, useBatchPendingQuery, useInvoiceRecordByInvoiceNoMutation, useInvoiceApprovalMutation, useRejectReasonDropdownQuery, useGetClientByIDMutation } = billingApi;
