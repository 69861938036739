import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAddToBatchMutation, useClientListQuery, useCreateBatchMutation } from '../../../../store/api/reception/batchApi';
import TableRowBatch from '../component/TableRowBatch';
import toast from "react-hot-toast";
import ReceptionLayout from '../../ReceptionLayout';
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent';
import { useSelector } from 'react-redux';

const Create = () => {
  const { user } = useSelector((store) => {
    return store.userSlice;
  });
  const navigate = useNavigate()

  // for create batch
  const [batchNo, setBatchNo] = useState("")
  const [createBatch, response] = useCreateBatchMutation()
  const handleCreateBatch = () => {
    createBatch().then((res) => {
      setBatchNo(res?.data?.data?.batch_Number)
    })
  }

  useEffect(() => {
    handleCreateBatch()
  }, [])

  // get client list
  const clientList = useClientListQuery();
  // Add to Batch
  const [checkedItems, setCheckedItems] = useState([]);
  const handleCheckboxChange = (value) => {
    if (checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter(item => item !== value));
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = clientList?.data?.data.map((item) => item.client_id);
      setCheckedItems(allIds);
    } else {
      setCheckedItems([]);
    }
  };

  const [addToBatch] = useAddToBatchMutation()
  const handleAddToBatch = () => {
    const data = [
      {
        batch_Number: batchNo,
        client_id: checkedItems,
      }
    ]
    if (checkedItems.length > 0 && checkedItems.length <= 100) {
      addToBatch(data).then((res) => {
        toast.success("Added to Batch", { duration: 7000 })
        navigate("/reception/batch-management")
      })
    } else if (checkedItems.length === 0) {
      toast.error("Please select minimum of 1 client", { duration: 7000 })
    } else {
      toast.error("You can't add more than 100 clients in a batch", { duration: 7000 })
    }
  }

  return (
    <ReceptionLayout>
      <div className="container-full">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="d-flex align-items-center">
            <div className="me-auto">
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">
                        <i className="mdi mdi-home-outline" />
                      </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Batch Management
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Create Batch
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="box">
            <div className="box-header with-border">
              <div className="box-in">
                {user.role === "Super User/Admin" ?
                  <button onClick={() => navigate("/dashboard")} className='btn btn-danger-light'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-left"
                    >
                      <line x1={19} y1={12} x2={5} y2={12} />
                      <polyline points="12 19 5 12 12 5" />
                    </svg>
                  </button> :
                  <button onClick={() => navigate("/reception/dashboard")} className='btn btn-danger-light'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-left"
                    >
                      <line x1={19} y1={12} x2={5} y2={12} />
                      <polyline points="12 19 5 12 12 5" />
                    </svg>
                  </button>
                }
                <h4 className="box-title me-auto ms-4">
                  <strong>Create Batch</strong>
                </h4>
              </div>
            </div>
            <div className="box-body">
              {(clientList.isError) && (
                <LoadingErrorComponent error />
              )}
              {clientList.isLoading && (
                <LoadingErrorComponent loading />
              )}
              {clientList.isSuccess && (
                <>
                  {clientList?.data?.data.length > 0 && (
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="d-flex align-items-center">
                          <label htmlFor="Batchno" className="text-nowrap me-3"><strong>Batch Number</strong></label>
                          <span className='text-primary underline'>{batchNo}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {
                    clientList?.data?.data.length > 0 ? (
                      <>
                        <div className="row">
                          <div className="col-12">
                            <p>Assign Client Record [{checkedItems.length} records selected]</p>
                            <div className="table-responsive rounded card-table">
                              <table className="table border-no" id="example1" style={{ width: "100%" }}>
                                <thead>
                                  <tr>
                                    <th className="vertical-align">
                                      <input
                                        type="checkbox"
                                        id="b_check"
                                        className="filled-in chk-col-danger"
                                        onChange={handleSelectAll}
                                        checked={checkedItems.length === clientList?.data?.data.length}
                                      />
                                      <label htmlFor="b_check" />
                                    </th>
                                    <th>Sr.</th>
                                    <th>Capture Date</th>
                                    <th>CDC Barcode No.</th>
                                    <th>Client Name</th>
                                    <th>Client ID</th>
                                    <th>Verification ID</th>
                                    <th>Booking ID</th>
                                    <th>Folder Number</th>
                                    <th>Status</th>
                                    <th>Action Items</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* table row mapping will go here */}
                                  {
                                    clientList?.data?.data.map((el, i) => (
                                      <TableRowBatch key={i} data={el} index={i} handleCheckboxChange={handleCheckboxChange} checkedItems={checkedItems} />
                                    ))
                                  }
                                </tbody>
                              </table>
                            </div>
                            {/* <div className='d-flex align-items-center justify-content-between mt-2'>
                              <div>{`Showing 1 to 10  of 100 entries`}</div>
                              <div>
                                <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                  />
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div className="mt-3 text-center">
                          {
                            response.isLoading === true ?
                              (<button disabled className="btn btn-primary">
                                <span className="spinnerInButton"></span>
                              </button>)
                              :
                              (<button type="button" className="btn btn-primary" onClick={handleAddToBatch}>
                                Add to Batch
                              </button>)
                          }
                        </div>
                      </>
                    ) : (
                      <div className="row">
                        <div className="col-12">
                          <h5 className='my-5'>No data found to create a batch !!</h5>
                        </div>
                      </div>
                    )
                  }

                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </ReceptionLayout>
  )
}

export default Create