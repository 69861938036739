import React from 'react'
import ClientLookEditForm from '../../../../components/Manage_Client_Record/ClientLookEditForm/ClientLookEditForm'
import { useSearchParams } from 'react-router-dom';
import { useGetSingleClientMutation, useUpdateClientMutation } from '../../../../store/api/reception/manageClient';

const ClientLookupView_and_edit = () => {
  const params = useSearchParams();
  let [singlePage, response] = useGetSingleClientMutation(params.clientID)
  let [updateClient,res] = useUpdateClientMutation()

  return (
    <ClientLookEditForm singlePage={singlePage} response={response} updateClient={updateClient} updateClientRes={res}/>
  )
}

export default ClientLookupView_and_edit