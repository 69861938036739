import React, { useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useUserDeleteMutation, useUserStatusChangeMutation } from '../../../store/api/userManagement/manageUserApi'
import toast from 'react-hot-toast'

const ExistingUserTableRow = ({ data, index }) => {
    const [statusChange] = useUserStatusChangeMutation()
    const [userDelete] = useUserDeleteMutation()
    const changeToPending = (id) => {
        let data = {
            user_Id: id,
            status: "deactivated"
        }
        statusChange(data).then((res) => {
            console.log(res)
            if (res?.data?.status === 1) {
                toast.error("User Deactivated !!", { duration: 7000 })
            }
        })
    }
    const changeToActive = (id) => {
        let data = {
            user_Id: id,
            status: "active"
        }
        statusChange(data).then((res) => {
            console.log(res)
            if (res?.data?.status === 1) {
                toast.success("User Activated !!", { duration: 7000 })
            }
        })
    }
    const deleteUser = (id) => {
        let data = {
            user_Id: id,
        }
        userDelete(data).then((res) => {
            console.log(res)
            if (res?.data?.status === 1) {
                toast.success("User Deleted !!", { duration: 7000 })
            }
        })
    }
    const [deletemodal, setDeleteModal] = useState(false);
    const [activePendingModal, setActivePendingModal] = useState(false);
    return (
        <tr className="hover-primary">
            <td>{index + 1}</td>
            <td>{data.firstname} {data.last_Name}</td>
            <td>
                <div>{data.contactNo}</div>
                <div>{data.email}</div>
            </td>
            <td>{data.postRole}</td>
            <td>{data.nationality === "south_african" && "South African"}</td>
            <td>
                <span className={data.user_Status.toLowerCase() === "deactivated" ? 'badge badge-danger-light' : data.user_Status.toLowerCase() === "pending" ? "badge badge-warning-light" : 'badge badge-success-light'}>{data.user_Status}</span>
            </td>
            <td>
                <div className="btn-group">
                    <Dropdown>
                        <Dropdown.Toggle variant="Secondary" >
                            <i className="fa fa-ellipsis-h"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to={`/user-management/manage-user/view-and-edit/${data.user_Id}`}>View & Edit</Dropdown.Item>
                            <Dropdown.Item>
                                {(data.user_Status === "pending" || data.user_Status === "deactivated") ?
                                    (<span onClick={() => setActivePendingModal(true)}>
                                        Activate User
                                    </span>) :
                                    (<span onClick={() => setActivePendingModal(true)}>
                                        Deactivate User
                                    </span>)
                                }
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => setDeleteModal(true)}
                            >Delete User</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </td>
            {/* delete modal */}
            <Modal show={deletemodal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDeleteModal(false)}>
                <Modal.Body style={{ display: "block" }}>
                    <div className="text-center p-3">
                        <h4 className="fw-bold mb-3">Are you sure want to Delete this user?</h4>
                        <div className="d-flex gap-2 justify-content-center">
                            <Button variant="primary" onClick={() => { deleteUser(data.user_Id); setDeleteModal(false) }}>
                                Yes
                            </Button>
                            <Button variant="secondary" onClick={() => setDeleteModal(false)}>
                                No
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Active Pending modal */}
            <Modal show={activePendingModal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setActivePendingModal(false)}>
                <Modal.Body style={{ display: "block" }}>
                    <div className="text-center p-3">
                        <h4 className="fw-bold mb-3">
                            Are you sure want to {(data.user_Status === "pending" || data.user_Status === "deactivated") ? "activate" : "deactivate"} this user?
                        </h4>
                        <div className="d-flex gap-2 justify-content-center">
                            {(data.user_Status === "pending" || data.user_Status === "deactivated") ?
                                (<Button variant="primary" onClick={() => { changeToActive(data.user_Id); setActivePendingModal(false) }}>
                                    Activate
                                </Button>) :
                                (<Button variant="primary" onClick={() => { changeToPending(data.user_Id); setActivePendingModal(false) }}>
                                    Deactivate
                                </Button>)
                            }
                            <Button variant="secondary" onClick={() => setActivePendingModal(false)}>
                                No
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </tr>
    )
}

export default ExistingUserTableRow
