import React, { useEffect, useState } from 'react'
import ReceptionLayout from '../../ReceptionLayout'
import { useInvoiceRecordQuery } from '../../../../store/api/reception/billingApi'
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent'
import { Link, useNavigate } from 'react-router-dom'

const ApproveRejectedPayment = () => {
    const navigate = useNavigate()
    const invoiceRecordList = useInvoiceRecordQuery()
    let approvedBatches = invoiceRecordList.data?.data?.filter(item => item.validationStatus
        .toLowerCase() === "approved");
    let rejectedBatches = invoiceRecordList.data?.data?.filter(item => item.validationStatus
        .toLowerCase() === "rejected");

    return (
        <ReceptionLayout>
            <div className="content-header">
                <div className="d-flex align-items-center">
                    <div className="me-auto">
                        <div className="d-inline-block align-items-center">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to=""><i className="mdi mdi-home-outline"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Facility Billing</li>
                                    <li className="breadcrumb-item active" aria-current="page">Approved & Rejected Payments</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="box">
                    <div className="box-header with-border">
                        <div className="box-in">
                            <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">

                                <strong>Approved & Rejected Payments List</strong>
                            </h4>
                        </div>
                    </div>
                    {invoiceRecordList.isError && <LoadingErrorComponent error />}
                    {invoiceRecordList.isLoading && <LoadingErrorComponent loading />}
                    {invoiceRecordList.isSuccess && (
                        <div className="box-body">
                            <div className="row gy-4">
                                <div className="col-md-12">
                                    <h5 className='fw-bold mb-3'>Approved Payments for Batches</h5>
                                    <div className="row g-3">
                                        {
                                            approvedBatches?.length > 0 ? (
                                                approvedBatches?.map((el, i) => (
                                                    <div className="col-xl-4 col-md-4 col-sm-6 col-12">
                                                        <div className="scheduleBox">
                                                            <div className="scheduleBoxTop">
                                                                <i className="fa fa-folder" style={{ color: 'rgb(255, 193, 7)' }}></i>
                                                                <div> {el.batchNo} </div>
                                                            </div>
                                                            <div className="scheduleLabel overdue">
                                                                <span className="scheduleBoxTitle">Invoice Number</span>
                                                                <span className="scheduleBoxTitleCount">{el.invoiceNo}</span>
                                                            </div>
                                                            <div className="scheduleLabel overdue">
                                                                <span className="scheduleBoxTitle">Statement Ref.</span>
                                                                <span className="scheduleBoxTitleCount">{el.statementRef} </span>
                                                            </div>
                                                            <div className="scheduleLabel overdue">
                                                                <span className="scheduleBoxTitle">Statement Amount</span>
                                                                <span className="scheduleBoxTitleCount">ZAR {el.amount} </span>
                                                            </div>
                                                            <div className="scheduleLabel total">
                                                                <span className="scheduleBoxTitle">Total Batches</span>
                                                                <span className="scheduleBoxTitleCount">{el.totalRecords}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                                : <div>No Data Found!!</div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h5 className='fw-bold mb-3'>Rejected Payments for Batches</h5>
                                    <div className="row g-3">
                                        {
                                            rejectedBatches?.length > 0 ? (
                                                rejectedBatches?.map((el, i) => (
                                                    <div className="col-xl-4 col-md-4 col-sm-6 col-12">
                                                        <Link to={`/reception/manage-billing/approved-rejected-payment/rejected-statement/${el.statementRef}/reupload-invoice`}>
                                                            <div className="scheduleBox">
                                                                <div className="scheduleBoxTop">
                                                                    <i className="fa fa-folder" style={{ color: 'rgb(255, 193, 7)' }}></i>
                                                                    <div> {el.statementRef} </div>
                                                                </div>
                                                                <div className="scheduleLabel overdue">
                                                                    <span className="scheduleBoxTitle">Invoice Number</span>
                                                                    <span className="scheduleBoxTitleCount">{el.invoiceNo}</span>
                                                                </div>
                                                                <div className="scheduleLabel overdue">
                                                                    <span className="scheduleBoxTitle">Statement Ref.</span>
                                                                    <span className="scheduleBoxTitleCount">{el.statementRef} </span>
                                                                </div>
                                                                <div className="scheduleLabel overdue">
                                                                    <span className="scheduleBoxTitle">Statement Amount</span>
                                                                    <span className="scheduleBoxTitleCount">ZAR {el.amount}</span>
                                                                </div>
                                                                <div className="scheduleLabel total">
                                                                    <span className="scheduleBoxTitle">Total Batches</span>
                                                                    <span className="scheduleBoxTitleCount">{el.totalRecords}</span>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                            )
                                                : <div>No Data Found!!</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*  */}
                        </div>)}

                </div>
            </section>
        </ReceptionLayout>
    )
}

export default ApproveRejectedPayment