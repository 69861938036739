import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const manageClient = createApi({
    reducerPath: "manageClient",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            return headers.set('Authorization', `Bearer ${userSlice.user.token}`);
        },
    }),
    tagTypes: ["manageClient"],
    endpoints: (builder) => ({
        //to add a new client
        addClient: builder.mutation({
            query: (data) => {
                return {
                    url: `/Client/AddClient`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["manageClient"],
        }),
        getClientList: builder.query({
            query: (data) => {
                return {
                    url: "/Client/GetClients",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["manageClient"],
        }),
        getDeletedClientList: builder.query({
            query: (data) => {
                return {
                    url: "Auth/ListDeletedClients",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["manageClient"],
        }),
        deleteClient: builder.mutation({
            query: (data) => {
                console.log(data)
                return {
                    url: "/Client/DeleteClient",
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["manageClient"],
        }),
        getSingleClient: builder.mutation({
            query: (id) => {
                return {
                    url: "/Client/GetClientByID",
                    method: "POST",
                    body: {
                        client_id: id
                    }
                };
            },
            invalidatesTags: ["manageClient"],
        }),
        getSingleDeletedClient: builder.query({
            query: (id) => {
                return {
                    url: "/Auth/GetDeletedClientById",
                    method: "GET",
                    params: {
                        id: id
                    }
                };
            },
            invalidatesTags: ["manageClient"],
        }),
        getMobiliserList: builder.query({
            query: () => {
                return {
                    url: "/Client/mobiliser-list",
                    method: "GET"
                }
            },
            providesTags: ["manageClient"],
        }),
        updateClient: builder.mutation({
            query: (data) => {
                return {
                    url: `/Client/UpdateClient`,
                    method: "POST",
                    body: data,
                };
            }
        }),
        getBulkClients: builder.query({
            query: (data) => {
                return {
                    url: "/Client/BulkClientLookUp",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["manageClient"],
        }),
        getDropDown: builder.query({
            query: () => {
                return {
                    url: "/Client/GetDropdown",
                    method: "GET"
                }
            },
            providesTags: ["manageClient"],
        }),
        getAeType: builder.query({
            query: () => {
                return {
                    url: "/Client/GetAETypes",
                    method: "GET"
                }
            },
            providesTags: ["manageClient"],
        }),
    }),
});

export const {
    useAddClientMutation,
    useGetClientListQuery,
    useGetDeletedClientListQuery,
    useDeleteClientMutation,
    useGetSingleClientMutation,
    useGetSingleDeletedClientQuery,
    useGetMobiliserListQuery,
    useGetBulkClientsQuery,
    useUpdateClientMutation,
    useGetDropDownQuery,
    useGetAeTypeQuery
} = manageClient;

