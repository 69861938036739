import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ReceptionLayout from '../../../ReceptionLayout'
import { useGetCurrentStatementQuery } from '../../../../../store/api/reception/billingApi';
import LoadingErrorComponent from '../../../../../components/LoadingErrorComponent/LoadingErrorComponent';

const BillingStatement = () => {
    const navigate = useNavigate();
    const currentStatement = useGetCurrentStatementQuery()

    return (
        <ReceptionLayout>
            <div class="content-header">
                <div class="d-flex align-items-center">
                    <div class="me-auto">
                        <div class="d-inline-block align-items-center">
                            <nav>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <a href="#">
                                            <i class="mdi mdi-home-outline"></i>
                                        </a>
                                    </li>
                                    <li class="breadcrumb-item" aria-current="page">Manage Billing</li>
                                    <li class="breadcrumb-item active" aria-current="page">
                                        <Link to={"/reception/manage-billing/billing-summery"}>Billing Summery</Link>
                                    </li>
                                    <li class="breadcrumb-item" aria-current="page">Billing Statement</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section class="content">
                <div class="box">
                    <div class="box-header with-border">
                        <div class="box-in">
                            <button onClick={() => navigate("/reception/manage-billing/billing-summery")} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">
                                <strong>Billing Statement</strong>
                            </h4>
                            <div class="box-right">
                                <div class="row justify-content-end">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="table-top-search">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Search by</option>
                                                <option value="batch_no">Batch No.</option>
                                            </select>
                                            <input type="search" class="form-control" placeholder="Search" aria-controls="complex_header" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {currentStatement.isError && <LoadingErrorComponent error />}
                    {currentStatement.isLoading && <LoadingErrorComponent loading />}
                    {currentStatement.isSuccess && (
                        <>
                            <div class="box-body">
                                {/* {BatchStatementData.isSuccess && (
                                    BatchStatementData?.data?.data && (
                                        <div class="mb-4">
                                            <p class="mb-1">
                                                <span class="fw-bold me-2">Statement -</span> Total {BatchStatementData?.data?.data?.noOfClientsInBatch} records
                                                <span class="fw-bold text-success ms-2">{BatchStatementData?.data?.data?.validClients} valid</span>
                                                <span class="fw-bold text-danger ms-2">{BatchStatementData?.data?.data?.invalidClients} invalid</span>
                                            </p>
                                        </div>
                                    )
                                )} */}
                                <div class="table-responsive">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Sr.</th>
                                                <th>Batch No.</th>
                                                <th>Clients</th>
                                                <th>Statement Ref. No.</th>
                                                <th>QA 1</th>
                                                <th>QA 2</th>
                                                <th>Total Cost</th>
                                                {/* <th>Action Items</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                currentStatement?.data?.data?.map((el, i) => (
                                                    <tr class="hover-primary" key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{el.batchNo}</td>
                                                        <td>{el.noOfClientsInBatch > 1 ? `${el.noOfClientsInBatch} Clients` : `${el.noOfClientsInBatch} Client`} </td>
                                                        <td>{el.stateRef}</td>
                                                        <td>
                                                            {el.qA1.toLowerCase() === "complete" && (
                                                                <span class="fs-14 badge badge-success-light">
                                                                    <i class="fa fa-check"></i>
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {el.qA2.toLowerCase() === "complete" && (
                                                                <span class="fs-14 badge badge-success-light">
                                                                    <i class="fa fa-check"></i>
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td className='text-center'> {el.totalBatchCost}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className='d-flex align-items-center justify-content-between mt-2'>
                                    {
                                        <div>
                                            {`Showing ${page - 1}1 to ${paginationDetails.TotalCount > page * 10 ? page * 10 : paginationDetails.TotalCount}  of ${paginationDetails.TotalCount} entries`}
                                        </div>
                                    }
                                    <div>
                                        <PaginationComp currentPage={paginationDetails.CurrentPage} totalPages={paginationDetails.TotalPages} onPageChange={changePage} />
                                    </div>
                                </div> */}

                                <div class="text-center mt-2">
                                    <Link to={`/reception/manage-billing/billing-summery/statement-to-process/${currentStatement?.data?.data[0]?.stateRef}/uploadInvoice`} class="btn btn-primary">Upload Invoice</Link>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>
        </ReceptionLayout>
    )
}

export default BillingStatement
