import React, { useState } from 'react'
import { Dropdown, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUserStatusChangeMutation } from '../../store/api/userManagement/manageUserApi';
import toast from 'react-hot-toast';

const SystemUserTableRow = ({ data, index }) => {
    // console.log(data)
    const [statusChange] = useUserStatusChangeMutation()
    const changeToPending = (id) => {
        // console.log(id)
        let data = {
            user_Id: id,
            status: "pending"
        }
        statusChange(data).then((res) => {
            console.log(res)
            if (res?.data?.status === 1) {
                toast.error("User Deactivated !!", { duration: 7000 })
            }
        })
    }
    const changeToActive = (id) => {
        // console.log(id)
        let data = {
            user_Id: id,
            status: "active"
        }
        statusChange(data).then((res) => {
            console.log(res)
            if (res?.data?.status === 1) {
                toast.success("User Activated !!", { duration: 7000 })
            }
        })
    }
    const [activePendingModal, setActivePendingModal] = useState(false)
    return (
        <>
            <tr className="hover-primary">
                <td>{index + 1}</td>
                <td>{data.firstname ? data.firstname : ""} {data.last_Name}</td>
                <td>{data.user_Id ? data.user_Id : ""}</td>
                {/* <td>RTC0001</td> */}
                <td>{data.postRole ? data.postRole : ""}</td>
                <td>
                    <span className={data?.user_Status?.toLowerCase() === "pending" ? 'badge badge-danger-light' : 'badge badge-success-light'}>{data?.user_Status?.toLowerCase() === "pending" ? "Pending" : "Active"}</span>
                </td>
                <td>
                    <div className="btn-group">
                        <Dropdown>
                            <Dropdown.Toggle variant="Secondary" >
                                <i className="fa fa-ellipsis-h"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to={`/user-management/manage-user/view-and-edit/${data.user_Id}`}>View & Edit</Dropdown.Item>
                                <Dropdown.Item>
                                    {(data.user_Status === "pending" || data.user_Status === "deactivated") ?
                                        (<span onClick={() => setActivePendingModal(true)}>
                                            Activate User
                                        </span>) :
                                        (<span onClick={() => setActivePendingModal(true)}>
                                            Deactivate User
                                        </span>)
                                    }
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </td>
            </tr>
            {/* Active Pending modal */}
            <Modal show={activePendingModal} size="sm" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setActivePendingModal(false)}>
                <Modal.Body style={{ display: "block" }}>
                    <div className="text-center p-3">
                        <h4 className="fw-bold mb-3">
                            Are you sure want to {(data.user_Status === "pending" || data.user_Status === "deactivated") ? "activate" : "deactivate"} this user?
                        </h4>
                        <div className="d-flex gap-2 justify-content-center">
                            {(data.user_Status === "pending" || data.user_Status === "deactivated") ?
                                (<Button variant="primary" onClick={() => { changeToActive(data.user_Id); setActivePendingModal(false) }}>
                                    Activate
                                </Button>) :
                                (<Button variant="primary" onClick={() => { changeToPending(data.user_Id); setActivePendingModal(false) }}>
                                    Deactivate
                                </Button>)
                            }
                            <Button variant="secondary" onClick={() => setActivePendingModal(false)}>
                                No
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SystemUserTableRow;