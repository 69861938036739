import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import BulkTableRow from './BulkTableRow';
import ReceptionLayout from '../../ReceptionLayout'
import LoadingErrorComponent from '../../../../components/LoadingErrorComponent/LoadingErrorComponent';
import { useGetBulkClientsQuery } from '../../../../store/api/reception/followupApi';
import PaginationComp from '../../../../components/Pagination/paginationComp';
import { saveAs } from "file-saver";
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const BulkDataUpload = () => {
  const { user } = useSelector((store) => {
    return store.userSlice;
  });
  const [paginationDetails, setPaginationDetails] = useState({})
  const [files, setFiles] = useState([]);
  const [showFetchButton, setShowFetchButton] = useState(false);
  const [clickRetriveButton, setClickRetriveButton] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [mobiliser, setMobiliser] = useState(searchParams.get("sort_by_mobiliser") || "");
  const [sort_by, setSortBy] = useState(searchParams.get("sort_by") || "");
  const [searchBy, setSearchBy] = useState(searchParams.get("searchBy") || "");
  const [searchKeyword, setSearchKeyword] = useState(
    searchParams.get("searchKeyword") || ""
  );
  const [page, setPageNo] = useState(searchParams.get("page") || 1);
  const bulkClientList = useGetBulkClientsQuery({
    sort_by: sort_by,
    searchKeyword: searchKeyword,
    page: page,
    per_page: 10,
    sort_by_mobiliser: mobiliser,
    searchBy: searchBy,
  });

  // FUNCTIONS TO HANDLE DATA SEARCH AND FILTER
  const changePage = (page) => {
    setPageNo(page);
  };
  const handleSortByMobiliser = (elem) => {
    setMobiliser(elem.target.value);
  };
  const handleSortBy = (elem) => {
    setSortBy(elem.target.value);
  };
  const handleSearchBy = (elem) => {
    setSearchBy(elem.target.value);
  };
  const handleSearchKeyword = (elem) => {
    setSearchKeyword(elem.target.value);
  };

  useEffect(() => {
    let params = { page };
    if (clickRetriveButton) {
      if (mobiliser) {
        params.sort_by_mobiliser = mobiliser;
      }
      if (sort_by) {
        params.sort_by = sort_by;
      }
      if (searchBy) {
        params.searchBy = searchBy;
      }
      if (searchKeyword) {
        params.searchKeyword = searchKeyword;
      }
      setSearchParams(params);
    }
  }, [page, mobiliser, sort_by, searchBy, searchKeyword, setSearchParams, clickRetriveButton]);


  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const triggerFileUpload = () => {
    document.getElementById('fileInput').click()
    // Set a timeout to call the function after 10 seconds
    setTimeout(() => {
      setShowFetchButton(true)
    }, 5000);
    // 10000 milliseconds = 10 seconds
  }

  const paginationDataString = bulkClientList?.data?.message.split(": ")[1];
  useEffect(() => {
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString?.replace(/=/g, ':').replace(/(\w+)/g, '"$1"'));
      setPaginationDetails({
        CurrentPage: Math.ceil(paginationData.CurrentPage),
        ItemsPerPage: Math.ceil(paginationData.ItemsPerPage),
        TotalCount: Math.ceil(paginationData.TotalCount),
        TotalPages: Math.ceil(paginationData.TotalPages),
      })
    }
  }, [bulkClientList?.data?.message])


  //download list of clients in excel 
  const [DownloadXLState, setDownloadXLState] = useState(false)
  const downloadDataAsExcel = async () => {
    setDownloadXLState(true)
    const queryString = new URLSearchParams({
      page: 1,
      per_page: paginationDetails.TotalCount,
    }).toString();

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/Client/GetClients?${queryString}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      return response.json()
    }).then((response) => {
      const userXLS = response?.data?.map((elem, i) => {
        return {
          "sr_no": i + 1,
          "clientID": elem.clientID,
          "barcode_cdc_number": elem.barcode_cdc_number,
          "barcode_cdf_number": elem.barcode_cdf_number,
          "capture_date": elem.capture_date,
          "folder_number": elem.folder_number,
          "Fullname": elem.firstName,
          "dateOfBirth": elem.dateOfBirth,
          "gender": elem.gender,
          "nationality": elem.nationality,
          "identityVerification": elem.identityVerification,
          "identityVerificationNumber": elem.identityVerificationNumber,
          "email": elem.email,
          "contactNumber": elem.contactNumber,
          "nextOfKinContactNumber": elem.nextOfKinContactNumber,
          "relationshipWithClient": elem.relationshipWithClient,
          "booking_id": elem.booking_id,
          "social_mobilizer": elem.social_mobilizer,
          "status": elem.status
        }
      })
      if (userXLS.length > 0) {
        // Convert data to CSV format
        const csvData = convertToCSV(userXLS);
        // Create a Blob containing the CSV data
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        // Trigger download
        saveAs(blob, `followup_bulk_client_list.csv`);
        setDownloadXLState(false)
      } else {
        toast.error('No record found to export', { duration: 7000 });
      }
    }).catch(err => {
      console.log(err)
      setDownloadXLState(false)
      toast.error('Unable to download excel file, please try again', { duration: 7000 });
    })
  };

  const convertToCSV = (dataArray) => {
    // Convert array of objects to CSV string
    const header = Object.keys(dataArray[0]).join(",") + "\n";
    const body = dataArray?.map((obj) => Object.values(obj).join(",")).join("\n");
    return header + body;
  };
  const navigate = useNavigate()
  return (
    <ReceptionLayout>
      <div className="container-full">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="d-flex align-items-center">
            <div className="me-auto">
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/"><i className="mdi mdi-home-outline"></i></Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">Follow-up Form</li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Bulk Upload
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="box">
            <div className="box-header with-border">
              <div className="box-in">
                {user.role === "Super User/Admin" ?
                  <button onClick={() => navigate("/dashboard")} className='btn btn-danger-light'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-left"
                    >
                      <line x1={19} y1={12} x2={5} y2={12} />
                      <polyline points="12 19 5 12 12 5" />
                    </svg>
                  </button> :
                  <button onClick={() => navigate("/reception/dashboard")} className='btn btn-danger-light'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-left"
                    >
                      <line x1={19} y1={12} x2={5} y2={12} />
                      <polyline points="12 19 5 12 12 5" />
                    </svg>
                  </button>
                }
                <h4 className="box-title me-auto ms-4">
                  <strong>Bulk Upload</strong>
                </h4>
                <div className="box-right">
                  {bulkClientList.isSuccess && clickRetriveButton && (
                    <button
                      type="button"
                      className="btn btn-primary btn-export"
                      onClick={downloadDataAsExcel}
                      disabled={DownloadXLState}
                    >
                      Export to XLS
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="box-body">
              {
                (clickRetriveButton === false) ? (
                  <>
                    <div id="uploadAreaContainer" className="mb-20" >
                      <div className="folder d-flex justify-content-center text-center flex-column mb-30">
                        <>
                          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 347.479 347.479" style={{ height: '100px' }} onClick={triggerFileUpload}>
                            <g>
                              <path style={{ fill: '#E0B03B' }} d="M292.251,79.766H103.644v-8.544c0-5.974-4.888-10.862-10.862-10.862H30.414
                                c-5.975,0-10.862,4.888-10.862,10.862v8.544h-3.258C7.332,79.766,0,87.098,0,96.059v174.766c0,8.961,7.332,16.293,16.293,16.293
                                h275.958c8.961,0,16.293-7.332,16.293-16.293V96.059C308.545,87.098,301.213,79.766,292.251,79.766z"/>
                              <rect x="23.243" y="95.385" style={{ fill: '#FFFFFF' }} width="262.059" height="176.113" />
                              <path style={{ fill: '#FFC843' }} d="M312.426,271.293c-2.135,8.704-11.213,15.825-20.175,15.825H16.293
                                c-8.961,0-14.547-7.121-12.412-15.825l34.598-141.05c2.135-8.704,11.213-15.825,20.175-15.825h275.958
                                c8.961,0,14.547,7.121,12.412,15.825L312.426,271.293z"/>
                            </g>
                          </svg>
                          <h5 className='mb-0 fw-bold'>Upload New Records: Select a folder</h5>
                          <input id="fileInput" type="file" accept="application/pdf,application/vnd.ms-excel" multiple hidden onChange={handleFileChange} />
                        </>
                      </div>
                      <div id="uploadAreaContainer" className="d-flex justify-content-center">
                        <div className="folder d-flex justify-content-center text-center flex-column">
                          <button className='btn btn-primary' onClick={() => setClickRetriveButton(true)}>
                            Retrieve Scanned Records
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    {
                      bulkClientList?.data?.data?.length > 0 ? (
                        <div className="row mb-3 gx-2 justify-content-between">
                          <div className="col-sm-12 col-md-6 col-xl-3">
                            <select
                              className="form-select mb-xl-0 mb-2"
                              aria-label="sort_by"
                              value={sort_by}
                              onChange={handleSortBy}
                            >
                              <option value="">Sort by</option>
                              <option value="first_to_last">First to Last</option>
                              <option value="last_to_first"> Last to First</option>
                            </select>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div className="table-top-search">
                              <select
                                className="form-select"
                                aria-label="search by"
                                onChange={handleSearchBy}
                                value={searchBy}
                              >
                                <option value="">Search by</option>
                                <option value="cdcc_barcode">Barcode CDCC No</option>
                                <option value="cdcf_barcode">Barcode CDCF No</option>
                                <option value="folder_number">Folder number</option>
                                <option value="client_name">Client Name</option>
                              </select>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                aria-controls="search"
                                value={searchKeyword}
                                onChange={handleSearchKeyword}
                              />
                            </div>
                          </div>
                        </div>
                      ) : ""
                    }
                    {bulkClientList.isError && (
                      <LoadingErrorComponent error />
                    )}
                    {bulkClientList.isLoading && (
                      <LoadingErrorComponent loading />
                    )}
                    {bulkClientList.isSuccess && (
                      <>
                        <div className="row">
                          {bulkClientList?.data?.data?.length > 0 ? (
                            <div className="col-12">
                              <div className="table-responsive rounded card-table">
                                {
                                  <table className="table border-no" id="example1" style={{ width: "100%" }}>
                                    <thead>
                                      <tr>
                                        <th>Sr.</th>
                                        <th>CDC Number</th>
                                        <th>CDCF Number</th>
                                        <th>Capture Date</th>
                                        {/* <th>Client ID</th> */}
                                        <th>Folder number</th>
                                        <th>Client Name</th>
                                        {/* <th>Status</th> */}
                                        <th>Action Items</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        bulkClientList?.data?.data?.map((elem, i) => {
                                          return (
                                            <BulkTableRow
                                              key={i}
                                              data={elem}
                                              serialNumber={i}
                                            />
                                          );
                                        })
                                      }
                                    </tbody>
                                  </table>
                                }
                              </div>
                              <div className='d-flex align-items-center justify-content-between mt-2'>
                                {
                                  <div>
                                    {`Showing ${page - 1}1 to ${paginationDetails.TotalCount > page * 10 ? page * 10 : paginationDetails.TotalCount}  of ${paginationDetails.TotalCount} entries`}
                                  </div>
                                }
                                <div>
                                  <PaginationComp currentPage={paginationDetails.CurrentPage} totalPages={paginationDetails.TotalPages} onPageChange={changePage} />
                                </div>
                              </div>
                            </div>
                          ) :
                            <div>No Data Found</div>
                          }

                        </div>
                      </>
                    )}
                  </div>
                )
              }
            </div>
          </div>
        </section>
      </div>
    </ReceptionLayout>
  )
}

export default BulkDataUpload