import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { useDeleteClientMutation } from "../../../../store/api/reception/manageClient";
import toast from 'react-hot-toast'
import { FaRegEye } from "react-icons/fa";

const BulkTableRow = ({ serialNumber, data }) => {
  const [show, setShow] = useState(false);
  const [clientDelete] = useDeleteClientMutation()
  let deleteClient = (clientID) => {
    clientDelete(clientID?.toString()).then((res) => {
      if (res?.data?.status === 1) {
        toast.success(res?.data?.message, { duration: 7000 })
      } else {
        toast.error(res?.data?.message, { duration: 7000 })
      }
    })
  }

  return (
    <tr className="hover-primary">
      <td>{serialNumber + 1}</td>
      <td>{data.barcode_cdc_number}</td>
      <td>{data.barcode_cdf_number}</td>
      <td className="name">{data.capture_date}</td>
      {/* <td>{data.clientID}</td> */}
      <td>{data.folder_number}</td>
      <td>
        <button
          type="button"
          className="btn btn-user"
          data-bs-toggle="modal"
          data-bs-target="#modal-right"
          onClick={() => setShow(true)}
        >
          <FaRegEye className="me-2" />
          {data?.firstName} {data?.surname}
        </button>
        <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
          <Offcanvas.Header closeButton className="bg-light">
            <Offcanvas.Title className="mb-0 fw-bold">
              User Details
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="user-ul ps-0">
              <li>
                <strong>Date of Birth</strong> <p>{data?.dateOfBirth}</p>
              </li>
              <li>
                <strong>Contact Number</strong> <p>{data?.contactNumber}</p>
              </li>
              <li>
                <strong>Nationality</strong> <p>{data?.nationality}</p>
              </li>
              <li>
                <strong>Verification ID</strong> <p>{data?.identityVerification}</p>
              </li>
              <li>
                <strong>Social Mobiliser</strong>{" "}
                <p>
                  <a href="#">{data?.social_mobilizer}</a>
                </p>
              </li>
              <li>
                <strong>Email</strong> <p>{data?.email}</p>
              </li>
              <li>
                <strong>Gender</strong> <p>{data?.gender}</p>
              </li>
              <li>
                <strong>Next of Kin Number</strong>{" "}
                <p>{data?.nextOfKinContactNumber}</p>
              </li>
              <li>
                <strong>Relationship with Client</strong>{" "}
                <p>{data?.relationshipWithClient}</p>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </td>
      {/* <td>
        {data.status && <span className="badge badge-danger-light">{data.status}</span>}
      </td> */}
      <td>
        <div className="btn-group">
          <Dropdown>
            <Dropdown.Toggle variant="Secondary" >
              <i className="fa fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={`/reception/follow-up/followup-form/${data.barcode_cdf_number}`}>
                Edit Client
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={`/reception/follow-up/schedule-lookup/fourtyeight-hours/total/view-client/${data.clientID}`}>View Client</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </td>
    </tr>
  );
};

export default BulkTableRow;
