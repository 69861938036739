export default function processPassword(password, type) {
    const shift = 3; // Caesar cipher shift
    let processedPassword = '';
    let shiftAmount = type === 'encrypt' ? shift : -shift;

    for (let i = 0; i < password?.length; i++) {
        let charCode = password?.charCodeAt(i);
        // Process only alphanumeric characters
        if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || (charCode >= 48 && charCode <= 57)) {
            charCode += shiftAmount;
            // Wrap around for letters
            if (type === 'encrypt') {
                if ((charCode > 90 && charCode <= 90 + shift) || (charCode > 122 && charCode <= 122 + shift)) {
                    charCode -= 26;
                }
                if (charCode > 57 && charCode <= 57 + shift) {
                    charCode -= 10;
                }
            } else if (type === 'decrypt') {
                if ((charCode < 65 && charCode >= 65 - shift) || (charCode < 97 && charCode >= 97 - shift)) {
                    charCode += 26;
                }
                if (charCode < 48 && charCode >= 48 - shift) {
                    charCode += 10;
                }
            }
        }
        processedPassword += String.fromCharCode(charCode);
    }

    return processedPassword;
}
