import React from 'react'
import Error500Img from '../../assets/images/auth-bg/500.png'
import SomthingWentWrongImg from '../../assets/images/auth-bg/404.png'
import loadingImg from '../../assets/images/preloaders/1.gif'
const LoadingErrorComponent = ({loading, error}) => {
    return (
        <div className="LoadingOrError">
            {/* ERROR STATE WITH ERROR TYPE */}
            {
                error && (
                    error.code === 500 ? (
                        <img src={Error500Img} alt="500" /> 
                    ) : (
                        <img src={SomthingWentWrongImg} alt="SomthingWentWrongImg" /> 
                    )
                )
            }
            {/* LOADING STATE */}
            {
                loading && (
                    <img src={loadingImg} alt="" className='loadingImg' /> 
                )
            }
        </div>
    )
}

export default LoadingErrorComponent