import React from 'react';
import { Link } from 'react-router-dom';

const PaginationComp = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
        const delta = 2;
        const range = [];
        for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
            range.push(i);
        }
        if (currentPage - delta > 2) {
            range.unshift('...');
        }
        if (currentPage + delta < totalPages - 1) {
            range.push('...');
        }
        range.unshift(1);
        range.push(totalPages);
        return Array.from(new Set(range));
    };

    const handlePageChange = (page) => {
        if (page === '...') return;
        onPageChange(page);
    };

    return (
        <div className='dataTables_paginate paging_simple_numbers'>
            <ul className="pagination" role="navigation" aria-label="Pagination">
                <li className="previous">
                    <button className="page-link" tabIndex="-1" aria-disabled="true" aria-label="Previous page" rel="prev" onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1 ? true : false}
                    >
                        Prev
                    </button>
                </li>
                {getPageNumbers().map((page, index) => (
                    <li className={page === currentPage ? 'active' : ''} key={index} >
                        <Link rel="canonical" role="button" tabIndex="-1" aria-label="Page 1 is your current page" aria-current="page" onClick={() => handlePageChange(page)} className={page === currentPage ? 'pagActive' : ''}>
                            {page}
                        </Link>
                    </li>
                ))}
                <li className="next" >
                    <button className="page-link" tabIndex="0" aria-disabled="false" aria-label="Next page" rel="next" onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default PaginationComp;