import { DatePicker } from "antd";
import React, { useReducer, useState, useEffect } from "react";
import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useGetQAReasonQuery, useQaOneVerifyRecordMutation } from "../../../../store/api/qa_one/QAOneApi";
import { useQaTwoVerifyRecordMutation } from "../../../../store/api/qa_two/QATwoApi";
import toast from "react-hot-toast";
import ViewImageModal from "../../../../components/modal/ViewImageModal";
import { getNextDate } from "../../../../methods/getNextDate";
import { formatDate } from "../../../../methods/convertDateFormat";
import { useGetAeTypeQuery, useGetDropDownQuery } from "../../../../store/api/reception/manageClient";
import validateSAID from "../../../../methods/validateSAID";
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const ViewQAClient = ({ user, QaType, data }) => {

    const [accordionActiveKey, setAccordionActiveKey] = useState("0");
    const navigate = useNavigate();
    const params = useParams();
    let singleData = data

    const initialState = {
        formDetails: {
            serviceDeliveryPartner: "",
            facility: "",
            barcodeCDCNumber: "",
            procedureDate: "",
            vmmcServiceDeliverySetting: "",
            folderNo: ""
        },
        patientDetails: {
            clientID: 0,
            active: 0,
            firstName: "",
            surname: "",
            dateOfBirth: "",
            nationality: "",
            identityVerification: "",
            email: "",
            contactNumber: "",
            gender: "",
            nextOfKinContactNumber: "",
            relationshipWithClient: "",
            countryOfOrigin: "",
            identityVerificationNumber: ""
        },
        medicalServicesReceived: {
            medicalService: "",
            hivTesting: "",
            hivStatusTestResult: "",
            positiveCheckbox: ""
        },
        voluntaryMaleMedicalCircumcision: {
            clientName: ""
        },
        consent: {
            medicalProcedures: [],
            paitientSignature: "",
            consentFormAttachment: false,
            guardianParentSignature: "",
            guardianParentNameAndSurname: "",
            counsellorNameAndSurname: "",
            parentSignatureAttachment: false,
            counsellorSignature: ""
        },
        procedure: {
            surgeonName: "",
            surgeonSignature: "",
            surgeonHPCSA_SANCNo: "",
            surgicalMethod: "",
            deviceOrAidName: "",
            procedureDone: ""
        },
        marketingSurveys: {
            smNo: "",
            recruiterName: "",
            reasonForCircumcised: [],
            wherePatientHear: ""
        },
        intraOperativeObservations: {
            aeSeverity: "",
            aeType: [],
            notesOnAe: "",
            observation: ""
        }
    }

    const addClientReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_STATE":
                return { ...payload };
            case "SET_FORM_DETAILS":
                return {
                    ...state,
                    formDetails: {
                        ...state.formDetails,
                        [payload.name]: payload.data,
                    },
                };
            case "SET_PATIENT_DETAILS":
                return {
                    ...state,
                    patientDetails: {
                        ...state.patientDetails,
                        [payload.name]: payload.data,
                    },
                };
            case "SET_MEDICAL_SERVICE":
                return {
                    ...state,
                    medicalServicesReceived: {
                        ...state.medicalServicesReceived,
                        [payload.name]: payload.data,
                    },
                };
            case "SET_VOLUNTARY_MALE":
                return {
                    ...state,
                    voluntaryMaleMedicalCircumcision: {
                        ...state.voluntaryMaleMedicalCircumcision,
                        [payload.name]: payload.data,
                    },
                };
            case "SET_CONSENT":
                return {
                    ...state,
                    consent: {
                        ...state.consent,
                        [payload.name]: payload.data,
                    },
                };
            case "SET_PROCEDURE":
                return {
                    ...state,
                    procedure: {
                        ...state.procedure,
                        [payload.name]: payload.data,
                    },
                };
            case "SET_MARKETING":
                return {
                    ...state,
                    marketingSurveys: {
                        ...state.marketingSurveys,
                        [payload.name]: payload.data,
                    },
                };
            case "SET_INTRA":
                return {
                    ...state,
                    intraOperativeObservations: {
                        ...state.intraOperativeObservations,
                        [payload.name]: payload.data,
                    },
                };
            default:
                return state;
        }
    };

    const [formStates, dispatch] = useReducer(addClientReducer, initialState);

    // attachment
    const [idImageModal, setIdImageModal] = useState(false)
    const [base64String, setBase64String] = useState('');
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file?.size < 2621440) {
            const reader = new FileReader();
            if (event.target.files[0].type.includes("/pdf")) {
                reader.onloadend = () => {
                    const base64String = reader.result.split(",")[1]; // Get base64 string excluding the data URL prefix
                    setBase64String("data:application/pdf;base64," + base64String);
                };
            } else if (event.target.files[0].type.includes("image/")) {
                reader.onload = () => {
                    const base64 = reader.result;
                    setBase64String(base64);
                };
            }
            reader.readAsDataURL(file);
        } else {
            toast.error("File too large. Max size: 2.5 MB. Please upload a smaller file.")
        }
    };
    const [otherAttachmentModal, setOtherAttachmentModal] = useState(false)
    const [base64StringOthers, setBase64StringOthers] = useState('');
    const handleImageUploadOthers = (event) => {
        const file = event.target.files[0];
        if (file?.size < 2621440) {
            const reader = new FileReader();
            if (event.target.files[0].type.includes("/pdf")) {
                reader.onloadend = () => {
                    const base64String = reader.result.split(",")[1]; // Get base64 string excluding the data URL prefix
                    setBase64StringOthers("data:application/pdf;base64," + base64String);
                };
            } else if (event.target.files[0].type.includes("image/")) {
                reader.onload = () => {
                    const base64 = reader.result;
                    setBase64StringOthers(base64);
                };
            }
            reader.readAsDataURL(file);
        } else {
            toast.error("File too large. Max size: 2.5 MB. Please upload a smaller file.")
        }
    };
    const [secondaryAttachmentModal, setSecondaryAttachmentModal] = useState(false)
    const [base64StringSecondary, setBase64StringSecondary] = useState('');
    const handleImageUploadSecondary = (event) => {
        const file = event.target.files[0];
        if (file?.size < 2621440) {
            const reader = new FileReader();
            if (event.target.files[0].type.includes("/pdf")) {
                reader.onloadend = () => {
                    const base64String = reader.result.split(",")[1]; // Get base64 string excluding the data URL prefix
                    setBase64StringSecondary("data:application/pdf;base64," + base64String);
                };
            } else if (event.target.files[0].type.includes("image/")) {
                reader.onload = () => {
                    const base64 = reader.result;
                    setBase64StringSecondary(base64);
                };
            }
            reader.readAsDataURL(file);
        } else {
            toast.error("File too large. Max size: 2.5 MB. Please upload a smaller file.")
        }
    };
    const [pdfFile, setPdfFile] = useState("")
    const [consentModal, setConsentModal] = useState(false)
    const handlePdfFileChange = (event) => {
        const file = event.target.files[0];
        if (file?.size < 2621440) {
            const reader = new FileReader();
            if (event.target.files[0].type.includes("/pdf")) {
                reader.onloadend = () => {
                    const base64String = reader.result.split(",")[1]; // Get base64 string excluding the data URL prefix
                    setPdfFile("data:application/pdf;base64," + base64String);
                };
            } else if (event.target.files[0].type.includes("image/")) {
                reader.onload = () => {
                    const base64 = reader.result;
                    setPdfFile(base64);
                };
            }
            reader.readAsDataURL(file);
        } else {
            toast.error("File too large. Max size: 2.5 MB. Please upload a smaller file.")
        }
    };
    const [attachmentValidate, setAttachmentValidate] = useState(false)
    const [validated_attachment, setValidated_attachment] = useState(false)
    const submitAttachment = () => {
        if (age > 17 && base64String && pdfFile) {
            setAttachmentValidate(true)
            setAccordionActiveKey("1")
        } else if ((age >= 10 && age <= 17) && base64String && base64StringOthers && pdfFile) {
            setAttachmentValidate(true)
            setAccordionActiveKey("1")
        } else {
            setAttachmentValidate(false)
        }
        setValidated_attachment(true)
    }

    // form details
    const getDropDown = useGetDropDownQuery();
    const handleChangeFormDetails = (e) => {
        dispatch({
            type: "SET_FORM_DETAILS",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };
    const handleChangeProcedureDate = (a, date) => {
        dispatch({
            type: "SET_FORM_DETAILS",
            payload: {
                name: "procedureDate",
                data: date,
            },
        });
    }
    const [formDetailsValidate, setFormDetailsValidate] = useState(false)
    const [validated_formDetails, setValidated_formDetails] = useState(false)
    const submitFormDetails = (e) => {
        e.preventDefault()
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === true && formStates.formDetails.procedureDate) {
            setFormDetailsValidate(true)
            setAccordionActiveKey("2")
        } else {
            setFormDetailsValidate(false)
        }
        setValidated_formDetails(true);
    }

    // PATIENT DETAILS
    const handleChangePatientdetails = (e) => {
        dispatch({
            type: "SET_PATIENT_DETAILS",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
        // to empty fields when gender is female 
        if (e.target.value === "female") {
            // invalidate voluntary male medical circumcision and remove value
            setVoluntaryMaleValidate(false)
            dispatch({
                type: "SET_VOLUNTARY_MALE",
                payload: {
                    name: "clientName",
                    data: "",
                },
            });
            // in consent remove medical procedure
            setConsentValidate(false)
            dispatch({
                type: "SET_CONSENT",
                payload: {
                    name: "medicalProcedures",
                    data: [],
                },
            });
            // in medical service received
            setMedicalServiceValidate(false)
            dispatch({
                type: "SET_MEDICAL_SERVICE",
                payload: {
                    name: "hivTesting",
                    data: "",
                },
            });
            // in intra-operative observation
            setIntraValidate(false)
            dispatch({
                type: "SET_INTRA",
                payload: {
                    name: "aeSeverity",
                    data: "",
                },
            });
            dispatch({
                type: "SET_INTRA",
                payload: {
                    name: "aeType",
                    data: [],
                },
            });
            dispatch({
                type: "SET_INTRA",
                payload: {
                    name: "notesOnAe",
                    data: "",
                },
            });
            dispatch({
                type: "SET_INTRA",
                payload: {
                    name: "observation",
                    data: "",
                },
            });
            // in marketing survey
            setMarketingValidate(false)
            dispatch({
                type: "SET_MARKETING",
                payload: {
                    name: "wherePatientHear",
                    data: "",
                },
            });
            dispatch({
                type: "SET_MARKETING",
                payload: {
                    name: "smNo",
                    data: "",
                },
            });
            dispatch({
                type: "SET_MARKETING",
                payload: {
                    name: "recruiterName",
                    data: "",
                },
            });
            dispatch({
                type: "SET_MARKETING",
                payload: {
                    name: "reasonForCircumcised",
                    data: [],
                },
            });
            // in procedure
            setProcedureValidate(false)
            dispatch({
                type: "SET_PROCEDURE",
                payload: {
                    name: "procedureDone",
                    data: "",
                },
            });
            dispatch({
                type: "SET_PROCEDURE",
                payload: {
                    name: "surgeonName",
                    data: "",
                },
            });
            dispatch({
                type: "SET_PROCEDURE",
                payload: {
                    name: "surgeonSignature",
                    data: "",
                },
            });
            dispatch({
                type: "SET_PROCEDURE",
                payload: {
                    name: "surgeonHPCSA_SANCNo",
                    data: "",
                },
            });
            dispatch({
                type: "SET_PROCEDURE",
                payload: {
                    name: "surgicalMethod",
                    data: "",
                },
            });
            dispatch({
                type: "SET_PROCEDURE",
                payload: {
                    name: "deviceOrAidName",
                    data: "",
                },
            });
        }
    };
    const handleChangeDob = (a, date) => {
        dispatch({
            type: "SET_PATIENT_DETAILS",
            payload: {
                name: "dateOfBirth",
                data: date,
            },
        });

        // calculate proper age
        let NextDate = getNextDate(date)
        let formattedDate = formatDate(NextDate)
        const age = new Date(new Date() - new Date(formattedDate)).getFullYear() - 1970;

        if (isNaN(age)) {
            setAge("");
        } else {
            setAge(age);
        }
        if (age > 14) {
            setDateValid(true);
        } else {
            setDateValid(false);
        }
    }
    const handleNationalityChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === "south_african") {
            dispatch({
                type: "SET_PATIENT_DETAILS",
                payload: {
                    name: 'countryOfOrigin',
                    data: 'south_africa',
                },
            });
        }
        dispatch({
            type: "SET_PATIENT_DETAILS",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };
    const handleContactNumber = (num) => {
        dispatch({
            type: "SET_PATIENT_DETAILS",
            payload: {
                name: "contactNumber",
                data: num,
            },
        });
    }
    const handleNextKinOfContact = (num) => {
        dispatch({
            type: "SET_PATIENT_DETAILS",
            payload: {
                name: "nextOfKinContactNumber",
                data: num,
            },
        });
    }
    const handleChangeIdNumber = (e) => {
        let details = validateSAID(e.target.value);
        setClientDetailsId(details);
        dispatch({
            type: "SET_PATIENT_DETAILS",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };
    const [patientDetailsValidate, setPatientDetailsValidate] = useState(false)
    const [validated_patient, setValidated_patient] = useState(false)
    const [clientDetailsId, setClientDetailsId] = useState({})
    const [age, setAge] = useState("")
    const [dateValid, setDateValid] = useState("")
    const [adultAdviceModal, setAdultAdviceModal] = useState(false)
    const submitPatientDetails = (e) => {
        e.preventDefault()
        e.stopPropagation();
        const form = e.currentTarget;

        // when gender is male or female
        if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "south_african"
            && (formStates.patientDetails.identityVerification === "South African ID"
                || formStates.patientDetails.identityVerification === "Driving License"
                || formStates.patientDetails.identityVerification === "Birth Certificate"
                || formStates.patientDetails.identityVerification === "South African Passport")
            && clientDetailsId.isValid === true
            && formStates.patientDetails.gender === clientDetailsId.gender
            && clientDetailsId.dateOfBirth === formStates.patientDetails.dateOfBirth
            && formStates.patientDetails.gender !== "other"
            && (formStates.patientDetails.gender === "male" || formStates.patientDetails.gender === "female")
            && age > 17
            && contactNumberValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("3")
        }
        // when gender is other
        else if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "south_african"
            && (formStates.patientDetails.identityVerification === "South African ID"
                || formStates.patientDetails.identityVerification === "Driving License"
                || formStates.patientDetails.identityVerification === "Birth Certificate"
                || formStates.patientDetails.identityVerification === "South African Passport")
            && clientDetailsId.isValid === true
            && clientDetailsId.dateOfBirth === formStates.patientDetails.dateOfBirth
            && formStates.patientDetails.gender !== "male"
            && formStates.patientDetails.gender !== "female"
            && formStates.patientDetails.gender === "other"
            && age > 17
            && contactNumberValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("3")
        }
        // when gender is male or female
        else if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "south_african"
            && (formStates.patientDetails.identityVerification === "South African ID"
                || formStates.patientDetails.identityVerification === "Driving License"
                || formStates.patientDetails.identityVerification === "Birth Certificate"
                || formStates.patientDetails.identityVerification === "South African Passport")
            && clientDetailsId.isValid === true
            && formStates.patientDetails.gender === clientDetailsId.gender
            && clientDetailsId.dateOfBirth === formStates.patientDetails.dateOfBirth
            && (formStates.patientDetails.gender === "male" || formStates.patientDetails.gender === "female")
            && formStates.patientDetails.gender !== "other"
            && ((age >= 15 && age <= 17) || ((age >= 10 && age <= 17) && formStates.formDetails.serviceDeliveryPartner === "RT 35"))
            && nextKinOfContactValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("3")
        }
        // when gender is other
        else if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "south_african"
            && (formStates.patientDetails.identityVerification === "South African ID"
                || formStates.patientDetails.identityVerification === "Driving License"
                || formStates.patientDetails.identityVerification === "Birth Certificate"
                || formStates.patientDetails.identityVerification === "South African Passport")
            && clientDetailsId.isValid === true
            && clientDetailsId.dateOfBirth === formStates.patientDetails.dateOfBirth
            && formStates.patientDetails.gender !== "male"
            && formStates.patientDetails.gender !== "female"
            && formStates.patientDetails.gender === "other"
            && ((age >= 15 && age <= 17) || ((age >= 10 && age <= 17) && formStates.formDetails.serviceDeliveryPartner === "RT 35"))
            && nextKinOfContactValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("3")
        }
        // when gender is male or female
        else if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "south_african"
            && formStates.patientDetails.identityVerification === "South African Affidavit"
            && (formStates.patientDetails.gender === "male" || formStates.patientDetails.gender === "female")
            && formStates.patientDetails.gender !== "other"
            && age > 17
            && contactNumberValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("3")
        }
        // when gender is other
        else if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "south_african"
            && formStates.patientDetails.identityVerification === "South African Affidavit"
            && formStates.patientDetails.gender !== "male"
            && formStates.patientDetails.gender !== "female"
            && formStates.patientDetails.gender === "other"
            && age > 17
            && contactNumberValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("2")
        }
        // when gender is male or female
        else if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "south_african"
            && formStates.patientDetails.identityVerification === "South African Affidavit"
            && (formStates.patientDetails.gender === "male" || formStates.patientDetails.gender === "female")
            && formStates.patientDetails.gender !== "other"
            && ((age >= 15 && age <= 17) || ((age >= 10 && age <= 17) && formStates.formDetails.serviceDeliveryPartner === "RT 35"))
            && nextKinOfContactValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("3")
        }
        // when gender is other
        else if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "south_african"
            && formStates.patientDetails.identityVerification === "South African Affidavit"
            && formStates.patientDetails.gender !== "male"
            && formStates.patientDetails.gender !== "female"
            && formStates.patientDetails.gender === "other"
            && ((age >= 15 && age <= 17) || ((age >= 10 && age <= 17) && formStates.formDetails.serviceDeliveryPartner === "RT 35"))
            && nextKinOfContactValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("3")
        }
        // when gender is male or female
        else if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "non_south_african"
            && (formStates.patientDetails.gender === "male" || formStates.patientDetails.gender === "female")
            && formStates.patientDetails.gender !== "other"
            && age > 17
            && contactNumberValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("3")
        }
        // when gender is other
        else if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "non_south_african"
            && formStates.patientDetails.gender !== "male"
            && formStates.patientDetails.gender !== "female"
            && formStates.patientDetails.gender === "other"
            && age > 17
            && contactNumberValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("3")
        }
        // when gender is male or female
        else if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "non_south_african"
            && (formStates.patientDetails.gender === "male" || formStates.patientDetails.gender === "female")
            && formStates.patientDetails.gender !== "other"
            && ((age >= 15 && age <= 17) || ((age >= 10 && age <= 17) && formStates.formDetails.serviceDeliveryPartner === "RT 35"))
            && nextKinOfContactValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("3")
        }
        // when gender is other
        else if (form.checkValidity() === true
            && formStates.patientDetails.nationality === "non_south_african"
            && formStates.patientDetails.gender !== "male"
            && formStates.patientDetails.gender !== "female"
            && formStates.patientDetails.gender === "other"
            && ((age >= 15 && age <= 17) || ((age >= 10 && age <= 17) && formStates.formDetails.serviceDeliveryPartner === "RT 35"))
            && nextKinOfContactValid) {
            setPatientDetailsValidate(true)
            setAccordionActiveKey("3")
        }
        else {
            setPatientDetailsValidate(false)
        }
        setValidated_patient(true);
    }
    // PHONE NUMBER VALIDATION
    const [nextKinOfContactValid, setNextKinOfContactValid] = useState("")
    const [contactNumberValid, setContactNumberValid] = useState("")
    useEffect(() => {
        if (formStates.patientDetails.nextOfKinContactNumber) {
            setNextKinOfContactValid(isValidPhoneNumber(formStates.patientDetails.nextOfKinContactNumber?.toString()))
        }
        if (formStates.patientDetails.contactNumber) {
            setContactNumberValid(isValidPhoneNumber(formStates.patientDetails.contactNumber?.toString()))
        }
    }, [formStates.patientDetails.contactNumber, formStates.patientDetails.nextOfKinContactNumber])
    const PhoneInputElem = document.querySelectorAll('.PhoneInput')
    useEffect(() => {
        // PHONE NUMBER VALIDATION
        if (PhoneInputElem) {
            PhoneInputElem.forEach((elem) => {
                elem.querySelector('.PhoneInputInput').setAttribute('required', true)
            })
        }
    }, [PhoneInputElem])

    // hiv test
    const [hivTestValidate, setHivTestValidate] = useState(false)
    const submitHivTest = () => {
        setHivTestValidate(true)
        setAccordionActiveKey("4")
    }

    // medical service received
    const [medicalServiceValidate, setMedicalServiceValidate] = useState(false)
    const [validated_medicalService, setValidated_medicalService] = useState(false);
    const handleChangeMedicalService = (e) => {
        dispatch({
            type: "SET_MEDICAL_SERVICE",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };
    const handleChangeHivTestResult = (e) => {
        dispatch({
            type: "SET_MEDICAL_SERVICE",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
        dispatch({
            type: "SET_MEDICAL_SERVICE",
            payload: {
                name: "positiveCheckbox",
                data: "",
            },
        });
    };
    const handleMedicalServiceReceiveNo = (e) => {
        dispatch({
            type: "SET_MEDICAL_SERVICE",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
        dispatch({
            type: "SET_MEDICAL_SERVICE",
            payload: {
                name: "hivTesting",
                data: "",
            },
        });
        dispatch({
            type: "SET_MEDICAL_SERVICE",
            payload: {
                name: "hivStatusTestResult",
                data: "",
            },
        });
        dispatch({
            type: "SET_MEDICAL_SERVICE",
            payload: {
                name: "positiveCheckbox",
                data: "",
            },
        });
    }
    const submitMedicalService = (e) => {
        e.preventDefault()
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            setMedicalServiceValidate(true)
            setAccordionActiveKey("5")
        } else {
            setMedicalServiceValidate(false)
        }
        setValidated_medicalService(true)
    }

    // voluntary male medical circumcision
    const [voluntaryMaleValidate, setVoluntaryMaleValidate] = useState(false)
    const [validated_voluntaryMale, setValidated_voluntaryMale] = useState(false);
    const handleChangeVoluntaryMale = (e) => {
        dispatch({
            type: "SET_VOLUNTARY_MALE",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    }
    const submitVoluntaryMale = (e) => {
        e.preventDefault()
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            setVoluntaryMaleValidate(true)
            setAccordionActiveKey("6")
        } else if (formStates.patientDetails.gender === "female") {
            setVoluntaryMaleValidate(true)
            setAccordionActiveKey("6")
        } else {
            setVoluntaryMaleValidate(false)
        }
        setValidated_voluntaryMale(true)
    }

    // consent
    const [consentValidate, setConsentValidate] = useState(false)
    const [validated_consent, setValidated_consent] = useState(false);
    const handleChangeConsent = (e) => {
        dispatch({
            type: "SET_CONSENT",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    }
    const handleChangeConsentCheckBox = (e) => {
        dispatch({
            type: "SET_CONSENT",
            payload: {
                name: e.target.name,
                data: e.target.checked,
            },
        });
    }
    const handleChangeMedicalProcedure = (e) => {
        if (e.target.checked) {
            dispatch({
                type: "SET_CONSENT",
                payload: {
                    name: e.target.name,
                    data: [...formStates.consent.medicalProcedures, e.target.value],
                },
            });
        } else {
            dispatch({
                type: "SET_CONSENT",
                payload: {
                    name: e.target.name,
                    data: formStates.consent.medicalProcedures.filter((item) => item !== e.target.value),
                },
            });
        }
    }
    const submitConsent = (e) => {
        e.preventDefault()
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === true && formStates.consent.medicalProcedures.length > 0) {
            setConsentValidate(true)
            setAccordionActiveKey("7")
        } else {
            setConsentValidate(false)
        }
        setValidated_consent(true)
    }
    useEffect(() => {
        if (age > 17) {
            dispatch({
                type: "SET_CONSENT",
                payload: {
                    name: "guardianParentNameAndSurname",
                    data: "",
                },
            });
            dispatch({
                type: "SET_CONSENT",
                payload: {
                    name: "guardianParentSignature",
                    data: "",
                },
            });
            dispatch({
                type: "SET_CONSENT",
                payload: {
                    name: "parentSignatureAttachment",
                    data: false,
                },
            });
        }
        setConsentValidate(false)
    }, [age])

    // procedure
    const [procedureValidate, setProcedureValidate] = useState(false)
    const [validated_procedure, setValidated_procedure] = useState(false);
    const handleChangeProcedure = (e) => {
        dispatch({
            type: "SET_PROCEDURE",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    }
    const handleChangeProcedureNo = (e) => {
        dispatch({
            type: "SET_PROCEDURE",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
        dispatch({
            type: "SET_PROCEDURE",
            payload: {
                name: "surgeonName",
                data: "",
            },
        });
        dispatch({
            type: "SET_PROCEDURE",
            payload: {
                name: "surgeonSignature",
                data: "",
            },
        });
        dispatch({
            type: "SET_PROCEDURE",
            payload: {
                name: "surgeonHPCSA_SANCNo",
                data: "",
            },
        });
        dispatch({
            type: "SET_PROCEDURE",
            payload: {
                name: "surgicalMethod",
                data: "",
            },
        });
        dispatch({
            type: "SET_PROCEDURE",
            payload: {
                name: "deviceOrAidName",
                data: "",
            },
        });
        // removing values from intra operative observation
        dispatch({
            type: "SET_INTRA",
            payload: {
                name: "observation",
                data: "",
            },
        });
        dispatch({
            type: "SET_INTRA",
            payload: {
                name: "aeSeverity",
                data: "",
            },
        });
        dispatch({
            type: "SET_INTRA",
            payload: {
                name: "aeType",
                data: [],
            },
        });
        dispatch({
            type: "SET_INTRA",
            payload: {
                name: "notesOnAe",
                data: "",
            },
        });
    }
    const handleChangeSurgicalMethod = (e) => {
        dispatch({
            type: "SET_PROCEDURE",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
        dispatch({
            type: "SET_PROCEDURE",
            payload: {
                name: "deviceOrAidName",
                data: "",
            },
        });
    }
    const submitProcedure = (e) => {
        e.preventDefault()
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            setProcedureValidate(true)
            setAccordionActiveKey("8")
        } else if (formStates.patientDetails.gender === "female") {
            setMarketingValidate(true)
            setAccordionActiveKey("9")
        } else {
            setProcedureValidate(false)
        }
        setValidated_procedure(true)
    }

    //marketing surveys
    const [marketingValidate, setMarketingValidate] = useState(false)
    const [validated_marketing, setValidated_marketing] = useState(false);
    const handleChangeMarketing = (e) => {
        dispatch({
            type: "SET_MARKETING",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    }
    const handleChangeWherePatientHear = (e) => {
        dispatch({
            type: "SET_MARKETING",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
        dispatch({
            type: "SET_MARKETING",
            payload: {
                name: "smNo",
                data: "",
            },
        });
        dispatch({
            type: "SET_MARKETING",
            payload: {
                name: "recruiterName",
                data: "",
            },
        });
    }
    const handleChangeWhyCircumcised = (e) => {
        if (e.target.checked) {
            dispatch({
                type: "SET_MARKETING",
                payload: {
                    name: e.target.name,
                    data: [...formStates.marketingSurveys.reasonForCircumcised, e.target.value],
                },
            });
        } else {
            dispatch({
                type: "SET_MARKETING",
                payload: {
                    name: e.target.name,
                    data: formStates.marketingSurveys.reasonForCircumcised.filter((item) => item !== e.target.value),
                },
            });
        }
    }
    const submitMarketingSurvayes = (e) => {
        e.preventDefault()
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === true && formStates.marketingSurveys.reasonForCircumcised.length > 0) {
            setMarketingValidate(true)
            setAccordionActiveKey("9")
        } else if (formStates.patientDetails.gender === "female") {
            setMarketingValidate(true)
            setAccordionActiveKey("9")
        } else {
            setMarketingValidate(false)
        }
        setValidated_marketing(true)
    }

    // intra operative observation
    const [intraValidate, setIntraValidate] = useState(false)
    const [validated_intra, setValidated_intra] = useState(false);
    const getAeType = useGetAeTypeQuery()
    const handleChangeIntra = (e) => {
        dispatch({
            type: "SET_INTRA",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    }
    const handleChangeIntraNo = (e) => {
        dispatch({
            type: "SET_INTRA",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
        dispatch({
            type: "SET_INTRA",
            payload: {
                name: "aeSeverity",
                data: "",
            },
        });
        dispatch({
            type: "SET_INTRA",
            payload: {
                name: "aeType",
                data: [],
            },
        });
        dispatch({
            type: "SET_INTRA",
            payload: {
                name: "notesOnAe",
                data: "",
            },
        });
    }
    const handleChangeAeType = (e) => {
        if (e.target.checked) {
            dispatch({
                type: "SET_INTRA",
                payload: {
                    name: e.target.name,
                    data: [...formStates.intraOperativeObservations.aeType, e.target.value],
                },
            });
        } else {
            dispatch({
                type: "SET_INTRA",
                payload: {
                    name: e.target.name,
                    data: formStates.intraOperativeObservations.aeType.filter((item) => item !== e.target.value),
                },
            });
        }
    }
    useEffect(() => {
        if (formStates.intraOperativeObservations.observation === "yes" && formStates.intraOperativeObservations.aeType.length > 0 && formStates.intraOperativeObservations.notesOnAe && formStates.intraOperativeObservations.aeSeverity) {
            setIntraValidate(true)
        } else if (formStates.intraOperativeObservations.observation === "no") {
            setIntraValidate(true)
        } else if (formStates.patientDetails.gender === "female" || formStates.procedure.procedureDone === "no") {
            setIntraValidate(true)
        }
        else {
            setIntraValidate(false)
        }
        setValidated_intra(true)
    }, [formStates.intraOperativeObservations.observation, formStates.intraOperativeObservations.aeType.length, formStates.intraOperativeObservations.notesOnAe, formStates.intraOperativeObservations.aeSeverity, formStates.patientDetails.gender, formStates.procedure.procedureDone])





    useEffect(() => {

        let details = validateSAID(singleData?.patientDetails?.identityVerificationNumber);
        setClientDetailsId(details);
        dispatch({
            type: "SET_STATE",
            payload: {
                formDetails: {
                    serviceDeliveryPartner: singleData?.formDetails?.serviceDeliveryPartner || "",
                    facility: singleData?.formDetails?.facility || "",
                    barcodeCDCNumber: singleData?.formDetails?.barcodeCDCNumber || "",
                    procedureDate: singleData?.formDetails?.procedureDate?.split("/").join("-") || "",
                    vmmcServiceDeliverySetting: singleData?.formDetails?.vmmcServiceDeliverySetting.toLowerCase() || "",
                    folderNo: singleData?.formDetails?.folderNo || ""
                },
                patientDetails: {
                    active: singleData?.patientDetails?.active,
                    clientID: params.clientID,
                    firstName: singleData?.patientDetails?.firstName || "",
                    surname: singleData?.patientDetails?.surname || "",
                    dateOfBirth: singleData?.patientDetails?.dateOfBirth.split("/").join('-') || "",
                    nationality: singleData?.patientDetails?.nationality.toLowerCase() || "",
                    identityVerification: singleData?.patientDetails?.identityVerification || "",
                    email: singleData?.patientDetails?.email || "",
                    contactNumber: singleData?.patientDetails?.contactNumber || "",
                    gender: singleData?.patientDetails?.gender.toLowerCase() || "",
                    nextOfKinContactNumber: singleData?.patientDetails?.nextOfKinContactNumber || "",
                    relationshipWithClient: singleData?.patientDetails?.relationshipWithClient.toLowerCase() || "",
                    countryOfOrigin: singleData?.patientDetails?.countryOfOrigin || "",
                    identityVerificationNumber: singleData?.patientDetails?.identityVerificationNumber || ""
                },
                medicalServicesReceived: {
                    medicalService: singleData?.medicalServicesReceived?.medicalService.toLowerCase() || "",
                    hivTesting: singleData?.medicalServicesReceived?.hivTesting || "",
                    hivStatusTestResult: singleData?.medicalServicesReceived?.hivStatusTestResult || "",
                    positiveCheckbox: singleData?.medicalServicesReceived?.positiveCheckbox.toLowerCase() || ""
                },
                voluntaryMaleMedicalCircumcision: {
                    clientName: singleData?.voluntaryMaleMedicalCircumcision?.clientName || ""
                },
                consent: {
                    medicalProcedures: singleData?.consent?.medicalProcedures || [],
                    paitientSignature: singleData?.consent?.paitientSignature.toLowerCase() || "",
                    consentFormAttachment: singleData?.consent?.consentFormAttachment || false,
                    guardianParentSignature: singleData?.consent?.guardianParentSignature || "",
                    guardianParentNameAndSurname: singleData?.consent?.guardianParentNameAndSurname || "",
                    counsellorNameAndSurname: singleData?.consent?.counsellorNameAndSurname || "",
                    parentSignatureAttachment: singleData?.consent?.parentSignatureAttachment || false,
                    counsellorSignature: singleData?.consent?.counsellorSignature || ""
                },
                procedure: {
                    surgeonName: singleData?.procedure?.surgeonName || "",
                    surgeonSignature: singleData?.procedure?.surgeonSignature.toLowerCase() || "",
                    surgeonHPCSA_SANCNo: singleData?.procedure?.surgeonHPCSA_SANCNo || "",
                    surgicalMethod: singleData?.procedure?.surgicalMethod.toLowerCase() === 'dorsal slit' ? (
                        "Dorsal"
                    ) : (
                        singleData?.procedure?.surgicalMethod.toLowerCase() === 'sleeve resection' ? (
                            "Sleeve"
                        ) : (
                            singleData?.procedure?.surgicalMethod.toLowerCase() === 'device/surgical aid' ? (
                                "DeviceOrAid"
                            ) : (
                                singleData?.procedure?.surgicalMethod || ""
                            )
                        )
                    ),
                    deviceOrAidName: singleData?.procedure?.deviceOrAidName || "",
                    procedureDone: singleData?.procedure?.procedureDone || ""
                },
                marketingSurveys: {
                    smNo: singleData?.marketingSurveys?.smNo || "",
                    recruiterName: singleData?.marketingSurveys?.recruiterName || "",
                    reasonForCircumcised: singleData?.marketingSurveys?.reasonForCircumcised || [],
                    wherePatientHear: singleData?.marketingSurveys?.wherePatientHear === 'Social Mobiliser' ? (
                        "mobilizerExist"
                    ) : (
                        singleData?.marketingSurveys?.wherePatientHear || ""
                    )
                },
                intraOperativeObservations: {
                    aeSeverity: singleData?.intraOperativeObservations?.aeSeverity || "",
                    aeType: singleData?.intraOperativeObservations?.aeType || [],
                    notesOnAe: singleData?.intraOperativeObservations?.notesOnAe || "",
                    observation: singleData?.intraOperativeObservations?.observation?.toLowerCase() || ""
                }
            }
        })

        // attachment
        if (singleData?.attachment[0]?.attachment_Data) {
            let IdentityCard = singleData?.attachment?.find(item => item.attachment_Name === "Identity Card");
            setBase64String(IdentityCard?.attachment_Data)
        }
        if (singleData?.attachment[1]?.attachment_Data) {
            let other = singleData?.attachment?.find(item => item.attachment_Name === "Other Attachment");
            setBase64StringOthers(other?.attachment_Data)
        }
        if (singleData?.attachment[2]?.attachment_Data) {
            let secondary = singleData?.attachment?.find(item => item.attachment_Name === "Secondary Attachment");
            setBase64StringSecondary(secondary?.attachment_Data)
        }
        // consent form
        setPdfFile(singleData?.consentFile && singleData?.consentFile?.consentData)

        // age
        const dobString = singleData?.patientDetails?.dateOfBirth?.toString();
        let NextDate = getNextDate(dobString)
        let formatted = formatDate(NextDate)
        const age = new Date(new Date() - new Date(formatted)).getFullYear() - 1970;

        if (isNaN(age)) {
            setAge("");
        } else {
            setAge(age);
        }
        if (age > 14) {
            setDateValid(true);
        } else {
            setDateValid(false);
        }


        if (singleData?.patientDetails?.nextOfKinContactNumber) {
            setNextKinOfContactValid(isValidPhoneNumber(singleData?.patientDetails?.nextOfKinContactNumber?.toString()))
        }
        if (singleData?.patientDetails?.contactNumber) {
            setContactNumberValid(isValidPhoneNumber(singleData?.patientDetails?.contactNumber?.toString()))
        }

        if (singleData?.patientDetails?.contactNumber) {
            PhoneInputElem.forEach((elem) => {
                elem.querySelector('.PhoneInputInput').setAttribute('required', true)
            })
        }
    }, [data])

    let procedureDateGet = data?.formDetails?.procedureDate?.split("/").join("-");
    let clientDOB_Date = data?.patientDetails?.dateOfBirth?.split("/").join("-");

    // for QA valid or invalid part
    let [QaOneverifyData] = useQaOneVerifyRecordMutation()
    let [QaTwoverifyData] = useQaTwoVerifyRecordMutation()
    const qaReason = useGetQAReasonQuery()
    const dataValidationInitState = {
        confirmation: false,
        status: '',
        invalidMessage: '',
        invalidMessageOthers: ''
    }
    const DataValidationReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_STATE":
                return { ...payload };
            case "SET_CONFIRMATION":
                return {
                    ...state,
                    confirmation: payload,
                };
            case "SET_STATUS":
                return {
                    ...state,
                    status: payload
                };
            case "SET_INVALID_MESSAGE":
                return {
                    ...state,
                    invalidMessage: payload
                };
            case "SET_INVALID_MESSAGE_OTHERS":
                return {
                    ...state,
                    invalidMessageOthers: payload
                };
            default:
                return state;
        }
    }
    const [DataValidationState, DataValidationDispatch] = useReducer(DataValidationReducer, dataValidationInitState);
    const [validated, setValidated] = useState(false)
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            const body = {
                batchNo: params.id,
                client_id: params.clientID,
                verificationStatus: DataValidationState.status,
                qaReason: DataValidationState.invalidMessage.toLowerCase() === "other" ? `Other: ${DataValidationState.invalidMessageOthers}` : DataValidationState.invalidMessage
            }
            if (user === 'QA_one') {
                QaOneverifyData(body).then((res) => {
                    console.log(res)
                    if (res?.data?.status === 1) {
                        toast.success("Verified successfully !!", { duration: 7000 });
                        if (user === 'QA_one') {
                            navigate(`/qa-1/manage-batch-validation/progress/${params.id}`)
                        } else {
                            navigate(`/qa-2/manage-batch-validation/progress/${params.id}`)
                        }
                    } else if (res?.error?.status === 'FETCH_ERROR') {
                        toast.error("Check your internet connection !!", { duration: 7000 });
                    } else {
                        toast.error("Someting went wrong !!", { duration: 7000 });
                    }
                }).catch((error) => {
                    toast.error("Reason for rejection is required !!", { duration: 7000 });
                    console.log(error)
                })
            } else {
                QaTwoverifyData(body).then((res) => {
                    console.log(res)
                    if (res?.data?.status === 1) {
                        toast.success("Verified successfully !!", { duration: 7000 });
                        if (user === 'QA_one') {
                            navigate(`/qa-1/manage-batch-validation/progress/${params.id}`)
                        } else {
                            navigate(`/qa-2/manage-batch-validation/progress/${params.id}`)
                        }
                    } else if (res?.error?.status === 'FETCH_ERROR') {
                        toast.error("Check your internet connection !!", { duration: 7000 });
                    }
                    else if (res?.data?.status === 0) {
                        toast.error("Unable to view client record, please try again", { duration: 7000 })
                    }
                    else {
                        toast.error("Unable to view client record, please try again", { duration: 7000 });
                    }
                }).catch((error) => {
                    toast.error("Unable to view client record, please try again", { duration: 7000 });
                })
            }
        }
        setValidated(true);
    }

    useEffect(() => {
        if (DataValidationState.confirmation === false) {
            DataValidationDispatch({
                type: 'SET_STATUS',
                payload: ''
            })
            DataValidationDispatch({
                type: 'SET_INVALID_MESSAGE',
                payload: ''
            })
            DataValidationDispatch({
                type: 'SET_INVALID_MESSAGE_OTHERS',
                payload: ''
            })
        }

        if (DataValidationState.status === 'valid') {
            DataValidationDispatch({
                type: 'SET_INVALID_MESSAGE',
                payload: ''
            })
            DataValidationDispatch({
                type: 'SET_INVALID_MESSAGE_OTHERS',
                payload: ''
            })
        }
        if (DataValidationState.invalidMessage !== "other") {
            DataValidationDispatch({
                type: 'SET_INVALID_MESSAGE_OTHERS',
                payload: ''
            })
        }
    }, [DataValidationState.status, DataValidationState.confirmation, DataValidationState.invalidMessage])

    useEffect(() => {
        if (QaType !== "progress") {
            DataValidationDispatch({
                type: 'SET_STATE',
                payload: {
                    confirmation: singleData?.verificationStatus ? true : false,
                    status: singleData?.verificationStatus,
                    invalidMessage: singleData?.qaReason
                }
            })
        }
    }, [data])

    return (
        <>
            {singleData?.qaReason &&
                (<div className="col-12">
                    <div className="bg-danger-light p-4 mb-15 rounded-3">
                        <strong>Reason for invalidation</strong>
                        <p className="mb-0">
                            {singleData?.qaReason}
                        </p>
                    </div>
                </div>)
            }
            <Accordion activeKey={accordionActiveKey} flush>
                {/* file attachment */}
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("0")}>
                        <div className="acco-box">
                            <p>File Attachment</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="row g-3 timeline-steps aos-init aos-animate" data-aos="fade-up">
                            {/* consent form */}
                            <div className="col-md-3">
                                <div className="timeline-step">

                                    {pdfFile &&
                                        (<>
                                            <h3 className="h6 mt-3 mb-2">Consent Form</h3>
                                            <div className="timeline-fill-box overlay-timeline-fill-box" >
                                                <div className="overlay" onClick={() => setConsentModal(true)}>Click here to view</div>
                                                {
                                                    pdfFile.includes("image/") ? (
                                                        <img src={pdfFile} alt="" />
                                                    ) : (
                                                        <iframe src={pdfFile} alt="" height={300} width={"100%"} title="pdf" />
                                                    )
                                                }
                                            </div>
                                        </>)
                                    }
                                </div>
                            </div>
                            {pdfFile && <ViewImageModal ModalTitle={"Consent Form"} modalState={consentModal} modalSetState={setConsentModal} modalUrl={pdfFile} />}

                            {/* Identity Verification Card */}
                            <div className="col-md-3">
                                <div className="timeline-step">

                                    {base64String &&
                                        <>
                                            <h3 className="h6 mt-3 mb-2">{formStates.patientDetails.identityVerification || "Identity Card"}</h3>
                                            <div className="timeline-fill-box overlay-timeline-fill-box">
                                                <div className="overlay" onClick={() => setIdImageModal(true)}>Click here to view</div>
                                                {
                                                    base64String.includes("image/") ? (
                                                        <img src={base64String} alt="" />
                                                    ) : (
                                                        <iframe src={base64String} alt="" height={300} width={"100%"} title="pdf" />
                                                    )
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            {base64String && <ViewImageModal ModalTitle={"Identity Card"} modalUrl={base64String} modalState={idImageModal} modalSetState={setIdImageModal} />}

                            {/* other attachment */}
                            <div className="col-md-3">
                                <div className="timeline-step">

                                    {base64StringOthers &&
                                        <>
                                            <h3 className="h6 mt-3 mb-2">Other Attachment</h3>
                                            <div className="timeline-fill-box overlay-timeline-fill-box">
                                                <div className="overlay" onClick={() => setOtherAttachmentModal(true)}>Click here to view</div>
                                                {
                                                    base64StringOthers.includes("image/") ? (
                                                        <img src={base64StringOthers} style={{ width: '100%' }} alt="" />
                                                    ) : (
                                                        <iframe src={base64StringOthers} alt="" height={300} width={"100%"} title="pdf" />
                                                    )
                                                }

                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            {base64StringOthers && <ViewImageModal ModalTitle={"Others Attachment"} modalUrl={base64StringOthers} modalState={otherAttachmentModal} modalSetState={setOtherAttachmentModal} />}

                            {/* secondary attachment */}
                            <div className="col-md-3">
                                <div className="timeline-step">

                                    {base64StringSecondary &&
                                        (<>
                                            <h3 className="h6 mt-3 mb-2">Secondary Attachment</h3>
                                            <div className="timeline-fill-box overlay-timeline-fill-box">
                                                <div className="overlay" onClick={() => setSecondaryAttachmentModal(true)}>Click here to view</div>
                                                {
                                                    base64StringSecondary.includes("image/") ? (
                                                        <img src={base64StringSecondary} alt="" />
                                                    ) : (
                                                        <iframe src={base64StringSecondary} alt="" height={300} width={"100%"} title="pdf" />
                                                    )
                                                }

                                            </div>
                                        </>)
                                    }
                                </div>
                            </div>
                            {base64StringSecondary && <ViewImageModal ModalTitle={"Secondary Attachment"} modalUrl={base64StringSecondary} modalState={secondaryAttachmentModal} modalSetState={setSecondaryAttachmentModal} />}
                        </div>
                        {!formStates.patientDetails.identityVerification &&
                            <div className="text-center">Please select Identity Verification in Patient Details</div>
                        }
                    </Accordion.Body>
                </Accordion.Item>
                {/* form details */}
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("1")}>
                        <div className="acco-box">
                            <p>Form details</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Form noValidate validated={validated_formDetails} className="we-form" onSubmit={submitFormDetails}>
                            {/* barcode cdc number */}
                            <Row>
                                <Form.Group xl="6" as={Col} className="mb-2 pb-1">
                                    <Form.Label>Barcode CDC Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Barcode CDC Number"
                                        value={formStates.formDetails.barcodeCDCNumber}
                                        name="barcodeCDCNumber"
                                        onChange={handleChangeFormDetails}
                                        required
                                        disabled
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                {/* facility */}
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="relations" className="form-label">
                                            Facility
                                        </label>
                                        <select
                                            className="form-select"
                                            required
                                            name="facility"
                                            value={formStates.formDetails.facility}
                                            onChange={handleChangeFormDetails}
                                            disabled
                                        >
                                            <option value="">Select</option>
                                            {getDropDown?.data?.data?.facility?.map((el, i) => (
                                                <option key={i} value={el.facilityName}>{el.facilityName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {/* Service Delivery Partner */}
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="relations" className="form-label">
                                            Service Delivery Partner
                                        </label>
                                        <select
                                            className="form-select"
                                            required
                                            name="serviceDeliveryPartner"
                                            value={formStates.formDetails.serviceDeliveryPartner}
                                            onChange={handleChangeFormDetails}
                                            disabled
                                        >
                                            <option value="">Select</option>
                                            {getDropDown?.data?.data?.serviceDeliveryPartner?.map((el, i) => (
                                                <option key={i} value={el.serviceDeliveryPartnerName}>{el.serviceDeliveryPartnerName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </Row>
                            {/* VMMC delivery setting */}
                            <Row>
                                <div className="col-md-12">
                                    <div className="Service-Delivery mb-3">
                                        <h3 className="me-3 mb-md-0 mb-2">
                                            VMMC SERVICE DELIVERY SETTING
                                        </h3>
                                        <div className="d-flex flex-wrap">
                                            <div>
                                                <input
                                                    name="vmmcServiceDeliverySetting"
                                                    type="radio"
                                                    id="radio1"
                                                    className="with-gap radio-col-danger form-check-input"
                                                    onChange={handleChangeFormDetails}
                                                    checked={formStates.formDetails.vmmcServiceDeliverySetting === "fixed"}
                                                    value="fixed"
                                                    required
                                                    disabled
                                                />
                                                <label className="form-check-label" htmlFor="radio1">Fixed</label>
                                            </div>
                                            <div>
                                                <input
                                                    name="vmmcServiceDeliverySetting"
                                                    type="radio"
                                                    id="radio2"
                                                    className="with-gap radio-col-danger form-check-input"
                                                    onChange={handleChangeFormDetails}
                                                    value="outreach"
                                                    checked={formStates.formDetails.vmmcServiceDeliverySetting === "outreach"}
                                                    required
                                                    disabled
                                                />
                                                <label className="form-check-label" htmlFor="radio2">Outreach</label>
                                            </div>
                                            <div>
                                                <input
                                                    name="vmmcServiceDeliverySetting"
                                                    type="radio"
                                                    id="radio3"
                                                    className="with-gap radio-col-danger form-check-input"
                                                    onChange={handleChangeFormDetails}
                                                    checked={formStates.formDetails.vmmcServiceDeliverySetting === "mobile"}
                                                    value="mobile"
                                                    required
                                                    disabled
                                                />
                                                <label className="form-check-label" htmlFor="radio3">Mobile</label>
                                            </div>
                                            <div>
                                                <input
                                                    name="vmmcServiceDeliverySetting"
                                                    type="radio"
                                                    id="radio4"
                                                    className="with-gap radio-col-danger form-check-input"
                                                    onChange={handleChangeFormDetails}
                                                    value="TMI_CMI"
                                                    required
                                                    disabled
                                                />
                                                <label className="form-check-label" htmlFor="radio4">TMI / CMI</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                {/* folder number */}
                                <Form.Group as={Col} className="mb-2 pb-1">
                                    <Form.Label>Folder no.</Form.Label>
                                    <Form.Control
                                        className="prevent-validation"
                                        type="text"
                                        placeholder="Enter folderNo"
                                        name="folderNo"
                                        value={formStates.formDetails.folderNo}
                                        onChange={handleChangeFormDetails}
                                        style={{ border: '1px solid #bbbbbb', backgroundImage: 'none' }}
                                        disabled
                                    />
                                </Form.Group>
                                {/* procedure date */}
                                <Form.Group as={Col} className="mb-2 pb-1">
                                    <Form.Label>Procedure Date</Form.Label>
                                    <DatePicker
                                        maxDate={dayjs(formatDate(new Date()), dateFormat)}
                                        defaultValue={procedureDateGet && dayjs(procedureDateGet, dateFormat)}
                                        className={!formStates.formDetails.procedureDate && validated_formDetails ? "form-control form-control-invalid" : formStates.formDetails.procedureDate && validated_formDetails ? "form-control form-control-valid" : "form-control"}
                                        name="procedureDate"
                                        onChange={handleChangeProcedureDate}
                                        disabled
                                    />
                                </Form.Group>
                            </Row>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
                {/* patient details */}
                <Accordion.Item eventKey="2">
                    <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("2")}>
                        <div className="acco-box">
                            <p>Patient details</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Form noValidate validated={validated_patient} className="we-form" onSubmit={submitPatientDetails}>
                            <Row>
                                {/* first name */}
                                <Form.Group className="col-lg-4 col-md-6">
                                    <Form.Label> First name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="firstName"
                                        placeholder="First name"
                                        required
                                        name="firstName"
                                        value={formStates.patientDetails.firstName}
                                        onChange={handleChangePatientdetails}
                                        disabled
                                    />
                                </Form.Group>
                                {/* surname */}
                                <Form.Group className="col-lg-4 col-md-6">
                                    <Form.Label> Surname</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="Surname"
                                        placeholder="Surname"
                                        name="surname"
                                        value={formStates.patientDetails.surname}
                                        onChange={handleChangePatientdetails}
                                        required
                                        disabled
                                    />
                                </Form.Group>
                                {/* date of birth */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="row gx-2">
                                        <Form.Group as={Col} className="mb-2 pb-1 col-md-9">
                                            <Form.Label>Date of birth</Form.Label>
                                            <DatePicker
                                                placeholder="Date of birth"
                                                onChange={handleChangeDob}
                                                maxDate={dayjs(formatDate(new Date()), dateFormat)}
                                                defaultValue={clientDOB_Date && dayjs(clientDOB_Date, dateFormat)}
                                                required
                                                className={!formStates.patientDetails.dateOfBirth && validated_patient ? "form-control form-control-invalid" : formStates.patientDetails.dateOfBirth && validated_patient ? "form-control form-control-valid" : "form-control"}
                                                disabled
                                            />
                                            <Form.Control.Feedback
                                                type="invalid"
                                                className="justify-content-between"
                                                style={{ display: dateValid === null ? "none" : dateValid === false ? "flex" : "none" }}
                                            >
                                                {formStates.patientDetails.dateOfBirth === "" && validated_patient && "This field is required"}
                                                <div> {(age && age <= 14) && "age: " + age}</div>
                                                <div>{(age && age <= 14) && "You are not eligible"}</div>
                                            </Form.Control.Feedback>
                                            <Form.Control.Feedback
                                                type={age > 17 ? "valid" : "invalid"}
                                                className="justify-content-between"
                                                style={{ display: dateValid === true ? "flex" : "" }}
                                            >
                                                <div> {age && "age: " + age}</div>
                                                {(age >= 10 && age <= 17) && (
                                                    <div onClick={() => setAdultAdviceModal(true)} style={{ cursor: 'pointer' }}><i className="si-exclamation si me-1"></i>Adult advice required</div>
                                                )}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Modal show={adultAdviceModal} onHide={() => setAdultAdviceModal(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Underage message</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <p>As the patient is under 18 years of age, we kindly request the presence and guidance of a parent, guardian, or caregiver during the appointment or procedure.</p>
                                                <div>
                                                    <h6>Requirements</h6>
                                                    <ul>
                                                        <li>
                                                            Signed Affidavit from parent
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setAdultAdviceModal(false)}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <Form.Group as={Col} className="mb-2 pb-1 col-md-3">
                                            <Form.Label>Age</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control"
                                                placeholder="Age"
                                                value={age}
                                                required
                                                disabled
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                {/* nationality */}
                                <Form.Group className="col-lg-4 col-md-6">
                                    <div className="mb-3">
                                        <Form.Label className="form-label">
                                            Nationality
                                        </Form.Label>
                                        <select
                                            className="form-select"
                                            id="National"
                                            name="nationality"
                                            required
                                            onChange={handleNationalityChange}
                                            value={formStates.patientDetails.nationality}
                                            disabled
                                        >
                                            <option value="">Select</option>
                                            <option value="south_african">
                                                South African
                                            </option>
                                            <option value="non_south_african">
                                                Non South African
                                            </option>
                                        </select>
                                    </div>
                                </Form.Group>
                                {/* country of origin */}
                                <div id="cog" className="col-lg-4 col-md-6 NationalID">
                                    {formStates.patientDetails.nationality === "south_african" ? (
                                        <>
                                            <label htmlFor="National" className="form-label" >
                                                Country of Origin
                                            </label>
                                            <select
                                                className="form-select"
                                                id="National"
                                                onChange={handleChangePatientdetails}
                                                name="countryOfOrigin"
                                                value={"south_africa"}
                                                required
                                                disabled
                                            >
                                                <option value="">Select</option>
                                                <option value={"south_africa"}>
                                                    South Africa
                                                </option>
                                            </select>
                                        </>
                                    ) : (
                                        <>
                                            <label htmlFor="National" className="form-label" >
                                                Country of Origin
                                            </label>
                                            <select required className="form-select" id="National" name="countryOfOrigin" onChange={handleChangePatientdetails} value={formStates.patientDetails.countryOfOrigin.toLowerCase()} disabled>
                                                <option value="" selected>Select</option>
                                                <option value={"botswana"}>Botswana</option>
                                                <option value={"eswatini"}>Eswatini</option>
                                                <option value={"lesotho"}>Lesotho</option>
                                                <option value={"mozambique"}>Mozambique</option>
                                                <option value={"namibia"}>Namibia</option>
                                                <option value={"zimbabwe"}>Zimbabwe</option>
                                            </select>
                                        </>
                                    )}
                                </div>
                                {/* identity verification */}
                                <div className="col-lg-4 col-md-6" id="SAID">
                                    <div className="mb-3">
                                        <label htmlFor="saID" className="form-label">
                                            Identity Verification
                                        </label>
                                        <select className="form-select" id="saID" required onChange={handleChangePatientdetails} value={formStates.patientDetails.identityVerification} name="identityVerification" disabled >
                                            <option value="">Select</option>
                                            {formStates.patientDetails.nationality === "south_african" && <option value="South African ID">South African ID</option>}
                                            <option value="Driving License">Driving License</option>
                                            <option value="Birth Certificate"> Birth Certificate</option>
                                            {formStates.patientDetails.nationality === "south_african" && <option value="South African Affidavit">South African Affidavit</option>}
                                            {formStates.patientDetails.nationality === "south_african" && <option value="South African Passport">South African Passport</option>}
                                            {formStates.patientDetails.nationality === "non_south_african" && <option value="Affidavit">Affidavit</option>}
                                            {formStates.patientDetails.nationality === "non_south_african" && <option value="Passport">Passport</option>}
                                            {formStates.patientDetails.nationality === "non_south_african" && <option value="Assylum Number">Assylum Number</option>}
                                        </select>
                                    </div>
                                </div>
                                {formStates.patientDetails.identityVerification
                                    && (
                                        <div className="col-lg-4 margin-medium" id="additionalInput">
                                            <label htmlFor="saID" className="form-label">
                                                {formStates.patientDetails.nationality === "south_african" ?
                                                    (formStates.patientDetails.identityVerification === "South African ID")
                                                        || (formStates.patientDetails.identityVerification === "Driving License")
                                                        || (formStates.patientDetails.identityVerification === "Birth Certificate")
                                                        || (formStates.patientDetails.identityVerification === "South African Passport") ? "South African ID" : "South African Affidavit" :
                                                    formStates.patientDetails.identityVerification
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={"Number"}
                                                onChange={handleChangeIdNumber}
                                                name="identityVerificationNumber"
                                                value={formStates.patientDetails.identityVerificationNumber}
                                                required
                                                disabled
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {/* sa id validation message */}
                                                {formStates.patientDetails.nationality === "south_african" ?
                                                    (((formStates.patientDetails.identityVerification === "South African ID")
                                                        || (formStates.patientDetails.identityVerification === "Driving License")
                                                        || (formStates.patientDetails.identityVerification === "Birth Certificate")
                                                        || (formStates.patientDetails.identityVerification === "South African Passport"))
                                                        && clientDetailsId.isValid === false) ? (
                                                        <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                                            Enter a valid SA ID
                                                        </div>
                                                    ) : "" :
                                                    ((formStates.patientDetails.identityVerification === "South African ID")
                                                        && clientDetailsId.isValid === false) && (
                                                        <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                                            Enter a valid SA ID
                                                        </div>
                                                    )
                                                }
                                                {/* date of birth message show */}
                                                {formStates.patientDetails.nationality === "south_african" ?
                                                    (((formStates.patientDetails.identityVerification === "South African ID")
                                                        || (formStates.patientDetails.identityVerification === "Driving License")
                                                        || (formStates.patientDetails.identityVerification === "Birth Certificate")
                                                        || (formStates.patientDetails.identityVerification === "South African Passport"))
                                                        && clientDetailsId.dateOfBirth && clientDetailsId.dateOfBirth !== formStates.patientDetails.dateOfBirth) ? (
                                                        <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                                            Date of birth mismatch
                                                        </div>
                                                    ) : "" :
                                                    ((formStates.patientDetails.identityVerification === "South African ID")
                                                        && clientDetailsId.dateOfBirth && clientDetailsId.dateOfBirth !== formStates.patientDetails.dateOfBirth) && (
                                                        <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                                            Date of birth mismatch
                                                        </div>
                                                    )
                                                }
                                                {/* gender message show */}
                                                {formStates.patientDetails.nationality === "south_african" ?
                                                    (((formStates.patientDetails.identityVerification === "South African ID")
                                                        || (formStates.patientDetails.identityVerification === "Driving License")
                                                        || (formStates.patientDetails.identityVerification === "Birth Certificate")
                                                        || (formStates.patientDetails.identityVerification === "South African Passport"))
                                                        && clientDetailsId.gender && formStates.patientDetails.gender !== clientDetailsId.gender) ? (
                                                        <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                                            Gender mismatch
                                                        </div>
                                                    ) : "" :
                                                    ((formStates.patientDetails.identityVerification === "South African ID")
                                                        && clientDetailsId.gender && formStates.patientDetails.gender !== clientDetailsId.gender) && (
                                                        <div style={{ fontSize: "0.875em", color: "rgb(220, 53, 69)", marginTop: '0.25rem' }}>
                                                            Gender mismatch
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )}
                                {/* email */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="email001">
                                            Email (if any)
                                        </label>
                                        <input
                                            id="email001"
                                            className="prevent-validation form-control"
                                            type="email"
                                            placeholder="Email"
                                            style={{ border: '1px solid #bbbbbb', backgroundImage: 'none' }}
                                            name="email"
                                            value={formStates.patientDetails.email}
                                            onChange={handleChangePatientdetails}
                                            disabled
                                        />
                                    </div>
                                </div>
                                {/* contact number */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="mobile">
                                            Contact number
                                        </label>
                                        <PhoneInput
                                            international
                                            defaultCountry="ZA"
                                            placeholder="Enter phone number"
                                            onChange={handleContactNumber}
                                            value={formStates.patientDetails.contactNumber}
                                            name="contactNumber"
                                            id="mobile2"
                                            required={age > 17 ? true : false}
                                            className={(formStates.patientDetails.contactNumber && isValidPhoneNumber(formStates.patientDetails.contactNumber?.toString()) && validated_patient) ? 'form-control-valid' : !formStates.patientDetails.contactNumber && validated_patient && age > 17 ? "form-control-invalid" : formStates.patientDetails.contactNumber && validated_patient && !(isValidPhoneNumber(formStates.patientDetails.contactNumber?.toString())) ? "form-control-invalid" : ""}
                                            disabled
                                        />
                                    </div>
                                </div>
                                {/* gender */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="sex" className="form-label">
                                            Gender
                                        </label>
                                        <select
                                            className={"form-select"}
                                            id="sex"
                                            name={"gender"}
                                            required
                                            onChange={handleChangePatientdetails}
                                            value={formStates.patientDetails.gender.toLowerCase()}
                                            disabled
                                        >
                                            <option value="">Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                </div>
                                {/* next kin of contact number */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="mobile2">
                                            Next of Kin Contact Number
                                        </label>
                                        <PhoneInput
                                            international
                                            defaultCountry="ZA"
                                            placeholder="Enter phone number"
                                            onChange={handleNextKinOfContact}
                                            value={formStates.patientDetails.nextOfKinContactNumber}
                                            name="nextOfKinContactNumber"
                                            id="mobile2"
                                            required={(age >= 10 && age <= 17) ? true : false}
                                            className={(formStates.patientDetails.nextOfKinContactNumber && isValidPhoneNumber(formStates.patientDetails.nextOfKinContactNumber?.toString()) && validated_patient) ? 'form-control-valid' : !formStates.patientDetails.nextOfKinContactNumber && validated_patient && (age >= 10 && age <= 17) ? "form-control-invalid" : formStates.patientDetails.nextOfKinContactNumber && validated_patient && !(isValidPhoneNumber(formStates.patientDetails.nextOfKinContactNumber?.toString())) ? "form-control-invalid" : ""}
                                            disabled
                                        />
                                    </div>
                                </div>
                                {/* relationship with client */}
                                {age > 17 ? (
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="relations" className="form-label">
                                                Relationship with Client
                                            </label>
                                            <select
                                                className="form-select prevent-validation"
                                                id="relations"
                                                style={{ border: '1px solid #bbbbbb', backgroundImage: 'none' }}
                                                name="relationshipWithClient"
                                                onChange={handleChangePatientdetails}
                                                value={formStates.patientDetails.relationshipWithClient.toLowerCase()}
                                                disabled
                                            >
                                                <option value="">Select</option>
                                                <option value="father">Father</option>
                                                <option value="mother">Mother</option>
                                                <option value="spouse">Spouse</option>
                                                <option value="brother">Brother</option>
                                                <option value="sister">Sister</option>
                                                <option value="wife">Wife</option>
                                                <option value="uncle">Uncle</option>
                                            </select>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="relations" className="form-label">
                                                Relationship with Client
                                            </label>
                                            <select
                                                className="form-select prevent-validation"
                                                id="relations"
                                                required
                                                name="relationshipWithClient"
                                                onChange={handleChangePatientdetails}
                                                value={formStates.patientDetails.relationshipWithClient.toLowerCase()}
                                                disabled
                                            >
                                                <option value="">Select</option>
                                                <option value="father">Father</option>
                                                <option value="mother">Mother</option>
                                                <option value="spouse">Spouse</option>
                                                <option value="brother">Brother</option>
                                                <option value="sister">Sister</option>
                                                <option value="wife">Wife</option>
                                                <option value="uncle">Uncle</option>
                                            </select>
                                        </div>
                                    </div>
                                )}
                            </Row>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
                {/* HIV test */}
                <Accordion.Item eventKey="3">
                    <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("3")}>
                        <div className="acco-box">
                            <p>HIV TEST</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="mb-3">
                            <p className="mb-4">
                                I, the client identified above, declare that I
                                have received pre-test counselling on
                                HIV/AIDS. I understand the test is voluntary
                                and I consent to have my HIV status disclosed
                                to me. I have been informed about the nature,
                                risks and implications involved in an HIV
                                test. I received and understood all the
                                relevant information about the test. I had
                                enough opportunity to ask questions and decide
                                if I want to do the test. I consent to a
                                sample of blood being taken for an HIV test to
                                be performed by the counsellor below. I agree
                                that my test results can be communicated to
                                the testing provider, to my doctor and to a
                                third party for data collection and reporting,
                                provided that confidentiality is respected. I
                                confirm that I received the HIV test free of
                                charge.
                            </p>
                        </div>

                    </Accordion.Body>
                </Accordion.Item>
                {/* medical service received */}
                <Accordion.Item eventKey="4">
                    <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("4")}>
                        <div className="acco-box">
                            <p>MEDICAL SERVICES RECEIVED</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Form noValidate validated={validated_medicalService} className="we-form" onSubmit={submitMedicalService}>
                            <div className="d-flex flex-column flex-sm-row mb-3 col-md-6">
                                <div className="d-flex align-items-center text-nowrap me-3">
                                    Medical Service Received :
                                </div>
                                <div className="d-flex align-items-center flex-wrap">
                                    <div>
                                        <input
                                            type="radio"
                                            name="medicalService"
                                            id="MedicalServiceYes"
                                            className="filled-in chk-col-danger form-check-input"
                                            value={"yes"}
                                            onChange={handleChangeMedicalService}
                                            checked={formStates.medicalServicesReceived.medicalService.toLowerCase() === "yes"}
                                            required
                                            disabled
                                        />
                                        <label htmlFor="MedicalServiceYes" className="form-check-label" >
                                            Yes
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            id="MedicalServiceNo"
                                            name="medicalService"
                                            className="filled-in chk-col-danger form-check-input"
                                            value={"no"}
                                            checked={formStates.medicalServicesReceived.medicalService.toLowerCase() === "no"}
                                            onChange={handleMedicalServiceReceiveNo}
                                            required
                                            disabled
                                        />
                                        <label htmlFor="MedicalServiceNo" className="form-check-label" >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {formStates.medicalServicesReceived.medicalService.toLowerCase() === "yes" && (
                                <div className="row">
                                    {/* First Column */}
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <div className="test-box">
                                                <h6>HIV testing (tick 1 option)</h6>
                                                <Form.Group>
                                                    <Form.Check
                                                        type="radio"
                                                        id="Patient"
                                                        label="Patient counselled and tested for HIV at this MMC point / Facility"
                                                        name="hivTesting"
                                                        value={'tested_hiv'}
                                                        onChange={handleChangeMedicalService}
                                                        required={formStates.medicalServicesReceived.medicalService === "yes" ? true : false}
                                                        checked={formStates.medicalServicesReceived.hivTesting === 'tested_hiv' || formStates.medicalServicesReceived.hivTesting === 'Patient counselled and tested for HIV at this MMC point / Facility'}
                                                        disabled
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="testing"
                                                        label="Patient arrived with known HIV status (i.e. no testing required)"
                                                        name="hivTesting"
                                                        value={'known_status'}
                                                        onChange={handleChangeMedicalService}
                                                        required={formStates.medicalServicesReceived.medicalService === "yes" ? true : false}
                                                        checked={formStates.medicalServicesReceived.hivTesting === 'known_status' || formStates.medicalServicesReceived.hivTesting === 'Patient arrived with known HIV status (i.e. no testing required)'}
                                                        disabled
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="declined"
                                                        label="Patient declined to test for HIV"
                                                        name="hivTesting"
                                                        value={'declined_to_test'}
                                                        onChange={handleChangeMedicalService}
                                                        feedbackType="invalid"
                                                        feedback="You must select one option"
                                                        required={formStates.medicalServicesReceived.medicalService === "yes" ? true : false}
                                                        checked={formStates.medicalServicesReceived.hivTesting === 'declined_to_test' || formStates.medicalServicesReceived.hivTesting === 'Patient declined to test for HIV'}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Second Column */}
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <div className="test-box">
                                                <h6>
                                                    HIV status/test result (tick 1 option)
                                                </h6>
                                                <Form.Group>
                                                    <Form.Check
                                                        type="radio"
                                                        id="HIVpositive"
                                                        label="HIV positive"
                                                        name="hivStatusTestResult"
                                                        required={formStates.medicalServicesReceived.medicalService === "yes" ? true : false}
                                                        value={'positive'}
                                                        onChange={handleChangeHivTestResult}
                                                        checked={formStates.medicalServicesReceived.hivStatusTestResult === 'positive' || formStates.medicalServicesReceived.hivStatusTestResult === 'HIV positive'}
                                                        disabled
                                                    />
                                                    {
                                                        (formStates.medicalServicesReceived.hivStatusTestResult === "positive" || formStates.medicalServicesReceived.hivStatusTestResult === 'HIV positive')
                                                        &&
                                                        <div className="d-flex align-items-start">
                                                            <input
                                                                type="checkbox"
                                                                id="deviceCheck"
                                                                className="filled-in chk-col-danger"
                                                                required={formStates.medicalServicesReceived.hivStatusTestResult === "positive" ? true : false}
                                                                checked={formStates.medicalServicesReceived.hivStatusTestResult === 'positive' || formStates.medicalServicesReceived.hivStatusTestResult === 'HIV positive'}
                                                                disabled
                                                            />
                                                            <label htmlFor="deviceCheck">
                                                                If HIV positive, was the patient refferd to HIV care service
                                                            </label>
                                                            <div className="d-flex align-items-center align-items-start">
                                                                <div>
                                                                    <input
                                                                        type="radio"
                                                                        name="positiveCheckbox"
                                                                        id="IfHivYes"
                                                                        className="filled-in chk-col-danger form-check-input"
                                                                        value={"yes"}
                                                                        onChange={handleChangeMedicalService}
                                                                        required={formStates.medicalServicesReceived.hivStatusTestResult === "positive" ? true : false}
                                                                        checked={formStates.medicalServicesReceived.positiveCheckbox === 'yes'}
                                                                        disabled
                                                                    />
                                                                    <label htmlFor="IfHivYes" className="form-check-label" >
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="radio"
                                                                        id="IfHivNo"
                                                                        name="positiveCheckbox"
                                                                        className="filled-in chk-col-danger form-check-input"
                                                                        value={"no"}
                                                                        onChange={handleChangeMedicalService}
                                                                        required={formStates.medicalServicesReceived.hivStatusTestResult === "positive" ? true : false}
                                                                        checked={formStates.medicalServicesReceived.positiveCheckbox === 'no'}
                                                                        disabled
                                                                    />
                                                                    <label htmlFor="IfHivNo" className="form-check-label" >
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <Form.Check
                                                        type="radio"
                                                        id="HIVnegative"
                                                        label="HIV negative"
                                                        name="hivStatusTestResult"
                                                        value={'negative'}
                                                        onChange={handleChangeHivTestResult}
                                                        checked={formStates.medicalServicesReceived.hivStatusTestResult === 'negative' || formStates.medicalServicesReceived.hivStatusTestResult === 'HIV negative'}
                                                        disabled
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="Inconclusive"
                                                        label="Inconclusive"
                                                        name="hivStatusTestResult"
                                                        value={'inconclusive'}
                                                        onChange={handleChangeHivTestResult}
                                                        feedbackType="invalid"
                                                        feedback="You must select one option"
                                                        checked={formStates.medicalServicesReceived.hivStatusTestResult === 'inconclusive' || formStates.medicalServicesReceived.hivStatusTestResult === 'Inconclusive'}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
                {/* voluntary male medical circumcision */}
                <Accordion.Item eventKey="5">
                    <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("5")}>
                        <div className="acco-box">
                            <p>VOLUNTARY MALE MEDICAL CIRCUMCISION</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Form noValidate validated={validated_voluntaryMale} className="we-form" onSubmit={submitVoluntaryMale}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <p className="mb-4">
                                            I, the client identified above, willingly
                                            give my consent/assent to be medically
                                            circumcised by a healthcare provider at the
                                            facility listed above. The procedure has
                                            been explained to me, as well as the
                                            possible advantages and risks. I have had
                                            opportunity to ask questions and all
                                            questions have been answered. By signing
                                            this informed consent, I agree to have
                                            circumcision surgery. I agree to adhere to
                                            the post-operative instructions given to me.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3"></div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="checkbox_1">
                                            <span>I,</span>
                                            <input
                                                type="text"
                                                className="form-control style2 w-200 d-inline-block"
                                                id="barcode"
                                                required
                                                name="clientName"
                                                value={formStates.voluntaryMaleMedicalCircumcision.clientName}
                                                onChange={handleChangeVoluntaryMale}
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                This field is required
                                            </Form.Control.Feedback>
                                            <span>
                                                hereby further authorize Right to Care NPC
                                                ("RTC") and any authorized person or
                                                entity acting on behalf of RTC to collect,
                                                use, disclose, dispose off and otherwise
                                                process my personal data in accordance
                                                with the South African Protection of
                                                Personal Information Act No. 4 of 2013,
                                                various sources of law that impose privacy
                                                obligations and the Centers for Disease
                                                Control and Prevention ("CDC") Data
                                                Protection Principles, for the purposes of
                                                HIV Testing and Medical Male Circumcision.
                                            </span>
                                            <span>
                                                I further understand that I have the right
                                                to withdraw my consent at any time. To
                                                withdraw your consent, please contact
                                                Email: popia@righttocare.org
                                            </span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
                {/* consent */}
                <Accordion.Item eventKey="6">
                    <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("6")}>
                        <div className="acco-box">
                            <p>CONSENT</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Form noValidate validated={validated_consent} className="we-form" onSubmit={submitConsent}>
                            <div className="row gy-2">
                                <div className="col-md-12">
                                    <div className="d-md-flex mb-3">
                                        <p className="me-3 mb-md-0 mb-2">
                                            I have been counselled and I consent to the following medical procedures:
                                        </p>
                                        <div className="d-flex flex-wrap">
                                            <div>
                                                <input
                                                    name="medicalProcedures"
                                                    type="checkbox"
                                                    id="HIV"
                                                    className="filled-in form-check-input chk-col-danger"
                                                    value={"hivTest"}
                                                    onChange={handleChangeMedicalProcedure}
                                                    checked={formStates.consent.medicalProcedures.includes("hivTest")}
                                                    disabled
                                                />
                                                <label htmlFor="HIV" style={{ color: formStates.consent.medicalProcedures.length === 0 && validated_consent ? "rgb(227,53,69)" : "" }}>HIV test</label>
                                            </div>

                                            <div>
                                                <input
                                                    name="medicalProcedures"
                                                    type="checkbox"
                                                    id="circumcision"
                                                    className="filled-in form-check-input chk-col-danger"
                                                    value={"maleCircumcision"}
                                                    onChange={handleChangeMedicalProcedure}
                                                    checked={formStates.consent.medicalProcedures.includes("maleCircumcision")}
                                                    disabled
                                                />
                                                <label htmlFor="circumcision" style={{ color: formStates.consent.medicalProcedures.length === 0 && validated_consent ? "rgb(227,53,69)" : "" }}> Male circumcision </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex flex-column flex-sm-row">
                                        <div className="text-nowrap me-3">
                                            Patient signature :
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap">
                                            <div>
                                                <input
                                                    type="radio"
                                                    name="paitientSignature"
                                                    id="PatientSignatureYes"
                                                    className="filled-in chk-col-danger form-check-input"
                                                    value={"yes"}
                                                    onChange={handleChangeConsent}
                                                    required
                                                    checked={formStates.consent.paitientSignature === "yes"}
                                                    disabled
                                                />
                                                <label htmlFor="PatientSignatureYes" className="form-check-label" >
                                                    Yes
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="PatientSignatureNo"
                                                    name="paitientSignature"
                                                    className="filled-in chk-col-danger form-check-input"
                                                    value={"no"}
                                                    onChange={handleChangeConsent}
                                                    required
                                                    checked={formStates.consent.paitientSignature === "no"}
                                                    disabled
                                                />
                                                <label htmlFor="PatientSignatureNo" className="form-check-label" >
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="d-flex">
                                        <input
                                            type="checkbox"
                                            id="Signed"
                                            className="filled-in chk-col-danger"
                                            name={"consentFormAttachment"}
                                            onChange={handleChangeConsentCheckBox}
                                            checked={formStates.consent.consentFormAttachment}
                                            disabled
                                        />
                                        <label htmlFor="Signed">
                                            Signed consent form attached to patient file
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label htmlFor="Counsellor" className="form-label" >
                                            Counsellor name &amp; surname
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Counsellor"
                                            name="counsellorNameAndSurname"
                                            placeholder="Counsellor name & surname"
                                            required
                                            onChange={handleChangeConsent}
                                            value={formStates.consent.counsellorNameAndSurname}
                                            disabled
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            This field is required
                                        </Form.Control.Feedback>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <div className="text-nowrap me-3 mb-md-2">
                                            Counsellor signature :
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap">
                                            <div>
                                                <input
                                                    type="radio"
                                                    name="counsellorSignature"
                                                    id="CounsellorSignatureYes"
                                                    className="filled-in chk-col-danger form-check-input"
                                                    value={"yes"}
                                                    onChange={handleChangeConsent}
                                                    checked={formStates.consent.counsellorSignature.toLowerCase() === "yes"}
                                                    required
                                                    disabled
                                                />
                                                <label htmlFor="CounsellorSignatureYes" className="form-check-label" >
                                                    Yes
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="CounsellorSignatureNo"
                                                    name="counsellorSignature"
                                                    className="filled-in chk-col-danger form-check-input"
                                                    value={"no"}
                                                    onChange={handleChangeConsent}
                                                    required
                                                    checked={formStates.consent.counsellorSignature.toLowerCase() === "no"}
                                                    disabled
                                                />
                                                <label htmlFor="CounsellorSignatureNo" className="form-check-label"> No </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {age && (age >= 10 && age <= 17) && (
                                    <>
                                        <div className="col-12">
                                            <h5 className="mb-0 mt-3">
                                                If patient is between age of  15 to 17 years of age, parent,
                                                guardian or caregiver must sign
                                            </h5>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="Parent/Guardian" className="form-label" >
                                                    Parent/GuardianParent/Guardian Caregiver name &amp; surname
                                                </label>
                                                <input
                                                    type="text"
                                                    name="guardianParentNameAndSurname"
                                                    className="form-control"
                                                    id="Parent/Guardian"
                                                    placeholder="Parent/Guardian Caregiver name & surname"
                                                    value={formStates.consent.guardianParentNameAndSurname}
                                                    onChange={handleChangeConsent}
                                                    required={(age >= 10 && age <= 17) ? true : false}
                                                    disabled
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required
                                                </Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div>
                                                <div className="text-nowrap me-0 mb-2">
                                                    Parent/Guardian Caregiver signature :
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap">
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name="guardianParentSignature"
                                                            id="GurdianPatientSignatureYes"
                                                            className="with-gap radio-col-danger form-check-input"
                                                            value={"yes"}
                                                            onChange={handleChangeConsent}
                                                            required
                                                            checked={formStates.consent.guardianParentSignature.toLowerCase() === "yes"}
                                                            disabled
                                                        />
                                                        <label htmlFor="GurdianPatientSignatureYes" className="form-check-label" >
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id="GurdianPatientSignatureNo"
                                                            name="guardianParentSignature"
                                                            className="with-gap radio-col-danger form-check-input"
                                                            value={"no"}
                                                            checked={formStates.consent.guardianParentSignature.toLowerCase() === "no"}
                                                            onChange={handleChangeConsent}
                                                            required
                                                            disabled
                                                        />
                                                        <label htmlFor="GurdianPatientSignatureNo" className="form-check-label" >
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <input
                                                    type="checkbox"
                                                    id="patient"
                                                    name="parentSignatureAttachment"
                                                    className="filled-in chk-col-danger"
                                                    value={formStates.consent.parentSignatureAttachment}
                                                    onChange={handleChangeConsentCheckBox}
                                                    checked={formStates.consent.parentSignatureAttachment === true}
                                                    disabled
                                                />
                                                <label htmlFor="patient">
                                                    Parent/Guardian/Caregiver signature attached to patient file
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
                {/* procedure */}
                <Accordion.Item eventKey="7">
                    <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("7")}>
                        <div className="acco-box">
                            <p>PROCEDURE</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Form noValidate
                            validated={validated_procedure}
                            className="we-form"
                            onSubmit={submitProcedure}
                        >
                            <div className="d-flex flex-column flex-sm-row mb-3 col-md-6">
                                <div className="d-flex align-items-center text-nowrap me-3">
                                    Procedure Done :
                                </div>
                                <div className="d-flex align-items-center flex-wrap">
                                    <div>
                                        <input
                                            type="radio"
                                            name="procedureDone"
                                            id="ProcedureDoneYes"
                                            className="with-gap radio-col-danger form-check-input"
                                            value={"yes"}
                                            onChange={handleChangeProcedure}
                                            required
                                            disabled
                                            checked={formStates.procedure.procedureDone.toLowerCase() === "yes"}
                                        />
                                        <label htmlFor="ProcedureDoneYes" className="form-check-label" >
                                            Yes
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            id="ProcedureDoneNo"
                                            name="procedureDone"
                                            className="with-gap radio-col-danger form-check-input"
                                            value={"no"}
                                            onChange={handleChangeProcedureNo}
                                            required
                                            disabled
                                            checked={formStates.procedure.procedureDone.toLowerCase() === "no"}
                                        />
                                        <label htmlFor="ProcedureDoneNo" className="form-check-label" >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {formStates.procedure.procedureDone.toLowerCase() === "yes" &&
                                <>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="Service-Delivery mb-3">
                                                <div className="d-flex flex-wrap">
                                                    <div className="me-3">
                                                        <label htmlFor="Surgical"> Surgical method used </label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            name="surgicalMethod"
                                                            type="radio"
                                                            id="Dorsal"
                                                            className="with-gap radio-col-danger form-check-input"
                                                            value="Dorsal"
                                                            required={formStates.procedure.procedureDone === "yes" ? true : false}
                                                            onChange={handleChangeSurgicalMethod}
                                                            checked={formStates.procedure.surgicalMethod.toLowerCase() === "dorsal"}
                                                            disabled
                                                        />
                                                        <label htmlFor="Dorsal">Dorsal slit</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            name="surgicalMethod"
                                                            type="radio"
                                                            id="Sleeve"
                                                            className="with-gap radio-col-danger form-check-input"
                                                            value="Sleeve"
                                                            checked={formStates.procedure.surgicalMethod.toLowerCase() === "sleeve"}
                                                            required={formStates.procedure.procedureDone === "yes" ? true : false}
                                                            onChange={handleChangeSurgicalMethod}
                                                            disabled
                                                        />
                                                        <label htmlFor="Sleeve"> Sleeve resection </label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            name="surgicalMethod"
                                                            type="radio"
                                                            id="DeviceOrAid"
                                                            className="with-gap radio-col-danger form-check-input"
                                                            value="DeviceOrAid"
                                                            required={formStates.procedure.procedureDone === "yes" ? true : false}
                                                            onChange={handleChangeProcedure}
                                                            checked={formStates.procedure.surgicalMethod.toLowerCase() === "deviceoraid"}
                                                            disabled
                                                        />
                                                        <label htmlFor="DeviceOrAid"> Device or Surgical Aid </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            formStates.procedure.surgicalMethod.toLowerCase() === "deviceoraid" && (
                                                <div className="mb-3 col-md-6">
                                                    <label htmlFor="Surgeon" className="form-label" >
                                                        Device / Surgical Aid Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Surgeon"
                                                        placeholder="Device / Surgical Aid Name"
                                                        required
                                                        name="deviceOrAidName"
                                                        value={formStates.procedure.deviceOrAidName}
                                                        onChange={handleChangeProcedure}
                                                        disabled
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="test-box mb-3">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="Surgeon" className="form-label" >
                                                        Surgeon's ("Cutter") Name &amp; Surname:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Surgeon"
                                                        placeholder="Surgeon's ('Cutter') Name & Surname"
                                                        required
                                                        name="surgeonName"
                                                        value={formStates.procedure.surgeonName}
                                                        onChange={handleChangeProcedure}
                                                        disabled
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        This field is required
                                                    </Form.Control.Feedback>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="SurgeonCutter" className="form-label">
                                                        Surgeon's ("Cutter") HPCSA / SANC No:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="SurgeonCutter"
                                                        placeholder="Surgeon's ('Cutter') HPCSA / SANC No"
                                                        required
                                                        name="surgeonHPCSA_SANCNo"
                                                        value={formStates.procedure.surgeonHPCSA_SANCNo}
                                                        onChange={handleChangeProcedure}
                                                        disabled
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        This field is required
                                                    </Form.Control.Feedback>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="d-flex align-items-center text-nowrap mb-2">
                                                    Surgeon's ("Cutter") Signature:
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap">
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name="surgeonSignature"
                                                            id="SurgeonSignatureYes"
                                                            className="with-gap radio-col-danger form-check-input"
                                                            value={"yes"}
                                                            onChange={handleChangeProcedure}
                                                            required
                                                            checked={formStates.procedure.surgeonSignature.toLowerCase() === 'yes'}
                                                            disabled
                                                        />
                                                        <label htmlFor="SurgeonSignatureYes" className="form-check-label" >
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id="SurgeonSignatureNo"
                                                            name="surgeonSignature"
                                                            className="with-gap radio-col-danger form-check-input"
                                                            value={"no"}
                                                            onChange={handleChangeProcedure}
                                                            required
                                                            disabled
                                                            checked={formStates.procedure.surgeonSignature.toLowerCase() === 'no'}
                                                        />
                                                        <label htmlFor="SurgeonSignatureNo" className="form-check-label" >
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}

                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
                {/* marketing surveys */}
                <Accordion.Item eventKey="8">
                    <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("8")}>
                        <div className="acco-box">
                            <p>MARKETING SURVEYS</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Form noValidate validated={validated_marketing} className="we-form" onSubmit={submitMarketingSurvayes}>
                            <div className="row">
                                {/* left section */}
                                <div className="col-xl-7">
                                    <div className="mb-3">
                                        <div className="test-box">
                                            <h6>
                                                Where did patient hear about medical
                                                circumcision? (tick 1 option)
                                            </h6>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="inner_box mb-3">
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-5">
                                                                <input
                                                                    type="radio"
                                                                    id="Mobiliser"
                                                                    className="filled-in chk-col-danger"
                                                                    name="wherePatientHear"
                                                                    onChange={handleChangeMarketing}
                                                                    value={"mobilizerExist"}
                                                                    checked={formStates.marketingSurveys.wherePatientHear === "mobilizerExist"}
                                                                    disabled
                                                                />
                                                                <label htmlFor="Mobiliser">
                                                                    Social Mobiliser (SM)
                                                                </label>
                                                            </div>
                                                            {formStates.marketingSurveys.wherePatientHear === "mobilizerExist" && (
                                                                <div className="col-lg-7">
                                                                    <div className="d-flex align-items-center">
                                                                        <label htmlFor="SMNumber" className="text-nowrap me-2" >
                                                                            SM Number
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className={!formStates.marketingSurveys.smNo && validated_marketing ? "form-control form-control-invalid" : "form-control"}
                                                                            id="SMNumber"
                                                                            placeholder="SM Number"
                                                                            onChange={handleChangeMarketing}
                                                                            name="smNo"
                                                                            value={formStates.marketingSurveys.smNo}
                                                                            required={formStates.marketingSurveys.wherePatientHear === "mobilizerExist" ? true : false}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {formStates.marketingSurveys.wherePatientHear === "mobilizerExist" && (
                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-between align-items-center inner_box mb-3">
                                                            <label htmlFor="SocialMobiliser" className="text-nowrap me-2">
                                                                Social Mobiliser Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={!formStates.marketingSurveys.recruiterName && validated_marketing ? "form-control form-control-invalid" : "form-control"}
                                                                id="SocialMobiliser"
                                                                name="recruiterName"
                                                                placeholder="Social Mobiliser Name"
                                                                onChange={handleChangeMarketing}
                                                                value={formStates.marketingSurveys.recruiterName}
                                                                required={formStates.marketingSurveys.wherePatientHear === "mobilizerExist" ? true : false}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col-md-12">
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4">
                                                            <input
                                                                type="radio"
                                                                id="TV"
                                                                className="filled-in chk-col-danger"
                                                                name="wherePatientHear"
                                                                onChange={handleChangeWherePatientHear}
                                                                value={"tv"}
                                                                checked={formStates.marketingSurveys.wherePatientHear === "tv"}
                                                                disabled
                                                            />
                                                            <label htmlFor="TV">TV</label>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <input
                                                                type="radio"
                                                                id="Moya"
                                                                className="filled-in chk-col-danger"
                                                                name="wherePatientHear"
                                                                onChange={handleChangeWherePatientHear}
                                                                value={"moyaApp"}
                                                                checked={formStates.marketingSurveys.wherePatientHear === "moyaApp"}
                                                                disabled
                                                            />
                                                            <label htmlFor="Moya">
                                                                Moya App
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <input
                                                                type="radio"
                                                                id="Callcentre"
                                                                className="filled-in chk-col-danger"
                                                                name="wherePatientHear"
                                                                onChange={handleChangeWherePatientHear}
                                                                value={"callCentre"}
                                                                checked={formStates.marketingSurveys.wherePatientHear === "callCentre"}
                                                                disabled
                                                            />
                                                            <label htmlFor="Callcentre">
                                                                Call centre
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4">
                                                            <input
                                                                type="radio"
                                                                id="Radio"
                                                                className="filled-in chk-col-danger"
                                                                value={"radio"}
                                                                name="wherePatientHear"
                                                                onChange={handleChangeWherePatientHear}
                                                                checked={formStates.marketingSurveys.wherePatientHear === "radio"}
                                                                disabled
                                                            />
                                                            <label htmlFor="Radio">Radio</label>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <input
                                                                type="radio"
                                                                id="Media"
                                                                className="filled-in chk-col-danger"
                                                                name="wherePatientHear"
                                                                onChange={handleChangeWherePatientHear}
                                                                value={"socialMedia"}
                                                                checked={formStates.marketingSurveys.wherePatientHear === "socialMedia"}
                                                                disabled
                                                            />
                                                            <label htmlFor="Media">
                                                                Social Media
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <input
                                                                type="radio"
                                                                id="Church"
                                                                className="filled-in chk-col-danger"
                                                                name="wherePatientHear"
                                                                onChange={handleChangeWherePatientHear}
                                                                value={"church"}
                                                                checked={formStates.marketingSurveys.wherePatientHear === "church"}
                                                                disabled
                                                            />
                                                            <label htmlFor="Church">
                                                                Church
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4">
                                                            <input
                                                                type="radio"
                                                                id="AmbassadorChampion"
                                                                className="filled-in chk-col-danger"
                                                                value={"ambasadorOrCampaign"}
                                                                name="wherePatientHear"
                                                                onChange={handleChangeWherePatientHear}
                                                                checked={formStates.marketingSurveys.wherePatientHear === "ambasadorOrCampaign"}
                                                                disabled
                                                            />
                                                            <label htmlFor="AmbassadorChampion">
                                                                Ambassador / Champion
                                                            </label>
                                                            {(!formStates.marketingSurveys.wherePatientHear && validated_marketing) &&
                                                                (<div style={{ fontSize: "0.875em", color: "#dc3545", marginTop: "0.25rem" }}>
                                                                    check only one
                                                                </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <input
                                                                type="radio"
                                                                id="Friend"
                                                                className="filled-in chk-col-danger"
                                                                name="wherePatientHear"
                                                                onChange={handleChangeWherePatientHear}
                                                                value={"friendOrFamily"}
                                                                checked={formStates.marketingSurveys.wherePatientHear === "friendOrFamily"}
                                                                disabled
                                                            />
                                                            <label htmlFor="Friend">
                                                                Friend / Family
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <input
                                                                type="radio"
                                                                id="School"
                                                                className="filled-in chk-col-danger"
                                                                name="wherePatientHear"
                                                                onChange={handleChangeWherePatientHear}
                                                                value={"school"}
                                                                checked={formStates.marketingSurveys.wherePatientHear === "school"}
                                                                disabled
                                                            />
                                                            <label htmlFor="School">
                                                                School
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* right section */}
                                <div className="col-xl-5">
                                    <div className="mb-3">
                                        <div className="test-box">
                                            <h6>
                                                Why does patient want to be circumcised? (tick at least 1 option)
                                            </h6>
                                            <div className="col-md-12">
                                                <div className="row mb-3 g-0">
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="checkbox"
                                                            id="Tradition"
                                                            className="filled-in chk-col-danger"
                                                            value={"tradition"}
                                                            onChange={handleChangeWhyCircumcised}
                                                            name="reasonForCircumcised"
                                                            checked={formStates.marketingSurveys.reasonForCircumcised.includes("tradition") || formStates.marketingSurveys.reasonForCircumcised.includes("Tradition / Culture")}
                                                            disabled
                                                        />
                                                        <label htmlFor="Tradition">
                                                            Tradition / Culture
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="checkbox"
                                                            id="riskSTD"
                                                            className="filled-in chk-col-danger"
                                                            value={"reduceRiskSTD"}
                                                            name="reasonForCircumcised"
                                                            onChange={handleChangeWhyCircumcised}
                                                            checked={formStates.marketingSurveys.reasonForCircumcised.includes("reduceRiskSTD") || formStates.marketingSurveys.reasonForCircumcised.includes("Reduce the risk of STD")}
                                                            disabled
                                                        />
                                                        <label htmlFor="riskSTD">
                                                            Reduce the risk of STD
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row mb-3 g-0">
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="checkbox"
                                                            id="cleanliness"
                                                            className="filled-in chk-col-danger"
                                                            value={"hygine"}
                                                            name="reasonForCircumcised"
                                                            onChange={handleChangeWhyCircumcised}
                                                            checked={formStates.marketingSurveys.reasonForCircumcised.includes("hygine") || formStates.marketingSurveys.reasonForCircumcised.includes("Hygiene and cleanliness")}
                                                            disabled
                                                        />
                                                        <label htmlFor="cleanliness">
                                                            Hygiene and cleanliness
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="checkbox"
                                                            id="betterSex"
                                                            className="filled-in chk-col-danger"
                                                            value={"betterSex"}
                                                            name="reasonForCircumcised"
                                                            onChange={handleChangeWhyCircumcised}
                                                            checked={formStates.marketingSurveys.reasonForCircumcised.includes("betterSex") || formStates.marketingSurveys.reasonForCircumcised.includes("Better sex")}
                                                            disabled
                                                        />
                                                        <label htmlFor="betterSex">
                                                            Better sex
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row mb-3 g-0">
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="checkbox"
                                                            id="riskHIV"
                                                            className="filled-in chk-col-danger"
                                                            value={"reduceRiskHIV"}
                                                            name="reasonForCircumcised"
                                                            onChange={handleChangeWhyCircumcised}
                                                            checked={formStates.marketingSurveys.reasonForCircumcised.includes("reduceRiskHIV") || formStates.marketingSurveys.reasonForCircumcised.includes("Reduce the risk of HIV")}
                                                            disabled
                                                        />
                                                        <label htmlFor="riskHIV">
                                                            Reduce the risk of HIV
                                                        </label>
                                                        {(formStates.marketingSurveys.reasonForCircumcised.length === 0 && validated_marketing) &&
                                                            (<div style={{ fontSize: "0.875em", color: "#dc3545", marginTop: "0.25rem" }}>
                                                                Check atleast one
                                                            </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="checkbox"
                                                            id="Religion"
                                                            className="filled-in chk-col-danger"
                                                            value={"religion"}
                                                            name="reasonForCircumcised"
                                                            onChange={handleChangeWhyCircumcised}
                                                            checked={formStates.marketingSurveys.reasonForCircumcised.includes("religion") || formStates.marketingSurveys.reasonForCircumcised.includes("Religion")}
                                                            disabled
                                                        />
                                                        <label htmlFor="Religion">
                                                            Religion
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
                {/* intra-operative observations */}
                <Accordion.Item eventKey="9">
                    <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("9")}>
                        <div className="acco-box">
                            <p>INTRA-OPERATIVE OBSERVATIONS</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Form noValidate
                            validated={validated_intra}
                            className="we-form"
                        >
                            <div className="d-flex flex-column flex-sm-row mb-3 col-md-6">
                                <div className="d-flex align-items-center text-nowrap me-3">
                                    Intra-operative adverse events (tick 1 option)
                                </div>
                                <div className="d-flex align-items-center flex-wrap">
                                    <div>
                                        <input
                                            type="radio"
                                            name="observation"
                                            id="IntraOperativeYes"
                                            className="filled-in chk-col-danger form-check-input"
                                            value={"yes"}
                                            onChange={handleChangeIntra}
                                            required
                                            checked={formStates.intraOperativeObservations.observation === "yes"}
                                            disabled
                                        />
                                        <label htmlFor="IntraOperativeYes" className="form-check-label" >
                                            Yes
                                        </label>
                                        <div className="invalid-feedback">This field is required</div>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            id="IntraOperativeNo"
                                            name="observation"
                                            className="filled-in chk-col-danger form-check-input"
                                            value={"no"}
                                            onChange={handleChangeIntraNo}
                                            checked={formStates.intraOperativeObservations.observation === "no"}
                                            required
                                            disabled
                                        />
                                        <label htmlFor="IntraOperativeNo" className="form-check-label" >
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {formStates.intraOperativeObservations.observation === "yes" && <>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="test-box">
                                            <div className="col-md-12">
                                                <div className="d-flex flex-column flex-sm-row mb-3">
                                                    <strong className="text-nowrap me-3">
                                                        AE Severity:
                                                    </strong>
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                name="aeSeverity"
                                                                id="Mild"
                                                                className="filled-in chk-col-danger form-check-input"
                                                                value={"mild"}
                                                                onChange={handleChangeIntra}
                                                                required={formStates.intraOperativeObservations.observation === "yes" ? true : false}
                                                                checked={formStates.intraOperativeObservations.aeSeverity.toLowerCase() === "mild"}
                                                                disabled
                                                            />
                                                            <label htmlFor="Mild" className="form-check-label" >
                                                                Mild
                                                            </label>
                                                            <div className="invalid-feedback">This field is required</div>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                id="Moderate"
                                                                name="aeSeverity"
                                                                className="filled-in chk-col-danger form-check-input"
                                                                value={"moderate"}
                                                                checked={formStates.intraOperativeObservations.aeSeverity.toLowerCase() === "moderate"}
                                                                onChange={handleChangeIntra}
                                                                required={formStates.intraOperativeObservations.observation === "yes" ? true : false}
                                                                disabled
                                                            />
                                                            <label htmlFor="Moderate" className="form-check-label" >
                                                                Moderate
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                id="Severe"
                                                                name="aeSeverity"
                                                                className="filled-in chk-col-danger form-check-input"
                                                                value={"severe"}
                                                                checked={formStates.intraOperativeObservations.aeSeverity.toLowerCase() === "severe"}
                                                                onChange={handleChangeIntra}
                                                                required={formStates.intraOperativeObservations.observation === "yes" ? true : false}
                                                                disabled
                                                            />
                                                            <label htmlFor="Severe" className="form-check-label" >
                                                                Severe
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="d-flex flex-column flex-sm-row">
                                                    <strong className="text-nowrap me-3">
                                                        AE Type:
                                                    </strong>
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        {
                                                            getAeType?.data?.data?.map((el, i) => (
                                                                <div>
                                                                    <input
                                                                        type="checkbox"
                                                                        name="aeType"
                                                                        id={el.aE_Type_value}
                                                                        className="filled-in chk-col-danger form-check-input"
                                                                        value={el.aE_Type_value}
                                                                        onChange={handleChangeAeType}
                                                                        checked={formStates.intraOperativeObservations.aeType.includes(el.aE_Type_value)}
                                                                        disabled
                                                                    />
                                                                    <label htmlFor={el.aE_Type_value} className="form-check-label" >
                                                                        {el.aE_Type}
                                                                    </label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                {(formStates.intraOperativeObservations.aeType.length === 0 && validated_intra) &&
                                                    (<div style={{ fontSize: "0.875em", color: "#dc3545", marginTop: "0.25rem" }} className="mt-0">
                                                        This field is required
                                                    </div>
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label htmlFor="immediate" className="form-check-label">
                                                        Notes on immediate management of AE
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="immediate"
                                                        placeholder="Notes on immediate management of AE"
                                                        required={formStates.intraOperativeObservations.observation === "yes" ? true : false}
                                                        name="notesOnAe"
                                                        value={formStates.intraOperativeObservations.notesOnAe}
                                                        onChange={handleChangeIntra}
                                                        disabled
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        This field is required
                                                    </Form.Control.Feedback>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}

                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
                {/* Data Validation */}
                <Accordion.Item eventKey="10" className="mb-0">
                    <Accordion.Header className="accordion-header" id="headingOne" onClick={() => setAccordionActiveKey("10")}>
                        <div className="acco-box">
                            <p>Data Validation</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className="row afterConfirmCheck justify-content-start">
                                <div className="col-md-5 col-sm-12">
                                    <div className="form-check ps-0 mt-3 mb-3">
                                        <input
                                            className="filled-in chk-col-danger edit-form edit-form"
                                            type="checkbox"
                                            name="confirmCheck"
                                            id="confirmCheck"
                                            onChange={(event) => DataValidationDispatch({
                                                type: 'SET_CONFIRMATION',
                                                payload: event.target.checked
                                            })}
                                            checked={DataValidationState.confirmation}
                                            disabled={QaType !== 'progress'}
                                        />
                                        <label className="form-check-label" for="confirmCheck">
                                            I confirm the above data been validated to my knowledge.
                                        </label>
                                    </div>
                                    {
                                        DataValidationState.confirmation && (
                                            <div>
                                                <div className="form-check form-check-inline me-2 ps-0 mb-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="validInvalid"
                                                        id="validRadio"
                                                        value="valid"
                                                        onChange={(event) => DataValidationDispatch({
                                                            type: 'SET_STATUS',
                                                            payload: event.target.value
                                                        })}
                                                        checked={DataValidationState.status.toLowerCase() === 'valid'}
                                                        disabled={QaType !== 'progress'}
                                                    />
                                                    <label className="form-check-label me-0" for="validRadio">
                                                        Valid data
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline mx-2 ps-0 mb-0">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="validInvalid"
                                                        id="invalidRadio"
                                                        value="invalid"
                                                        onChange={(event) => DataValidationDispatch({
                                                            type: 'SET_STATUS',
                                                            payload: event.target.value
                                                        })}
                                                        checked={DataValidationState.status.toLowerCase() === 'invalid'}
                                                        disabled={QaType !== 'progress'}
                                                    />
                                                    <label className="form-check-label me-0" for="invalidRadio">
                                                        Invalid data
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        (DataValidationState.confirmation && DataValidationState.status.toLowerCase() === 'invalid') && (
                                            <div class="mt-4 invalidatedTextarea text-start">
                                                <label for="" class="form-label">Reason for rejection</label>
                                                <select name="" id="" className='form-select'
                                                    onChange={(event) => DataValidationDispatch({
                                                        type: 'SET_INVALID_MESSAGE',
                                                        payload: event.target.value
                                                    })}
                                                >
                                                    <option value="">Select Reason</option>
                                                    {
                                                        qaReason?.data?.data?.reasons?.map((el) => {
                                                            return (
                                                                <option value={el.reason}>{el.reason}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                {(DataValidationState.invalidMessage.toLowerCase() === "other") && (
                                                    <textarea textarea
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        rows="5"
                                                        class="form-control mt-3"
                                                        placeholder="Write reason for rejection... "
                                                        onChange={(event) => DataValidationDispatch({
                                                            type: 'SET_INVALID_MESSAGE_OTHERS',
                                                            payload: event.target.value
                                                        })}
                                                        value={DataValidationState.invalidMessageOther}
                                                        required={DataValidationState.invalidMessage.toLowerCase() === "other" ? true : false}
                                                    ></textarea>
                                                )}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                QaType === 'progress' && (
                                    DataValidationState.status !== '' && (
                                        <div className="text-start mt-4">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    )
                                )
                            }
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}

export default ViewQAClient