import React from 'react';
import BillingLayout from '../BillingLayout';
import { Link } from 'react-router-dom';
import { useViewDueAmountQuery, useViewPendingAmountQuery } from '../../../store/api/billing/billingApi';
import LoadingErrorComponent from '../../../components/LoadingErrorComponent/LoadingErrorComponent';

const BillingDashboard = () => {
    const dueAmount = useViewDueAmountQuery()
    const pendingAmount = useViewPendingAmountQuery()

    return (
        <BillingLayout>
            <div class="content-header">
                <div class="d-flex align-items-center">
                    <div class="me-auto">
                        <div class="d-inline-block align-items-center">
                            <nav>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <Link to=""><i class="mdi mdi-home-outline"></i></Link>
                                    </li>
                                    <li class="breadcrumb-item" aria-current="page">Billing & Invoices</li>
                                    <li class="breadcrumb-item active" aria-current="page">Billing Dashboard</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                </div>
            </div>
            <section class="content">
                {dueAmount.isError && pendingAmount.isError && <LoadingErrorComponent error />}
                {dueAmount.isLoading && pendingAmount.isLoading && <LoadingErrorComponent loading />}
                {dueAmount.isSuccess && pendingAmount.isSuccess && <div class="row">
                    <div class="col-xl-6 col-12">
                        <div class="box">
                            <div class="box-header">
                                <h4 class="box-title">Amount Due</h4>
                            </div>
                            <Link style={{ cursor: "pointer" }} to="/billing/dashboard/amountDue">
                                <div class="box-body">
                                    <h5>Amount due from statement</h5>
                                    <h4>ZAR {dueAmount?.data?.data?.totalAmount || 0}</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div class="col-xl-6 col-12">
                        <div class="box">
                            <div class="box-header">
                                <h4 class="box-title">Estimated Amount Pending</h4>
                            </div>
                            <Link style={{ cursor: "pointer" }} to="/billing/dashboard/amountProgress">
                                <div class="box-body">
                                    <h5>Amount in Progress</h5>
                                    <h4>ZAR {pendingAmount?.data?.data?.totalAmount || 0}</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>}
            </section>
        </BillingLayout>
    )
}

export default BillingDashboard;
