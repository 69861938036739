import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const { user } = useSelector((store) => {
    return store.userSlice;
  });
  const authenticate = user.token?.length > 0 ? true : false;

  if (!authenticate) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default PrivateRoute;
