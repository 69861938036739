import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const QaTwo = createApi({
    reducerPath: "QATwo",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            return headers.set('Authorization', `Bearer ${userSlice.user.token}`);
        },
    }),
    tagTypes: ["QATwoBatches", "QATwoRecordsInBatch"],
    endpoints: (builder) => ({
        getBatches: builder.query({
            query: () => {
                return {
                    url: "/QA2/ViewQABatches",
                    method: "GET",
                }
            },
            invalidatesTags: ['QATwoRecordsInBatch']
        }),
        getRecordsInBatch: builder.query({
            query: (data) => {
                return {
                    url: "/QA2/ViewRecordsInBatch",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ['QATwoRecordsInBatch']
        }),
        getClientByID: builder.mutation({
            query: (data) => {
                console.log(data)
                return {
                    url: `/QA2/GetClientByID`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["QATwoRecordsInBatch"],
        }),
        qaTwoVerifyRecord: builder.mutation({
            query: (data) => {
                return {
                    url: `/QA2/VerifyRecord`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["QATwoRecordsInBatch"],
        }),
        SubmitBatch: builder.mutation({
            query: (data) => {
                return {
                    url: `/QA2/SubmitBatch`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["QATwoRecordsInBatch"],
        })
    }),
});

export const { 
    useGetBatchesQuery,
    useGetRecordsInBatchQuery,
    useGetClientByIDMutation,
    useSubmitBatchMutation,
    useQaTwoVerifyRecordMutation
} = QaTwo;