import React from 'react'
import GoBackWrapper from '../../../components/wrapper/GoBackWrapper/GoBackWrapper'
import RegistrationForm from '../../../components/auth/Registration/Registration'
import { Link } from 'react-router-dom'

function Registration() {
  return (
    <GoBackWrapper>
      <div className="auth_container py-45" style={{overflowY: 'auto', overflowX: 'hidden', display: 'block'}}>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="bg-white rounded10 shadow-lg p-40">
              <div class="content-top-agile mb-30">
                <img src="/static/media/logo.8cee97cc5a379525cf9c.png" class="w-120" alt="Your Logo" />
                <h3 class="text-dark fw-bold mt-3 mb-0">Welcome! Please Register</h3>
              </div>
              <RegistrationForm/>
            </div>
            <div className="text-center mt-40 h5">
              Go back to the <Link to="/login">Login page</Link>
            </div>
          </div>
        </div>
      </div>
    </GoBackWrapper>
  )
}

export default Registration