import React from 'react'
import ReceptionLayout from '../../../ReceptionLayout'
import { useNavigate } from 'react-router-dom'

const ApprovedBatchView = () => {
    const navigate = useNavigate()
    return (
        <ReceptionLayout>
            <div className="content-header">
                <div className="d-flex align-items-center">
                    <div className="me-auto">
                        <div className="d-inline-block align-items-center">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="#"><i className="mdi mdi-home-outline"></i></a>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Medical Form</li>
                                    <li className="breadcrumb-item active" aria-current="page">Manage Batches</li>
                                    <li className="breadcrumb-item active" aria-current="page">Approved Batch</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="box">
                    <div className="box-header with-border">
                        <div className="box-in">
                            <button onClick={() => navigate(-1)} className='btn btn-danger-light'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-left"
                                >
                                    <line x1={19} y1={12} x2={5} y2={12} />
                                    <polyline points="12 19 5 12 12 5" />
                                </svg>
                            </button>
                            <h4 className="box-title me-auto ms-4">

                                <strong>Approved Batch -  GP2024041910</strong>
                            </h4>
                        </div>
                    </div>
                    <div className="box-body">
                        <div className="row mb-3 g-2 justify-content-between">
                            <div className="col-sm-12 col-md-2">
                                <select className="form-select mb-xl-0 mb-2"
                                    aria-label="Default select example">
                                    <option selected>Sort by</option>
                                    <option value="1">Latest to First</option>
                                    <option value="2">First to Latest</option>
                                    <option value="3">South African</option>
                                    <option value="4">Non South African</option>
                                </select>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="table-top-search">
                                    <select className="form-select" aria-label="Default select example">
                                        <option selected>Search by</option>
                                        <option value="1">Client ID</option>
                                        <option value="2">Client Name</option>
                                        <option value="3">Booking ID</option>
                                        <option value="4">Identity Number</option>
                                        <option value="4">Contact number</option>
                                        <option value="4">Folder number</option>
                                    </select>
                                    <input type="search" className="form-control" placeholder="Search" aria-controls="complex_header" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <p className='mb-2'>
                                        <span className="fw-bold me-2">Invoice Number -</span> INV123456
                                        <span className="fw-bold ms-2">Invoice Date -</span> 2024-05-06
                                        <span className="fw-bold ms-2">Invoice Amount</span> - 4000 ZAR
                                    </p>
                                    <p className="mb-0">
                                        <span className="fw-bold me-2">Statement -</span>
                                        Total 10 records
                                        <span className="fw-bold text-success ms-2">5 valid</span>
                                        <span className="fw-bold text-danger ms-2">5 invalid</span>
                                    </p>
                                </div>
                                <div className="text-end">
                                    <button type="button" className="btn btn-primary deleteClient d-none">Delete</button>
                                </div>
                                <div className="table-responsive rounded card-table">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Sr.</th>
                                                <th>Capture Date</th>
                                                <th>CDC Barcode No.</th>
                                                <th>Client Name</th>
                                                {/* <th>Client ID</th> */}
                                                <th>Verification ID</th>
                                                <th>Folder Number</th>
                                                <th>QA 1</th>
                                                <th>QA 2</th>
                                                <th>Validation Status</th>
                                                <th>Action Items</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="hover-primary">
                                                <td>1</td>
                                                <td>2024/04/12</td>
                                                <td>CDC123409876</td>
                                                <td>KOKIS SEKELE</td>
                                                {/* <td>226</td> */}
                                                <td><div>South African ID</div><div>199805130157083</div></td>
                                                <td>F256245</td>
                                                <td>
                                                    <span className="fs-14 badge badge-success-light">
                                                        <i className="fa fa-check"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    {/* <span className="fs-14 badge badge-danger-light">
                                                        <i className="fa fa-times"></i>
                                                    </span> */}
                                                </td>
                                                <td>
                                                    <span className="badge badge-success-light">Valid</span>
                                                </td>
                                                <td>
                                                    <div className="btn-group">
                                                        <a href="view_rejected_client_details.html" className="hover-primary dropdown-toggle no-caret btn-more px-2">
                                                            <i className="fa fa-eye"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="hover-primary">
                                                <td>2</td>
                                                <td>2024/04/12</td>
                                                <td>CDC123409876</td>
                                                <td>KOKIS SEKELE</td>
                                                <td>226</td>
                                                <td><div>South African ID</div><div>199805130157083</div></td>
                                                <td>F256245</td>
                                                <td>
                                                    <span className="fs-14 badge badge-success-light">
                                                        <i className="fa fa-check"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    {/* <span className="fs-14 badge badge-danger-light">
                                                        <i className="fa fa-times"></i>
                                                    </span> */}
                                                </td>
                                                <td>
                                                    <span className="badge badge-danger-light">Invalid</span>
                                                </td>
                                                <td>
                                                    <div className="btn-group">
                                                        <a href="view_rejected_client_details.html" className="hover-primary dropdown-toggle no-caret btn-more px-2">
                                                            <i className="fa fa-eye"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="hover-primary">
                                                <td>1</td>
                                                <td>2024/04/12</td>
                                                <td>CDC123409876</td>
                                                <td>KOKIS SEKELE</td>
                                                <td>226</td>
                                                <td><div>South African ID</div><div>199805130157083</div></td>
                                                <td>F256245</td>
                                                <td>
                                                    <span className="fs-14 badge badge-success-light">
                                                        <i className="fa fa-check"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    {/* <span className="fs-14 badge badge-danger-light">
                                                        <i className="fa fa-times"></i>
                                                    </span> */}
                                                </td>
                                                <td>
                                                    <span className="badge badge-success-light">Valid</span>
                                                </td>
                                                <td>
                                                    <div className="btn-group">
                                                        <a href="view_rejected_client_details.html" className="hover-primary dropdown-toggle no-caret btn-more px-2">
                                                            <i className="fa fa-eye"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="hover-primary">
                                                <td>2</td>
                                                <td>2024/04/12</td>
                                                <td>CDC123409876</td>
                                                <td>KOKIS SEKELE</td>
                                                <td>226</td>
                                                <td><div>South African ID</div><div>199805130157083</div></td>
                                                <td>F256245</td>
                                                <td>
                                                    <span className="fs-14 badge badge-success-light">
                                                        <i className="fa fa-check"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    {/* <span className="fs-14 badge badge-danger-light">
                                                        <i className="fa fa-times"></i>
                                                    </span> */}
                                                </td>
                                                <td>
                                                    <span className="badge badge-danger-light">Invalid</span>
                                                </td>
                                                <td>
                                                    <div className="btn-group">
                                                        <a href="view_rejected_client_details.html" className="hover-primary dropdown-toggle no-caret btn-more px-2">
                                                            <i className="fa fa-eye"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ReceptionLayout>
    )
}

export default ApprovedBatchView;
