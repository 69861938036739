import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const batchApi = createApi({
    reducerPath: "batchApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            return headers.set('Authorization', `Bearer ${userSlice.user.token}`);
        },
    }),
    tagTypes: ["batch"],
    endpoints: (builder) => ({
        // create batch
        createBatch: builder.mutation({
            query: () => {
                return {
                    url: `/MedicalForm/CreateBatch`,
                    method: "POST",
                };
            },
            invalidatesTags: ["batch"],
        }),
        // clent list who are not in any batch
        clientList: builder.query({
            query: () => {
                return {
                    url: "/Client/BatchLookup",
                    method: "GET",
                }
            },
            providesTags: ["batch"],
        }),
        addToBatch: builder.mutation({
            query: (data) => {
                return {
                    url: `/MedicalForm/AddToBatch`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["batch"],
        }),
        getBatches: builder.query({
            query: () => {
                return {
                    url: "/MedicalForm/GetBatches",
                    method: "GET",
                }
            },
            providesTags: ["batch"],
        }),
        viewBatch: builder.query({
            query: (data) => {
                return {
                    url: "/MedicalForm/ViewBatch",
                    method: "GET",
                    params: data
                }
            },
            providesTags: ["batch"],
        }),
        submitBatch: builder.mutation({
            query: (data) => {
                return {
                    url: `/MedicalForm/EditBatch`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["batch"],
        }),
    }),
});

export const { 
    useGetBatchesQuery, 
    useViewBatchQuery, 
    useCreateBatchMutation, 
    useClientListQuery, 
    useAddToBatchMutation, 
    useSubmitBatchMutation 
} = batchApi;
